import React from 'react'
import ReactTooltip from 'react-tooltip'

interface Props {
  fieldValue: string
  translation: string
  t: (key, params?) => any
}

class TranslationText extends React.Component<Props, any> {

  renderPersonnelTranslation = (translation) => {

    if (translation) {
      return (
        <div>
          <label className='translation-text__label'>{this.props.t('translation.title')}: </label>
          <span>{translation && translation.value}</span>
        </div>
      )
    }
  }

  renderExclamation = (translation) => {

    if (translation && translation.isOutdated) {
      return (
        <i
          className='fa fa-exclamation-triangle'
          aria-hidden='true'
          data-tip={this.props.t('translation.updatedAfterTranslation')}>
          <ReactTooltip place='top' type='dark' effect='float'/>
        </i>
      )
    }
  }

  render() {

    const {fieldValue, translation} = this.props

    return (
      <div className='translation-text'>
        <div className='translation-text__value'>
          {fieldValue}
          {this.renderExclamation(translation)}
        </div>
        {this.renderPersonnelTranslation(translation)}
      </div>
    )
  }
}

export default TranslationText
