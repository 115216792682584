import React from 'react'
import {Component} from 'react'
import Loader from 'react-loader'
import {Router} from 'react-router'

import I18n, {reload} from '../services/I18n'

import {connect, Provider} from 'react-redux'
import {initialize, teardown} from '../modules/App'
import LocalizationsModule from '../modules/Localizations'
import App from '../models/App'
import Localizations from '../models/Localizations'
import User from '../models/User'

export interface Props {
  history: any
  routes: any
  store: any

  app: App
  authenticatedUser: User
  localizations: Localizations
  initialize: () => any
  teardown: () => any
  getLocalizations: (queryParams?, reset?) => any
}

interface State {
  languageChanged: boolean
  initialLocalizationsLoadOnce: boolean
  initialLocalizationsLoaded: boolean
}

class AppContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      languageChanged: false,
      initialLocalizationsLoadOnce: false,
      initialLocalizationsLoaded: false
    }
  }

  componentDidMount() {
    I18n.on('languageChanged', this.onLanguageChanged)
    this.props.initialize()
  }

  componentWillUnmount() {
    this.props.teardown()
  }

  componentDidUpdate(prevProps) {
    const {authenticatedUser, localizations, getLocalizations, app} = this.props
    const {authenticated, selectedSiteStudyId} = authenticatedUser
    const selectedSiteStudyIdChanged =
      selectedSiteStudyId !== prevProps.authenticatedUser.selectedSiteStudyId

    if (authenticated && app.initialized) {
      const {
        languageChanged,
        initialLocalizationsLoadOnce,
        initialLocalizationsLoaded
      } = this.state

      const shouldLoadLocalizations =
        !initialLocalizationsLoadOnce || selectedSiteStudyIdChanged || languageChanged
      const localizationsLoaded = prevProps.localizations.isLoading && !localizations.isLoading

      if (shouldLoadLocalizations) {
        if (languageChanged) {
          this.setState({languageChanged: false})
        }

        if (!initialLocalizationsLoadOnce) {
          this.setState({initialLocalizationsLoadOnce: true})
        }

        getLocalizations({language: I18n.language}, true)
      } else if (localizationsLoaded) {
        this.setupLocalizations(localizations)

        if (!initialLocalizationsLoaded) {
          this.setState({initialLocalizationsLoaded: true})
        } else {
          this.forceUpdate()
        }
      }
    }

    if (!app.initialized && !app.initializing) {
      this.props.initialize()
    }
  }

  setupLocalizations = localizations => {
    reload(I18n.language)

    localizations.list
      .filter(localization => localization.language === I18n.language)
      .forEach(localization => {
        I18n.addResourceBundle(
          localization.language,
          localization.namespace,
          localization.bundle,
          true,
          true
        )
      })
  }

  onLanguageChanged = _lng => this.setState({languageChanged: true})

  render() {
    const {authenticatedUser, app, history, routes, store} = this.props
    const isLoading =
      (authenticatedUser.authenticated && !this.state.initialLocalizationsLoaded) ||
      (!app.initialized && app.initializing)

    if (isLoading) {
      return (
        <div style={{height: '100%'}}>
          <Loader loaded={false} width={12} radius={35}/>
        </div>
      )
    }

    return (
      <Provider store={store}>
        <Router history={history} children={routes}/>
      </Provider>
    )
  }
}

const mapActionCreators = {
  initialize,
  teardown,
  getLocalizations: LocalizationsModule.getModels
}

const mapStateToProps = ({authenticatedUser, app, localizations}, _ownProps) => {
  return {authenticatedUser, app, localizations}
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(AppContainer)
