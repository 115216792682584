import React from 'react'
import {Component} from 'react'
import {getPeriodValue} from '../../../../components/CommonFilterValueFunctions'

interface Props {
  data: any
  t?: (key: string, params?: object) => any
}

interface State {}

export default class PeriodColumn extends Component<Props, State> {
  render() {
    const {data, t} = this.props

    return <span>{getPeriodValue(data, null, null, t)}</span>
  }
}
