/**
 * Created by Mauritz Untamala on 18/02/16.
 */
import React from 'react'
import SiteStudyDetails from './SiteStudyDetails'
import ModelView, {BaseProps, BaseState} from '../ModelView'
import SaveButton from '../SaveButton'

import changeCase from 'change-case'

import {connect} from 'react-redux'
import SiteStudyModule from '../../modules/SiteStudy'
import {navigate} from '../../modules/Location'
import {getAccessRights} from '../../modules/AccessRights'
import SiteStudyModel from '../../models/SiteStudy'

interface Props extends BaseProps<SiteStudyModel> {
  siteId: number
  isNew: boolean
}

interface State extends BaseState<SiteStudyModel> {
}

class SiteStudy extends ModelView<SiteStudyModel, Props, State> {
  constructor(props: Props) {
    super(props)

    let {isNew, siteId, model} = props
    model = isNew ? model.set('siteId', siteId) as SiteStudyModel : model

    this.state = Object.assign({}, this.state, {model})
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    const {isNew, model, siteId} = this.props

    if (isNew
      && (prevProps.model.id !== model.id || prevProps.model !== model)
      && !model.error
      && !model.isSaving) {
      this.setState({model: model.set('siteId', siteId) as SiteStudyModel})
    }
  }

  getContent() {
    const {model} = this.state
    const {accessRights, modelName, t} = this.props

    return [
      (
        <SiteStudyDetails t={t}
                          key='site-study-details'
                          siteStudy={model}
                          onChange={this.updateModel}
                          accessRights={accessRights}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      )
    ]
  }
}

const mapActionCreators = {
  getModel: SiteStudyModule.getModel,
  saveModel: SiteStudyModule.saveModel,
  resetModel: SiteStudyModule.resetModel,
  getAccessRights,
  navigate
}

const mapStateToProps = ({siteStudy, accessRights}, _ownProps) => ({
  model: siteStudy,
  accessRights
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelId: ownProps.siteStudyId,
    modelName: 'siteStudy',
    backHref: '/admin/site/' + ownProps.siteId,
    forceBackHref: true,
    editUrl: '/admin/site/' + ownProps.siteId + '/study'
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps)
}

export default connect(
  mapStateToProps,
  mapActionCreators,
  mergeProps
)(SiteStudy)
