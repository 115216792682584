import React from 'react'
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView'
import SaveButton from '../../../components/SaveButton'

import {withNamespaces} from 'react-i18next'
import changeCase from 'change-case'

import {connect} from 'react-redux'
import {navigate, navigateBack} from '../../../modules/Location'
import PeriodModule from '../../../modules/Period'
import PeriodsModule from '../../../modules/Periods'
import {getAccessRights} from '../../../modules/AccessRights'
import SiteStudyModule from '../../../modules/SiteStudy'
import StudyModule from '../../../modules/Study'
import Period from '../../../models/Period'
import Periods from '../../../models/Periods'
import User from '../../../models/User'
import Study from '../../../models/Study'

import SiteStudy from '../../../models/SiteStudy'
import '../Periods/Periods.less'
import Tabs from '../../../components/Tabs'
import PeriodTranslations from './components/PeriodTranslations'
import PeriodForm from './components/PeriodForm'
import {stringify} from 'querystring'
import _ from 'lodash'
import classNames from 'classnames'
import I18n from '../../../services/I18n'

interface Props extends BaseProps<Period> {
  authenticatedUser: User
  getPeriods: () => any
  getStudy: (id, queryParams?) => any
  getSiteStudy: (id, queryParams?) => any
  location: any
  tab: string
  siteStudy: SiteStudy
  study: Study
  periods: Periods
  defaultLanguage: string
  additionalLanguages: string[]
  getAccessRights(objectName, object)
}

interface State extends BaseState<Period> {
  showError: boolean
  validationErrors: any
  prevPeriod: Period
  nextPeriod: Period
}

const formId = 'form'
const translationsTabId = 'translations'
const tabsIds = [formId, translationsTabId]

class PeriodView extends ModelView<Period, Props, State> {

  constructor(props) {
    super(props)

    const {periods, model} = props
    const nextPeriod = periods.findNext(model.order)
    const prevPeriod = periods.findPrev(model.order)

    this.state = {
      ...this.state,
      showError: false,
      validationErrors: undefined,
      nextPeriod,
      prevPeriod
    }
  }

  componentDidMount() {
    super.componentDidMount()

    const {
      authenticatedUser,
      getSiteStudy,
      getPeriods
    } = this.props

    getSiteStudy(authenticatedUser.selectedSiteStudyId)
    getPeriods()
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    const {siteStudy, periods, getStudy, study} = this.props
    const {model, showError} = this.state
    const validationErrors = model.validateByLanguages(study.languages.toArray())

    if (showError && _.isEmpty(validationErrors)) {
      this.setState({showError: false})
    }

    if (prevState.model.isSaving && !model.isSaving) {
      this.onModelSaved()
    }

    if (!model.id && model.studyId !== siteStudy.studyId) {
      this.updateModel(model.set('studyId', siteStudy.studyId) as Period)
    }

    if (prevState.model !== model) {
      this.setState({validationErrors, showError: !!(validationErrors && model.id)})
    }

    if (!!siteStudy.studyId && (!study.isLoading && study.id !== siteStudy.studyId)) {
      getStudy(siteStudy.studyId)
    }

    if (prevProps.periods !== periods || prevState.model.id !== model.id) {
      const nextPeriod = periods.findNext(model.order)
      const prevPeriod = periods.findPrev(model.order)
      this.setState({nextPeriod, prevPeriod})
    }
  }

  goToPeriod = (id: number) => () => {
    const {tab, navigate, t} = this.props
    const params = tab ? `?tab=${tab}` : ''

    if (this.isFormDirty() && !confirm(t('confirm.unsavedEntries'))) {
      return
    }

    navigate(`/admin/period/${id}${params}`)
  }

  renderPeriodNavigationLink = (period: Period, classNameArray: string[]) => {

    const className = classNames(classNameArray)

    if (!period) {
      return <span className={className}/>
    }

    return (
      <span className={className} onClick={this.goToPeriod(period.id)}>
        {period.getName()}
      </span>
    )
  }

  /**
   * @override
   */
  getTitle() {

    const {model} = this.state
    const {modelName, t} = this.props
    const {prevPeriod, nextPeriod, validationErrors} = this.state

    return (
      <React.Fragment>
        <div className='period-editor-navbar'>
          <div className='period-navbar-container'>
            <div className='period-navbar'>
              {this.renderPeriodNavigationLink(prevPeriod, ['prev-period'])}

              <SaveButton
                key='period-update-button'
                model={model}
                accessRightsKey={changeCase.paramCase(modelName)}
                save={this.save}
                onModelSaved={this.onModelSaved}
                enabled={!validationErrors}
                t={t}
              />

              {this.renderPeriodNavigationLink(nextPeriod, ['next-period'])}
            </div>
          </div>
        </div>

        <div className='period-editor-title'>
          <h1>{t('period.title')}</h1>
        </div>
      </React.Fragment>
    )
  }

  /**
   * @override
   */
  updateModel = (model: Period) => {
    const {defaultLanguage, additionalLanguages} = this.props
    this.setState({model: model.cleanupTranslations(defaultLanguage, additionalLanguages)})
  }

  getActiveTab = () => {
    const {tab} = this.props
    return tab ? tab : tabsIds[0]
  }

  onTabClick = (tab, event) => {

    event.preventDefault()

    const {navigate, modelId} = this.props
    const target = modelId ? modelId : 'new'
    const query = stringify({tab})

    navigate(`/admin/period/${target}?${query}`)
  }

  getTabComponent = activeTab => {

    const {
      authenticatedUser,
      location,
      navigate,
      modelName,
      accessRights,
      t,
      study,
      defaultLanguage
    } = this.props

    const {model, validationErrors, showError} = this.state

    switch (activeTab) {
      case formId:

        return (
          <PeriodForm authenticatedUser={authenticatedUser}
                      study={study}
                      updateModel={this.updateModel}
                      defaultLanguage={defaultLanguage}
                      location={location}
                      navigate={navigate}
                      model={model}
                      modelName={modelName}
                      accessRights={accessRights}
                      validationErrors={validationErrors}
                      showError={showError}
                      t={t}/>
        )
      case translationsTabId:
        return (
          <PeriodTranslations updatePeriod={this.updateModel}
                              defaultLanguage={defaultLanguage}
                              period={model}
                              study={study}
                              t={t}/>
        )
      default:
        return null
    }
  }

  getTabIdsWithError = () => {

    const {validationErrors, showError} = this.state
    const {additionalLanguages} = this.props

    if (!showError || _.isEmpty(validationErrors)) {
      return undefined
    }

    const additionalLanguageError = Object.keys(validationErrors)
      .some(errorKey => {

        const testErrorKey = (lang) => new RegExp(`.*\.${lang}(\..*|$)`).test(errorKey)

        return additionalLanguages.some(testErrorKey)
      })

    return additionalLanguageError ? [translationsTabId] : undefined
  }

  getTabContent = () => {

    const activeTab = this.getActiveTab()

    return (
      <Tabs
        key={'tab-' + activeTab}
        activeTab={activeTab}
        onTabClick={this.onTabClick}
        context='period.tabs'
        tabIds={tabsIds}
        tabIdsWithError={this.getTabIdsWithError()}>
        {this.getTabComponent(activeTab)}
      </Tabs>
    )
  }

  getContent() {

    return this.getTabContent()
  }
}

const mapStateToProps = (
  {accessRights, authenticatedUser, periods, period, siteStudy, study},
  ownProps
) => {
  const {location: {query: {tab}}, params: {id}} = ownProps
  const periodId = id ? parseInt(id, 10) : undefined
  const forceBackHref = true
  const navigateBackOnSaved = false
  const backHref = '/admin/periods'
  const editUrl = `/admin/period`

  const languages = study.languages.toArray()
  const defaultLanguage = study.hasLanguage(I18n.language) ? I18n.language : _.first(languages)
  const additionalLanguages = languages.filter(l => l !== defaultLanguage)

  return {
    siteStudy,
    study,
    accessRights,
    authenticatedUser,
    modelId: periodId,
    model: period,
    periods,
    wrapperClass: 'period-management-view period-editor',
    modelName: 'period',
    forceBackHref,
    backHref,
    editUrl,
    navigateBackOnSaved,
    tab,
    defaultLanguage,
    additionalLanguages
  }
}

const mapActionToProps = {
  getModel: PeriodModule.getModel,
  resetModel: PeriodModule.resetModel,
  saveModel: PeriodModule.saveModel,
  navigate,
  navigateBack,
  getAccessRights,
  getPeriods: PeriodsModule.getModels,
  getStudy: StudyModule.getModel,
  getSiteStudy: SiteStudyModule.getModel
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(PeriodView))