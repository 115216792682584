import Messages from '../models/Messages'
import ModelsModule from './ModelsModule'
import {add} from './ActionsCommon'
import {bind} from '../util'
import Message from './Message'
import moment from 'moment'
import {List} from 'immutable'

const initialState = new Messages()

class MessagesModule extends ModelsModule {
  constructor() {
    super('message', initialState)
    bind(this, this.fetchDedicatedMessages, this.setMessageConsumed)
  }

  public fetchDedicatedMessages(dedicatedSubjectId, replyRole) {
    return this.getModels({dedicatedSubjectId, replyRole}, true)
  }

  public setMessageConsumed(ids) {
    return (dispatch, getState) => {
      const messages = getState().messages.getModelsByIds(ids)

      return dispatch(add('message', 'consume', undefined, {ids}))
        .then(() =>
          dispatch(this.getModelsSuccessAction(
            {result: messages.forEach(message => message.set('consumedTimestamp', moment()).toJS())}
            )
          ))
        .catch((error) => dispatch(this.handleError(this.getModelsFailedAction, error)))
    }
  }

  protected getAdditionalActionHandlers() {
    return {
      [Message.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Message.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Message.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id),
      [this.resetModelsType]: (state) => state.set('list', List())
    }
  }
}

export default new MessagesModule()
