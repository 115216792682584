import ActivityTrackers from '../models/ActivityTrackers'
import ModelsModule from './ModelsModule'
import ActivityTracker from './ActivityTracker'

const initialState = new ActivityTrackers()

export class ActivityTrackersModule extends ModelsModule {
  constructor() {
    super('tracker', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [ActivityTracker.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [ActivityTracker.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [ActivityTracker.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new ActivityTrackersModule()
