/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import GroupsColumn from './components/GroupsColumn'
import AddReminderBox from './components/AddReminderBox'
import ActionsColumn from './components/ActionsColumn'
import ConsumedColumn from './components/ConsumedColumn'
import TableComponent from '../../../components/TableComponent'

import _ from 'lodash'
import moment from 'moment'
import {withNamespaces} from 'react-i18next'
import {getStringValue} from '../../../util'
import {DATE_FORMAT_LONG} from '../../../config/constants'

import {connect} from 'react-redux'
import ReminderModule from '../../../modules/Reminder'
import RemindersModule from '../../../modules/Reminders'
import UsersModule from '../../../modules/Users'
import StudyGroupsModule from '../../../modules/StudyGroups'
import SmssModule from '../../../modules/Smss'
import CriteriaModel from '../../../models/Criteria'
import {UIAction} from '../../../models/UIAction'
import {SmsType} from '../../../models/SmsType'
import App from '../../../models/App'
import User from '../../../models/User'
import Users from '../../../models/Users'
import Smss from '../../../models/Smss'
import StudyGroups from '../../../models/StudyGroups'
import RemindersModel from '../../../models/Reminders'

import './Reminders.less'

let reminderPollInterval

interface Props {
  app: App
  user: User
  users: Users
  reminders: RemindersModel
  smss: Smss
  studyGroups: StudyGroups
  getReminders: (queryParams?, reset?) => any
  getStudyGroups: (queryParams?, reset?) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  getSmssOfType: (smsType?: SmsType) => any
  saveReminder: (reminder, pathComponents?, queryParams?) => any
  removeReminder: (reminder) => any
  t: (key, params?) => any
}

interface State {}

export class Reminders extends PureComponent<Props, State> {
  componentDidMount() {
    this.startPoll()
    this.fetchData()
  }

  componentWillUnmount() {
    this.stopPoll()
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props

    if (prevProps.user !== user && !user.isSaving && !user.isLoading) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const {user, getReminders, getStudyGroups, getUsers, getSmssOfType} = this.props

    getReminders()
    getStudyGroups()
    getUsers(null, null, null, user.selectedSiteStudyId)
    getSmssOfType(SmsType.REMINDER)
  }

  startPoll = () => {
    if (reminderPollInterval) {
      clearInterval(reminderPollInterval)
    }

    reminderPollInterval = setInterval(() => {
      this.props.getReminders()
      this.props.getSmssOfType(SmsType.REMINDER)
    }, 30 * 1000)
  }

  stopPoll = () => clearInterval(reminderPollInterval)

  getResults = () => {
    return this.props.reminders.list
      .map(function(reminder) {
        let reminderJS = reminder.toJS()

        reminderJS.groupIds = _.compact(_.uniq(_.map(reminderJS.receivers, 'groupId')))
        reminderJS.personIds = _.compact(_.uniq(_.map(reminderJS.receivers, 'personId')))

        return _.extend(reminderJS, {_src: reminder})
      })
      .toArray()
  }

  getSmsResults = () =>
    this.props.smss.list.map(sms => _.extend(sms.toJS(), {_src: sms})).toArray()

  renderPersonColumn = props => {
    const persons = props.data.map(personId => {
      const person = this.props.users.getModelById(personId)

      if (!person) {
        return ''
      }

      const visibleName = person.visibleName()
      const id = person.id || this.props.t('unknown')

      return (
        <span key={'person-' + id} className='badge'>
          {id} : {visibleName}
        </span>
      )
    })

    return <div className='persons'>{persons}</div>
  }

  getGroupsValue = groupIds => {
    const {studyGroups, t} = this.props

    if (!_.isArray(groupIds)) {
      return ''
    }

    return groupIds
      .map(groupId => {
        const group = studyGroups.getModelById(groupId)

        return group ? group.name : t('unknown')
      })
      .join(' , ')
  }

  renderSentTimeColumn = props => {
    const timestamp = props.data ? moment(props.data).format(DATE_FORMAT_LONG) : ''

    return <div className='sendTime'>{timestamp}</div>
  }

  getTimestampValue = value =>
    value ? moment(value).format(DATE_FORMAT_LONG) : this.props.t('unknown')

  renderSentTimestampColumn = props => (
    <div className='sendTime'>{this.getTimestampValue(props.data)}</div>
  )

  getPersonsValue = persons => {
    if (!_.isArray(persons)) {
      return ''
    }

    return persons
      .map(personId => {
        const person = this.props.users.getModelById(personId)

        if (!person) {
          return ''
        }

        const visibleName = person.visibleName()
        const id = person.id || this.props.t('unknown')

        return id + ' : ' + visibleName
      })
      .join(' , ')
  }

  renderStringColumn = ({data}) => <div>{getStringValue(data)}</div>

  getColumnMetadata = () => {
    const {t} = this.props

    return [
      {
        columnName: 'id',
        displayName: t('remindersView.reminderId'),
        order: 0,
        locked: false,
        visible: true,
        cssClassName: 'id-column',
        customComponent: this.renderStringColumn,
        filterValue: getStringValue,
        sortValue: getStringValue
      },
      {
        columnName: 'message',
        displayName: t('remindersView.message'),
        order: 1,
        locked: false,
        visible: true,
        cssClassName: 'message-column',
        customComponent: this.renderStringColumn,
        filterValue: getStringValue,
        sortValue: getStringValue
      },
      {
        columnName: 'groupIds',
        displayName: t('remindersView.groups'),
        order: 2,
        locked: false,
        visible: true,
        cssClassName: 'groups-column',
        customComponent: props => <GroupsColumn {...this.props} {...props} />,
        filterValue: this.getGroupsValue,
        sortValue: this.getGroupsValue
      },
      {
        columnName: 'personIds',
        displayName: t('remindersView.users'),
        order: 3,
        locked: false,
        visible: true,
        cssClassName: 'persons-column',
        customComponent: this.renderPersonColumn,
        filterValue: this.getPersonsValue,
        sortValue: this.getPersonsValue
      },
      {
        columnName: 'sendTime',
        displayName: t('remindersView.sendTime'),
        order: 4,
        locked: false,
        visible: true,
        cssClassName: 'sendTime-column',
        customComponent: this.renderSentTimestampColumn,
        filterValue: this.getTimestampValue,
        sortValue: this.getTimestampValue
      },
      {
        columnName: 'status',
        displayName: t('remindersView.status'),
        order: 5,
        locked: false,
        visible: true,
        cssClassName: 'receiver-status-column',
        customComponent: ConsumedColumn,
        sortable: false
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        order: 6,
        locked: false,
        visible: true,
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ]
  }

  getSmsColumnMetadata = () => {
    const {t} = this.props

    return [
      {
        columnName: 'reminderId',
        displayName: t('remindersView.reminderId'),
        order: 0,
        locked: false,
        visible: true,
        cssClassName: 'reminder-id-column',
        customComponent: this.renderStringColumn
      },
      {
        columnName: 'personId',
        displayName: t('remindersView.personId'),
        order: 1,
        locked: false,
        visible: true,
        cssClassName: 'person-id-column',
        customComponent: this.renderStringColumn
      },
      {
        columnName: 'twilioResponse.sid',
        displayName: t('remindersView.smsId'),
        order: 3,
        locked: false,
        visible: true,
        cssClassName: 'sms-id-column',
        customComponent: this.renderStringColumn
      },
      {
        columnName: 'twilioResponse.dateUpdated',
        displayName: t('remindersView.smsTimeUpdated'),
        order: 4,
        locked: false,
        visible: true,
        cssClassName: 'sms-time-column',
        customComponent: this.renderSentTimeColumn,
        filterValue: this.getTimestampValue,
        sortValue: this.getTimestampValue
      },
      {
        columnName: 'twilioCallback.SmsStatus',
        displayName: t('remindersView.smsStatus'),
        order: 5,
        locked: false,
        visible: true,
        cssClassName: 'sms-network-column',
        customComponent: this.renderStringColumn,
        filterValue: getStringValue,
        sortValue: getStringValue
      }
    ]
  }

  submitReminderNow = reminder => {
    if (confirm(this.props.t('remindersView.areYouSureSend'))) {
      const sendTime = moment()
        .add(15, 'seconds')
        .toISOString()

      this.props.saveReminder(reminder.set('sendTime', sendTime))
    }
  }

  getAddReminderBox = () => {
    if (!this.props.app.hasAccessToAction(UIAction.ADD_REMINDER)) {
      return null
    }

    const {studyGroups, user, users, saveReminder, t} = this.props

    return (
      <AddReminderBox
        groups={studyGroups}
        user={user}
        users={users}
        sendCallback={saveReminder}
        sendNowCallback={this.submitReminderNow}
        t={t}
      />
    )
  }

  render() {

    const {t} = this.props

    return (
      <div className='reminders-view-container'>
        <h1>{t('remindersView.title')}</h1>
        {this.getAddReminderBox()}
        <div className='table-panel'>
          <TableComponent
            key='reminders'
            ref='reminders'
            data={this.getResults()}
            showFilter={true}
            rowKey={'id'}
            columns={this.getColumnMetadata()}
            useFixedHeader={true}
            t={t}
          />
        </div>
        <div className='table-panel sms-table'>
          <TableComponent
            key='smses'
            ref='smses'
            data={this.getSmsResults()}
            showFilter={true}
            rowKey={'id'}
            columns={this.getSmsColumnMetadata()}
            useFixedHeader={true}
            t={t}
          />
        </div>
      </div>
    )
  }
}

const mapActionToProps = {
  getReminders: RemindersModule.getModels,
  getUsers: UsersModule.getUsers,
  getStudyGroups: StudyGroupsModule.getModels,
  getSmssOfType: SmssModule.getAllOfType,
  saveReminder: ReminderModule.saveModel,
  removeReminder: ReminderModule.deleteModel
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams ? CriteriaModel.fromQuery(query) : new CriteriaModel()
}

const mapStateToProps = (
  {app, authenticatedUser, users, reminders, studyGroups, smss},
  ownProps
) => {
  return {
    app,
    criteria: getCriteria(ownProps.location),
    user: authenticatedUser,
    users,
    studyGroups,
    smss,
    reminders
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Reminders))
