import React from 'react'
import {Component} from 'react'

interface Props {
  id: any
  remove: (event) => any
}

interface State {}

export default class DeleteButton extends Component<Props, State> {
  render() {
    const {id, remove} = this.props

    return (
      <button key={'remove_' + id} className='btn btn-default' onClick={remove} role='button'>
        <i className='fa fa-trash fa-lg'/>
      </button>
    )
  }
}
