import Reports from '../models/Reports'
import ModelsModule from './ModelsModule'
import {get} from './ActionsCommon'
import {PaginationContext} from '../models/Pagination'
import ErrorModel from '../models/Error'
import {downloadFailed, downloadSuccess} from './Download'
import {bind} from '../util'
import _ from 'lodash'
import {TabId} from '../models/TabId'
import {setPagination} from './Pagination'
import {createAction} from 'redux-actions'

const initialState = new Reports()

export class ReportsModule extends ModelsModule {
  getReportWrongContextType: string
  getReportWrongContextAction: any

  constructor() {
    super('report', initialState, PaginationContext.REPORT)
    bind(this,
      this.downloadReports,
      this.reset
    )
  }

  // Override
  public getModels(queryParams?, reset = false) {
    return (dispatch, getState) => {

      let pagination = this.getPagination(getState())

      if (pagination) {
        if (reset) {
          pagination.offset = 0
        }

        queryParams = _.merge({}, queryParams, pagination)
      }

      dispatch(this.getModelsRequestAction(queryParams))

      const currentTab = getState().reports.tabId
      const requestedTab = queryParams && queryParams.tab

      if (requestedTab && currentTab && requestedTab !== currentTab) {
        const wrongContextError = new ErrorModel({
          message: `Current: ${currentTab} != Requested: ${requestedTab}`,
          fieldErrors: 'tabId'
        })
        return dispatch(this.getReportWrongContextAction(wrongContextError))
      }

      return dispatch(get(this.name, null, queryParams))
        .then((response) => {
          if (pagination) {
            const {results, nextPage} = response
            pagination.offset = nextPage
            pagination.hasMore = nextPage && results.length >= pagination.limit

            dispatch(setPagination(this.paginationContext, pagination))
            return dispatch(this.getModelsSuccessAction({results, reset}))
          } else {
            return dispatch(this.getModelsSuccessAction({results: response, reset}))
          }
        })
        .catch((err) => dispatch(this.getModelsFailedAction(err)))
    }
  }

  public downloadReports(criteria, queryParams) {
    return (dispatch) => {
      return dispatch(get('report', 'download', _.merge(criteria.getQueryParams(), queryParams)))
        .then((response) => dispatch(downloadSuccess(response)))
        .catch((error) => dispatch(this.handleError(downloadFailed, error)))
    }
  }

  public reset(tabId: TabId = undefined) {
    return this.resetModels({tabId})
  }

  protected initializeTypes() {
    super.initializeTypes()
    this.getReportWrongContextType = `${this.name}.WRONG_CONTEXT`
  }

  protected initializeActions() {
    super.initializeActions()
    this.getReportWrongContextAction = createAction(this.getReportWrongContextType)
  }

  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelsType]: (_state, {payload: {tabId}}) => this.initialState.set('tabId', tabId),
      [this.getReportWrongContextType]: (state, {payload}) => state.endOfLoading(payload)
    }
  }
}

export default new ReportsModule()
