import BaseModel from './BaseModel'

const constraints = {
  personId: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['reminderId', 'personId', 'groupId', 'sendCount', 'time', 'status']

const defaultValues = {
  reminderId: undefined,
  personId: undefined,
  groupId: undefined,
  sendCount: undefined,
  time: undefined,
  status: undefined
}

export default class ReminderReceiver extends BaseModel(defaultValues, interestedKeys, constraints)<ReminderReceiver> {
  reminderId: number
  personId: number
  groupId: number
  sendCount: number
  time: any
  status: any

  fromJS(js): ReminderReceiver {
    return new ReminderReceiver(js)
  }
}
