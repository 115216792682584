/**
 * Created by Mauritz Untamala on 25/02/16.
 */
import React, {Component} from 'react'
import Toggle from 'react-toggle'
import Input from './Input'
import RangeDatePicker from './RangeDatePicker'
import SelectFormInput from './SelectFormInput'
import TimePickerAccordion from './TimePicker'
import PhoneNumberInput from './PhoneNumberInput'

import _ from 'lodash'
import classNames from 'classnames'
import {generateSelectOptions, getFieldError} from '../util'
import moment from 'moment'
import {List} from 'immutable'

import {connect} from 'react-redux'
import ActivityTrackers from '../modules/ActivityTrackers'
import SiteStudies from '../modules/SiteStudies'
import Sites from '../modules/Sites'
import Studies from '../modules/Studies'
import StudyGroups from '../modules/StudyGroups'
import {warning} from '../modules/Notifications'
import User, {UserStatus} from '../models/User'
import {Feature} from '../models/Feature'
import StudyGroupsModel from '../models/StudyGroups'
import SitesModel from '../models/Sites'
import StudiesModel from '../models/Studies'
import AccessRights from '../models/AccessRights'
import ActivityTrackersModel from '../models/ActivityTrackers'
import SiteStudy from '../models/SiteStudy'

interface Props {
  hasAccessToTrackers: boolean
  user: User
  isProfile: boolean
  isTrackerDateVisible: boolean
  type: string
  availableLanguages: List<any>
  studyGroups: StudyGroupsModel
  sites: SitesModel
  studies: StudiesModel
  accessRights: AccessRights
  activityTrackers: ActivityTrackersModel
  minimalDetails: boolean
  fields: any
  availableRoles: List<any>
  selectableSiteStudies: any
  onChange: (model) => any
  getStudyGroups: (queryParams?, reset?) => any
  getSites: (queryParams?, reset?) => any
  getSiteStudies: (queryParams?, reset?) => any
  getStudies: (queryParams?, reset?) => any
  getTrackers: (queryParams?, reset?) => any
  warning: (message) => any
  t: (key, params?) => any
  siteStudy?: SiteStudy
}

export class UserDetails extends Component<Props, any> {
  componentDidMount() {
    const {
      getStudyGroups,
      getSites,
      getSiteStudies,
      getStudies,
      getTrackers,
      user,
      hasAccessToTrackers
    } = this.props

    getStudyGroups()
    getSites()
    getSiteStudies()
    getStudies()

    if (user.isSubject() && hasAccessToTrackers) {
      getTrackers()
    }
  }

  render() {
    const {user} = this.props

    if (!user) {
      return null
    }

    return <div className='form-horizontal'>{this.getInputs()}</div>
  }

  onFieldChangeCallback = field => value => {
    const {onChange, user} = this.props

    onChange(user.set(field, value))
  }

  getInputField = (field, writeAccess) => {

    const {user, t} = this.props
    const value = user.get(field)
    const fieldError = getFieldError(field, user.validate(), user.error, value)
    const wrapperClassName = classNames(['col-xs-9 col-sm-10', field])

    return (
      <Input
        key={user._id + 'input_' + field}
        disabled={!writeAccess}
        label={t(field)}
        labelClassName='col-xs-3 col-sm-2'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={value || ''}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    )
  }

  createPhoneNumberInput = (field: string, writeAccess: boolean) => {
    const {user, onChange, t} = this.props
    const value = user.getPhone()
    const error = getFieldError(field, user.validate(), user.error, value)

    const handleOnChange = (value, dialCode) => {

      onChange(user.setDialCode(dialCode).setPhone(value))
    }

    return (
      <PhoneNumberInput
        key='input-phone-number'
        modelId={user.id}
        field={field}
        disabled={!writeAccess}
        value={value}
        error={error}
        onChange={handleOnChange}
        t={t}
      />
    )
  }

  getLanguageOptions = () => {
    const {availableLanguages, t} = this.props
    const languageLabel = (key) => t('language.' + key)

    return generateSelectOptions(availableLanguages, languageLabel)
  }

  getRoleOptions = () => generateSelectOptions(this.props.availableRoles)

  getStudyOptions = () => generateSelectOptions(this.props.studies, 'studyName', 'id')

  getSiteOptions = () => generateSelectOptions(this.props.sites, 'name', 'id')

  getUserStatusOptions = () => {

    const {t} = this.props

    const availableUserStatuses = UserStatus.getAvailableStatusList()

    const avaliableOptions = availableUserStatuses.filter(status => status !== UserStatus.locked)

    const statusLabel = (key) => t('personStatus.' + key)

    return generateSelectOptions(avaliableOptions, statusLabel)
  }

  getSiteStudyOptions = () =>
    generateSelectOptions(this.props.selectableSiteStudies, ['studyName', 'number'], 'id')

  getStudyGroupOptions = () => {
    return [
      {
        label: this.props.t('subjectHasNoStudyGroup'),
        value: null
      }
    ].concat(generateSelectOptions(this.props.studyGroups, 'name', 'id'))
  }

  getTrackerOptions = () => {
    const user = this.props.user

    return generateSelectOptions(
      this.props.activityTrackers.list.filter(tracker => {
        if (tracker.get('userId') === user.id) {
          return true
        }
        return !tracker.get('userId')
      }),
      'deviceId',
      'id'
    )
  }

  createLanguageInput = writeAccess => {
    return this.createSelectInput('language', writeAccess, this.getLanguageOptions())
  }

  createRoleInput = writeAccess => {
    return this.createSelectInput('roles', writeAccess, this.getRoleOptions())
  }

  createStudyInput = writeAccess => {
    return this.createSelectInput('studyIds', writeAccess, this.getStudyOptions(), true)
  }

  createUserStatusInput = writeAccess => {
    return this.createSelectInput('status', writeAccess, this.getUserStatusOptions())
  }

  onSiteInitiatedChange = user => {
    const siteStudyIds = user.get('siteStudyIds').toJS()
    const siteIds = user.get('siteIds').toJS()
    const id = user.get('id')

    if (id) {
      const orphanSiteStudyId = siteStudyIds.find(siteStudyId => {
        let siteStudy = this.props.selectableSiteStudies.find(
          siteStudy => siteStudy.id === siteStudyId
        )
        return !_.includes(siteIds, siteStudy.siteId)
      })

      if (orphanSiteStudyId) {
        const siteStudy = this.props.selectableSiteStudies.find(
          siteStudy => siteStudy.id === orphanSiteStudyId
        )

        this.props.warning(
          this.props.t('user.site.removalNotAllowedHasSiteStudies', {
            name: siteStudy.studyName,
            number: siteStudy.number
          })
        )
      } else {
        this.props.onChange(user)
      }
    } else {
      this.props.onChange(user)
    }
  }

  createTrackerInput = writeAccess => {
    return this.createSelectInput(
      'trackerId',
      writeAccess,
      this.getTrackerOptions(),
      false,
      null,
      this.onTrackerAssignedOrRemoved
    )
  }

  createSiteInput = writeAccess => {
    return this.createSelectInput(
      'siteIds',
      writeAccess,
      this.getSiteOptions(),
      true,
      null,
      this.onSiteInitiatedChange
    )
  }

  createSiteStudiesInput = writeAccess => {
    return this.createSelectInput(
      'siteStudyIds',
      writeAccess,
      this.getSiteStudyOptions(),
      !this.props.user.isSubject()
    )
  }

  createStudyGroupInput = writeAccess => {
    return this.createSelectInput(
      'studyGroupIds',
      writeAccess,
      this.getStudyGroupOptions(),
      true,
      this.props.t('subjectHasNoStudyGroup')
    )
  }

  createStartAndEndDateInput = _writeAccess => {
    const {user, isTrackerDateVisible, t} = this.props

    return (
      <div
        className='form-group'
        key='select-assignedPeriod'
        hidden={!this.props.isTrackerDateVisible}>
        <label className='col-xs-3 col-sm-2 control-label'>
          {t('userView.assignedPeriod')}
        </label>
        <div className='col-xs-9 col-sm-10'>
          <RangeDatePicker
            id='range'
            keys={['periodStart', 'periodEnd']}
            from={user.periodStart && isTrackerDateVisible ? moment(user.periodStart) : null}
            to={user.periodEnd && isTrackerDateVisible ? moment(user.periodEnd) : null}
            onChange={this.onAssignedPeriodChanged}
            maxFrom={moment()}
            className='form-control'
            showTimeSelect={true}
            t={t}
          />
        </div>
      </div>
    )
  }

  createSelectInput = (field, writeAccess, options, multi?, placeholder?, onChange?) => {

    const {user, t} = this.props

    return (
      <SelectFormInput
        key={'select-' + field}
        model={user}
        modelName='user'
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange || this.props.onChange}
        t={t}
      />
    )
  }

  onAssignedPeriodChanged = (from, to) => {
    this.props.onChange(this.props.user.set('periodStart', from).set('periodEnd', to))
  }

  onTrackerAssignedOrRemoved = user => {
    this.props.onChange(
      this.props.user
        .set('trackerId', user.trackerId)
        .set('periodStart', user.trackerId ? moment() : null)
        .set('periodEnd', null)
    )
  }

  onDataLockTimeChange = change => this.props.onChange(this.props.user.setDataLockTime(change.time))

  onDateOverrideTimeChange = change => this.props.onChange(this.props.user.setDateOverrideTime(change.time))

  dateTimeInput = (writeAccess, field, labelKey, onChange) => {
    const {user, t} = this.props
    const fieldError = getFieldError(field, user.validate(), user.error)
    const wrapperClassName = classNames(['col-xs-9 col-sm-10', field])
    const formGroupClasses = classNames({
      'form-group': true,
      'has-error': !!fieldError
    })

    return (
      <div key={field} className={formGroupClasses}>
        <label className='col-xs-3 col-sm-2 control-label' htmlFor='criteria'>
          {t(labelKey)}
        </label>

        <div className={wrapperClassName}>
          <TimePickerAccordion
            id={field}
            time={user.get(field)}
            showDateOnlyToggle={false}
            onChange={onChange}
            showNow={true}
            showReset={true}
            readonly={!writeAccess}
            error={fieldError}
            t={t}
          />
        </div>
      </div>
    )
  }

  createSubjectNumberLoginToggleInput = (field, writeAccess, onChange) => {

    const {user, t} = this.props
    const fieldError = getFieldError(field, user.validate(), user.error)
    const wrapperClassName = classNames(['col-xs-9 col-sm-10', field])
    const formGroupClasses = classNames({
      'form-group': true,
      'has-error': !!fieldError
    })

    return (
      <div key={field} className={formGroupClasses}>
        <label className='col-xs-3 col-sm-2 control-label' htmlFor={field}>
          {t(field)}
        </label>

        <div className={wrapperClassName}>
          <Toggle
            key={'toggle_' + field}
            id={field}
            defaultChecked={user.subjectNumberLogin}
            disabled={!writeAccess}
            onChange={onChange}
          />
        </div>
      </div>
    )
  }

  onSubjectNumberLoginChange = () => {

    const {user, onChange} = this.props

    onChange(user.toggleSubjectNumberLogin())
  }

  resolveFields = () => {
    const {user, hasAccessToTrackers} = this.props
    let fields

    if (this.props.minimalDetails) {
      if (user.isSubject()) {
        fields = ['phone', 'screeningNumber', 'subjectNumber', 'language', 'subjectNumberLogin']

        if (hasAccessToTrackers) {
          fields.push('trackerId')
          fields.push('assignedPeriod')
        }
      } else {
        fields = ['username', 'firstName', 'lastName', 'email', 'roles', 'language']

        if (user.isSiteUser()) {
          fields.push('siteIds')
        } else if (user.isStudyUser()) {
          fields.push('studyIds')
        }
      }
    } else {
      if (user.isSubject()) {
        fields = [
          'phone',
          'screeningNumber',
          'subjectNumber',
          'studyGroupIds',
          'dataLockTime',
          'language',
          'subjectNumberLogin',
          'dateOverride'
        ]

        if (hasAccessToTrackers) {
          fields.push('trackerId')
          fields.push('assignedPeriod')
        }
      } else {
        fields = ['username', 'firstName', 'lastName', 'phone', 'email', 'roles', 'language']

        if (user.isSiteUser()) {
          fields.push('siteIds')

          if (user.get('siteIds').size > 0 || user.get('siteStudyIds').size > 0) {
            fields.push('siteStudyIds')
          }
        } else if (user.isStudyUser()) {
          fields.push('studyIds')
        }
      }

      fields = fields.concat(['status', 'password', 'password2'])
    }

    return fields
  }

  getInputs = () => {
    const {user, accessRights, isProfile} = this.props
    const objectName = isProfile ? 'profile' : 'person'

    return _.map(this.resolveFields(), field => {

      const readAccess = accessRights.hasReadAccess(objectName, user, field)

      if (!readAccess) {
        return null
      }

      const writeAccess = accessRights.hasWriteAccess(objectName, user, field)

      switch (field) {
        case 'roles':
          return this.createRoleInput(writeAccess)
        case 'studyIds':
          return this.createStudyInput(writeAccess)
        case 'siteIds':
          return this.createSiteInput(writeAccess)
        case 'siteStudyIds':
          return this.createSiteStudiesInput(writeAccess)
        case 'dataLockTime':
          return this.dateTimeInput(writeAccess, field, 'lockTime', this.onDataLockTimeChange)
        case 'dateOverride':
          // Only present the field to users with write access.
          if (writeAccess === false) {
            return null
          }
          return this.dateTimeInput(writeAccess, field, 'dateOverride', this.onDateOverrideTimeChange)
        case 'studyGroupIds':
          return this.createStudyGroupInput(writeAccess)
        case 'status':
          return this.createUserStatusInput(writeAccess)
        case 'trackerId':
          return this.createTrackerInput(writeAccess)
        case 'assignedPeriod':
          return this.createStartAndEndDateInput(writeAccess)
        case 'language':
          return this.createLanguageInput(writeAccess)
        case 'phone':
          return this.createPhoneNumberInput(field, writeAccess)
        case 'subjectNumberLogin':
          return this.createSubjectNumberLoginToggleInput(field, writeAccess, this.onSubjectNumberLoginChange)
        default:
          return this.getInputField(field, writeAccess)
      }
    })
  }
}

const mapActionCreators = {
  getStudyGroups: StudyGroups.getModels,
  getSites: Sites.getModels,
  getSiteStudies: SiteStudies.getModels,
  getStudies: Studies.getModels,
  getTrackers: ActivityTrackers.getModels,
  warning
} as any

const mapStateToProps = (
  {app, studyGroups, sites, studies, accessRights, activityTrackers},
  ownProps
): any => {
  const {user, siteStudy} = ownProps
  const availableRoles = app.getAvailableRoles(user.getType())
  const selectableSiteStudies = app.getSelectableSiteStudies(user.get('siteIds').toArray())

  let availableLanguages = app.availableLanguages

  if (user.isSubject()) {
    const study = siteStudy && studies.getModelById(siteStudy.studyId)
    availableLanguages = study && study.languages
  }

  return {
    hasAccessToTrackers: app.hasAccessToFeature(Feature.trackers),
    availableRoles,
    availableLanguages,
    selectableSiteStudies,
    studyGroups: studyGroups.list,
    sites: sites.list,
    studies: studies.list,
    accessRights,
    activityTrackers
  }
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(UserDetails)
