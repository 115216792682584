/**
 * Created by Mauritz Untamala on 03/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import AdministrationEvent from './AdministrationEvent'

import moment from 'moment'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import AdministrationEventsModule from '../../../modules/AdministrationEvents'
import {navigate} from '../../../modules/Location'
import AdministrationEventsModel from '../../../models/AdministrationEvents'

import './AdministrationEvents.less'

interface Props extends WithNamespaces {
  events: AdministrationEventsModel
  getEvents: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {}

class AdministrationEvents extends PureComponent<Props, State> {
  componentDidMount() {
    this.props.getEvents()
  }

  addNewCharcoalEvent = () => this.props.navigate('/event/create-charcoal-event')

  addNewWaterEvent = () => this.props.navigate('/event/create-water-event')

  getListItem = event => (
    <AdministrationEvent
      key={event._id}
      event={event}
      navigate={this.props.navigate}
      t={this.props.t}
    />
  )

  getListItems = () => {
    const {t, events} = this.props
    const listItems = []

    listItems.push(
      <li
        className='list-group-item add'
        key='add_new_charcoal_event'
        onClick={this.addNewCharcoalEvent}>
        {t('addNewCharcoalEventText')}
      </li>
    )
    listItems.push(
      <li className='list-group-item add' key='add_new_water_event' onClick={this.addNewWaterEvent}>
        {t('addNewWaterEventText')}
      </li>
    )
    listItems.push(
      events.list.sort((e1, e2) => -moment(e1.time).diff(moment(e2.time))).map(this.getListItem)
    )

    return listItems
  }

  render() {
    return (
      <div className='container-fluid events-view'>
        <h1>{this.props.t('subjectAdministrationEventsViewTitle')}</h1>
        <p>{this.props.t('subjectAdministrationEventsViewHelp')}</p>
        <ul className='list-group'>{this.getListItems()}</ul>
      </div>
    )
  }
}

const mapActionToProps = {
  getEvents: AdministrationEventsModule.getModels,
  navigate
}

const mapStateToProps = ({administrationEvents}, _ownProps) => {
  return {
    events: administrationEvents
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(AdministrationEvents))
