/**
 * Created by Mauritz Untamala on 01/12/15.
 */
import React from 'react'
import ModelView, {BaseProps, BaseState} from './ModelView'
import Input from './Input'
import InputWithTranslation from './InputWithTranslation'
import {ControlLabel} from 'react-bootstrap'
import TimePickerAccordion from './TimePicker'

import _ from 'lodash'
import changeCase from 'change-case'
import classNames from 'classnames'
import {getFieldError} from '../util'

import ModelInterface from '../models/ModelInterface'
import User from '../models/User'
import EventTranslation from '../models/EventTranslation'
import {ROLES} from '../config/constants'

export interface BaseEventProps<M> extends BaseProps<M> {
  type: string
  authenticatedUser: User
}

export interface BaseEventState<M> extends BaseState<M> {}

interface BaseModelEvent<M> extends ModelInterface<M> {
  addTranslation?: (translation: EventTranslation) => any
  getTranslation?: (field: string) => any
  locked: boolean
}

export default abstract class BaseEventComponent<M extends BaseModelEvent<M>,
  P extends BaseEventProps<M>,
  S extends BaseEventState<M>> extends ModelView<M, P, S> {
  subjectFields: Array<any>
  investigatorFields: Array<any>

  constructor(props: any, subjectFields?: Array<any>, investigatorFields?: Array<any>) {
    super(props)
    this.subjectFields = subjectFields
    this.investigatorFields = investigatorFields
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    let prevModel = prevState.model
    let {model, backButtonDisabled} = this.state

    if (model.isSaving && !backButtonDisabled) {
      this.setState({backButtonDisabled: true})
    }

    if (prevModel.isSaving && !model.isSaving && !model.error && backButtonDisabled) {
      this.setState({backButtonDisabled: false})
    }
  }

  getWriteAccess = field => {
    const {authenticatedUser, accessRights, modelName} = this.props
    const accessRightsKey = changeCase.paramCase(modelName)

    if (!accessRights.getAccessRights(accessRightsKey, this.state.model)) {
      // While we have no access rights show readonly for the fields,
      // we know should be viewable to have the components
      // to get rendered empty instead of blank page
      // which creates a large contrast in component flicker.
      if (authenticatedUser.isSubject()) {
        return _.includes(this.subjectFields || [], field) ? false : null
      } else if (authenticatedUser.hasRole(ROLES.INVESTIGATOR)) {
        return _.includes(this.investigatorFields || [], field) ? false : null
      } else {
        return null
      }
    }

    const writeAccess = accessRights.hasWriteAccess(accessRightsKey, this.state.model, field)

    if (
      writeAccess === false &&
      !accessRights.hasReadAccess(accessRightsKey, this.state.model, field)
    ) {
      return null
    }

    return writeAccess
  }

  /**
   * @override
   */
  getTitle() {

    const {type, modelName, modelId, authenticatedUser, t} = this.props
    const context = type ? `${type}Event` : modelName
    const className = classNames({
      'event-title': true,
      'container': authenticatedUser.isPersonnel()
    })
    const titleKey = modelId ? `${context}.titleUpdate` : `${context}.titleAdd`

    return <h1 className={className}>{t(titleKey)}</h1>
  }

  getHeading() {

    const {authenticatedUser, t} = this.props

    if (authenticatedUser.isPersonnel()) {
      return (
        <div className='row event-heading'>
          <div className='col-xs-6'>
            {t('subject')}
          </div>
          <div className='col-xs-6'>
            {t('personnel')}
          </div>
        </div>
      )
    }
  }

  onFieldUpdate = (field, value) => {
    return this.updateModel(this.state.model.set(field, value || ''))
  }

  onFieldChange = field => {
    return function(value) {
      this.onFieldUpdate(field, value)
    }.bind(this)
  }

  onTranslationChange = (updatedTranslation) => {

    this.updateModel(
      this.state.model.addTranslation(updatedTranslation)
    )
  }

  getFieldTranslation(field) {

    const isPersonnel = this.props.authenticatedUser.isPersonnel()

    return isPersonnel ? this.state.model.getTranslation(field) || new EventTranslation({field}) : undefined
  }

  onTimeFieldChange = (timeField, dateOnlyField) => {
    return obj => {
      let updatedModel = this.state.model.set(timeField, obj.time).set(dateOnlyField, obj.dateOnly)
      this.updateModel(updatedModel)
    }
  }

  createInputFieldWithTranslation = (context, field, writeAccess, type, additionalProps, disabled?) => {

    const {model} = this.state

    if (writeAccess === null) {
      return
    }

    const {t, authenticatedUser} = this.props

    return (
      <InputWithTranslation
        key={field}
        type={type ? type : 'text'}
        field={field}
        fieldValue={model[field]}
        label={t(context + '.' + field)}
        labelClassName={classNames('col-xs-6', 'event-form-input-label')}
        onFieldChange={this.onFieldChange(field)}
        disabled={writeAccess === false || !!disabled}
        error={getFieldError(field, model.validate(), model.error)}
        enableTranslationFeature={authenticatedUser.isPersonnel()}
        t={t}
        onTranslationChange={this.onTranslationChange}
        translation={this.getFieldTranslation(field)}
        translationLabel={t(`${context}.${field}Translation`)}
        {...additionalProps}
      />
    )
  }

  createInputField = (context, field, writeAccess, type, additionalProps, disabled?) => {
    const {model} = this.state
    const {t} = this.props

    if (writeAccess === null) {
      return
    }

    return (
      <div className='row'>
        <Input
          key={field}
          type={type ? type : 'text'}
          name={field}
          value={model[field]}
          label={t(context + '.' + field)}
          onChange={this.onFieldChange(field)}
          disabled={writeAccess === false || !!disabled}
          error={getFieldError(field, model.validate(), model.error)}
          t={t}
          {...additionalProps}
        />
      </div>
    )
  }

  createTimePicker = (context, field, writeAccess, onChange, additionalProps, label?, className?) => {
    const {authenticatedUser, t} = this.props
    const {model} = this.state
    const inputKey = `${field}-time-picker`
    const isPersonnel = authenticatedUser.isPersonnel()

    const wrapperClassName = className || classNames({
      'col-xs-12': !isPersonnel,
      'col-xs-6': isPersonnel
    })

    return (
      <div key={inputKey} className='row'>
        <div className={wrapperClassName}>

          <ControlLabel bsClass={`control-label event-form-input-label`}>
            {label || t(`${context}.${field}`)}
          </ControlLabel>

          <TimePickerAccordion
            id={inputKey}
            time={model.get(field)}
            showDateOnlyToggle={false}
            onChange={onChange}
            readonly={!writeAccess || model.locked}
            error={getFieldError(field, model.validate(), model.error)}
            t={t}
            {...additionalProps}
          />
        </div>
      </div>
    )
  }
}
