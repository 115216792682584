/**
 * Created by Mauritz Untamala on 20/02/16.
 */
import React from 'react'
import {PureComponent} from 'react'
import TableComponent from '../TableComponent'
import ActionsColumn from './ActionsColumn'

import _ from 'lodash'

import {connect} from 'react-redux'
import {navigate} from '../../modules/Location'
import SiteStudyModule from '../../modules/SiteStudy'
import SiteStudiesModule from '../../modules/SiteStudies'
import AuthenticatedUserModule from '../../modules/AuthenticatedUser'
import {UIAction} from '../../models/UIAction'
import App from '../../models/App'
import Site from '../../models/Site'
import SiteStudies from '../../models/SiteStudies'
import User from '../../models/User'

interface Props {
  app: App
  authenticatedUser: User
  siteId: number
  site: Site
  hasAccessToAddSiteStudy: boolean
  siteStudies: SiteStudies
  getSiteStudies: (id) => any
  deleteSiteStudy: (model) => any
  updateUser: (model) => any
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {
}

class SiteStudiesTable extends PureComponent<Props, State> {
  componentDidMount() {
    this.props.getSiteStudies(this.props.siteId)
  }

  componentDidUpdate(prevProps) {
    const {siteId, getSiteStudies} = this.props

    if (siteId !== prevProps.siteId) {
      getSiteStudies(siteId)
    }
  }

  getResults = () => {
    return this.props.siteStudies.list
      .map(siteStudy => _.extend(siteStudy.toJS(), {_src: siteStudy}))
      .toArray()
  }

  getColumnMeta = () => {
    const {t} = this.props

    return [
      {
        columnName: 'studyName',
        displayName: t('column.studyName')
      },
      {
        columnName: 'number',
        displayName: t('column.studySiteNumber')
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ]
  }

  showSiteStudy = rowData =>
    this.props.navigate('/admin/site/' + this.props.site.id + '/study/' + rowData.id)

  addSiteStudy = () => this.props.navigate('/admin/site/' + this.props.site.id + '/study/new')

  getAdderView = () => {
    const {hasAccessToAddSiteStudy, site, t} = this.props

    if (hasAccessToAddSiteStudy && site.id) {
      return (
        <button className='btn btn-default pull-right' onClick={this.addSiteStudy}>
          {t('button.addSiteStudy')}
        </button>
      )
    }
  }

  render() {
    return (
      <div className='table-panel site-studies-table'>
        <TableComponent
          key='site-studies'
          ref='site-studies'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnMeta()}
          useFixedHeader={true}
          onRowClick={this.showSiteStudy}
          topContent={this.getAdderView()}
          t={this.props.t}
        />
      </div>
    )
  }
}

const mapActionCreators = {
  getSiteStudies: SiteStudiesModule.fetchSiteStudies,
  deleteSiteStudy: SiteStudyModule.deleteModel,
  updateUser: AuthenticatedUserModule.updateModel,
  navigate
}

const mapStateToProps = ({authenticatedUser, siteStudies, app}, _ownProps) => ({
  app,
  hasAccessToAddSiteStudy: app.hasAccessToAction(UIAction.ADD_SITE_STUDY),
  authenticatedUser,
  siteStudies
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(SiteStudiesTable)
