import React from 'react'
import {PureComponent} from 'react'
import Results from './Results'
import TimestampColumn from '../../../../components/TimestampColumn'
import {getFilterValue, getSortValue} from './TabComponents'
import {sortableTrackingColumns} from './HealthTrackings'

import _ from 'lodash'
import {List} from 'immutable'

import Criteria from '../../../../models/Criteria'
import Tracking from '../../../../models/SleepTracking'

const sleepTrackingColumns = [
  'siteStudyName',
  'user',
  'deviceId',
  'activityType',
  'sleepDuration',
  'awakeDuration',
  'fallAsleepDuration',
  'numberOfWakeups',
  'startTime',
  'averageHeartRate',
  'peakHeartRate',
  'lowestHeartRate'
]

interface Props {
  trackings: List<Tracking>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onRowClick?: (rowData) => any
  onProcessedData?: () => any

  t: (key, params?) => any
}

interface State {}

export default class SleepTrackings extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'startTime':
      case 'endTime':
        return TimestampColumn
      default:
        return null
    }
  }

  getColumns = () => {
    return _.map(sleepTrackingColumns, field => {
      return {
        columnName: field,
        displayName: this.props.t('column.' + field),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field),
        sortable: _.includes(sortableTrackingColumns, field)
      }
    })
  }

  render() {
    return (
      <Results
        columns={this.getColumns()}
        results={this.props.trackings}
        ref='results'
        {...this.props}
      />
    )
  }
}
