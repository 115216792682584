import React from 'react'
import {Component} from 'react'
import DeleteButton from '../../../../components/DeleteButton'

interface Props {
  removeReminder: (reminder) => any
  rowData: any
  t: (key, params?) => any
}

interface State {}

export default class ActionsColumn extends Component<Props, State> {
  remove = () => {
    const {t, removeReminder, rowData} = this.props

    if (confirm(t('remindersView.areYouSureRemove'))) {
      removeReminder(rowData._src)
    }
  }

  getActions = () => {
    const {rowData} = this.props

    if (rowData.id && rowData.consumed === false) {
      return <DeleteButton id={rowData.id} remove={this.remove}/>
    }
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}
