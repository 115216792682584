import {fromJS} from 'immutable'
import BaseModel from './BaseModel'

const defaultValues = {
  object: undefined
}

export default class ReportItem extends BaseModel(defaultValues)<ReportItem> {
  object: any

  constructor(js?) {
    super(js)

    return this.set('object', fromJS(js)) as ReportItem
  }

  fromJS(js): ReportItem {
    return new ReportItem(js)
  }
}
