import React from 'react'
import {PureComponent} from 'react'
import Results from './Results'
import EcrfTransferTimeColumn from './EcrfTransferTimeColumn'
import TranslationText from './TranslationText'
import SanitizedHTML from 'react-sanitized-html'

import {DATE_DEFAULT_TIMEZONE, DATE_FORMAT_TIMEZONE} from '../../../../config/constants'
import _ from 'lodash'
import {getFilterValue, getSortValue} from './TabComponents'
import moment from 'moment-timezone'
import numberFormatter from 'format-number-with-string'
import {List} from 'immutable'

import App from '../../../../models/App'
import QuestionnaireAnswer from '../../../../models/QuestionnaireAnswer'
import {Type} from '../../../../models/QuestionComponent'
import Criteria from '../../../../models/Criteria'
import BooleanColumn from './BooleanColumn'
import TimestampUtcColumn from '../../../../components/TimestampUtcColumn'
import {openNewTab} from '../../../../util'

const questionnaireColumns = [
  'siteStudyName',
  'person',
  'createTime',
  'updateTime',
  'period:questionnairePeriod',
  'title',
  'description',
  'question',
  'questionPart',
  'questionPartAnswer',
  'personnelComment',
  'ecrfTransferTime',
  'personnel',
  'locked'
]

const notSortableColumns = [
  'period:questionnairePeriod',
  'title',
  'description',
  'question',
  'questionPart',
  'questionPartAnswer'
]

interface Props {
  app: App
  answers: List<QuestionnaireAnswer>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any
  navigate: (url: string) => any

  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any

  t: (key, params?) => any
}

interface State {
}

export default class Questionnaires extends PureComponent<Props, State> {

  getCustomComponent = field => {
    switch (field) {
      case 'createTime':
      case 'updateTime':
        return TimestampUtcColumn
      case 'ecrfTransferTime':
        return EcrfTransferTimeColumn
      case 'question':
        return ({data}) => <span>{data.title}</span>
      case 'questionPart':
        return ({rowData, data}) => {
          const field = data
          const component = rowData.question.components.find(c => c.field === data)

          return this.renderAsHtml(this.getHumanReadablePart(component, component.localization, field))
        }
      case 'questionPartAnswer':
        return ({rowData: {questionPart, question, translation, timezone}, data}) => {
          const field = questionPart
          const fieldValue = data
          const component = question.components.find(c => c.field === field)

          return this.getHumanReadablePartAnswer(component, component.localization, fieldValue, translation, timezone)
        }
      case 'locked':
        return BooleanColumn
      default:
        return null
    }
  }

  getFilterValue = (props, field) => {
    switch (field) {
      case 'question':
        return (question, _object, _field, _t) => {
          return question.title
        }
      default:
        return getFilterValue(props, field)
    }
  }

  getSortValue = (props, field) => {
    switch (field) {
      case 'question':
        return (question, _object, _field, _t) => question.title
      default:
        return getSortValue(props, field)
    }
  }

  getColumns = () => {
    const {t} = this.props
    return questionnaireColumns.map(field => {
      const keys = field.split(':')
      const valueKey = keys[0]
      const localeKey = keys.length > 1 ? keys[1] : valueKey

      return {
        columnName: valueKey,
        displayName: t('column.' + localeKey),
        locked: this.getFilterValue(this.props, field),
        visible: true,
        customComponent: this.getCustomComponent(valueKey),
        filterValue: this.getFilterValue(this.props, valueKey),
        sortValue: this.getSortValue(this.props, valueKey),
        sortable: !_.includes(notSortableColumns, field)
      }
    })
  }

  onRowClick = ({id, questionnaireId}) => {
    const {app} = this.props
    const path = `/admin/questionnaires/${questionnaireId}/answer/${id}`

    if (app.hasAccessToPage(path)) {
      openNewTab(path)
    }
  }

  getHumanReadablePart = (component, localization, _field) => {
    switch (component.type) {
      case 'number':
        return localization.label
      case 'text':
      case 'datetime':
        return localization
      case 'select':
      default:
        return _.isObject(localization) ? '' : localization
    }
  }

  getHumanReadablePartAnswer = (
    component,
    localization,
    fieldValue,
    translation,
    timezone = DATE_DEFAULT_TIMEZONE
  ) => {

    const {t} = this.props

    switch (component.type) {
      case Type.number:
        const getValue = () => {
          if (component.options && component.options.format) {
            return numberFormatter(fieldValue, component.options.format)
          }
          return fieldValue
        }

        return getValue() + (localization.unit ? ` ${localization.unit}` : '')
      case Type.text:
      case Type.textarea:

        return (
          <TranslationText t={t} fieldValue={fieldValue} translation={translation}/>
        )
      case Type.datetime:
        return fieldValue && moment(fieldValue).tz(timezone).format(DATE_FORMAT_TIMEZONE)
      case Type.multiselect:
        return fieldValue ? fieldValue.map(value => localization[value]).join(', ') : ''
      case Type.select:
      default:
        return _.isObject(localization) && localization[fieldValue]
          ? localization[fieldValue]
          : fieldValue
    }
  }

  renderAsHtml = value => <SanitizedHTML allowedTags={['b']} html={value}/>

  getResults = () => {
    const {answers} = this.props

    if (!answers.find(a => a.get('question'))) {
      return List()
    }

    return answers.map(questionnaireAnswer => {
      const answer = questionnaireAnswer.toJS()
      const {id, questionPart, question} = answer
      const {page, order} = question
      const rowId = `${id}-${page}-${order}-${questionPart}`

      return _.merge({rowId}, answer)
    })
  }

  render() {
    const results = this.getResults() as List<QuestionnaireAnswer>

    return (
      <Results
        columns={this.getColumns()}
        results={results}
        ref='results'
        rowKey={'rowId'}
        onRowClick={this.onRowClick}
        {...this.props}
      />
    )
  }
}
