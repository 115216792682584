import React from 'react'
import {PureComponent} from 'react'
import Results from './Results'
import TimestampColumn from '../../../../components/TimestampColumn'
import {getFilterValue, getSortValue} from './TabComponents'
import {List} from 'immutable'

import Message from '../../../../models/Message'
import Criteria from '../../../../models/Criteria'

const messageColumns = [
  'siteStudyName',
  'personFrom',
  'personTo',
  'responseToId',
  'content',
  'conversationId',
  'writtenTimestamp',
  'consumedTimestamp'
]

interface Props {
  messages: List<Message>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onRowClick?: (rowData) => any
  onProcessedData?: () => any
  t: (key, params?) => any
}

interface State {}

export default class Messages extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'writtenTimestamp':
      case 'consumedTimestamp':
        return TimestampColumn
      default:
        return null
    }
  }

  getColumns = () => {
    return messageColumns.map(field => {
      return {
        columnName: field,
        displayName: this.props.t('column.' + field),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    return (
      <Results
        columns={this.getColumns()}
        results={this.props.messages}
        ref='results'
        {...this.props}
      />
    )
  }
}
