/**
 * Created by Mauritz Untamala on 26/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import ImmutableSelect from './ImmutableSelect'
import RangeDatePicker from './RangeDatePicker'
import CheckboxFormInput from './CheckboxFormInput'

import classNames from 'classnames'
import {List} from 'immutable'

import User from '../models/User'
import Criteria from '../models/Criteria'

export interface BaseProps {
  users: List<User>
  criteria: Criteria
  onCriteriaChange: (criteria) => any
  t: (key, params?) => any
}

export interface BaseState {
}

abstract class BaseCriteria<P extends BaseProps, S extends BaseState> extends PureComponent<P, S> {
  abstract getCriteriaForm

  onListCriteriaChange = (listName, newItems) => {
    const {criteria, onCriteriaChange} = this.props
    const list = criteria.get(listName).clear()
    onCriteriaChange(criteria.set(listName, list.concat(newItems)))
  }

  onSelectedUsersChange = selectedUsers => this.onListCriteriaChange('users', selectedUsers)

  onRangeChange = (from, to) => {
    const {criteria, onCriteriaChange} = this.props
    onCriteriaChange(criteria.set('from', from).set('to', to))
  }

  getLabel = object => object.visibleName()

  createCheckboxCriteria = criteriaName => {
    const {criteria, onCriteriaChange, t} = this.props
    const inputClassName = classNames(['col-xs-9 col-sm-10', criteriaName])
    const value = criteria.get(criteriaName)

    const onCheckboxChange = () => {
      onCriteriaChange(criteria.set(criteriaName, !value))
    }

    return (
      <CheckboxFormInput
        key={`${criteriaName}-group`}
        labelClassName='col-xs-3 col-sm-2'
        inputClassName={inputClassName}
        field={criteriaName}
        label={t(`criteria.${criteriaName}`)}
        value={value}
        disabled={false}
        onChange={onCheckboxChange}
      />
    )
  }

  createSelectCriteria = (criteriaName, label, onChange) => {
    const {criteria, t} = this.props
    const selectKey = `select-criteria-${criteriaName}`

    return (
      <div key={criteriaName + '_group'} className='form-group'>
        <label className='col-xs-3 col-sm-2 control-label' htmlFor={selectKey}>
          {t('criteria.' + criteriaName)}
        </label>

        <div className='col-xs-9 col-sm-10'>
          <ImmutableSelect id={selectKey}
                           name={selectKey}
                           options={this.props[criteriaName]}
                           selectedOptions={criteria[criteriaName]}
                           label={label}
                           onChange={onChange}
                           className='form-control'
                           t={t}/>
        </div>
      </div>
    )
  }

  createDateRangeCriteria = () => {
    const {criteria, t} = this.props

    return (
      <div key='date_group' className='form-group'>
        <label className='col-xs-3 col-sm-2 control-label' htmlFor='range'>
          {t('timeRange')}
        </label>

        <div className='col-xs-9 col-sm-10'>
          <RangeDatePicker
            id='range'
            from={criteria.from}
            to={criteria.to}
            onChange={this.onRangeChange}
            className='form-control'
            t={t}
            showTimeSelect={true}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='criteria panel panel-default'>
        <div className='panel-body'>
          {this.getCriteriaForm()}
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default BaseCriteria
