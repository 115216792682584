import BaseModel from './BaseModel'

const constraints = {
  number: {
    presence: {allowEmpty: false}
  },
  name: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['number', 'name']

const defaultValues = {
  name: undefined,
  number: undefined
}

export default class Site extends BaseModel(defaultValues, interestedKeys, constraints)<Site> {
  name: string
  number: number

  fromJS(js): Site {
    return new Site(js)
  }
}
