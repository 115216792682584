import React from 'react'
import {Component} from 'react'
import DeleteButton from '../../../../components/DeleteButton'

import {UIAction} from '../../../../models/UIAction'
import App from '../../../../models/App'

interface Props {
  app: App
  rowData: any
  deleteModel: (model) => any
}

interface State {}

export default class ActionsColumn extends Component<Props, State> {
  remove = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.deleteModel(this.props.rowData._src)
  }

  getActions = () => {
    if (this.props.app.hasAccessToAction(UIAction.DELETE_SITE)) {
      return <DeleteButton id={this.props.rowData.id} remove={this.remove}/>
    }
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}
