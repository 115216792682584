/**
 * Created by Mauritz Untamala on 10/12/15.
 */
import React from 'react'
import ModelView, {BaseProps, BaseState} from './ModelView'
import SaveButton from './SaveButton'
import StudyDetails from './StudyDetails'

import changeCase from 'change-case'
import {List} from 'immutable'
import {ROLES} from '../config/constants'

import {connect} from 'react-redux'
import {getAccessRights} from '../modules/AccessRights'
import {navigate} from '../modules/Location'
import StudyModule from '../modules/Study'
import UsersModule from '../modules/Users'
import Study from '../models/Study'
import Users from '../models/Users'
import {Feature} from '../models/Feature'

import '../styles/study.less'

const applicableRoles = [ROLES.LEAD_MONITOR, ROLES.DATA_MANAGER]

interface Props extends BaseProps<Study> {
  availableFeatures: List<Feature>
  availableLanguages: List<any>
  users: Users
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
}

interface State extends BaseState<Study> {
}

class StudyView extends ModelView<Study, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getUsers(applicableRoles)
  }

  getContent() {
    const model = this.state.model
    const {t, modelName, users, availableFeatures, availableLanguages, accessRights} = this.props

    return [
      (
        <StudyDetails key='study-details'
                      study={model}
                      users={users}
                      accessRights={accessRights}
                      availableFeatures={availableFeatures}
                      availableLanguages={availableLanguages}
                      onChange={this.updateModel}
                      t={t}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      )
    ]
  }
}

const mapActionCreators = {
  getUsers: UsersModule.getUsers,
  getModel: StudyModule.getModel,
  saveModel: StudyModule.saveModel,
  resetModel: StudyModule.resetModel,
  getAccessRights,
  navigate
}

const mapStateToProps = (
  {users, study, accessRights, app: {availableFeatures, availableLanguages}}
) => ({
  users,
  model: study,
  accessRights,
  availableFeatures,
  availableLanguages
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    backHref: ownProps.notSubPage ? null : '/admin/studies',
    forceBackHref: true,
    editUrl: `/admin/study`,
    modelId: ownProps.studyId,
    modelName: 'study'
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps)
}

export default connect(
  mapStateToProps,
  mapActionCreators,
  mergeProps
)(StudyView)
