import ModelsModule from './ModelsModule'
import AdministrationEvents from '../models/AdministrationEvents'
import AdministrationEvent from './AdministrationEvent'

const initialState = new AdministrationEvents()

class AdministrationEventsModule extends ModelsModule {
  constructor() {
    super('event/administration', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [AdministrationEvent.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [AdministrationEvent.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [AdministrationEvent.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new AdministrationEventsModule()
