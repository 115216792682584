import Localizations from '../models/Localizations'
import ModelsModule from './ModelsModule'
import Localization from './Localization'

const initialState = new Localizations()

export class LocalizationsModule extends ModelsModule {
  constructor() {
    super('localization', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [Localization.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Localization.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [Localization.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new LocalizationsModule()
