import React from 'react'
import {PureComponent} from 'react'
import Results from './components/Results'

import _ from 'lodash'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import PeriodsModule from '../../../modules/Periods'
import PeriodModule from '../../../modules/Period'
import App from '../../../models/App'
import Criteria from '../../../models/Criteria'
import PeriodsModel from '../../../models/Periods'
import UserModel from '../../../models/User'

import './Periods.less'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  app: App
  periods: PeriodsModel
  authenticatedUser: UserModel
  criteria: Criteria
  location: string
  updateCriteria: (location, criteria) => any
  getPeriods: (queryParams?, reset?) => any
  deletePeriod: (model) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
}

export class Periods extends PureComponent<Props, State> {

  componentDidMount() {
    const {getPeriods, criteria} = this.props
    getPeriods(criteria.getQueryParams(), true)
  }

  componentDidUpdate(prevProps) {

    const {getPeriods, criteria, authenticatedUser} = this.props
    const siteStudyChanged = prevProps.authenticatedUser.selectedSiteStudyId
      !== authenticatedUser.selectedSiteStudyId && !authenticatedUser.isLoading

    if (!prevProps.criteria.isEqual(criteria) || siteStudyChanged) {
      getPeriods(criteria.getQueryParams(), true)
    }
  }

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props
    updateCriteria(location, criteria)
  }

  render() {
    const {app, criteria, periods, deletePeriod, navigate, t} = this.props

    return (
      <div key='periods-view-container' className='periods-view'>
        <h1 className='page-title'>
          {t('periodsView.title')}
        </h1>
        <Results
          ref='results'
          app={app}
          periods={periods.list}
          criteria={criteria}
          deleteModel={deletePeriod}
          onCriteriaChange={this.onCriteriaChange}
          navigate={navigate}
          t={t}/>
      </div>
    )
  }
}

const mapActionToProps = {
  updateCriteria: PeriodsModule.updateCriteria,
  getPeriods: PeriodsModule.getModels,
  deletePeriod: PeriodModule.deleteModel,
  navigate
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams ? Criteria.fromQuery(query) : Criteria.getInitialCriteriaWithoutDateRange()
}

const mapStateToProps = ({app, periods, authenticatedUser}, ownProps) => {
  const criteria = getCriteria(ownProps.location)

  return {
    app,
    criteria,
    periods,
    authenticatedUser
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Periods))
