import ModelModule from './ModelModule'
import StudyGroup from '../models/StudyGroup'
import {bind} from '../util'

export class StudyGroupModule extends ModelModule<StudyGroup> {
  constructor() {
    super('group', 'studyGroup')
    bind(this, this.resetModelWithSiteStudyId)
  }

  protected getInitialState = () => new StudyGroup()

  public resetModelWithSiteStudyId(siteStudyId) {
    return (dispatch) => dispatch(this.resetModelAction(siteStudyId))
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelType]: (_state, {payload}) => this.getInitialState().setStudySiteId(payload)
    }
  }

  /**
   * @override
   */
  protected deleteSuccessMessageProps({name}) {
    return {name}
  }
}

export default new StudyGroupModule()
