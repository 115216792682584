import React from 'react'
import {Component} from 'react'
import SiteView from '../../../components/Site'

import {connect} from 'react-redux'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  siteId: number
  tab: string
}

interface State {}

class Site extends Component<Props, State> {
  render() {
    const {siteId, tab, t} = this.props
    return <SiteView key='site-view' siteId={siteId} tab={tab} t={t}/>
  }
}

const mapStateToProps = (_state, ownProps) => {
  const siteId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined
  const tab = ownProps.location.query ? ownProps.location.query.tab : undefined

  return {siteId, tab}
}

const mapActionToProps = {}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Site))
