/**
 * Created by Mauritz Untamala on 03/12/15.
 */
import React from 'react'
import {Component} from 'react'
import Toggle from 'react-toggle'
import {Panel} from 'react-bootstrap'

import _ from 'lodash'
import classNames from 'classnames'

const periods = ['period1', 'period2', 'period3', 'period4']

interface Props {
  id?: string
  label?: string

  period: string
  rescheduled: boolean
  readonly: boolean
  onPeriodChange: () => any
  onRescheduledChange: (value) => any
  error: object | string

  t: (key, params?) => any
}

interface State {
  open: boolean
}

export default class Period extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {open: false}
  }

  getRescheduledIcon = () => {
    if (this.props.rescheduled) {
      return <i className='fa fa-share'/>
    }
  }

  getError = () => {
    if (this.props.error) {
      return <div className='error'>{this.props.error}</div>
    }

    return <div/>
  }

  selectPeriod = period => {
    return function() {
      this.setState(
        {open: false},
        function() {
          this.props.onPeriodChange(period)
        }.bind(this)
      )
    }.bind(this)
  }

  getListItems = () => {
    return _.map(periods, period => {
      return (
        <li className='list-group-item' key={period} onClick={this.selectPeriod(period)}>
          {this.props.t('period.' + period)}
        </li>
      )
    })
  }

  toggleRescheduled = () => this.props.onRescheduledChange(!this.props.rescheduled)

  getRescheduledToggle = () => {
    return (
      <div className='rescheduled'>
        <Toggle
          key='rescheduled'
          id='rescheduled'
          checked={this.props.rescheduled}
          onChange={this.toggleRescheduled}
        />
        <span>{this.props.t('toggleRescheduled')}</span>
      </div>
    )
  }

  getHeader = () => {
    const classNameHeader = classNames({
      'period-header': true
    })

    return (
      <Panel.Heading>
        <Panel.Title componentClass='span' className={classNameHeader} toggle={true}>
          <span className='period-label'>{this.props.label}</span>
          <span className='period'>
            {this.props.t('period.' + this.props.period)} {this.getRescheduledIcon()}
          </span>
        </Panel.Title>
      </Panel.Heading>
    )
  }

  render() {
    const wrapperClasses = classNames({
      'period-component': true,
      'input-group': true,
      'panel-group': true,
      'has-error': !!this.props.error
    })

    return (
      <div className={wrapperClasses}>
        <Panel
          eventKey='1'
          expanded={this.state.open && !this.props.readonly}
          onToggle={() => this.setState({open: !this.state.open})}>
          {this.getHeader()}
          <Panel.Body collapsible={true}>
            <ul className='list-group'>{this.getListItems()}</ul>
            {this.getRescheduledToggle()}
          </Panel.Body>
        </Panel>
        {this.getError()}
      </div>
    )
  }
}
