import React from 'react'
import {Component} from 'react'

interface Props {
  data: boolean
  t: (key, params?) => any
}

interface State {
}

export default class BooleanColumn extends Component<Props, State> {

  render() {

    const {t, data} = this.props

    return <span>{t('boolean.' + (data === true))}</span>
  }
}
