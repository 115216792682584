import {Record} from 'immutable'
import {validate} from 'validate.js'

const loginConstraints = {
  username: {
    presence: {allowEmpty: false, message: 'not_null'}
  },
  password: {
    presence: {allowEmpty: false, message: 'not_null'}
  }
}

class LoginModel extends Record({
  username: undefined,
  password: undefined
}) {
  username: string
  password: string

  validate() {
    return validate(this, loginConstraints, {fullMessages: false})
  }

  isValid() {
    return !this.validate()
  }
}

export default LoginModel
