export enum ListenerContext {
  messageReceived,
  conversationUnlocked
}

export interface MessageListener {
  receiveMessage(payload: any)
}

export interface ConversationListener {
  receiveUnlockedConversation(payload: any)
}

class ListenerManager {
  private listenersContexts = new Map<ListenerContext, Array<MessageListener | ConversationListener>>()

  addListener(context: ListenerContext, listener: MessageListener | ConversationListener) {
    const listeners = this.listenersContexts.get(context) || []
    listeners.push(listener)
    this.listenersContexts.set(context, listeners)
  }

  removeListener(context: ListenerContext, listener: MessageListener | ConversationListener) {
    const listeners = this.listenersContexts.get(context) || []
    const index = listeners.indexOf(listener)
    if (index !== -1) {
      listeners.splice(index, 1)
      this.listenersContexts.set(context, listeners)
    }
  }

  notifyListeners(context: ListenerContext, payload) {
    const listeners = this.listenersContexts.get(context) || []

    switch (context) {
      case ListenerContext.messageReceived:
        listeners.forEach((listener: MessageListener) => listener.receiveMessage(payload))
        return
      case ListenerContext.conversationUnlocked:
        listeners.forEach((listener: ConversationListener) => listener.receiveUnlockedConversation(payload))
        return
      default:
    }
  }
}

export default new ListenerManager()
