/**
 * Created by jarkkohiitoluoma on 26/11/15.
 */
import React from 'react'
import {Component} from 'react'
import SubPageView from '../../components/SubPageView'
import MessagingView from '../../components/MessagingView'

import {WithNamespaces, withNamespaces} from 'react-i18next'
import {ROLES} from '../../config/constants'

import {connect} from 'react-redux'

interface Props extends WithNamespaces {
  replyRole: 'nurses' | 'investigators'
  dedicatedSubjectId: number
}

interface State {}

class Messages extends Component<Props, State> {
  render() {
    const {t, replyRole, dedicatedSubjectId} = this.props
    const navItems = [{label: 'back', href: '/messages', className: 'back-navigation'}]
    const role = /nurses/i.test(replyRole) ? ROLES.STUDY_NURSE : ROLES.INVESTIGATOR

    return (
      <SubPageView navItems={navItems} t={t}>
        <div key='subject-messages-view' className='subject-messages-view'>
          <MessagingView
            key='messaging-view'
            replyRole={role}
            title={t('messaging.' + replyRole)}
            dedicatedSubjectId={dedicatedSubjectId}
            t={t}
          />
        </div>
      </SubPageView>
    )
  }
}

const mapStateToProps = ({authenticatedUser}, ownProps) => {
  const replyRole = ownProps.params.replyRole

  return {
    replyRole,
    dedicatedSubjectId: authenticatedUser.id
  }
}

export default withNamespaces(['common'], {wait: true})(connect(mapStateToProps)(Messages))
