import Questionnaire from './Questionnaire'
import OrderedCollection from './OrderedCollection'
import {List} from 'immutable'

export default class Questionnaires extends OrderedCollection(Questionnaire)<Questionnaire> {

  getQuestionnairesByPeriodDay(periodDayId: number): List<Questionnaire> {
    return this.list.filter(questionnaire => questionnaire.periodDayIds.includes(periodDayId)) as List<Questionnaire>
  }
}
