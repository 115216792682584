export enum UIAction {
  ADD_SITE = 'ADD_SITE',
  DELETE_SITE = 'DELETE_SITE',
  ADD_SITE_STUDY = 'ADD_SITE_STUDY',
  DELETE_SITE_STUDY = 'DELETE_SITE_STUDY',
  ADD_SUBJECT = 'ADD_SUBJECT',
  ADD_PERSONNEL = 'ADD_PERSONNEL',
  DELETE_USER = 'DELETE_USER',
  LOCK_USER = 'LOCK_USER',
  ASSIGN_STUDY_GROUP = 'ASSIGN_STUDY_GROUP',
  ADD_STUDY_GROUP = 'ADD_STUDY_GROUP',
  DELETE_STUDY_GROUP = 'DELETE_STUDY_GROUP',
  SEND_RESET_SMS = 'SEND_RESET_SMS',
  ADD_STUDY = 'ADD_STUDY',
  DELETE_STUDY = 'DELETE_STUDY',
  ADD_REMINDER = 'ADD_REMINDER',
  ADD_TRACKER = 'ADD_TRACKER',
  UNLINK_TRACKER = 'UNLINK_TRACKER',
  DELETE_TRACKER = 'DELETE_TRACKER'
}
