import React from 'react'
import {PureComponent} from 'react'

import classNames from 'classnames'
import {formatDate} from '../../../util'

import Questionnaire from '../../../models/Questionnaire'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import Periods from '../../../models/Periods'

interface Props {
  periods: Periods
  questionnaire: Questionnaire
  answer: QuestionnaireAnswer
  navigate: (url: string, silent?: boolean) => any
}

interface State {}

export default class QuestionnaireAnswerListItem extends PureComponent<Props, State> {
  onClick = () => {
    const {navigate, answer} = this.props
    navigate(`/questionnaires/${answer.questionnaireId}/answer/${answer.id}`)
  }

  render() {
    const {questionnaire, answer, periods} = this.props
    const classes = classNames({
      'list-group-item': true,
      readonly: answer.locked
    })

    return (
      <li className={classes} onClick={this.onClick}>
        <div className='row'>
          <span className='time col-xs-12 col-sm-3'>{formatDate(answer.createTime)}</span>
          <span className='period col-xs-12 col-sm-9'>
            {questionnaire ? questionnaire.getDisplayName(periods, answer.periodDayId) : ''}
          </span>
        </div>
      </li>
    )
  }
}
