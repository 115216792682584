import React from 'react'
import {PureComponent} from 'react'
import MessagingView from '../../../components/MessagingView'
import ConversationListView from '../../../components/ConversationListView'

import {Record} from 'immutable'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import ListenerManager, {ListenerContext, MessageListener} from '../../../services/ListenerManager'
import {bind} from '../../../util'
import {ROLES} from '../../../config/constants'

import {connect} from 'react-redux'
import UsersModule from '../../../modules/Users'
import ConversationsModule from '../../../modules/Conversations'
import User from '../../../models/User'
import Users from '../../../models/Users'
import Conversation from '../../../models/Conversation'
import Conversations from '../../../models/Conversations'

import '../../../styles/messages.less'

const SelectedIndex = Record({index: 0})

interface Props extends WithNamespaces {
  authenticatedUser: User
  users: Users
  conversations: Conversations
  location: string
  download: any
  pagination: any
  params: object

  getConversations: (queryParams?, reset?) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  navigate: (url: string) => any
}

interface State {
  index: Record<any, any>
  conversations: Conversation[]
}

class Messages extends PureComponent<Props, State> implements MessageListener {
  constructor(props: Props) {
    super(props)

    const {conversations, users} = props

    this.state = {
      conversations: this.summarizeConversations(conversations, users),
      index: new SelectedIndex()
    }
    bind(this, this.receiveMessage)
  }

  componentDidMount() {
    const {getUsers, authenticatedUser, getConversations} = this.props

    getUsers(ROLES.SUBJECT, null, null, authenticatedUser.selectedSiteStudyId)
    getConversations()
    ListenerManager.addListener(ListenerContext.messageReceived, this)
  }

  componentWillUnmount() {
    ListenerManager.removeListener(ListenerContext.messageReceived, this)
  }

  componentDidUpdate(prevProps) {
    const {authenticatedUser, conversations, users, getUsers, getConversations} = this.props

    if (authenticatedUser.selectedSiteStudyId !== prevProps.authenticatedUser.selectedSiteStudyId) {
      getUsers(ROLES.SUBJECT, null, null, authenticatedUser.selectedSiteStudyId)
      getConversations()
    } else if (prevProps.conversations !== conversations || prevProps.users !== users) {
      this.setState({
        conversations: this.summarizeConversations(conversations, users)
      })
    }
  }

  receiveMessage(_msg) {
    const {getConversations} = this.props
    getConversations()
  }

  getMessageCount = conversation => {
    if (conversation && !conversation.messages.isEmpty()) {
      return conversation.messages.reduce((memo, {personFromId, consumedTimestamp}) => {
        if (personFromId === conversation.dedicatedSubjectId && !consumedTimestamp) {
          return memo + 1
        }

        return memo
      }, 0)
    }

    return 0
  }

  summarizeConversations = (conversations, subjects) => {
    let replyRole = this.props.authenticatedUser.roles.get(0)

    if (subjects.list.isEmpty()) {
      return []
    }

    return subjects.list
      .map(subject => {
        const dedicatedSubjectId = subject.id
        const conversation = conversations.list.find(conversation => {
          return (
            conversation.replyRole === replyRole &&
            conversation.dedicatedSubjectId === dedicatedSubjectId
          )
        })

        const summary = {
          count: this.getMessageCount(conversation),
          dedicatedSubjectId,
          replyRole,
          title: subject.subjectNumber || subject.screeningNumber,
          lastMessage: '',
          lastMessageTimestamp: '',
          conversation
        }

        return summary
      })
      .toArray()
  }

  handleClick = index => this.setState({index: this.state.index.set('index', index)})

  getConversationView = () => {
    const index = this.state.index.get('index')
    const list = this.state.conversations

    if (!list[index]) {
      return <p>{this.props.t('messaging.loadingConversations')}</p>
    }

    return <ConversationListView index={index} list={list} handleClick={this.handleClick}/>
  }

  getMessagingView = () => {
    const index = this.state.index.get('index')
    const list = this.state.conversations
    const {t} = this.props

    if (!list[index]) {
      return <p>{t('messaging.loadingMessages')}</p>
    }

    const {replyRole, dedicatedSubjectId, conversation, title} = list[index]

    return (
      <MessagingView
        replyRole={replyRole}
        dedicatedSubjectId={dedicatedSubjectId}
        conversation={conversation}
        title={title}
        t={t}
      />
    )
  }

  render() {
    const {t} = this.props

    return (
      <div className='personnel-messages-view'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xs-6 conversations'>
              <h1>{t('navigation.subjects')}</h1>
              {this.getConversationView()}
            </div>
            <div className='col-xs-6 messages'>{this.getMessagingView()}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapActionToProps = {
  getUsers: UsersModule.getUsers,
  getConversations: ConversationsModule.getModels
}

const mapStateToProps = ({authenticatedUser, conversations, users}, _ownProps) => {
  return {
    authenticatedUser,
    conversations,
    users
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(Messages)
)
