/**
 * Created by Mauritz Untamala on 11/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Results from './components/Results'

import _ from 'lodash'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import StudiesModule from '../../../modules/Studies'
import StudyModule from '../../../modules/Study'
import App from '../../../models/App'
import Criteria from '../../../models/Criteria'
import StudiesModel from '../../../models/Studies'

import './Studies.less'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  app: App
  studies: StudiesModel
  criteria: Criteria
  location: string
  updateCriteria: (location, criteria) => any
  getStudies: (queryParams?, reset?) => any
  deleteStudy: (model) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
}

export class Studies extends PureComponent<Props, State> {

  componentDidMount() {
    const {getStudies, criteria} = this.props
    getStudies(criteria.getQueryParams(), true)
  }

  componentDidUpdate(prevProps) {

    const {getStudies, criteria} = this.props

    if (!prevProps.criteria.isEqual(criteria)) {
      getStudies(criteria.getQueryParams(), true)
    }
  }

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props
    updateCriteria(location, criteria)
  }

  render() {
    const {app, criteria, studies, deleteStudy, navigate, t} = this.props

    return (
      <div key='studies-view-container' className='studies-view'>
        <h1 className='page-title'>
          {t('studiesView.title')}
        </h1>
        <Results
          ref='results'
          app={app}
          studies={studies.list}
          criteria={criteria}
          deleteModel={deleteStudy}
          onCriteriaChange={this.onCriteriaChange}
          navigate={navigate}
          t={t}/>
      </div>
    )
  }
}

const mapActionToProps = {
  updateCriteria: StudiesModule.updateCriteria,
  getStudies: StudiesModule.getModels,
  deleteStudy: StudyModule.deleteModel,
  navigate
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams ? Criteria.fromQuery(query) : Criteria.getInitialCriteriaWithoutDateRange()
}

const mapStateToProps = ({app, studies}, ownProps) => {
  const criteria = getCriteria(ownProps.location)

  return {
    app,
    criteria,
    studies
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Studies))
