import Collection from './ImmutableCollection'
import {List} from 'immutable'
import {reOrder} from '../util'
import OrderedModelInterface from './OrderedModelInterface'

export default function OrderedCollection(Model) {
  class OrderedCollection<T extends OrderedModelInterface<T>> extends Collection(Model)<T> {

    reOrder(srcOrder: number, dstOrder: number) {

      const updatedList = this.list.map((q: T) => reOrder(q, srcOrder, dstOrder)) as List<T>

      return this.set('list', updatedList)
    }

    findNext(currentOrder: number) {
      return this.list.filter((q) => q.order > currentOrder)
        .sortBy((q) => q.order)
        .first()
    }

    findPrev(currentOrder: number) {
      return this.list.filter((q) => q.order < currentOrder)
        .sortBy((q) => q.order)
        .last()
    }
  }

  return OrderedCollection
}