import ModelModule from './ModelModule'
import SiteStudy from '../models/SiteStudy'
import {getNavItems} from './App'

export class SiteStudyModule extends ModelModule<SiteStudy> {
  constructor() {
    super('site-study', 'siteStudy')
  }

  protected getInitialState = () => new SiteStudy()

  /**
   * @override
   */
  protected deleteSuccessMessageProps({name}) {
    return {name}
  }

  protected onUpdateSuccess(model) {
    return (dispatch) => {
      dispatch(super.onUpdateSuccess(model))
      dispatch(getNavItems())
    }
  }
}

export default new SiteStudyModule()
