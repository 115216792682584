import React from 'react'
import {PureComponent} from 'react'
import CriteriaForm, {Props as FormProps} from './CriteriaForm'

import {List} from 'immutable'

import User from '../models/User'
import SiteStudy from '../models/SiteStudy'

interface Props extends FormProps {
  showLocked?: boolean
}

interface State {}

export default class PropertyFilter extends PureComponent<Props, State> {
  getSelectableUsers = () => {
    const {users, criteria} = this.props

    if (criteria.siteStudies.isEmpty()) {
      return users
    }

    return users.filter(
      user => !!user.siteStudyIds.find(siteStudyId => criteria.siteStudies.includes(siteStudyId))
    ) as List<User>
  }

  getSelectableSiteStudies = () => {
    const {siteStudies, criteria} = this.props

    if (criteria.studies.isEmpty()) {
      return siteStudies
    }

    return siteStudies.filter(siteStudy => criteria.studies.includes(siteStudy.studyId)) as List<SiteStudy>
  }

  render() {
    const {users, siteStudies, children, ...rest} = this.props

    return (
      <CriteriaForm
        users={this.getSelectableUsers()}
        siteStudies={this.getSelectableSiteStudies()}
        {...rest}>
        {children}
      </CriteriaForm>
    )
  }
}
