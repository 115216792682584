/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import UserView from './UserView'
import UsersTable from './UsersTable'

import {List} from 'immutable'
import _ from 'lodash'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import {USER_TYPES} from '../config/constants'

import {connect} from 'react-redux'
import UsersModule from '../modules/Users'
import UserModule from '../modules/User'
import {UIAction} from '../models/UIAction'
import {navigate} from '../modules/Location'
import App from '../models/App'
import Criteria from '../models/Criteria'
import User from '../models/User'
import Users from '../models/Users'

interface Props extends WithNamespaces {
  app: App
  criteria: Criteria
  user: User
  users: Users
  location: any
  params: object
  subjects: boolean
  selectedSiteStudyId: number

  getModels: (queryParams, reset) => any
  getModel: (id, queryParams?) => any
  saveModel: (model, pathComponents?, queryParams?) => any
  resetModel: (type) => any
  updateCriteria: (location, criteria) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
}

export class UsersView extends PureComponent<Props, State> {

  componentDidMount() {
    const {criteria, getModels} = this.props
    getModels(criteria.getQueryParams(), true)
  }

  componentDidUpdate(prevProps) {
    const {selectedSiteStudyId, criteria, subjects, getModels} = this.props

    if (prevProps.selectedSiteStudyId !== selectedSiteStudyId) {
      const siteStudies = List(selectedSiteStudyId && subjects ? [selectedSiteStudyId] : [])

      this.onCriteriaChange(criteria.set('siteStudies', siteStudies))

      // resetModel to ensure subjects are created to the right study site
      const {resetModel} = this.props
      resetModel(criteria.type)
    }

    if (!prevProps.criteria.isEqual(criteria)) {
      getModels(criteria.getQueryParams(), true)
    }
  }

  render() {
    const {subjects, users, t, criteria} = this.props

    return (
      <div className='users-view-container'>
        <h1>{t('usersView.title.' + this.getType())}</h1>
        {this.getAdderView()}
        <UsersTable
          subjects={subjects}
          users={users}
          onRowClick={this.onRowClick}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          t={t}
        />
      </div>
    )
  }

  getType = () => (this.props.subjects ? USER_TYPES.SUBJECT : USER_TYPES.PERSONNEL)

  getAdderView = () => {
    const {app, user, subjects, getModel, saveModel, resetModel, t} = this.props
    const action = subjects ? UIAction.ADD_SUBJECT : UIAction.ADD_PERSONNEL

    if (app.hasAccessToAction(action)) {
      return (
        <div className='panel panel-default'>
          <UserView
            key='user-view'
            newUser={true}
            user={user}
            type={this.getType()}
            wrapperClass='add-user panel-body'
            minimalDetails={true}
            clearAfterSave={true}
            saveModel={saveModel}
            getModel={getModel}
            resetModel={resetModel}
            t={t}
          />
        </div>
      )
    }
  }

  onRowClick = rowData => {
    const {app, navigate} = this.props
    const path = '/admin/user/' + rowData.id

    if (app.hasAccessToPage(path)) {
      navigate(path)
    }
  }

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props
    updateCriteria(location, criteria)
  }
}

const getCriteria = ({location: {query}, subjects}, authenticatedUser) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')
  const type = subjects ? USER_TYPES.SUBJECT : USER_TYPES.PERSONNEL
  const siteStudies = subjects ? [authenticatedUser.selectedSiteStudyId] : []
  const additionalCriteria = {type, siteStudies}

  return hasParams
    ? Criteria.fromQuery(_.merge(query, additionalCriteria))
    : Criteria.getInitialCriteriaWithoutDateRange(additionalCriteria)
}

const mapActionCreators = {
  updateCriteria: UsersModule.updateCriteria,
  getModels: UsersModule.getModels,
  saveModel: UserModule.saveModel,
  getModel: UserModule.getModel,
  resetModel: UserModule.resetModelWithType,
  navigate
}

const mapStateToProps = ({app, user, users, authenticatedUser}, ownProps) => {
  const selectedSiteStudyId = ownProps.subjects ? authenticatedUser.selectedSiteStudyId : null

  return {
    criteria: getCriteria(ownProps, authenticatedUser),
    app,
    user,
    users,
    selectedSiteStudyId
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(UsersView))
