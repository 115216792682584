import React from 'react'
import {Component} from 'react'
import {HelpBlock} from 'react-bootstrap'
import DateTime from './QuestionComponent/DateTime'
import Number from './QuestionComponent/Number'
import Text from './QuestionComponent/Text'
import Select from './QuestionComponent/Select'
import Instruction from './QuestionComponent/Instruction'
import MultiSelect from './QuestionComponent/MultiSelect'

import Question from '../models/Question'
import QuestionAnswer from '../models/QuestionAnswer'
import QuestionComponent, {Type} from '../models/QuestionComponent'
import QuestionAnswerTranslation from '../models/QuestionAnswerTranslation'

import _ from 'lodash'
import classNames from 'classnames'
import {List} from 'immutable'
import {formatDate, getFieldError, samePageAndOrder} from '../util'
import QuestionnaireAnswer from '../models/QuestionnaireAnswer'

interface Props {
  translations: List<QuestionAnswerTranslation>
  isPersonnel: boolean
  enableTranslationFeature: boolean
  writeAccess: boolean
  showError: boolean
  locked: boolean
  question: Question
  questionnaireAnswer: QuestionnaireAnswer
  answer: QuestionAnswer
  onChange: (questionAnswer) => any
  onTranslationChange: (questionAnswerTranslation) => any
  language: string
  t: (key, args?) => any
}

interface State {
  errors: any
}

export default class QuestionView extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    const {answer, questionnaireAnswer, question} = props
    this.state = {errors: answer.validateWithQuestion(question, questionnaireAnswer.answers)}
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {

    const {translations, enableTranslationFeature, writeAccess, showError, question, answer, language} = this.props

    return !translations.equals(nextProps.translations)
      || enableTranslationFeature !== nextProps.enableTranslationFeature
      || writeAccess !== nextProps.writeAccess
      || showError !== nextProps.showError
      || question !== nextProps.question
      || answer !== nextProps.answer
      || language !== nextProps.language
      || this.state !== nextState
  }

  componentDidUpdate(prevProps) {
    if (prevProps.answer !== this.props.answer) {
      const {answer, question, questionnaireAnswer} = this.props
      this.setState({errors: answer.validateWithQuestion(question, questionnaireAnswer.answers)})
    }
  }

  getFieldTranslation(field) {
    const {answer: {order, page}, translations} = this.props

    return translations.find(t => t.field === field) || new QuestionAnswerTranslation({order, page, field})
  }

  renderComponentType = (component: QuestionComponent) => {
    const {
      writeAccess,
      answer,
      showError,
      translations,
      locked,
      isPersonnel,
      enableTranslationFeature,
      questionnaireAnswer,
      t,
      ...rest
    } = this.props
    const field = component.getField()
    const errors = this.state.errors
    const value = answer.getFieldValue(field)
    const translation = enableTranslationFeature ? this.getFieldTranslation(field) : undefined
    let fieldError = showError && getFieldError(field, errors, answer.error, value)

    if (fieldError && component.isCrossFieldConstraint()) {
      fieldError = this.populateValidationErrorCrossFieldDetails(component, questionnaireAnswer, fieldError, t)
    }

    const adjustedProps = _.merge(
      {
        fieldError,
        answer,
        field,
        disabled: writeAccess === false || enableTranslationFeature || locked,
        translation,
        isPersonnel,
        enableTranslationFeature,
        t
      },
      rest
    )

    switch (component.getType()) {
      case Type.datetime:
        return <DateTime key={field} component={component} {...adjustedProps} />

      case Type.number:
        return <Number key={field} component={component} {...adjustedProps} />

      case Type.text:
        return <Text key={field} component={component} {...adjustedProps} />

      case Type.textarea:
        return (
          <Text key={field} componentClass='textarea' component={component} {...adjustedProps} />
        )

      case Type.select:
        return <Select key={field} component={component} {...adjustedProps} />

      case Type.instruction:
        return <Instruction key={field} component={component} {...adjustedProps} />

      case Type.multiselect:
        return <MultiSelect key={field} component={component} {...adjustedProps} />

      default:
        return <div>Unknown</div>
    }
  }

  populateValidationErrorCrossFieldDetails(
    component: QuestionComponent,
    questionnaireAnswer: QuestionnaireAnswer,
    fieldError,
    t: (key, args?
    ) => any) {

    const {target} = component.getOptions()
    const targetQuestion = questionnaireAnswer.questionnaire.questions
      .find(q => samePageAndOrder(q, target))

    switch (fieldError) {
      case QuestionAnswer.targetAnswerNotFound:

        return t(fieldError, {name: targetQuestion.getTitle()})
      case QuestionAnswer.notLessThan:
      case QuestionAnswer.notLessThanOrEqual:
      case QuestionAnswer.notEqual:
      case QuestionAnswer.notGreaterThan:
      case QuestionAnswer.notGreaterThanOrEqual:

        const targetAnswer = questionnaireAnswer.answers
          .find(q => samePageAndOrder(q, component.getOptions().target))
        const value = targetAnswer ? formatDate(targetAnswer.value[target.field]) : undefined

        return t(fieldError, {name: targetQuestion.getTitle(), value})
      default:
        return t(fieldError)
    }
  }

  renderTitle = () => {

    const {question, isPersonnel, enableTranslationFeature} = this.props

    return (
      <label key={`question-title-${question.getPage()}-${question.getOrder()}`}
             className={!isPersonnel || !enableTranslationFeature ? 'col-xs-12' : 'col-xs-6'}>
        {question.getTitle()}
      </label>
    )
  }

  renderComponents = () => {
    const {question} = this.props

    return (
      <div className='col-xs-12'>
        {question.components.map(this.renderComponentType)}
      </div>
    )
  }

  isInstruction = () => {
    const {question} = this.props

    return question.components.every(c => c.getType() === Type.instruction)
  }

  getError = () => {
    const {showError, answer} = this.props
    if (this.isInstruction()) {
      return null
    }

    const {errors} = this.state
    const error = getFieldError('value', errors, answer.error)
    if (error && showError) {
      return <HelpBlock>{error}</HelpBlock>
    }
  }

  render() {
    const error = this.getError()
    const classes = classNames({
      'question-view': true,
      'error-context': !!error,
      'has-error': !!error
    })

    return (
      <div className={classes}>
        <div className='row'>
          {this.renderTitle()}
        </div>

        <div className='row'>
          {this.renderComponents()}
        </div>

        {error}
      </div>
    )
  }
}
