import {createAction, handleActions} from 'redux-actions'
import {add, get, remove, update} from './ActionsCommon'
import ModelInterface from '../models/ModelInterface'
import {infoKey, warningKey} from './Notifications'
import _ from 'lodash'
import {bind, logDebug} from '../util'
import ErrorCode from '../services/ErrorCode'

export default abstract class ModelModule<T extends ModelInterface<any>> {
  protected name: string
  protected context: string
  protected resetModelType: string
  protected getModelRequestType: string
  protected getModelSuccessType: string
  protected getModelFailedType: string
  protected createModelRequestType: string
  protected createModelSuccessType: string
  protected createModelFailedType: string
  protected updateModelRequestType: string
  protected updateModelSuccessType: string
  protected updateModelFailedType: string
  protected deleteModelRequestType: string
  protected deleteModelSuccessType: string
  protected deleteModelFailedType: string
  protected resetModelAction: any
  protected getModelRequestAction: any
  protected getModelSuccessAction: any
  protected getModelFailedAction: any
  protected updateModelRequestAction: any
  protected updateModelSuccessAction: any
  protected updateModelFailedAction: any
  protected createModelRequestAction: any
  protected createModelSuccessAction: any
  protected createModelFailedAction: any
  protected deleteModelRequestAction: any
  protected deleteModelSuccessAction: any
  protected deleteModelFailedAction: any

  protected abstract getInitialState: () => T

  constructor(name: string, context?: string) {
    this.name = name
    this.context = context ? context : name
    this.initializeTypes()
    this.initializeActions()
    bind(
      this,
      this.createModel,
      this.getModel,
      this.saveModel,
      this.updateModel,
      this.deleteModel,
      this.resetModel,
      this.handleError
    )
  }

  public createModel(model, pathComponents?, queryParams?) {
    return (dispatch, getState) => {
      if (model.isValid()) {
        dispatch(this.createModelRequestAction())

        return dispatch(add(this.name, pathComponents, queryParams, model))
          .then((response) => {

            const {[this.context]: model} = getState()

            if (model.isSaving) {
              dispatch(this.createModelSuccessAction(response))
              return dispatch(this.onCreateSuccess(model))
            }
          })
          .catch(error => dispatch(this.handleError(this.createModelFailedAction, error)))
      } else {
        return dispatch(this.createModelFailedAction(model.validate()))
      }
    }
  }

  public getModel(id, queryParams?) {
    return (dispatch, getState) => {
      dispatch(this.getModelRequestAction())

      return dispatch(get(this.name, id, queryParams))
        .then((response) => {

          const {[this.context]: model} = getState()

          if (model.isLoading) {
            return dispatch(this.getModelSuccessAction(response))
          }

        })
        .catch(error => dispatch(this.handleError(this.getModelFailedAction, error)))
    }
  }

  public saveModel(model, pathComponents?, queryParams?) {
    return dispatch => {
      if (model.id) {
        return dispatch(this.updateModel(model))
      } else {
        return dispatch(this.createModel(model, pathComponents, queryParams))
      }
    }
  }

  public updateModel(model) {
    return (dispatch, getState) => {
      if (model.isValid()) {
        dispatch(this.updateModelRequestAction())

        return dispatch(update(this.name, model.id, null, model))
          .then((response) => {

            const {[this.context]: model} = getState()

            if (model.isSaving) {
              dispatch(this.updateModelSuccessAction(response))
              return dispatch(this.onUpdateSuccess(model))
            }

          })
          .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error)))
      } else {
        return Promise.resolve(dispatch(this.updateModelFailedAction(model.validate())))
      }
    }
  }

  public deleteModel(model) {
    return dispatch => {
      dispatch(this.deleteModelRequestAction())

      return dispatch(remove(this.name, model.id))
        .then(() => dispatch(this.deleteModelSuccessAction(model)))
        .then(() => dispatch(this.onDeleteSuccess(model)))
        .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error)))
    }
  }

  public resetModel() {
    return dispatch => dispatch(this.resetModelAction())
  }

  public getActionHandlers() {
    const actionHandlers = {
      [this.createModelRequestType]: state => state.startOfSaving(),
      [this.getModelRequestType]: state => state.startOfLoading(),
      [this.updateModelRequestType]: state => state.startOfSaving(),
      [this.deleteModelRequestType]: state => state.startOfLoading(),
      [this.createModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.getModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.updateModelSuccessType]: (state, {payload}) => state.fromJS(payload),
      [this.deleteModelSuccessType]: () => this.getInitialState(),
      [this.createModelFailedType]: (state, {payload}) => state.endOfSaving(payload),
      [this.getModelFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.updateModelFailedType]: (state, {payload}) => state.endOfSaving(payload),
      [this.deleteModelFailedType]: (state, {payload}) => state.endOfLoading(payload),
      [this.resetModelType]: () => this.getInitialState()
    }

    return _.merge(actionHandlers, this.getAdditionalActionHandlers())
  }

  public getReducer() {
    return handleActions(this.getActionHandlers(), this.getInitialState())
  }

  public getCreateModelSuccessType() {
    return this.createModelSuccessType
  }

  public getUpdateModelSuccessType() {
    return this.updateModelSuccessType
  }

  public getDeleteModelSuccessType() {
    return this.deleteModelSuccessType
  }

  protected getAdditionalActionHandlers() {
    return null
  }

  protected saveSuccessMessageProps(_model) {
    return undefined
  }

  protected onCreateSuccess(model) {
    return _dispatch => {
      infoKey(`${this.context}.saved`, this.saveSuccessMessageProps(model))
    }
  }

  protected onUpdateSuccess(model) {
    return _dispatch => {
      infoKey(`${this.context}.saved`, this.saveSuccessMessageProps(model))
    }
  }

  protected deleteSuccessMessageProps(_model) {
    return undefined
  }

  protected onDeleteSuccess(model) {
    return _dispatch => {
      infoKey(`${this.context}.deleted`, this.deleteSuccessMessageProps(model))
    }
  }

  protected initializeTypes() {
    this.resetModelType = `${this.name}.RESET_MODEL`

    this.getModelRequestType = `${this.name}.GET_MODEL_REQUEST`
    this.getModelSuccessType = `${this.name}.GET_MODEL_SUCCESS`
    this.getModelFailedType = `${this.name}.GET_MODEL_FAILED`

    this.createModelRequestType = `${this.name}.CREATE_MODEL_REQUEST`
    this.createModelSuccessType = `${this.name}.CREATE_MODEL_SUCCESS`
    this.createModelFailedType = `${this.name}.CREATE_MODEL_FAILED`

    this.updateModelRequestType = `${this.name}.UPDATE_MODEL_REQUEST`
    this.updateModelSuccessType = `${this.name}.UPDATE_MODEL_SUCCESS`
    this.updateModelFailedType = `${this.name}.UPDATE_MODEL_FAILED`

    this.deleteModelRequestType = `${this.name}.DELETE_MODEL_REQUEST`
    this.deleteModelSuccessType = `${this.name}.DELETE_MODEL_SUCCESS`
    this.deleteModelFailedType = `${this.name}.DELETE_MODEL_FAILED`
  }

  protected initializeActions() {
    this.resetModelAction = createAction(this.resetModelType)

    this.createModelRequestAction = createAction(this.createModelRequestType)
    this.createModelSuccessAction = createAction(this.createModelSuccessType)
    this.createModelFailedAction = createAction(this.createModelFailedType)

    this.getModelRequestAction = createAction(this.getModelRequestType)
    this.getModelSuccessAction = createAction(this.getModelSuccessType)
    this.getModelFailedAction = createAction(this.getModelFailedType)

    this.updateModelRequestAction = createAction(this.updateModelRequestType)
    this.updateModelSuccessAction = createAction(this.updateModelSuccessType)
    this.updateModelFailedAction = createAction(this.updateModelFailedType)

    this.deleteModelRequestAction = createAction(this.deleteModelRequestType)
    this.deleteModelSuccessAction = createAction(this.deleteModelSuccessType)
    this.deleteModelFailedAction = createAction(this.deleteModelFailedType)
  }

  protected handleError(action, error) {
    return (dispatch, getState) => {
      const errorCode = error.data && error.data.errorCode

      if (errorCode) {
        switch (errorCode) {
          case ErrorCode.BAD_REQUEST:
          case ErrorCode.CONFLICT:
          case ErrorCode.CONNECTION_ERROR:
          case ErrorCode.FORBIDDEN:
          case ErrorCode.INTERNAL_SERVER_ERROR:
          case ErrorCode.NOT_FOUND:
            warningKey('networkError.' + errorCode)
            break
          case ErrorCode.UNAUTHORIZED:
            const {authenticatedUser} = getState()
            if (authenticatedUser.authenticated) {
              warningKey('networkError.' + errorCode)
            } else {
              logDebug('Received unauthorized response for unauthenticated user.')
            }
            break
          default:
            warningKey('networkError.generalError')
        }
      }

      return dispatch(action(error.data ? error.data : error))
    }
  }
}
