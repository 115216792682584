/**
 * Created by Mauritz Untamala on 09/12/15.
 */
import React from 'react'
import Results from './components/Results'
import BaseDownloadComponent, {BaseProps, BaseState} from '../../../components/BaseDownloadComponent'
import Criteria from '../../../components/Criteria'

import _ from 'lodash'
import {withNamespaces} from 'react-i18next'
import {ROLES, USER_TYPES} from '../../../config/constants'

import {connect} from 'react-redux'
import AuditTrailsModule from '../../../modules/Audits'
import {downloadResource, pollDownload} from '../../../modules/Download'
import {navigate} from '../../../modules/Location'
import SiteModule from '../../../modules/Site'
import SiteStudiesModule from '../../../modules/SiteStudies'
import StudiesModule from '../../../modules/Studies'
import UsersModule from '../../../modules/Users'
import CriteriaModel from '../../../models/Criteria'
import {PaginationContext} from '../../../models/Pagination'
import App from '../../../models/App'
import User from '../../../models/User'
import Users from '../../../models/Users'
import Studies from '../../../models/Studies'
import SiteStudies from '../../../models/SiteStudies'
import AuditTrailsModel from '../../../models/AuditTrails'

import './AuditTrails.less'

interface Props extends BaseProps {
  app: App
  criteria: CriteriaModel
  user: User
  users: Users
  studies: Studies
  siteStudies: SiteStudies
  auditTrails: AuditTrailsModel
  location: Location
  pagination: any
  params: object
  updateCriteria: (location: Location, criteria: CriteriaModel) => any
  getAuditTrails: (queryParams?: any, reset?: boolean) => any
  downloadAuditTrails: (criteria: CriteriaModel) => any
  getUsers: (role?: ROLES, type?: USER_TYPES, siteId?: number, siteStudyIds?: number[]) => any
  getStudies: (studyIds: number[]) => any
  getSiteStudies: (siteStudyIds: number[]) => any
  deleteStudy: () => any
  navigate: (url: string) => any
}

interface State extends BaseState {
}

class AuditTrails extends BaseDownloadComponent<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    this.fetchData(this.props)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState)

    const {user, criteria} = this.props

    if (
      (prevProps.user !== user && !user.isSaving && !user.isLoading)
      || !prevProps.criteria.isEqual(criteria)
    ) {
      this.fetchData(this.props, prevProps)
    }
  }

  fetchData = (props: Props, prevProps?: Props) => {

    const {getUsers, getSiteStudies, getAuditTrails, getStudies, criteria, user} = props

    if (!prevProps || !criteria.siteStudies.equals(prevProps.criteria.siteStudies)) {
      getUsers(ROLES.SUBJECT, null, null, criteria.siteStudies.toJS())
    }

    if (!prevProps || !user.studyIds.equals(prevProps.user.studyIds)) {
      getStudies(user.studyIds.toJS())
    }

    if (!prevProps || !user.siteStudyIds.equals(prevProps.user.siteStudyIds)) {
      getSiteStudies(user.siteStudyIds.toJS())
    }

    getAuditTrails(criteria.getQueryParams(), true)
  }

  onDownload = () => this.props.downloadAuditTrails(this.props.criteria)

  loadMore = () => {
    const {getAuditTrails, auditTrails, criteria, pagination} = this.props

    if (!auditTrails.isLoading && pagination.hasMore) {
      getAuditTrails(criteria.getQueryParams())
    }
  }

  onCriteriaChange = criteria => {
    const {getUsers, user, users, siteStudies, updateCriteria, location} = this.props
    criteria = criteria.sanitizeCriteria(user, users, siteStudies)

    if (
      !_.isEqual(this.props.criteria.siteStudies.toJS().sort(), criteria.siteStudies.toJS().sort())
    ) {
      getUsers(ROLES.SUBJECT, null, null, criteria.siteStudies.toJS())
    }

    updateCriteria(location, criteria)
  }

  render() {
    const {
      auditTrails,
      criteria,
      users,
      studies,
      siteStudies,
      pagination,
      navigate,
      t
    } = this.props

    return (
      <div key='audit-trail-view-container' className='audit-trails-view'>
        <h1 className='page-title'>
          {t('auditTrailsView.title')}
        </h1>

        <Criteria
          users={users.list}
          studies={studies.list}
          siteStudies={siteStudies.list}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          t={t}>
          {this.getDownloadButton(this.onDownload, 'button.download')}
        </Criteria>

        <Results
          ref='results'
          audits={auditTrails.list}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          navigate={navigate}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          loading={auditTrails.isLoading}
          t={t}
        />
      </div>
    )
  }
}

const mapActionToProps = {
  pollDownload,
  downloadResource,
  updateCriteria: AuditTrailsModule.updateCriteria,
  getAuditTrails: AuditTrailsModule.getModels,
  downloadAuditTrails: AuditTrailsModule.downloadAuditTrails,
  getUsers: UsersModule.getUsers,
  getStudies: StudiesModule.fetchStudies,
  getSiteStudies: SiteStudiesModule.fetchSiteStudiesWithIds,
  deleteStudy: SiteModule.deleteModel,
  navigate
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), function(key) {
    return key !== ''
  })

  if (hasParams) {
    return CriteriaModel.fromQuery(query)
  }

  return CriteriaModel.getInitialCriteria()
}

const mapStateToProps = (
  {app, authenticatedUser, users, studies, siteStudies, auditTrails, download, pagination},
  ownProps
) => {
  const user = authenticatedUser
  const criteria = getCriteria(ownProps.location).sanitizeCriteria(user, users, siteStudies)

  return {
    app,
    criteria,
    user,
    users,
    studies,
    siteStudies,
    auditTrails,
    download,
    pagination: pagination.getPagination(PaginationContext.AUDIT_TRAIL)
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(AuditTrails))
