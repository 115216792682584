import ModelModule from './ModelModule'
import AdverseEventOptions from '../models/AdverseEventOptions'
import {get} from './ActionsCommon'
import {bind} from '../util'

class AdverseEventOptionsModule extends ModelModule<AdverseEventOptions> {
  constructor() {
    super('event/adverse/select-options', 'adverseEventOptions')
    bind(this, this.getOptions)
  }

  protected getInitialState = () => new AdverseEventOptions()

  public getOptions() {
    return (dispatch) => {
      dispatch(this.getModelRequestAction())

      return dispatch(get('event', ['adverse', 'select-options']))
        .then((options) => dispatch(this.getModelSuccessAction(options)))
        .catch((error) => dispatch(this.handleError(this.getModelFailedAction, error)))
    }
  }
}

export default new AdverseEventOptionsModule()
