/**
 * Created by Mauritz Untamala on 05/01/16.
 */
import moment from 'moment'
import _ from 'lodash'

function getSortFunc(columnSettings) {
  if (columnSettings && columnSettings.sortValue) {
    return function(item) {
      const data = _.get(item, columnSettings.columnName)

      return columnSettings.sortValue(data, item, columnSettings.columnName)
    }
  }

  return function(item) {
    if (!item || !columnSettings) {
      return null
    }

    return _.get(item, columnSettings.columnName)
  }
}

function filterRowData(filter, columns, rowData) {
  const filterLowerCase = filter.toLowerCase()

  return columns
    .map((column) => {
      let value = _.get(rowData, column.columnName)

      if (column.filterValue) {
        value = column.filterValue(value, rowData, column.columnName)
      }

      if (_.isString(value)) {
        return value.toLowerCase().indexOf(filterLowerCase) !== -1
      } else if (_.isNumber(value)) {
        return ('' + value).indexOf(filterLowerCase) !== -1
      } else {
        return false
      }
    })
    .reduce((accu, value) => accu || value, false)
}

export function processedData(tableCriteria, data, columns) {
  if (tableCriteria.filter) {
    data = data.filter(filterRowData.bind(undefined, tableCriteria.filter, columns))
  }

  if (tableCriteria.columnName) {
    const columnSettings = _.find(columns, {columnName: tableCriteria.columnName})

    const func = getSortFunc(columnSettings)

    data = data.sort((object, otherObject) => {
      const value = func(object)
      const otherValue = func(otherObject)

      if (_.isString(value) || _.isString(otherValue)) {
        const first = _.isString(value) ? value : ''
        const second = _.isString(otherValue) ? otherValue : ''
        return first.toLowerCase().localeCompare(second.toLowerCase())
      }

      if (moment.isMoment(value)) {
        return value.diff(otherValue)
      }

      if (_.isNumber(value)) {
        return value - otherValue
      }

      return value < otherValue ? -1 : value > otherValue ? 1 : 0
    })
    data = tableCriteria.sort === 'desc' ? data.reverse() : data
  }

  return data
}
