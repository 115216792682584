import MedicationEvents from '../models/MedicationEvents'
import ModelsModule from './ModelsModule'
import MedicationEvent from './MedicationEvent'

const initialState = new MedicationEvents()

class MedicationEventsModule extends ModelsModule {
  constructor() {
    super('event/medicine', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [MedicationEvent.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [MedicationEvent.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [MedicationEvent.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new MedicationEventsModule()
