import React from 'react'
import {PureComponent} from 'react'
import Input from '../../../../components/Input'
import SelectFormInput from '../../../../components/SelectFormInput'

import classNames from 'classnames'
import {getFieldError} from '../../../../util'

import AccessRights from '../../../../models/AccessRights'
import StudyGroup from '../../../../models/StudyGroup'
import Users from '../../../../models/Users'

interface Props {
  group: StudyGroup
  accessRights: AccessRights
  users: Users
  onChange: (group) => any
  t: (key, params?) => any
}

interface State {
}

class GroupDetails extends PureComponent<Props, State> {

  onFieldChangeCallback = field => value => {

    const {onChange, group} = this.props

    onChange(group.set(field, value))
  }

  createSelectInput = (field, writeAccess, options, multi, placeholder?) => {

    const {group, onChange, t} = this.props

    return (
      <SelectFormInput
        key={'select_' + field}
        model={group}
        modelName='studyGroup'
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange}
        t={t}
      />
    )
  }

  getInputField = (field, writeAccess) => {

    const {group, t} = this.props
    const fieldError = getFieldError(field, group.validate(), group.error)
    const wrapperClassName = classNames(['col-xs-9 col-sm-10', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t('studyGroupView.' + field)}
        labelClassName='col-xs-3 col-sm-2'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={group.get(field)}
        wrapperClassName={wrapperClassName}
        t={t}
      />
    )
  }

  getPersonOptions = () => {
    return this.props.users.list
      .map(user => {
        return {
          label: user.visibleName(),
          value: user.get('id')
        }
      })
      .toArray()
  }

  getInputs = () => {
    const {accessRights, group} = this.props

    return ['name', 'userIds'].map(field => {
      const writeAccess = accessRights.hasWriteAccess('study-group', group, field)

      if (writeAccess !== false && writeAccess !== true) {
        return null
      }

      switch (field) {
        case 'userIds':
          return this.createSelectInput(field, writeAccess, this.getPersonOptions(), true)
        default:
          return this.getInputField(field, writeAccess)
      }
    })
  }

  render() {
    return <form className='form-horizontal'>{this.getInputs()}</form>
  }
}

export default GroupDetails
