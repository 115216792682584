/**
 * Created by Mauritz Untamala on 26/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import {getTimestampFilterValueForReports} from './CommonFilterValueFunctions'

interface Props {
  rowData: any
  data?: any
  column?: any
}

interface State {}

export default class TimestampColumn extends PureComponent<Props, State> {
  render() {
    const {data, rowData, column, children} = this.props

    return (
      <span>
        {getTimestampFilterValueForReports(data, rowData, column)}
        {children}
      </span>
    )
  }
}
