import React from 'react'
import i18n from '../services/I18n'
import {toast} from 'react-toastify'
import Notification from '../components/Notification'
import {NotificationType} from '../config/constants'

export function infoKey(messageKey, args?) {
  return info(i18n.t(messageKey, args))
}

export function info(message) {
  return toast.info(
    <Notification
      type={NotificationType.INFO}
      title={i18n.t(NotificationType.INFO)}
      message={message}
    />
  )
}

export function successKey(messageKey, args?) {
  return success(i18n.t(messageKey, args))
}

export function success(message) {
  return toast.success(
    <Notification
      type={NotificationType.SUCCESS}
      title={i18n.t(NotificationType.SUCCESS)}
      message={message}
    />
  )
}

export function warningKey(messageKey, args?) {
  return warning(i18n.t(messageKey, args))
}

export function warning(message) {
  return toast.warn(
    <Notification
      type={NotificationType.WARNING}
      title={i18n.t(NotificationType.WARNING)}
      message={message}
    />
  )
}

export function errorKey(messageKey, args?) {
  return error(i18n.t(messageKey, args))
}

export function error(message) {
  return toast.error(
    <Notification
      type={NotificationType.ERROR}
      title={i18n.t(NotificationType.ERROR)}
      message={message}
    />
  )
}

export default toast
