import React from 'react'
import {PureComponent} from 'react'
import Input from '../Input'

import classNames from 'classnames'
import {getFieldError} from '../../util'
import AccessRights from '../../models/AccessRights'
import Site from '../../models/Site'

interface Props {
  accessRights: AccessRights
  site: Site
  onChange: (model) => any
  t: (key, params?) => any
}

interface State {
}

export default class SiteDetails extends PureComponent<Props, State> {

  onFieldChangeCallback = field => (value) => {

    const {onChange, site} = this.props

    onChange(site.set(field, value))
  }

  getInputField = (field, writeAccess) => {

    const {site, t} = this.props
    const fieldError = getFieldError(field, site.validate(), site.error)
    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t('siteView.' + field)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={site.get(field)}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    )
  }

  render() {
    const {accessRights, site} = this.props

    const inputs = ['name', 'number'].map(field => {
      const writeAccess = accessRights.hasWriteAccess('site', site, field)

      if (writeAccess !== false && writeAccess !== true) {
        return null
      }

      return this.getInputField(field, writeAccess)
    })

    return <form className='form-horizontal'>{inputs}</form>
  }
}
