import {List} from 'immutable'
import moment from 'moment'
import React, {PureComponent} from 'react'
import TimestampColumn from '../../../../components/TimestampColumn'
import Criteria from '../../../../models/Criteria'
import Results from './Results'
import {getFilterValue, getSortValue} from './TabComponents'
import i18n from '../../../../services/I18n'

const garminSleepEventsColumns = [
  'siteStudyName',
  'person',
  'summaryId',
  'timestamp',
  'startTime',
  'duration'
]

interface Props {
  events: List<GarminSleepEvents>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class GarminSleepEvents extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'startTime':
      case 'timestamp':
        return TimestampColumn
      case 'duration':
        return ({data}) => {
          return <span>{moment.duration(data, 'seconds').locale(i18n.language).humanize()}</span>
        }
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return garminSleepEventsColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    const {events, ...rest} = this.props
    return <Results columns={this.getColumns()} results={events} ref='results' {...rest} />
  }
}
