import {List} from 'immutable'
import React, {PureComponent} from 'react'
import TimestampColumn from '../../../../components/TimestampColumn'
import Criteria from '../../../../models/Criteria'
import Results from './Results'
import DataColectionHourlyModel from '../../../../models/DataCollectionHourly'
import {getFilterValue, getSortValue} from './TabComponents'
import PercentageColumn from '../../../../components/PercentageColumn'

const wearableHourlyColumns = [
  'siteStudyName',
  'person',
  'startTime',
  'endTime',
  'value',
  'percentage'
]

interface Props {
  events: List<DataColectionHourlyModel>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class DataColectionHourly extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'startTime':
      case 'endTime':
        return TimestampColumn
      case 'percentage':
        return PercentageColumn
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return wearableHourlyColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    const {events, ...rest} = this.props
    return <Results columns={this.getColumns()} results={events} ref='results' {...rest} />
  }
}
