import ModelModule from './ModelModule'
import Reminder from '../models/Reminder'

export class ReminderModule extends ModelModule<Reminder> {
  constructor() {
    super('reminder')
  }

  protected getInitialState = () => new Reminder()
}

export default new ReminderModule()
