import React from 'react'
import {Component} from 'react'
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap'

import _ from 'lodash'
import {getFieldError} from '../../../../util'
import User from '../../../../models/User'

const logo = require('../../../../images/orion-pharma-logo-white.svg')

interface Props {
  model: User
  onSubmit: (model) => any
  t: (key, params?) => any
}

interface State {
  model: User
  showError: boolean
}

export default class ResetPasswordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      model: props.model,
      showError: false
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    const {model} = this.props

    // Update state if id changes or if user is updated but it's not saving
    // (we want to avoid the jitter of model when updating
    // as the isSaving is set to true while saving)
    if (prevProps.model.id !== model.id || prevProps.model !== model) {
      let updatedModel = model

      if (model.error) {
        updatedModel = this.state.model.endOfSaving(model.error)
      } else if (model.isSaving) {
        updatedModel = this.state.model.startOfSaving()
      }

      this.setState({model: updatedModel})
    }
  }

  handleSubmit = event => {
    event.preventDefault()

    const {model} = this.state
    const {onSubmit} = this.props

    this.setState({showError: true}, () => {
      if (!model.validatePasswordReset()) {
        onSubmit(model)
      }
    })
  }

  onFieldChange = field => event => {
    const {model} = this.state

    this.setState({
      model: model.set(field, event.target.value) as User
    })
  }

  shouldShowError = () => {
    const {model, showError} = this.state

    return model.error || (showError && !_.isEmpty(model.password))
  }

  getError(errors) {
    const {t} = this.props
    const error = _.first(errors)

    if (this.shouldShowError()) {
      return <p className='error'>{error && t(error)}</p>
    }
  }

  onBlur = () => this.setState({showError: true})

  onFocus = () => this.setState({showError: false})

  getPasswordField = field => {
    const {t} = this.props
    const {model} = this.state
    const inputId = `input-${field}`

    return (
      <FormGroup key={inputId} type='password'>
        <ControlLabel bsClass={`control-label sr-only`}>{t(field)}</ControlLabel>
        <h5>{t(field)}</h5>
        <FormControl
          className={'pass-numeric'}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onFieldChange(field)}
          value={model.get(field) || ''}
          type='password'
          autoComplete='off'
        />
      </FormGroup>
    )
  }

  render() {
    const {t} = this.props
    const {model} = this.state
    let errors = []

    const inputs = ['password', 'password2'].map(field => {
      const fieldError = getFieldError(field, model.validatePasswordReset(), model.error)
      if (fieldError) {
        errors.push(fieldError)
      }
      return this.getPasswordField(field)
    })

    return (
      <form key='reset-password-form' className='form-reset' onSubmit={this.handleSubmit}>
        <img src={logo} alt={t('organization')}/>
        {inputs}
        <button
          id='reset-button'
          className='btn btn-lg btn-info btn-block'
          disabled={_.isEmpty(model.password) || _.isEmpty(model.password2)}
          type='submit'>
          {t('reset')}
        </button>
        {this.getError(errors)}
      </form>
    )
  }
}
