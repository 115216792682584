import React from 'react'
import {Component} from 'react'
import NavigationView from '../../components/NavigationView'

import './NavigationLayout.less'

interface Props {}

interface State {}

class NavigationLayout extends Component<Props, State> {
  render() {
    const {children, ...rest} = this.props

    return (
      <NavigationView key='navigation-view' {...rest}>
        {children}
      </NavigationView>
    )
  }
}

export default NavigationLayout
