import QuestionComponent, {QuestionComponentConstraintOperator, Type} from '../../../models/QuestionComponent'
import React, {PureComponent} from 'react'
import {Checkbox} from 'react-bootstrap'
import {enumValues, getFieldError, getQuestionFieldName} from '../../../util'
import classNames from 'classnames'
import Input from '../../../components/Input'
import _ from 'lodash'
import Select from 'react-select'
import {SelectOption} from '../../../config/constants'
import Questionnaire from '../../../models/Questionnaire'

interface SelectOptionFieldProps {
  option: any
  language: string
  component: QuestionComponent
  idx: number
  onComponentChange: (question: QuestionComponent) => any
  t: (key, params?) => any
}

interface SelectOptionFieldState {
}

class SelectOptionField extends PureComponent<SelectOptionFieldProps, SelectOptionFieldState> {
  getOptions = () => {
    const {component} = this.props
    return component.getOptions() ? component.getOptions().slice() : []
  }

  onSelectOptionChange = value => {
    const {option, component, idx, onComponentChange, language} = this.props
    const options = this.getOptions()

    let optionName = option

    if (!option) {
      optionName = `option${idx}`
      options.push(optionName)
    }

    const localization = component.getLanguageLocalization(language)

    const newLocalization = {
      [language]: typeof localization === 'object' ? {
        ...localization,
        [optionName]: value
      } : {[optionName]: value}
    }

    const newComponent = component.setLocalization(newLocalization).setOptions(options)

    onComponentChange(newComponent)
  }

  onClickRemoveOption = () => {
    const {option, component, language, onComponentChange} = this.props
    const options = _.filter(this.getOptions(), o => o !== option)
    const localization = component.getLanguageLocalization(language)
    const newLocalization = {
      [language]: _.pick(localization, options)
    }
    const newComponent = component.setLocalization(newLocalization).setOptions(options)

    onComponentChange(newComponent)
  }

  renderCircleIcon = () => {
    return <i className='fa fa-circle-o'/>
  }

  render() {
    const {idx, t, language, option, component} = this.props
    const localization = component.getLanguageLocalization(language)
    const value = localization && localization[option]
    const fieldError = getFieldError(
      `localization.${language}.${option}`,
      component.validateByLanguage(language),
      component.error
    )

    const afterInput = !option ? null : (
      <div className='col-xs-1 remove-option'>
        <i className='fa fa-times fa-lg' onClick={this.onClickRemoveOption}/>
      </div>
    )

    return (
      <Input
        key={`input-question-component-option-${option ? option : `option${idx}`}`}
        groupClassName='input-field container-fluid is-flex'
        label={this.renderCircleIcon}
        labelClassName='col-xs-1 text-center select-option-label'
        wrapperClassName='col-xs-10 component-option'
        afterInput={afterInput}
        error={fieldError}
        onChange={this.onSelectOptionChange}
        value={value}
        type='text'
        placeholder={t('questionnaire.form.addOption')}
        t={t}
      />
    )
  }
}

interface ComponentProps {
  questionnaire: Questionnaire
  component: QuestionComponent
  language: string
  onChange: (question: QuestionComponent) => void
  onDelete: (question: QuestionComponent) => void
  t: (key, params?) => any
}

interface ComponentState {
}

export default class QuestionComponentView extends PureComponent<ComponentProps, ComponentState> {

  toggleMandatory = () => {

    const {onChange, component} = this.props

    onChange(component.setMandatory(!component.isMandatory()))
  }

  toggleConstraint = () => {

    const {onChange, component} = this.props

    onChange(component.setCrossFieldConstraint(!component.isCrossFieldConstraint()))
  }

  renderComponentMandatoryCheckbox = () => {
    const {component, t} = this.props

    if (component.isInstruction()) {
      return
    }

    const value = component.isMandatory()

    return (
      <Checkbox
        key={`checkbox-component-${component.getField()}-mandatory`}
        checked={value}
        onChange={this.toggleMandatory}>
        {t('questionnaire.form.mandatory')}
      </Checkbox>
    )
  }

  getOption = (value: string): SelectOption<string> => {
    const {t} = this.props

    return {
      label: value ? t(`questionnaire.form.questionType.${value}`) : t('questionnaire.form.type'),
      value
    }
  }

  getComponentOptions = () => enumValues(Type).map(this.getOption)

  onSelectComponentType = (option: SelectOption<Type>) => {
    const {component, onChange, language} = this.props
    const {value} = option

    const newComponent = component
      .setField(getQuestionFieldName(value))
      .setType(value)
      .setDefaultLocalization(language)
      .setDefaultOptions()

    onChange(newComponent)
  }

  renderSelectComponentType = () => {
    const {component, t} = this.props
    const options = this.getComponentOptions()
    const option = options.find(o => o.value === component.getType())
    const inputKey = 'select-component-type'

    return (
      <div className='form-group'>
        <label className='control-label' htmlFor={inputKey}>{t('questionnaire.form.type')}</label>
        <Select key={inputKey}
                id={inputKey}
                name={inputKey}
                value={option}
                options={this.getComponentOptions()}
                onChange={this.onSelectComponentType}
                noOptionsMessage={() => t('noOptions')}/>
      </div>
    )
  }

  onInstructionChange = value => {
    const {onChange, component, language} = this.props
    let newLocalization = {}
    newLocalization[language] = value

    onChange(component.setLocalization(newLocalization))
  }

  renderInstructionField = (component: QuestionComponent) => {
    const {language, t} = this.props
    const field = Type.instruction

    const fieldError = getFieldError(
      `localization.${language}`,
      component.validateByLanguage(language),
      component.error
    )

    return (
      <Input
        className={`component-${field}`}
        key={`input-textarea-question-component-${field}`}
        error={fieldError}
        onChange={this.onInstructionChange}
        value={component.getLanguageLocalization(language)}
        type='textarea'
        label={t('questionnaire.form.questionType.instruction')}
        t={t}
      />
    )
  }

  renderSelectOptionFields = (component: QuestionComponent) => {
    const {language, t, onChange} = this.props
    const options = component.getOptions() ? component.getOptions().slice() : []

    options.push('') // new option

    const renderOption = (option, idx) => {
      return (
        <SelectOptionField
          key={`select-option-field-${option ? option : `option${idx}`}`}
          option={option}
          language={language}
          component={component}
          idx={idx}
          onComponentChange={onChange}
          t={t}/>
      )
    }

    return (
      <div className='form-group'>
        <label className='control-label'>{t('questionnaire.form.selectOptions')}</label>
        {options.map(renderOption)}
      </div>
    )
  }

  renderComponentLocalization = (
    field,
    localization,
    component,
    validationErrors,
    classes = ['col-xs-12', 'col-sm-6']
  ) => {
    const {onChange, language, t} = this.props

    const fieldError = getFieldError(
      `localization.${language}.${field}`,
      validationErrors,
      component.error
    )

    const onLocalizationChange = value => {
      let newLocalization = Object.assign({}, localization)
      if (!newLocalization[language]) {
        newLocalization[language] = {}
      }
      newLocalization[language][field] = value
      onChange(component.setLocalization(newLocalization))
    }

    const groupClassName = classes ? classNames(classes) : undefined
    const value = localization && localization[language] ? localization[language][field] : ''

    return (
      <Input
        className={`question-component-localization-${field}`}
        groupClassName={groupClassName}
        key={`input-question-component-localization-${field}`}
        error={fieldError}
        onChange={onLocalizationChange}
        value={value}
        label={t(`questionnaire.form.componentLocalization.${field}`)}
        t={t}
      />
    )
  }

  renderComponentOption = (
    field,
    type,
    options,
    component,
    validationErrors,
    classes = ['col-xs-12', 'col-sm-6']
  ) => {
    const {onChange, t} = this.props

    const fieldError = getFieldError(`options.${field}`, validationErrors, component.error)

    const onOptionChange = value => {
      const newOptions = Object.assign({}, options)
      newOptions[field] = value
      onChange(component.setOptions(newOptions))
    }

    const groupClassName = classes ? classNames(classes) : undefined
    const value = options[field]

    return (
      <Input
        className={`question-component-option-${field}`}
        groupClassName={groupClassName}
        key={`input-question-component-option-${field}`}
        error={fieldError}
        onChange={onOptionChange}
        value={value}
        type={type}
        label={t(`questionnaire.form.componentOption.${field}`)}
        t={t}
      />
    )
  }

  renderNumberOptionFields = (component: QuestionComponent) => {
    const {language} = this.props
    const localization = component.getLocalization()
    const options = component.getOptions()
    const validationErrors = component.validateByLanguage(language)

    const renderLocalizationField = (field, classes) => {
      return this.renderComponentLocalization(
        field,
        localization,
        component,
        validationErrors,
        classes
      )
    }

    const renderOptionField = (field, type, classes) => {
      return this.renderComponentOption(field, type, options, component, validationErrors, classes)
    }

    return (
      <div className='row component-localizations'>
        {renderLocalizationField('label', ['col-xs-8', 'col-sm-4'])}
        {renderLocalizationField('unit', ['col-xs-4', 'col-sm-2'])}
        {renderOptionField('min', 'number', ['col-xs-6', 'col-sm-3'])}
        {renderOptionField('decimalDigits', 'number', ['col-xs-6', 'col-sm-3'])}
      </div>
    )
  }

  renderDatetimeFields = (component: QuestionComponent) => {

    if (!component.isCrossFieldConstraint()) {
      return undefined
    }

    const options = component.getOptions()
    const {language, t, questionnaire, onChange} = this.props
    const validationErrors = component.validateByLanguage(language)

    const getConstraintFieldOption = (field) => {
      return getConstraintFieldOptions(field)
        .find(o => _.isEqual(o.value, options[field]))
    }

    const getOperatorOptions = () => {
      return enumValues(QuestionComponentConstraintOperator).map(operator => {
        return {
          label: t(`questionnaire.form.constraintOperator.${operator}`),
          value: operator
        }
      })
    }

    const getTargetOptions = () => {
      return questionnaire.questions
        .filter(question => question.components.some(c => c.isDatetime() && !c.isEqual(component)))
        .toArray()
        .map(question => question.components
          .filter(c => c.isDatetime() && !c.isEqual(component))
          .toArray()
          .map(c => ({
            label: question.getTitle(),
            value: {
              order: question.getOrder(),
              page: question.getPage(),
              field: c.getField()
            }
          })))
        .reduce((accu, arr) => accu.concat(arr), [])
    }

    const getConstraintFieldOptions = (field) => {
      switch (field) {
        case 'operator':
          return getOperatorOptions()
        case 'target':
          return getTargetOptions()
        default:
          throw new Error(`Unsupported field ${field}`)
      }
    }

    const onFieldChange = (field, value) => {
      const newOptions = {...component.getOptions()}
      newOptions[field] = value.value
      onChange(component.setOptions(newOptions))
    }

    const renderConstraintField = (field, classes) => {
      const inputKey = `select-${field}`
      const fieldError = getFieldError(`${field}`, validationErrors, component.error)
      const groupClassName = classNames({'form-group': true, 'has-error': !!fieldError}, classes)
      const option = getConstraintFieldOption(field)
      const options = getConstraintFieldOptions(field)

      return (
        <div className={groupClassName}>
          <label className='control-label' htmlFor={inputKey}>{t(`questionnaire.form.${field}`)}</label>
          <Select key={inputKey}
                  id={inputKey}
                  name={inputKey}
                  value={option}
                  options={options}
                  onChange={change => onFieldChange(field, change)}
                  noOptionsMessage={() => t('noOptions')}/>
          <span className='help-block'>{fieldError && <div className='error'>{t(fieldError)}</div>}</span>
        </div>
      )
    }

    return (
      <div className='row component-localizations'>
        {renderConstraintField('operator', ['col-xs-6', 'col-sm-4'])}
        {renderConstraintField('target', ['col-xs-6', 'col-sm-6'])}
      </div>
    )
  }

  renderQuestionComponent = () => {
    const {component} = this.props

    switch (component.getType()) {
      case Type.instruction:
        return this.renderInstructionField(component)
      case Type.select:
      case Type.multiselect:
        return this.renderSelectOptionFields(component)
      case Type.number:
        return this.renderNumberOptionFields(component)
      case Type.datetime:
        return this.renderDatetimeFields(component)
      default:
        return null
    }
  }

  onDeleteComponent = () => {
    const {onDelete, component} = this.props

    onDelete(component)
  }

  renderDeleteComponent = () => {
    const {t} = this.props

    return (
      <i
        className='fa fa-trash-o delete'
        title={t('questionnaire.delete')}
        onClick={this.onDeleteComponent}/>
    )
  }

  renderComponentValidationCheckbox = () => {
    const {component, t} = this.props

    if (component.getType() !== Type.datetime) {
      return
    }

    const value = component.isCrossFieldConstraint()

    return (
      <Checkbox
        key={`checkbox-component-${component.getField()}-validation`}
        checked={value}
        onChange={this.toggleConstraint}>
        {t('questionnaire.form.validation')}
      </Checkbox>
    )
  }

  renderQuestionConfiguration() {
    return (
      <div className='question-configuration'>
        {this.renderComponentMandatoryCheckbox()}
        {this.renderComponentValidationCheckbox()}
      </div>
    )
  }

  renderComponentError = (componentError) => {
    if (!componentError) {
      return undefined
    }

    return <span className='help-block col-md-12'><div className='error'>{this.props.t(componentError)}</div></span>
  }

  render() {
    const {questionnaire, component} = this.props
    const componentError = questionnaire.error
      && questionnaire.error.fieldErrors
      && questionnaire.error.fieldErrors[`components.${component.getField()}`]
    const classes = classNames(
      ['row', 'question-content'],
      {'has-error': !!componentError}
    )

    return (
      <div className={classes}>
        <div className='col-md-8'>
          {this.renderQuestionComponent()}
          {this.renderQuestionConfiguration()}
        </div>
        <div className='col-md-4'>
          <div className='question-component-actions'>
            {this.renderSelectComponentType()}
            {this.renderDeleteComponent()}
          </div>
        </div>
        {this.renderComponentError(componentError)}
      </div>
    )
  }
}
