import React from 'react'
import {PureComponent} from 'react'
import ReactTooltip from 'react-tooltip'
import {isEventUpdatedAfterEcrf} from '../../../../components/CommonFilterValueFunctions'
import TimestampUtcColumn from '../../../../components/TimestampUtcColumn'

interface Props {
  rowData: any
  t: (key, params?) => any
}

interface State {
}

export default class EcrfTransferTimeColumn extends PureComponent<Props, State> {
  updatedAfterEcrf = () => {
    if (isEventUpdatedAfterEcrf(null, this.props.rowData)) {
      return (
        <i
          className='fa fa-exclamation-triangle'
          aria-hidden='true'
          data-tip={this.props.t('eventUpdatedAfterEcrf')}>
          <ReactTooltip place='top' type='dark' effect='float'/>
        </i>
      )
    }
  }

  render() {
    return <TimestampUtcColumn {...this.props}> {this.updatedAfterEcrf()}</TimestampUtcColumn>
  }
}
