import React from 'react'
import {Component} from 'react'

import LoginModel from '../../../../models/Login'

const logo = require('../../../../images/orion-pharma-logo-white.svg')

interface Props {
  error: any
  isProcessing: boolean
  onSubmit: (username, password) => any
  t: (key, params?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  loginModel: LoginModel
  showError: boolean
}

export default class LoginForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loginModel: new LoginModel(),
      showError: false
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const {loginModel} = this.state

    this.setState({showError: true}, () => {
      if (!loginModel.validate()) {
        this.props.onSubmit(loginModel.username, loginModel.password)
      }
    })
  }

  onFieldChange = loginModel => this.setState({loginModel})

  getError() {
    const {error, t} = this.props

    if (error && error.data && error.data.error) {
      return <p className='error'>{t(error.data.error)}</p>
    }
  }

  navigateToForgotPassword = () => {
    return this.props.navigate('/forgot-password')
  }

  render() {
    const {t} = this.props
    const {loginModel} = this.state

    return (
      <form className='form-signin' onSubmit={this.handleSubmit}>
        <img src={logo} alt='Orion Pharma'/>
        <label htmlFor='input-phone' className='sr-only'>
          {t('phone')}
        </label>
        <h5>{t('username')}</h5>
        <input
          id='input-phone'
          name='username'
          onChange={event => this.onFieldChange(loginModel.set('username', event.target.value))}
          type='phone'
          required={true}
          autoComplete='off'
          className='form-control'
        />
        <label htmlFor='input-pin-code' className='sr-only'>
          {t('password')}
        </label>
        <h5>{t('password')}</h5>
        <input
          id='input-pin-code'
          name='password'
          onChange={event => this.onFieldChange(loginModel.set('password', event.target.value))}
          type='password'
          required={true}
          autoComplete='off'
          className='form-control pass-numeric'
        />
        <span className='forgot-password' onClick={this.navigateToForgotPassword}>
          {t('forgotPassword.question')}
        </span>
        <button id='login-button' type='submit' className='btn btn-lg btn-info btn-block'>
          {t('login')}
        </button>
        {this.getError()}
      </form>
    )
  }
}
