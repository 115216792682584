import {List} from 'immutable'
import _ from 'lodash'
import i18n from '../services/I18n'
import {registerCustomValidators} from '../services/validators'
import {enumToValues, phoneOrEmailRegex, phoneRegex, subjectNumberRegex} from '../util'
import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import InvitationState from './InvitationState'
import {ROLES, USER_TYPES} from '../config/constants'

registerCustomValidators()

const constraints = {
  roles: {
    presence: true,
    roles: true
  }
}

const phoneValidation = {
  pattern: phoneRegex,
  message: 'validate.invalidPhone'
}

const phoneOrEmailValidation = {
  pattern: phoneOrEmailRegex,
  message: 'validate.invalidAccount'
}

const forgotPasswordConstraints = {
  account: {
    format: phoneOrEmailValidation,
    presence: {allowEmpty: false}
  }
}

const phoneNumberConstraints = {
  phone: {
    format: phoneValidation,
    presence: {allowEmpty: false}
  },
  dialCode: {
    presence: {allowEmpty: false}
  }
}

const subjectConstraints = _.merge({}, constraints, {
  screeningNumber: {
    presence: {allowEmpty: false}
  },
  password: {
    format: {
      pattern: '[0-9]{5,}',
      flags: 'i',
      message: 'passwordValidation.tooShortPIN'
    }
  },
  password2: {
    presence(value, object) {
      return !value && object.password
    },
    equality: {
      attribute: 'password',
      message: 'passwordValidation.noMatchPIN'
    }
  },
  siteStudyIds: {
    presence: true,
    listSize: 1
  }
})

const subjectNumberConstraint = {
  subjectNumber: {
    format: {
      pattern: subjectNumberRegex,
      message: 'validate.invalidSubjectNumber'
    },
    presence: {allowEmpty: false}
  }
}

const personnelConstraints = _.merge({}, constraints, {
  username: {
    presence: {allowEmpty: false}
  },
  firstName: {
    presence: {allowEmpty: false}
  },
  lastName: {
    presence: {allowEmpty: false}
  },
  email: {
    presence: {allowEmpty: false},
    email: true
  },
  password: {
    length: {
      minimum: 6,
      message: i18n.t('passwordValidation.tooShortPassword', {min: 6})
    }
  },
  password2: {
    presence(value, object) {
      return !value && object.password
    },
    equality: {
      attribute: 'password',
      message: 'passwordMatchFailed'
    }
  }
})

export const studyUserRoles = [ROLES.DATA_MANAGER, ROLES.LEAD_MONITOR]
export const siteUserRoles = [
  ROLES.INVESTIGATOR,
  ROLES.STUDY_NURSE,
  ROLES.MONITOR,
  ROLES.SITE_MANAGER,
  ROLES.INVITER
]

const interestedKeys = [
  'username',
  'firstName',
  'lastName',
  'email',
  'phone',
  'studyGroupIds',
  'screeningNumber',
  'subjectNumber',
  'status',
  'roles',
  'studyIds',
  'siteIds',
  'siteStudyIds',
  'selectedSiteStudyId',
  'dataLockTime',
  'dateOverride',
  'language',
  'dialCode',
  'accessToken'
]

export enum UserStatus {
  initial = 'initial',
  active = 'active',
  disabled = 'disabled',
  locked = 'locked'
}

export namespace UserStatus {

  export function getAvailableStatusList() {

    return List(enumToValues(UserStatus))
  }
}

const defaultValues = {
  /* Fields for personnel */
  username: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,

  /* Fields for subjects */
  studyGroupIds: List(),
  screeningNumber: undefined,
  subjectNumber: undefined,
  dataLockTime: undefined,
  dateOverride: undefined,

  /* Fields for user-tracker */
  trackerId: undefined,
  periodStart: undefined,
  periodEnd: undefined,

  /* Fields for subjects and site users */
  siteStudyIds: List(),

  /* Fields for site users */
  siteIds: List(),

  /* Fields for study users (data manager etc) */
  studyIds: List(),

  /* Common fields */
  phone: undefined,
  status: UserStatus.initial,
  roles: List([ROLES.SUBJECT]),
  selectedSiteStudyId: undefined,
  password: undefined,
  password2: undefined,

  passwordResetToken: undefined,
  passwordChanged: undefined,
  isValidatingToken: false,

  accessToken: undefined,
  authenticated: false,

  language: undefined,
  dialCode: undefined,

  account: undefined,
  forgotPasswordSuccess: undefined,

  subjectNumberLogin: false
}

export default class User extends BaseModel(defaultValues, interestedKeys, constraints)<User> {
  username: string
  firstName: string
  lastName: string
  email: string

  studyGroupIds: List<number>
  screeningNumber: string
  subjectNumber: string
  dataLockTime: any
  dateOverride: any

  trackerId: number
  periodStart: any
  periodEnd: any

  siteIds: List<number>
  studyIds: List<number>
  siteStudyIds: List<number>

  phone: string
  status: UserStatus
  roles: List<String>
  selectedSiteStudyId: number
  password: string
  password2: string

  passwordResetToken: String
  passwordChanged: String
  isValidatingToken: boolean

  authenticated: boolean
  accessToken: string

  language: string
  dialCode: string

  account: string
  forgotPasswordSuccess: boolean

  subjectNumberLogin: boolean

  public static visibleNameFromJS(js) {
    const key = _.find(['subjectNumber', 'screeningNumber'], field => js[field]) as any
    return key ? js[key] : User.getNameOrUnKnow(js)
  }

  public static getNameOrUnKnow({name, firstName, lastName}) {
    if (name) {
      return name
    }
    if (firstName) {
      name = firstName
    }
    if (lastName) {
      name = (name ? name + ' ' : '') + lastName
    }

    return name ? name : i18n.t('unknown')
  }

  constructor(js?) {
    super(js)

    const user = this.setListArray(['siteStudyIds', 'siteIds', 'studyIds', 'studyGroupIds', 'roles'], js)
    return setDateFields(user, ['dataLockTime', 'dateOverride'])
  }

  fromJS(js): User {
    return new User(js)
  }

  visibleName() {
    return User.visibleNameFromJS(this)
  }

  hasRole(expectedRole) {
    return !!this.roles.find(role => role === expectedRole)
  }

  isSubject() {
    return this.hasRole(ROLES.SUBJECT)
  }

  isPersonnel() {
    return !this.isSubject()
  }

  isSiteUser() {
    return !!_.find(siteUserRoles, role => this.hasRole(role))
  }

  isStudyUser() {
    return !!_.find(studyUserRoles, role => this.hasRole(role))
  }

  isPhoneHiddenWhenUpdate() {
    return !_.isNil(this.id) && _.isUndefined(this.phone) && _.isUndefined(this.dialCode)
  }

  validate() {
    if (!this.isSubject()) {
      return this._validate(personnelConstraints)
    }

    const constraints = _.merge(
      {},
      subjectConstraints,
      this.canSkipPhoneCheck() ? {} : phoneNumberConstraints,
      this.subjectNumberLogin ? subjectNumberConstraint : {}
    )

    return this._validate(constraints)
  }

  validatePasswordReset() {
    const constraints = this.isSubject() ? subjectConstraints : personnelConstraints
    const passwordResetConstraints = Object.assign({
        passwordResetToken: {
          presence: {allowEmpty: false}
        }
      },
      _.pick(constraints, 'password', 'password2')
    )

    return this._validate(passwordResetConstraints)
  }

  validateForgotPassword() {
    return this._validate(forgotPasswordConstraints)
  }

  getInvitationState() {
    if (!this.passwordResetToken && this.passwordChanged) {
      return InvitationState.RESET_DONE
    } else if (this.passwordResetToken && this.passwordChanged) {
      return InvitationState.PENDING_RESET // "previous reset done, currently pending";
    } else if (this.passwordResetToken && !this.passwordChanged) {
      return InvitationState.PENDING_INVITATION // "invitation sent and pending" -
    } else {
      return InvitationState.NOT_SENT // "no invitation sent"
    }
  }

  setAuthenticated(authenticated) {
    return this.set('authenticated', authenticated) as User
  }

  getType() {
    return this.isSubject() ? USER_TYPES.SUBJECT : USER_TYPES.PERSONNEL
  }

  setRoles(roles) {
    return this.set('roles', List(roles || [])) as User
  }

  getLanguage() {
    return this.language
  }

  setLanguage(language) {
    return this.set('language', language) as User
  }

  setDialCode(dialCode) {
    return this.set('dialCode', dialCode) as User
  }

  setPhone(phone) {
    return this.set('phone', phone) as User
  }

  getDialCode() {
    return this.get('dialCode')
  }

  getPhone() {
    return this.get('phone')
  }

  startOfValidatingToken() {
    return this.set('isValidatingToken', true).set('error', null)
  }

  endOfValidatingToken(error?) {
    return this.set('isValidatingToken', false).set('error', error) as User
  }

  setPasswordResetToken(token) {
    return this.set('passwordResetToken', token) as User
  }

  setAccount(account: string) {
    return this.set('account', account) as User
  }

  setForgotPasswordSuccess(forgotPasswordSuccess: boolean) {
    return this.set('forgotPasswordSuccess', forgotPasswordSuccess) as User
  }

  startOfForgotPassword(account: string) {
    return this.setAccount(account).startOfLoading()
  }

  endOfForgotPassword(error?) {
    return error ? this.endOfLoading(error) : this.setForgotPasswordSuccess(true).endOfLoading(undefined)
  }

  setDataLockTime(time) {
    return this.set('dataLockTime', time)
  }

  setDateOverrideTime(time) {
    return this.set('dateOverride', time)
  }

  toggleSubjectNumberLogin() {
    return this.set('subjectNumberLogin', !this.subjectNumberLogin)
  }

  private canSkipPhoneCheck() {

    return this.isPhoneHiddenWhenUpdate() || this.subjectNumberLogin
  }
}
