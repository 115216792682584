/**
 * Created by Mauritz Untamala on 31/12/15.
 */
import React from 'react'
import {Component} from 'react'

interface Props {
  rowData: any
  t?: (key, params?) => any
}

interface State {}

export default class SeverityCausalityOutcomeColumn extends Component<Props, State> {
  getContent = () => {
    return ['severity', 'causality', 'outcome'].map(column => {
      let value = this.props.rowData[column]
      return (
        <div key={column}>
          <span>{this.props.t(column)}:</span>
          <span>{value ? this.props.t('adverseEvent.' + column + '.' + value) : ''}</span>
        </div>
      )
    })
  }

  render() {
    return <div>{this.getContent()}</div>
  }
}
