/**
 * Created by Mauritz Untamala on 02/01/16.
 */
import React from 'react'
import {Component} from 'react'

import classnames from 'classnames'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import {pingAuthorization} from '../modules/App'
import App from '../models/App'

import './Connectivity.less'

const timeToShowOk = 3000

interface Props extends WithNamespaces {
  app: App
  pingAuthorization: () => any
}

interface State {
  connectivity: boolean
  showOk: boolean
}

class Connectivity extends Component<Props, State> {
  private mounted

  constructor(props: Props) {
    super(props)
    this.state = {
      connectivity: false,
      showOk: false
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.app.isConnected() && this.props.app.isConnected()) {
      this.setState({showOk: true}, () => {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({showOk: false})
          }
        }, timeToShowOk)
      })
    } else if (prevProps.app.isConnected() && !this.props.app.isConnected() && this.state.showOk) {
      this.setState({showOk: false})
    }
  }

  currentState = () => {
    if (this.props.app.isConnected()) {
      return this.props.t('connected')
    } else {
      return this.props.t('noConnectivity')
    }
  }

  stateIcon = () => {
    if (this.state.connectivity) {
      return <i className='fa fa-chain'/>
    } else {
      return <i className='fa fa-chain-broken'/>
    }
  }

  connectionOutage = () => {
    const classNames = classnames({
      'message-container': true,
      ok: this.state.showOk
    })

    return (
      <div className='modal-backdrop'>
        <div className='centered-container'>
          <div className='centered'>
            <div className={classNames}>
              <div className='connectivity-icon'>{this.stateIcon()}</div>
              <div className='connectivity-message'>
                <span>{this.currentState()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {app} = this.props
    const connectivity = app.isConnected()

    if (!connectivity || this.state.showOk) {
      return this.connectionOutage()
    } else {
      return <div/>
    }
  }
}

const mapStateToProps = ({app}, _ownProps) => {
  return {app}
}

const mapActionToProps = {
  pingAuthorization
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Connectivity))
