import React from 'react'
import {PureComponent} from 'react'
import QuestionnaireAnswerListItem from './QuestionnaireAnswerListItem'

import {WithNamespaces, withNamespaces} from 'react-i18next'
import {List} from 'immutable'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import PeriodsModule from '../../../modules/Periods'
import QuestionnairesModule from '../../../modules/Questionnaires'
import QuestionnaireAnswersModule from '../../../modules/QuestionnaireAnswers'
import QuestionnairesModel from '../../../models/Questionnaires'
import QuestionnaireAnswers from '../../../models/QuestionnaireAnswers'

import './Questionnaires.less'
import i18n from '../../../services/I18n'
import Pagination, {PaginationContext} from '../../../models/Pagination'
import Periods from '../../../models/Periods'

interface Props extends WithNamespaces {
  pagination: Pagination
  questionnaires: QuestionnairesModel
  questionnaireAnswers: QuestionnaireAnswers
  periods: Periods
  getQuestionnaires: (queryParams?, reset?) => any
  getAnswers: (queryParams?, reset?) => any
  getPeriods: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {}

class QuestionnairesView extends PureComponent<Props, State> {

  componentDidMount() {
    const {getPeriods, getQuestionnaires, getAnswers} = this.props
    getPeriods()
    getQuestionnaires({lang: i18n.language})
    getAnswers()
  }

  componentDidUpdate() {
    const {pagination, questionnaireAnswers, getAnswers} = this.props

    if (!questionnaireAnswers.isLoading && pagination.hasMore) {
      getAnswers()
    }
  }

  answerQuestionnaire = () => this.props.navigate('/questionnaires/select')

  getListItem = answer => {
    const {navigate, periods} = this.props

    return (
      <QuestionnaireAnswerListItem
        periods={periods}
        key={answer.id}
        answer={answer}
        questionnaire={answer.questionnaire}
        navigate={navigate}
      />
    )
  }

  getListItems = () => {
    const {t, questionnaireAnswers, questionnaires} = this.props
    const listItems = []
    const sortOrder = questionnaires.list
      .sort((e1, e2) => e2.order - e1.order)
      .map(q => q.id) as List<number>

    listItems.push(
      <li
        className='list-group-item add'
        key='answer_questionnaire'
        onClick={this.answerQuestionnaire}>
        {t('answerQuestionnaire')}
      </li>
    )
    listItems.push(
      questionnaireAnswers.list
        .sort((e1, e2) => {
          return sortOrder.indexOf(e1.questionnaireId) - sortOrder.indexOf(e2.questionnaireId)
        })
        .map(this.getListItem)
    )

    return listItems
  }

  render() {
    return (
      <div className='container-fluid questionnaires-view'>
        <h1>{this.props.t('questionnairesViewTitle')}</h1>
        <p>{this.props.t('questionnairesViewHelp')}</p>
        <ul className='list-group'>{this.getListItems()}</ul>
      </div>
    )
  }
}

const mapActionToProps = {
  getPeriods: PeriodsModule.getModels,
  getQuestionnaires: QuestionnairesModule.getModels,
  getAnswers: QuestionnaireAnswersModule.getModels,
  navigate
}

const mapStateToProps = ({questionnaires, questionnaireAnswers, pagination, periods}, _ownProps) => {
  return {
    periods,
    questionnaires,
    questionnaireAnswers,
    pagination: pagination.getPagination(PaginationContext.QUESTIONNAIRE_ANSWERS)
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(QuestionnairesView))
