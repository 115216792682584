import AuthLayout from '../../layouts/AuthLayout'
import LoginView from './containers/LoginView'
import LogoutView from './containers/LogoutView'
import ResetPasswordView from './containers/ResetPasswordView'
import ForgotPasswordView from './containers/ForgotPasswordView'

export default {
  component: AuthLayout,
  childRoutes: [
    {path: 'login', component: LoginView},
    {path: 'logout', component: LogoutView},
    {path: 'reset', component: ResetPasswordView},
    {path: 'forgot-password', component: ForgotPasswordView}
  ]
}
