import React from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import './TimelineChart.less'

export interface TimelineChartData {
  color: string
  id: string | number
  key: string | number
  tooltipText?: string
}

interface Props {
  data: TimelineChartData[]
  onClick?: (id: string | number) => void
  showTooltip?: boolean
  className?: string
  title?: string
}

const TimelineChart: React.FunctionComponent<Props> = props => {
  const {data, onClick, showTooltip, className, title} = props

  const renderChartItem = () => {
    return data.map(item => {
      const {color, id, key, tooltipText} = item

      const handleClick = () => {
        if (onClick) {
          onClick(id)
        }
      }

      return (
        <div
          key={key}
          data-tip={tooltipText}
          className='timeline-chart__item'
          onClick={handleClick}
          style={{backgroundColor: color}}
        />
      )
    })
  }

  const getTitle = () => {
    return title ? (<p className='timeline-chart__title'>{title}</p>) : null
  }

  return (
    <div className={classnames('timeline-chart', className)}>
      {getTitle()}
      <div className='timeline-chart__chart'>{renderChartItem()}</div>
      {showTooltip ? <ReactTooltip/> : null}
    </div>
  )
}

TimelineChart.defaultProps = {
  showTooltip: true
}

export default TimelineChart
