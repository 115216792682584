import _ from 'lodash'
import {stringify} from 'querystring'
import {withNamespaces} from 'react-i18next'
import {connect} from 'react-redux'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate, navigateBack} from '../../../modules/Location'
import QuestionnaireModule from '../../../modules/Questionnaire'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'
import PeriodsModule from '../../../modules/Periods'
import {parseIntOrNull} from '../../../util'
import React, {PureComponent} from 'react'
import {BaseProps} from '../../../components/ModelView'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import Periods from '../../../models/Periods'
import User from '../../../models/User'
import Questionnaire from '../../../models/Questionnaire'

interface Props extends BaseProps<QuestionnaireAnswer> {
  periods: Periods
  getPeriods: (queryParams?, reset?) => any
  location: any
  page: number
  authenticatedUser: User
  questionnaire: Questionnaire
  questionnaireId?: number
  getQuestionnaire?: (id, queryParams?) => any
  isPreview?: boolean
  onlyContent?: boolean
  forPrinting?: boolean
}

interface State {
}

class QuestionnaireAnswerView extends PureComponent<Props, State> {

  componentDidMount() {

    const {
      questionnaire,
      questionnaireId,
      getQuestionnaire,
      modelId,
      isPreview,
      periods,
      getPeriods
    } = this.props

    if (!periods.isLoading && periods.list.isEmpty()) {
      getPeriods()
    }

    // Fetch questionnaire if questionnaire answer is new and current questionnaire does not match
    if (!modelId && questionnaire.id !== questionnaireId && !isPreview) {
      getQuestionnaire(questionnaireId)
    }
  }

  render() {
    return <QuestionnaireAnswerComponent {...this.props}/>
  }
}

const mapActionToProps = {
  getPeriods: PeriodsModule.getModels,
  getQuestionnaire: QuestionnaireModule.getModel,
  getModel: QuestionnaireAnswerModule.getModel,
  resetModel: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  saveModel: QuestionnaireAnswerModule.saveModel,
  navigate,
  navigateBack,
  getAccessRights
}

const resolveQuestionnaire = (questionnaires, questionnaire, questionnaireId, questionnaireAnswer) => {

  // For a persisted questionnaire answer we have the questionnaire set in model
  if (questionnaireAnswer.id) {
    return questionnaireAnswer.questionnaire
  }

  if (questionnaire.id !== questionnaireId) {
    return questionnaires.getModelById(questionnaireId) || questionnaire
  }

  return questionnaire
}

const mapStateToProps = (
  {accessRights, authenticatedUser, questionnaire, questionnaires, questionnaireAnswer: model, periods},
  ownProps
) => {
  const {location, params} = ownProps
  const {qid, id} = params
  let {page, periodDayId} = location.query
  const questionnaireId = parseInt(qid, 10)
  const modelId = parseIntOrNull(id)
  periodDayId = parseIntOrNull(periodDayId)
  page = parseIntOrNull(page, 1)

  const resolvedQuestionnaire = resolveQuestionnaire(
    questionnaires,
    questionnaire,
    questionnaireId,
    model
  )
  const updatedQuery = stringify(_.merge({}, location.query, {page: page - 1}))
  const isSubject = authenticatedUser.isSubject()
  const editUrl = isSubject
    ? `/questionnaires/${resolvedQuestionnaire.id}/answer`
    : '/admin/reports?tab=questionnaires'
  const subjectBackHref = page > 1 ? `${location.pathname}?${updatedQuery}` : '/questionnaires'
  const backHref = isSubject ? subjectBackHref : editUrl
  const forceBackHref = isSubject
  const navigateBackOnSaved = true
  const period = periods.getPeriodByDayId(periodDayId)
  const title = period ? period.getDisplayTitle(periodDayId) : ''

  return {
    accessRights,
    authenticatedUser,
    questionnaireId,
    modelId,
    model,
    periods,
    periodDayId,
    questionnaire: resolvedQuestionnaire,
    page,
    wrapperClass: '',
    modelName: 'questionnaire-answer',
    backHref,
    forceBackHref,
    editUrl,
    navigateBackOnSaved,
    title
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {questionnaire, authenticatedUser, periodDayId} = stateProps

  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(questionnaire, periodDayId),
    navigate: authenticatedUser.isPersonnel() ? dispatchProps.navigateBack : dispatchProps.navigate
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps,
  {withRef: true}
)(QuestionnaireAnswerView))
