import React from 'react'
import {Component} from 'react'
import PageAnimation from '../../components/PageAnimation'

import {connect} from 'react-redux'
import {push} from 'react-router-redux'
import {UserIsAuthenticated} from '../../config/policies'

import './AppLayout.less'

interface Props {
  location: any
  redirectUrl: string
  push: any
}

interface State {}

@UserIsAuthenticated
class AppLayout extends Component<Props, State> {
  componentDidMount() {
    const {redirectUrl, push} = this.props

    if (redirectUrl) {
      push(redirectUrl)
    }
  }

  render() {
    return <PageAnimation path={this.props.location.pathname}>{this.props.children}</PageAnimation>
  }
}

const mapActionCreators = {push}

const mapStateToProps = (
  _state,
  {
    location: {
      query: {redirect}
    }
  }
) => {
  return {
    redirectUrl: redirect
  }
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(AppLayout)
