import React from 'react'
import {PureComponent} from 'react'
import UsersTable from '../UsersTable'

import {USER_TYPES} from '../../config/constants'

import {connect} from 'react-redux'
import UsersModule from '../../modules/Users'
import {navigate} from '../../modules/Location'
import App from '../../models/App'
import Users from '../../models/Users'

interface Props {
  siteId: number
  app: App
  users: Users
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {}

class SiteUsers extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    this.props.getUsers(null, USER_TYPES.PERSONNEL, this.props.siteId)
  }

  componentDidUpdate(prevProps) {
    const {siteId, getUsers} = this.props

    if (siteId !== prevProps.siteId) {
      getUsers(null, USER_TYPES.PERSONNEL, siteId)
    }
  }

  onRowClick = rowData => {
    const {app, navigate} = this.props
    const path = `/admin/user/${rowData.id}`

    if (app.hasAccessToPage(path)) {
      navigate(path)
    }
  }

  render() {

    const {users, t} = this.props

    return <UsersTable users={users} onRowClick={this.onRowClick} t={t}/>
  }
}

const mapActionCreators = {
  getUsers: UsersModule.getUsers,
  navigate
}

const mapStateToProps = ({users, app}, _ownProps) => ({
  users,
  app
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(SiteUsers)
