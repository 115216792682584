import StudyGroups from '../models/StudyGroups'
import ModelsModule from './ModelsModule'
import StudyGroup from './StudyGroup'

const initialState = new StudyGroups()

export class StudyGroupsModule extends ModelsModule {
  constructor() {
    super('group', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [StudyGroup.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [StudyGroup.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [StudyGroup.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new StudyGroupsModule()
