import React from 'react'
import {Component} from 'react'
import StudyView from '../../../components/StudyView'

import {connect} from 'react-redux'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  studyId: number
}

interface State {
}

class Study extends Component<Props, State> {
  render() {

    const {studyId, t} = this.props

    return <StudyView key='study-view' studyId={studyId} t={t}/>
  }
}

const mapStateToProps = (_state, ownProps) => {
  const studyId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined

  return {
    studyId
  }
}

const mapActionToProps = {}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Study))
