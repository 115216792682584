import React from 'react'
import {PureComponent} from 'react'

import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'
import ListenerManager, {ConversationListener, ListenerContext, MessageListener} from '../services/ListenerManager'
import {bind} from '../util'

import {connect} from 'react-redux'
import ConversationsModule from '../modules/Conversations'
import MessagesModule from '../modules/Messages'
import MessageModule from '../modules/Message'
import Message from '../models/Message'
import Messages from '../models/Messages'
import User from '../models/User'
import Users from '../models/Users'
import Conversation from '../models/Conversation'

interface Props {
  title: string | React.ReactNode
  dedicatedSubjectId: number
  replyRole: string
  conversation?: Conversation

  authenticatedUser: User
  unreadCount: number
  messages: Messages
  users: Users
  error: any
  getDedicatedMessages: (dedicatedSubjectId, replyRole) => any
  setMessageConsumed: (ids) => any
  postMessage: (model, pathComponents?, queryParams?) => any
  lockConversation: (conversationId, unlock) => any
  setUnreadCount: (count) => any
  handleMessageSent: () => any

  t: (key, params?) => any
}

interface State {
  value: string
}

class MessagingView extends PureComponent<Props, State> implements MessageListener, ConversationListener {
  shouldScrollBottom
  messagesWrapper

  constructor(props: Props) {
    super(props)
    this.state = {value: ''}
    bind(this, this.receiveMessage)
  }

  componentDidMount() {
    this.lockConversation()
    this.sendConsumed()
    this.props.getDedicatedMessages(this.props.dedicatedSubjectId, this.props.replyRole)
    window.addEventListener('unload', this.onBlur)
    window.addEventListener('beforeunload', this.onBlur)
    ListenerManager.addListener(ListenerContext.messageReceived, this)
    ListenerManager.addListener(ListenerContext.conversationUnlocked, this)
  }

  componentDidUpdate(prevProps, _prevState) {
    const {replyRole, dedicatedSubjectId, getDedicatedMessages, messages} = this.props

    if (dedicatedSubjectId !== prevProps.dedicatedSubjectId || replyRole !== prevProps.replyRole) {
      this.lockConversation(true)
      getDedicatedMessages(dedicatedSubjectId, replyRole)
    }

    if (prevProps.messages !== messages) {
      if (this.isConversationLockable()) {
        this.lockConversation()
      }
      this.sendConsumed()
    }

    if (this.shouldScrollBottom) {
      this.scrollToBottom()
    }
  }

  componentWillReceiveProps() {
    this.shouldScrollBottom = this.isScrollPositionAtBottom()
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.onBlur)
    window.removeEventListener('beforeunload', this.onBlur)
    this.lockConversation(true)
    ListenerManager.removeListener(ListenerContext.messageReceived, this)
    ListenerManager.removeListener(ListenerContext.conversationUnlocked, this)
  }

  receiveMessage(msg) {
    const {replyRole, dedicatedSubjectId, getDedicatedMessages} = this.props

    if (msg.dedicatedSubjectId === dedicatedSubjectId && msg.replyRole === replyRole) {
      getDedicatedMessages(dedicatedSubjectId, replyRole)
    }
  }

  receiveUnlockedConversation(unlockedConversation: Conversation) {
    const {conversation} = this.props

    if (conversation && unlockedConversation.id === conversation.id) {
      this.lockConversation()
    }
  }

  scrollToBottom = () => {
    const node = this.getScrollableNode()
    node.scrollTop = node.scrollHeight
  }

  conversationId = () => {
    const {messages} = this.props

    if (!messages.isLoading && !messages.list.isEmpty()) {
      return messages.list.first().conversationId
    }
  }

  isConversationLockable = () => {
    const {conversation} = this.props

    return conversation &&
      (!conversation.personnelLockId || moment(conversation.lockTime).add(2, 'minutes').isBefore(moment()))
  }

  lockConversation = (unlock?) => {
    const id = this.conversationId()

    if (id && !this.isCurrentUserSubject() && !this.props.error) {
      this.props.lockConversation(id, unlock)
    }
  }

  isCurrentUserSubject = () => this.props.authenticatedUser.isSubject()

  getScrollableNode = () => (this.messagesWrapper ? this.messagesWrapper : null)

  isScrollPositionAtBottom = () => {
    const node = this.getScrollableNode()

    if (node) {
      const currentPosition = node.scrollTop + node.offsetHeight
      return Math.abs(currentPosition - node.scrollHeight) < 10
    }

    return false
  }

  onFocus = _e => this.lockConversation()

  onBlur = _e => this.lockConversation(true)

  handleChange = e => {
    this.setState({value: e.target.value})
  }

  senderText = message => {
    const {authenticatedUser} = this.props
    const myId = authenticatedUser.id
    const amISubject = authenticatedUser.isSubject()
    const personFromId = message.personFromId

    if ((personFromId === myId && amISubject) || message.dedicatedSubjectId === personFromId) {
      return message.subjectNumber || message.screeningNumber
    }

    return message.firstName + ' ' + message.lastName
  }

  createMessage = () => {
    // TODO refactor this to store!
    const message = {content: this.state.value} as any
    const {messages, dedicatedSubjectId, authenticatedUser, replyRole} = this.props

    if (!messages.list.isEmpty()) {
      message.responseToId = messages.list.last().get('id')
    }

    message.dedicatedSubjectId = dedicatedSubjectId
    message.replyRole = replyRole
    message.conversationId = this.conversationId()
    message.personFromId = authenticatedUser.id
    message.writtenTimestamp = moment()

    const messageWithUserDetails = _.extend(
      message,
      _.pick(authenticatedUser.toJS(), 'lastName', 'firstName', 'subjectNumber', 'screeningNumber')
    )

    return new Message(messageWithUserDetails)
  }

  postMessage = () => {
    const {postMessage, handleMessageSent} = this.props
    postMessage(this.createMessage())

    this.setState({value: ''}, () => {
      if (handleMessageSent) {
        handleMessageSent()
      }
    })
  }

  sendConsumed = () => {
    const {
      authenticatedUser,
      setMessageConsumed,
      messages,
      setUnreadCount,
      unreadCount
    } = this.props
    const myId = authenticatedUser.id

    const consumedIds = messages.list
      .filter(message => message.personFromId !== myId && !message.consumedTimestamp)
      .map(message => message.id)
      .toJS()

    const consumedCount = consumedIds.length

    if (!_.isEmpty(consumedIds)) {

      setMessageConsumed(consumedIds)
    }

    // Adjust unread count
    const updatedUnreadCount = unreadCount - consumedCount
    setUnreadCount(updatedUnreadCount >= 0 ? updatedUnreadCount : 0)
  }

  onWriteMessageKeyPress = event => {
    if (event.key === 'Enter') {
      this.postMessage()
    }
  }

  messageError = message => {
    if (message.error) {
      return (
        <div className='message-error'>
          {this.props.t('messaging.sendingFailed', {message: message.error.message})}
        </div>
      )
    }
  }

  messages = () => {
    const {authenticatedUser, t, messages} = this.props

    if (messages.list.isEmpty()) {
      return <p>{t('messaging.noMessagesYet')}</p>
    }

    const myId = authenticatedUser.id

    return messages.list.map(message => {
      const sentByMe = message.personFromId === myId
      const classNames = classnames({
        message: true,
        sent: sentByMe,
        received: !sentByMe,
        error: !!message.error
      })

      return (
        <div className='message-wrapper' key={message._id}>
          <div className={classNames}>
            <div className='sender'>{this.senderText(message)}</div>
            <div className='content'>{message.get('content')}</div>
            {this.messageError(message)}
          </div>
          <div className='timestamp'>
            {moment(message.get('writtenTimestamp')).format('D.M.YYYY HH:mm:ss')}
          </div>
        </div>
      )
    })
  }

  lockedWarning = () => {
    const {authenticatedUser, t, conversation} = this.props
    const myId = authenticatedUser.id
    const personnelLockId = conversation ? conversation.personnelLockId : null

    if (!this.isCurrentUserSubject() && personnelLockId && myId !== personnelLockId) {
      return (
        <div className='label label-warning' role='alert'>
          {t('messaging.lockedConversation')}
        </div>
      )
    }
  }

  render() {
    const {title, t} = this.props

    return (
      <div className='messaging-view'>
        <label>
          {title}
          {this.lockedWarning()}
        </label>

        <div
          key='message-wrapper'
          ref={messagesWrapper => (this.messagesWrapper = messagesWrapper)}
          className='messages-wrapper'>
          {this.messages()}
        </div>

        <div key='write-message' className='write-message'>
          <div className='input-group'>
            <input
              type='text'
              value={this.state.value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyPress={this.onWriteMessageKeyPress}
              name='messageContent'
              className='form-control'
            />
            <div className='input-group-btn'>
              <button className='btn btn-default' type='button' onClick={this.postMessage}>
                {t('messaging.send')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapActionToProps = {
  getDedicatedMessages: MessagesModule.fetchDedicatedMessages,
  setMessageConsumed: MessagesModule.setMessageConsumed,
  postMessage: MessageModule.saveModel,
  lockConversation: ConversationsModule.lockConversation,
  setUnreadCount: ConversationsModule.setUnreadCount
}

const mapStateToProps = (
  {authenticatedUser, messages, conversations: {unreadCount}, users},
  _ownProps
) => {
  return {
    authenticatedUser,
    unreadCount,
    messages,
    users,
    error: messages.error
  }
}

export default connect(
  mapStateToProps,
  mapActionToProps
)(MessagingView)
