import BaseModel from './BaseModel'

const interestedKeys = ['reminderId', 'personId', 'phone', 'ctSmsId']

const defaultValues = {
  reminderId: undefined,
  personId: undefined,
  phone: undefined,
  twilioResponse: undefined,
  twilioCallback: undefined,
  smsType: undefined
}

export default class Sms extends BaseModel(defaultValues, interestedKeys)<Sms> {
  reminderId: number
  personId: number
  phone: string
  twilioResponse: any
  twilioCallback: any
  smsType: any

  fromJS(js): Sms {
    return new Sms(js)
  }
}
