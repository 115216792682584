import React from 'react'
import {Component} from 'react'

import classNames from 'classnames'
import {formatDate} from '../../../util'

import AdministrationEventModel from '../../../models/AdministrationEvent'

interface Props {
  event: AdministrationEventModel
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {}

export default class AdministrationEvent extends Component<Props, State> {
  onClick = () => {
    const {navigate, event} = this.props
    navigate(`/event/edit-${event.type}-event/${event.id}`)
  }

  getRescheduledIcon = () => {
    if (this.props.event.rescheduled) {
      return <i className='fa fa-share'/>
    }
  }

  render() {
    const {t, event} = this.props
    const classes = classNames({
      'list-group-item': true,
      readonly: event.get('locked')
    })

    return (
      <li className={classes} onClick={this.onClick}>
        <div className='row'>
          <span className='time col-xs-12 col-sm-3'>
            {formatDate(event.time, event.timeDateOnly)}
          </span>
          <span className='period col-xs-12 col-sm-9'>
            {t('administrationType.' + event.type)} – {t('period.' + event.period)}{' '}
            {this.getRescheduledIcon()}
          </span>
        </div>
      </li>
    )
  }
}
