/**
 * Created by Mauritz Untamala on 22/09/15.
 */
import React from 'react'
import ModelView, {BaseProps, BaseState} from './ModelView'
import UserDetails from './UserDetails'
import SaveButton from './SaveButton'

import _ from 'lodash'
import changeCase from 'change-case'
import {USER_TYPES} from '../config/constants'

import {connect} from 'react-redux'
import {navigate} from '../modules/Location'
import {getAccessRights} from '../modules/AccessRights'
import User from '../models/User'
import SiteStudy from '../models/SiteStudy'

import '../styles/users.less'

interface Props extends BaseProps<User> {
  minimalDetails: boolean
  clearAfterSave: boolean
  profile: boolean
  newUser: boolean
  type: USER_TYPES
  siteStudy: SiteStudy
  isMinimalNewSubject: boolean
}

interface State extends BaseState<User> {
}

class UserView extends ModelView<User, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {model: prevModel} = prevState
    const {siteStudy, isMinimalNewSubject} = this.props
    const {model} = this.state

    if (isMinimalNewSubject
      && _.isUndefined(prevModel.language)
      && _.isUndefined(model.language)
      && siteStudy
      && siteStudy.language) {
      this.updateModel(model.setLanguage(siteStudy.language))
    }

    super.componentDidUpdate(prevProps, prevState)
  }

  getTitle(): any {
    const {model} = this.state
    const {newUser, profile, t} = this.props

    if (newUser) {
      return false
    }

    if (profile) {
      return (
        <h1>
          {t('userView.titleProfile')}
        </h1>
      )
    }

    const tOptions = {name: model ? model.visibleName() : ''}

    if (model.isSubject()) {
      return (
        <h1>{t('userView.title', tOptions)}</h1>
      )
    }

    return (
      <h1>{t('userView.titlePersonnel', tOptions)}</h1>
    )
  }

  /**
   * @override
   */
  onModelSaved() {
    const {modelId, clearAfterSave, resetModel} = this.props

    if (!this.state.model.error) {
      if (!modelId && clearAfterSave) {
        resetModel()
      }
    }
  }

  getContent() {
    const {t, modelName, minimalDetails, type, profile, siteStudy} = this.props
    const {model} = this.state

    return [
      (
        <UserDetails key={'user-details' + model._id}
                     user={model}
                     onChange={this.updateModel}
                     isProfile={profile}
                     isTrackerDateVisible={model ? !!model.trackerId : false}
                     minimalDetails={minimalDetails}
                     type={type}
                     siteStudy={siteStudy}
                     t={t}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      )
    ]
  }
}

const isMinimalDetailsNewSubject = (props: Props): boolean => {
  const {minimalDetails, newUser, type} = props
  return minimalDetails && newUser && type === USER_TYPES.SUBJECT
}

const mapStateToProps = ({accessRights, siteStudies, authenticatedUser}, ownProps) => {
  const {user} = ownProps
  const isMinimalNewSubject = isMinimalDetailsNewSubject(ownProps)
  const siteStudy = (isMinimalNewSubject || (user && user.isSubject()))
    && siteStudies.getModelById(authenticatedUser.selectedSiteStudyId)

  return {
    accessRights,
    siteStudy,
    isMinimalNewSubject
  }
}

const mapActionToProps = {
  getAccessRights,
  navigate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {profile, minimalDetails, user, userId} = ownProps
  const backHref =
    profile || minimalDetails
      ? null
      : user && user.isSubject()
      ? '/admin/subjects'
      : '/admin/personnel'

  const additionalProps = {
    wrapperClass: 'user-view',
    modelId: userId,
    model: user,
    modelName: profile ? 'profile' : 'person',
    backHref
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps, {
    resetModel: () => ownProps.resetModel(ownProps.type)
  })
}

export default connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(UserView)
