import React, {Fragment, PureComponent} from 'react'
import {withNamespaces} from 'react-i18next'
import {connect} from 'react-redux'
import _ from 'lodash'
import i18n from '../../../services/I18n'
import CriteriaModel from '../../../models/Criteria'
import SubPageView from '../../../components/SubPageView'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import User from '../../../models/User'
import AccessRights from '../../../models/AccessRights'
import {PaginationContext} from '../../../models/Pagination'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate} from '../../../modules/Location'
import ReactToPrint from 'react-to-print'
import QuestionnaireAnswers from '../../../models/QuestionnaireAnswers'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'
import QuestionnaireAnswersModule from '../../../modules/QuestionnaireAnswers'
import PeriodsModule from '../../../modules/Periods'
import './Questionnaires.less'
import Periods from '../../../models/Periods'

const getInitialCriteria = () => {
  return CriteriaModel.getInitialCriteria({
    lang: i18n.language
  })
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams
    ? CriteriaModel.fromQuery(query)
    : getInitialCriteria()
}

interface Props {
  location: Location
  updatedQuestionnaireAnswer: QuestionnaireAnswer
  questionnaireAnswers: QuestionnaireAnswers
  periods: Periods
  authenticatedUser: User
  accessRights: AccessRights
  t: (key, params?) => any
  getQuestionnairesAnswers: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
  resetQuestionnaireAnswer: () => any
  saveQuestionnaireAnswer: (answer: QuestionnaireAnswer) => any
  getAccessRights: (objectName, object) => any
  getPeriods: () => any
  criteria: any
  hasMore: boolean
  loading: boolean
}

interface State {
  printTriggered: boolean
  renderForPrinting: boolean
}

const printPageStyle = 'size: A4;  margin: 2mm;'

class AnsweredQuestionnaires extends PureComponent<Props, State> {
  scroll
  printBtn
  private printAreaRef

  constructor(props: Props) {
    super(props)
    this.state = {printTriggered: false, renderForPrinting: false}
  }

  fetchData(reset = false) {
    const {getQuestionnairesAnswers, criteria} = this.props

    return getQuestionnairesAnswers(criteria.getQueryParams(), reset)
  }

  componentDidMount() {
    const {periods, getPeriods} = this.props

    if (!periods.isLoading && periods.list.isEmpty()) {
      getPeriods()
    }

    this.fetchData(true)
  }

  componentDidUpdate(prevProps: Props) {

    const {criteria, getAccessRights, questionnaireAnswers, accessRights, hasMore, loading} = this.props
    this.setupInfiniteScrolling()

    if (!prevProps.criteria.isEqual(criteria)) {

      this.fetchData(true)

    } else if (
      !accessRights.isLoading &&
      !accessRights.accessMap.has('questionnaire-answer') &&
      questionnaireAnswers.list.size > 0
    ) {

      getAccessRights('questionnaire-answer', questionnaireAnswers.list.get(0))

    } else if (this.state.printTriggered && !loading) {

      if (hasMore) {

        this.fetchData()

      } else {

        this.setState({printTriggered: false, renderForPrinting: true}, () => {
          this.printBtn.click()
        })
      }
    }
  }

  componentWillUnmount() {
    this.detachScrollListener()
  }

  scrollListener = () => {
    const element = this.scroll
    const scrollTop = (element && element.scrollTop) || document.body.scrollTop
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight
    const scrolledToBottom = scrollTop + element.clientHeight >= scrollHeight - 20
    const {hasMore, loading} = this.props

    if (this.hasScrollBar() && scrolledToBottom && hasMore && !loading) {
      this.detachScrollListener()
      this.fetchData()
    }
  }

  hasScrollBar = () => {
    const element = this.scroll
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight
    const clientHeight = (element && element.clientHeight) || document.body.clientHeight

    return scrollHeight > clientHeight
  }

  attachScrollListener = () => {
    if (!this.props.hasMore) {
      return
    }

    if (this.scroll) {
      this.scroll.addEventListener('scroll', this.scrollListener)
      this.scroll.addEventListener('resize', this.scrollListener)
      this.scrollListener()
    }
  }

  setupInfiniteScrolling = () => {
    this.attachScrollListener()

    const {hasMore, loading} = this.props

    // Load as much data until view get's scroll bar if there is data to be loaded.
    if (hasMore && !this.hasScrollBar() && !loading) {
      this.fetchData()
    }
  }

  detachScrollListener = () => {
    if (this.scroll) {
      this.scroll.removeEventListener('scroll', this.scrollListener)
      this.scroll.removeEventListener('resize', this.scrollListener)
    }
  }

  setPrintAreaRef = (node) => this.printAreaRef = node

  setPrintBtnRef = (node) => this.printBtn = node && node.triggerRef

  getPrintAreaRef = () => this.printAreaRef

  onPreviewSave = (_questionnaireId, _questionnaireAnswer) => {
    console.log(_questionnaireId, _questionnaireAnswer)
  }

  getTitle(model: QuestionnaireAnswer) {

    const period = this.props.periods.getPeriodByDayId(model.periodDayId)

    return period ? period.getDisplayTitle(model.periodDayId) : ''
  }

  renderQuestionnaireAnswer = (previewQuestionnaireAnswer: QuestionnaireAnswer) => {

    const {
      authenticatedUser,
      location,
      navigate,
      accessRights,
      t,
      updatedQuestionnaireAnswer,
      saveQuestionnaireAnswer,
      periods
    } = this.props

    const {questionnaire} = previewQuestionnaireAnswer
    const model = (updatedQuestionnaireAnswer.isSaving
      ? previewQuestionnaireAnswer.set('isSaving', true)
      : previewQuestionnaireAnswer) as QuestionnaireAnswer
    const period = periods.getPeriodByDayId(model.periodDayId)
    const title = period ? period.getDisplayTitle(model.periodDayId) : ''

    const questionnaireContent = (
      <QuestionnaireAnswerComponent key={`preview-questionnaire-answer-${model.id}`}
                                    wrapperClass='container questionnaire-preview preview-list-item'
                                    isPreview={false}
                                    onlyContent={true}
                                    forPrinting={this.state.renderForPrinting}
                                    model={model}
                                    modelId={model.id}
                                    modelName='questionnaire-answer'
                                    title={title}
                                    questionnaire={questionnaire}
                                    location={location}
                                    page={1}
                                    navigate={navigate}
                                    accessRights={accessRights}
                                    getAccessRights={() => undefined}
                                    authenticatedUser={authenticatedUser}
                                    getModel={() => undefined}
                                    editUrl='/admin/questionnaires'
                                    backHref='/admin/questionnaires'
                                    forceBackHref={true}
                                    resetModel={() => undefined}
                                    saveModel={saveQuestionnaireAnswer}
                                    t={t}/>
    )

    return (
      <Fragment>
        <h3>{previewQuestionnaireAnswer.siteStudyName}</h3>
        <p><b>{t('studyView.studyNumber')}:</b> {questionnaire.studyId}</p>
        <p><b>{t('subjectNumber')}:</b> {previewQuestionnaireAnswer.person}</p>
        <p><b>{t('remindersView.smsTimeUpdated')}:</b> {previewQuestionnaireAnswer.updateTime.toString()}</p>
        {questionnaireContent}
        <div className='questionnaire-tail'>
          <span>{t('questionnaire.ending')}</span>
        </div>
      </Fragment>
    )
  }

  triggerPrint = () => {
    this.setState({printTriggered: true})
  }

  renderPrintBtn = () => {

    const {t} = this.props

    return (
      <button className='print-btn' onClick={this.triggerPrint}>
        <i className='fa fa-print print'/> {t('questionnaire.print')}
      </button>
    )
  }

  renderQuestionnaireAnswers = () => {

    const {updatedQuestionnaireAnswer, questionnaireAnswers} = this.props

    return questionnaireAnswers.list.map(answer => {
      if (updatedQuestionnaireAnswer.id === answer.id) {
        return this.renderQuestionnaireAnswer(updatedQuestionnaireAnswer)
      } else {
        return this.renderQuestionnaireAnswer(answer)
      }
    })
      .toArray()
      .reduce((accu, item) => accu.concat(item), [])
  }

  render() {

    const {t} = this.props
    const navItems = [{label: 'back', href: '/admin/reports', className: 'back-navigation'}]

    return (
      <SubPageView navItems={navItems} t={t}>
        <div className='questionnaire-answers-preview'>
          <div className='scroll-wrapper' ref={scroll => this.scroll = scroll}>
            <div className='preview-heading'>
              {this.renderPrintBtn()}
              <ReactToPrint trigger={() => <button style={{display: 'none'}}/>}
                            pageStyle={printPageStyle}
                            onAfterPrint={() => this.setState({renderForPrinting: false})}
                            ref={this.setPrintBtnRef}
                            content={this.getPrintAreaRef}/>
            </div>
            <div ref={this.setPrintAreaRef} className='print-area'>
              {this.renderQuestionnaireAnswers()}
            </div>
          </div>
        </div>
      </SubPageView>
    )
  }
}

const mapActionToProps = {
  getQuestionnairesAnswers: QuestionnaireAnswersModule.getModels,
  resetQuestionnaireAnswer: QuestionnaireAnswerModule.resetModel,
  saveQuestionnaireAnswer: QuestionnaireAnswerModule.saveModel,
  getPeriods: PeriodsModule.getModels,
  getAccessRights,
  navigate
}

const mapStateToProps = (
  {
    accessRights,
    authenticatedUser,
    questionnaireAnswer,
    questionnaireAnswers,
    pagination,
    periods
  }, ownProps) => {

  const criteria = getCriteria(ownProps.location)
  const hasMore = pagination.getPagination(PaginationContext.QUESTIONNAIRE_ANSWERS).hasMore
  const loading = questionnaireAnswers.isLoading

  return {
    accessRights,
    authenticatedUser,
    updatedQuestionnaireAnswer: questionnaireAnswer,
    questionnaireAnswers,
    criteria,
    hasMore,
    loading,
    periods
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(AnsweredQuestionnaires)
)
