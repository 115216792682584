import ModelModule from './ModelModule'
import AuditTrail from '../models/AuditTrail'

class AuditTrailModule extends ModelModule<AuditTrail> {
  constructor() {
    super('audit', 'auditTrail')
  }

  protected getInitialState = () => new AuditTrail()
}

export default new AuditTrailModule()
