import React from 'react'
import {Component} from 'react'
import SubjectAdverseEventView from '../../components/SubjectAdverseEventView'

interface Props {
  params: any
  location: any
}

interface State {}

export default class AdverseEvent extends Component<Props, State> {
  render() {
    const {params, location} = this.props
    const eventId = params.id ? parseInt(params.id, 10) : undefined

    return (
      <SubjectAdverseEventView
        key='subject-adverse-event-view'
        navigateBackOnSaved={true}
        forceBackHref={true}
        location={location}
        eventId={eventId}
      />
    )
  }
}
