/**
 * Created by Mauritz Untamala on 08/11/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import './RangeDatePicker.less'

interface Props {
  from: any
  to: any
  onChange: (from, to) => any
  t: (key, params?) => any

  id?: string
  className?: string
  keys?: any[]
  maxFrom?: any
  showTimeSelect?: boolean
}

interface State {
}

export default class RangeDatePicker extends PureComponent<Props, State> {
  handleFromChange = date => {

    if (this.props.to && date > this.props.to) {
      date = moment(this.props.to)
    }

    this.props.onChange(date, this.props.to)
  }

  handleToChange = date => {

    this.props.onChange(this.props.from, date)
  }

  getDateFormat = () => {
    const {showTimeSelect} = this.props

    return showTimeSelect ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY'
  }

  render() {
    const {to, from, maxFrom, t, keys, showTimeSelect} = this.props

    return (
      <div className='range-container'>
        <DatePicker
          key={this.props.keys ? this.props.keys[0] : 'from'}
          selected={from}
          onChange={this.handleFromChange}
          maxDate={maxFrom ? maxFrom : null}
          placeholderText={t('rangePicker.start')}
          showTimeSelect={showTimeSelect}
          dateFormat={this.getDateFormat()}
          timeFormat='HH:mm'
        />
        <span className='range-divider'>-</span>
        <DatePicker
          key={keys ? keys[1] : 'to'}
          selected={to}
          onChange={this.handleToChange}
          minDate={from}
          placeholderText={t('rangePicker.end')}
          showTimeSelect={showTimeSelect}
          dateFormat={this.getDateFormat()}
          timeFormat='HH:mm'
        />
      </div>
    )
  }
}
