import React from 'react'
import {Component} from 'react'
import UserView from '../../components/UserView'

import {connect} from 'react-redux'
import User from '../../modules/AuthenticatedUser'
import UserModel from '../../models/User'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  user: UserModel
  getModel: (id, queryParams?) => any
  saveModel: (model, pathComponents?, queryParams?) => any
  resetModel: () => any
}

class Profile extends Component<Props, any> {
  render() {
    const {user, getModel, saveModel, resetModel, t} = this.props

    return (
      <UserView
        key='user-view'
        user={user}
        profile={true}
        userId={user.id}
        getModel={getModel}
        saveModel={saveModel}
        resetModel={resetModel}
        t={t}
      />
    )
  }
}

const mapActionCreators = {
  getModel: User.getModel,
  saveModel: User.saveModel,
  resetModel: User.resetModel
}

const mapStateToProps = ({authenticatedUser}, _ownProps) => ({
  user: authenticatedUser
})

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(Profile))
