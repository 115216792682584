import React from 'react'
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView'
import SaveButton from '../../../components/SaveButton'

import {withNamespaces} from 'react-i18next'
import changeCase from 'change-case'
import {List} from 'immutable'

import {connect} from 'react-redux'
import {navigate, navigateBack} from '../../../modules/Location'
import QuestionnaireModule from '../../../modules/Questionnaire'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'
import {getAccessRights} from '../../../modules/AccessRights'
import PeriodsModule from '../../../modules/Periods'
import SiteStudyModule from '../../../modules/SiteStudy'
import StudyModule from '../../../modules/Study'

import Question from '../../../models/Question'
import Questionnaire from '../../../models/Questionnaire'
import User from '../../../models/User'
import Study from '../../../models/Study'
import SiteStudy from '../../../models/SiteStudy'

import './Questionnaires.less'
import Tabs from '../../../components/Tabs'
import QuestionnaireTranslations from './QuestionnaireTranslations'
import QuestionnaireForm from './QuestionnaireForm'
import {stringify} from 'querystring'
import _ from 'lodash'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import classNames from 'classnames'
import Periods from '../../../models/Periods'

interface Props extends BaseProps<Questionnaire> {
  authenticatedUser: User
  date: Date
  page: number
  duplicated: boolean
  siteStudy: SiteStudy
  study: Study
  periods: Periods
  getSiteStudy: (id, queryParams?) => any
  getStudy: (id, queryParams?) => any
  getPeriods: (queryParams?) => any
  location: any
  tab: string
  nextQuestionnaire: Questionnaire
  prevQuestionnaire: Questionnaire
  questionnaireAnswer: QuestionnaireAnswer
  createPreview(questionnaire)
  getAccessRights(objectName, object)
}

interface State extends BaseState<Questionnaire> {
  showError: boolean
  pageQuestions: List<Question>
  validationErrors: any
  questionnaireAnswer: QuestionnaireAnswer
}

const formId = 'form'
const translationsId = 'translations'
const previewId = 'preview'
const tabsIds = [formId, translationsId, previewId]

class QuestionnaireView extends ModelView<Questionnaire, Props, State> {

  constructor(props) {
    super(props)

    this.state = Object.assign(
      {},
      this.state,
      {
        showError: false,
        validationErrors: undefined,
        questionnaireAnswer: new QuestionnaireAnswer()
      }
    )
  }

  componentDidMount() {
    super.componentDidMount()

    const {
      authenticatedUser,
      getSiteStudy,
      getPeriods,
      questionnaireAnswer
    } = this.props

    getSiteStudy(authenticatedUser.selectedSiteStudyId)
    getPeriods()
    this.setState({questionnaireAnswer})
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)

    const {duplicated, siteStudy, study, page, getStudy, questionnaireAnswer} = this.props
    const {model, showError} = this.state

    if (showError && model.isValid()) {
      this.setState({showError: false})
    }

    if (prevState.model.isSaving && !model.isSaving) {
      this.onModelSaved()
    }

    if (!model.id && model.studyId !== siteStudy.studyId) {
      this.updateModel(model.set('studyId', siteStudy.studyId))
    }

    if (duplicated && model.id) {
      this.updateModel(model.set('id', undefined))
    }

    if (prevState.model !== model || prevProps.page !== page) {
      const validationErrors = model.validate()
      this.setState({validationErrors, showError: !!(validationErrors && model.id)})
    }

    if (!!siteStudy.studyId && (!study.isLoading && study.id !== siteStudy.studyId)) {
      getStudy(siteStudy.studyId)
    }

    if (prevProps.questionnaireAnswer !== questionnaireAnswer) {
      this.setState({questionnaireAnswer})
    }
  }

  goToQuestionnaire = (id: number) => () => {
    const {tab, navigate, t} = this.props
    const params = tab ? `?tab=${tab}` : ''

    if (this.isFormDirty() && !confirm(t('confirm.unsavedEntries'))) {
      return
    }

    navigate(`/admin/questionnaires/${id}${params}`)
  }

  renderQuestionnaireNavigationLink = (questionnaire: Questionnaire, classNameArray: string[]) => {

    const className = classNames(classNameArray)

    if (!questionnaire) {
      return <span className={className}/>
    }

    return (
      <span className={className} onClick={this.goToQuestionnaire(questionnaire.id)}>
        {questionnaire.getTitle()}
      </span>
    )
  }

  /**
   * @override
   */
  getTitle() {

    const {model} = this.state
    const {modelName, prevQuestionnaire, nextQuestionnaire, t} = this.props

    return (
      <React.Fragment>
        <div className='questionnaire-editor-navbar'>
          <div className='questionnaire-navbar-container'>
            <div className='questionnaire-navbar'>
              {this.renderQuestionnaireNavigationLink(prevQuestionnaire, ['prev-questionnaire'])}

              <SaveButton
                key='questionnaire-update-button'
                model={model}
                accessRightsKey={changeCase.paramCase(modelName)}
                save={this.save}
                onModelSaved={this.onModelSaved}
                t={t}
              />

              {this.renderQuestionnaireNavigationLink(nextQuestionnaire, ['next-questionnaire'])}
            </div>
          </div>
        </div>

        <div className='questionnaire-editor-title'>
          <h1>{t('questionnaire.title')}</h1>
        </div>
      </React.Fragment>
    )
  }

  /**
   * @override
   */
  updateModel = model => this.setState({model: model.cleanupTranslations()})

  getActiveTab = () => {
    const {tab} = this.props

    return tab ? tab : tabsIds[0]
  }

  onTabClick = (tab, event) => {

    event.preventDefault()

    const {navigate, modelId, page} = this.props
    const target = modelId ? modelId : 'new'
    const query = stringify({tab, page})

    navigate(`/admin/questionnaires/${target}?${query}`)
  }

  getTabComponent = activeTab => {

    const {
      authenticatedUser,
      duplicated,
      siteStudy,
      study,
      page,
      location,
      navigate,
      modelName,
      accessRights,
      t,
      modelId,
      createPreview,
      getAccessRights,
      periods
    } = this.props

    const {model, validationErrors, showError, questionnaireAnswer} = this.state

    switch (activeTab) {
      case formId:
        return (
          <QuestionnaireForm authenticatedUser={authenticatedUser}
                             page={page}
                             duplicated={duplicated}
                             siteStudy={siteStudy}
                             study={study}
                             updateModel={this.updateModel}
                             location={location}
                             navigate={navigate}
                             model={model}
                             modelName={modelName}
                             accessRights={accessRights}
                             periods={periods}
                             validationErrors={validationErrors}
                             showError={showError}
                             t={t}/>
        )
      case translationsId:
        return (
          <QuestionnaireTranslations updateModel={this.updateModel}
                                     model={model}
                                     study={study}
                                     t={t}/>
        )
      case previewId:
        const {periodDayId} = questionnaireAnswer
        const period = periods.getPeriodByDayId(periodDayId)
        const title = period ? period.getDisplayTitle(periodDayId) : ''

        const previewProps = {
          authenticatedUser,
          location,
          page,
          navigate,
          accessRights,
          t,
          resetModel: () => createPreview(model),
          isPreview: true,
          questionnaire: model,
          questionnaireId: modelId,
          title,
          wrapperClass: 'questionnaire-preview',
          model: questionnaireAnswer,
          modelName: 'questionnaire-answer',
          getAccessRights,
          saveModel: this.onPreviewSave
        } as any

        return (
          <QuestionnaireAnswerComponent {...previewProps}/>
        )
      default:
        return null
    }
  }

  onPreviewSave = (questionnaireAnswer) => {
    this.setState({questionnaireAnswer})
  }

  getTabIdsWithError = () => {

    const {model, validationErrors, showError} = this.state

    if (!showError || _.isEmpty(validationErrors)) {
      return undefined
    }

    const additionalLanguageCodes = model.getAdditionalLanguages()
      .filter(l => l.isPublished())
      .map(l => l.language)

    const publishedAdditionalLanguageError = Object.keys(validationErrors)
      .some(errorKey => {

        const testErrorKey = (lang) => new RegExp(`.*\.${lang}(\..*|$)`).test(errorKey)

        return additionalLanguageCodes.some(testErrorKey)
      })

    return publishedAdditionalLanguageError ? [translationsId] : undefined
  }

  getTabContent = () => {

    const activeTab = this.getActiveTab()

    return (
      <Tabs
        key={'tab-' + activeTab}
        activeTab={activeTab}
        onTabClick={this.onTabClick}
        context='questionnaire.tabs'
        tabIds={tabsIds}
        tabIdsWithError={this.getTabIdsWithError()}>
        {this.getTabComponent(activeTab)}
      </Tabs>
    )
  }

  getContent() {

    return this.getTabContent()
  }
}

const mapStateToProps = (
  {accessRights, authenticatedUser, questionnaires, questionnaire, questionnaireAnswer, siteStudy, study, periods},
  ownProps
) => {
  const {location: {query: {page, duplicate, id, tab}}, params: {qid}} = ownProps
  const duplicated = duplicate ? !!duplicate : false
  const questionnaireId = qid ? parseInt(qid, 10) : duplicated ? parseInt(id, 10) : undefined
  const currentPage = page ? parseInt(page, 10) : 1

  const forceBackHref = true
  const navigateBackOnSaved = false
  const backHref = '/admin/questionnaires'
  const editUrl = `/admin/questionnaires`

  const nextQuestionnaire = questionnaires.findNext(questionnaire.order)
  const prevQuestionnaire = questionnaires.findPrev(questionnaire.order)

  return {
    siteStudy,
    study,
    accessRights,
    authenticatedUser,
    modelId: questionnaireId,
    model: questionnaire,
    questionnaireAnswer,
    periods,
    page: currentPage,
    duplicated,
    wrapperClass: 'questionnaire-management-view questionnaire-editor',
    modelName: 'questionnaire',
    forceBackHref,
    backHref,
    editUrl,
    navigateBackOnSaved,
    tab,
    nextQuestionnaire,
    prevQuestionnaire
  }
}

const mapActionToProps = {
  getModel: QuestionnaireModule.getModel,
  resetModel: QuestionnaireModule.resetModel,
  createPreview: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  saveModel: QuestionnaireModule.saveModel,
  navigate,
  navigateBack,
  getAccessRights,
  getStudy: StudyModule.getModel,
  getSiteStudy: SiteStudyModule.getModel,
  getPeriods: PeriodsModule.getModels
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(QuestionnaireView))
