import React, {PureComponent} from 'react'
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer'

import moment from 'moment'
import {DATE_FORMAT_TIMEZONE} from '../../../../config/constants'

import AuditTrail from '../../../../models/AuditTrail'
import {Panel} from 'react-bootstrap'
import User from '../../../../models/User'

interface Props {
  audit: AuditTrail
  t?: (key: string, param?: object) => any
}

interface State {
  panelExpanded: boolean
}

const newStyles = {
  variables: {
    light: {
      codeFoldBackground: 'transparent',
      gutterBackground: 'transparent'
    }
  }
}

export default class Details extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      panelExpanded: false
    }
  }

  onPanelToggle = () => {
    this.setState({panelExpanded: !this.state.panelExpanded})
  }

  renderFormGroup = (lbl, content) => {
    return (
      <div className='form-group'>
        <label className='col-xs-2'>{lbl}</label>

        <div className='col-xs-10'>
          {content}
        </div>
      </div>
    )
  }

  renderFormControl = (lbl, content) => {
    return this.renderFormGroup(lbl, <div className='form-control'>{content}</div>)
  }

  resolveUsername = data => {
    let name = User.visibleNameFromJS(data)

    if (!name) {
      name = data && data.name ? data.name : this.props.t('unknown')
    }

    return name
  }

  who = () => {
    const {t, audit} = this.props
    const who = audit.who || {}
    const content = JSON.stringify(who, undefined, 2)

    return this.renderFormGroup(t('column.user'), (
      <Panel eventKey='1' className='who' expanded={this.state.panelExpanded} onToggle={this.onPanelToggle}>
        <Panel.Heading className={this.state.panelExpanded && 'expanded'}>
          <Panel.Title componentClass='span' toggle={true}>
            <span className='username'>{this.resolveUsername(who)}</span>
            <span className='caret'/>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <pre className='json'>{content}</pre>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    ))
  }

  what = () => {
    const {t, audit} = this.props
    const label = t('column.what')
    const content = audit.what

    return this.renderFormControl(label, content)
  }

  when = () => {
    const {t, audit} = this.props
    const label = t('column.time')
    const content = audit.time ? moment(audit.time).format(DATE_FORMAT_TIMEZONE) : ''

    return this.renderFormControl(label, content)
  }

  diffString = (object) => {
    return object ? JSON.stringify(object, undefined, 2) : ''
  }

  changes = () => {
    const {t, audit: {original, updated}} = this.props
    const label = t('column.content')

    return this.renderFormGroup(label, (
      <div className='panel panel-default changes'>
        <ReactDiffViewer oldValue={this.diffString(original)}
                         newValue={this.diffString(updated)}
                         styles={newStyles}
                         hideLineNumbers={true}
                         showDiffOnly={false}
                         compareMethod={DiffMethod.WORDS}
                         useDarkTheme={false}
                         splitView={false}/>
      </div>
    ))
  }

  render() {
    return (
      <div className='form-horizontal'>
        {this.who()}
        {this.what()}
        {this.when()}
        {this.changes()}
      </div>
    )
  }
}
