import React from 'react'
import {PureComponent} from 'react'
import moment from 'moment'
import classNames from 'classnames'

import {Panel} from 'react-bootstrap'
import {formatDate} from '../../util'
import TimePicker from './TimePicker'

interface AccordionProps {
    id?: string
    wrapperClassName?: string
    label?: string | object
    dateOnly?: boolean
    readonly?: boolean
    showReset?: boolean
    showNow?: boolean
    error?: object | string
    time: any
    showDateOnlyToggle: boolean
    onChange: (value) => any
    t: (key, params?) => any
    minDate?: moment.Moment
    maxDate?: moment.Moment
}

interface AccordionState {
    panelExpanded: boolean,
    showError: boolean
}

export default class TimePickerAccordion extends PureComponent<AccordionProps, AccordionState> {
    constructor(props: AccordionProps) {
        super(props)

        this.state = {
            panelExpanded: false,
            showError: false
        }
    }

    getHeaderTime = () => {
        const {time, dateOnly, t} = this.props
        if (time) {
        return formatDate(time, dateOnly)
        }

        return t('timeNotSet')
    }

    getHeader = () => {
        const {label, readonly} = this.props

        const classNameHeader = classNames({
        'time-picker-header': true,
        'with-label': !!label,
        'cursor-not-allowed': readonly
        })

        return (
        <Panel.Heading>
            <Panel.Title componentClass='span' className={classNameHeader} toggle={true}>
            <span className='time-picker-label'>{label ? label : ' '}</span>
            <span className='time-picker-time'>{this.getHeaderTime()}</span>
            </Panel.Title>
        </Panel.Heading>
        )
    }

    getError = () => {
        if (this.props.error) {
        return <div className='error'>{this.props.error}</div>
        }

        return null
    }

    onPanelToggle = () => {
        const {readonly} = this.props

        if (readonly) {
        return
        }

        this.setState({panelExpanded: !this.state.panelExpanded})
        this.setState({showError: true})
    }

    render() {

        const {
        t,
        time,
        dateOnly,
        showDateOnlyToggle,
        showNow,
        showReset,
        onChange,
        error,
        readonly,
        minDate,
        maxDate
        } = this.props

        const {panelExpanded, showError} = this.state

        const wrapperClasses = classNames({
        'time-picker-component': true,
        'input-group': true,
        'panel-group': true,
        'has-error': showError && !panelExpanded && !!error
        })

        return (
        <div className={wrapperClasses}>
            <Panel eventKey='1' expanded={panelExpanded} onToggle={this.onPanelToggle}>
            {this.getHeader()}
            <Panel.Collapse>
                <Panel.Body>
                <TimePicker
                    key='timepicker'
                    t={t}
                    time={time}
                    dateOnly={dateOnly}
                    showDateOnlyToggle={showDateOnlyToggle}
                    showNow={showNow}
                    showReset={showReset}
                    onChange={onChange}
                    disabled={readonly}
                    error={error}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                </Panel.Body>
            </Panel.Collapse>
            </Panel>
            {showError && !panelExpanded && this.getError()}
        </div>
        )
    }
}
