import React, {PureComponent} from 'react'
import Input from '../../../../components/Input'
import Select from 'react-select'
import _ from 'lodash'
import classNames from 'classnames'
import {enumToValues, getFieldError} from '../../../../util'
import {SelectOption} from '../../../../config/constants'
import PeriodDays from './PeriodDays'

import Period from '../../../../models/Period'
import User from '../../../../models/User'
import StudyModel from '../../../../models/Study'

import '../../Periods/Periods.less'
import AccessRights from '../../../../models/AccessRights'
import PeriodDay from '../../../../models/PeriodDay'

interface Props {
  authenticatedUser: User
  study: StudyModel
  updateModel: (questionnaire) => any
  location: any
  model: Period
  modelName: string
  accessRights: AccessRights
  t: (key, params?) => any
  showError: boolean
  validationErrors: any
  navigate: (url: string, silent?: boolean) => any
  defaultLanguage: string
}

interface State {
}

type LanguageOption = SelectOption<string>

enum FormFields {
  language = 'language',
  name = 'name',
  periodDays = 'periodDays'
}

const fieldInputs = enumToValues(FormFields)

class PeriodForm extends PureComponent<Props, State> {

  updateModel = (model) => this.props.updateModel(model)

  getStudyLanguageOption = (languageCode: string): LanguageOption => {
    const {t} = this.props
    return {
      label: t(`language.${languageCode}`),
      value: languageCode
    }
  }

  getStudyLanguageOptions = () => {
    const {study} = this.props

    return study.languages.toArray().map(this.getStudyLanguageOption)
  }

  onFieldChangeCallback = (field, value) => {
    const {model, defaultLanguage} = this.props

    this.updateModel(model.setField(field, value, defaultLanguage))
  }

  renderDefaultLanguageSelectInput = (
    writeAccess: boolean,
    onChange?: (option) => any,
    multi?: boolean,
    placeholder?: string
  ) => {
    const {t, model, defaultLanguage, validationErrors, showError} = this.props
    const field = 'languages'
    const fieldError = getFieldError(
      field,
      validationErrors,
      model.error
    )
    const options = this.getStudyLanguageOptions()
    const value = defaultLanguage && options.find((option) => option.value === defaultLanguage)
    const wrapperClasses = classNames({
      'default-language': true,
      'has-error': showError && !!fieldError
    })
    const inputKey = `select-period-${field}`

    return (
      <div key='select-default-language' className={wrapperClasses}>
        <div key='select-default-language-wrapper' id='select-default-language'>
          <Select key={inputKey}
                  id={inputKey}
                  name={inputKey}
                  isDisabled={!writeAccess}
                  value={_.isEmpty(value) ? null : value}
                  isMulti={!!multi}
                  options={options}
                  onChange={onChange}
                  placeholder={placeholder || t('select')}
                  noOptionsMessage={() => t('noOptions')}/>
          <span className='help-block'>
            {fieldError && showError && (<div className='error'>{t(fieldError)}</div>)}
          </span>
        </div>
      </div>
    )
  }

  renderInputField = (field, writeAccess, language, onChange) => {

    const {t, model, validationErrors} = this.props
    const fieldError = getFieldError(
      language ? `${field}.${language}` : field,
      validationErrors,
      model.error
    )
    const wrapperClassName = classNames(['col-xs-7 col-sm-10', field])

    return (
      <Input
        groupClassName='row'
        key={`input_${field}`}
        disabled={!writeAccess}
        label={t(`period.form.${field}`)}
        labelClassName='col-xs-5 col-sm-2'
        error={fieldError}
        onChange={onChange}
        value={model.getField(field, language)}
        wrapperClassName={wrapperClassName}
        type='text'
        t={t}
      />
    )
  }

  onPeriodDayChange = (periodDay) => {

    const {model} = this.props

    this.updateModel(model.updatePeriodDay(periodDay))
  }

  onPeriodDayOrderChange = (srcOrder, dstOrder) => {
    const {model} = this.props

    this.updateModel(model.reOrderPeriodDays(srcOrder, dstOrder))
  }

  onDeletePeriodDay = (periodDay: PeriodDay) => {

    const {model} = this.props

    this.updateModel(model.deletePeriodDay(periodDay))
  }

  addPeriodDay = () => {

    const {model, defaultLanguage} = this.props

    this.updateModel(model.addPeriodDay(defaultLanguage))
  }

  addPeriodDayButton = (t) => {

    return (
      <div className='add-period-day-button-container'>
        <button
          className='add-period-day-button'
          onClick={this.addPeriodDay}>
          {t('period.form.addPeriodDay')}
        </button>
      </div>
    )
  }

  renderPeriodDays = (language) => {

    const {t, model} = this.props

    return (
      <div key='period-days-container' className='container-fluid period-days'>
        <div className='row'>
          <h1>{t('period.form.periodDays')}</h1>
        </div>
        <div className='row'>
          <PeriodDays
            key='period-days'
            periodDays={model.getPeriodDays()}
            language={language}
            onOrderChange={this.onPeriodDayOrderChange}
            onChange={this.onPeriodDayChange}
            onDelete={this.onDeletePeriodDay}
            t={t}
          />
        </div>
        {this.addPeriodDayButton(t)}
      </div>
    )
  }

  onNameChange = (value) => this.onFieldChangeCallback('name', value)

  getFieldInputs = (field) => {

    const {model, modelName, accessRights, defaultLanguage} = this.props
    const writeAccess = accessRights.hasWriteAccess(modelName, model, field)

    if (field !== FormFields.language && writeAccess !== false && writeAccess !== true) {
      return null
    }

    switch (field) {
      case FormFields.language:
        return this.renderDefaultLanguageSelectInput(false)
      case FormFields.name:
        return this.renderInputField(
          field,
          writeAccess,
          defaultLanguage,
          this.onNameChange
        )
      case FormFields.periodDays:
        return this.renderPeriodDays(defaultLanguage)
      default:
        throw new Error(`Unsupported field ${field}`)
    }
  }

  render() {

    const rest = _.without(fieldInputs, FormFields.language).map(this.getFieldInputs)

    return (
      <div className='period-form '>
        <div className='form-group language-row'>
          {this.getFieldInputs(FormFields.language)}
        </div>
        {rest}
      </div>
    )
  }
}

export default PeriodForm
