import React from 'react'
import {PureComponent} from 'react'

import {connect} from 'react-redux'

interface Props {
  unreadCount: number
}

interface State {}

export class MessageCountView extends PureComponent<Props, State> {
  render() {
    return <span className='badge message-count'>{this.props.unreadCount}</span>
  }
}

const mapActionCreators = null

const mapStateToProps = ({conversations: {unreadCount}}, _ownProps) => {
  return {unreadCount}
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(MessageCountView)
