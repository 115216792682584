import BaseModel from './BaseModel'
import {List} from 'immutable'
import Message from './Message'
import {ROLES} from '../config/constants'

const defaultValues = {
  dedicatedSubjectId: undefined,
  lockTime: undefined,
  personnelLockId: undefined,
  replyRole: ROLES.STUDY_NURSE,
  studySiteId: undefined,
  messages: List<Message>(),
  conversation: undefined,
  title: undefined
}

export default class Conversation extends BaseModel(defaultValues)<Conversation> {
  dedicatedSubjectId: number
  lockTime: any
  personnelLockId: number
  replyRole: string
  studySiteId: number
  messages: List<any>
  conversation: any
  title: any

  constructor(js?) {
    super(js)

    if (js) {
      return this.set(
        'messages',
        List((js.messages || []).map(message => new Message(message)))
      ) as Conversation
    }
  }

  fromJS(conversationJS): Conversation {
    return new Conversation(conversationJS)
  }
}
