import React, {PureComponent} from 'react'
import Results from './Results'
import TimestampColumn from '../../../../components/TimestampColumn'
import {getFilterValue, getSortValue} from './TabComponents'

import _ from 'lodash'
import {List} from 'immutable'

import HealthTracking from '../../../../models/HealthTracking'
import Criteria from '../../../../models/Criteria'

const healthTrackingColumns = [
  'siteStudyName',
  'user',
  'deviceId',
  'calendarDate',
  'stepsTaken',
  'averageHeartRate',
  'peakHeartRate',
  'lowestHeartRate',
  'totalDistance'
]

export const sortableTrackingColumns = ['siteStudyName', 'user', 'deviceId', 'calendarDate']

interface Props {
  trackings: List<HealthTracking>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onRowClick?: (rowData) => any
  onProcessedData?: () => any
  t: (key, params?) => any
}

interface State {}

export default class HealthTrackings extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'startTime':
      case 'endTime':
        return TimestampColumn
      default:
        return null
    }
  }

  getColumns = () => {
    return healthTrackingColumns.map(field => {
      return {
        columnName: field,
        displayName: this.props.t('column.' + field),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field),
        sortable: _.includes(sortableTrackingColumns, field)
      }
    })
  }

  render() {
    return (
      <Results
        columns={this.getColumns()}
        results={this.props.trackings}
        ref='results'
        {...this.props}
      />
    )
  }
}
