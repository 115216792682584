/**
 * Created by Mauritz Untamala on 16/03/16.
 */
import React, {Component} from 'react'
import Results from './components/Results'

import {withNamespaces} from 'react-i18next'
import {ROLES} from '../../../config/constants'

import {connect} from 'react-redux'
import ActivityTrackersModule from '../../../modules/ActivityTrackers'
import ActivityTrackerModule from '../../../modules/ActivityTracker'
import {navigate, navigateToBackend} from '../../../modules/Location'
import UsersModule from '../../../modules/Users'
import {infoKey, warningKey} from '../../../modules/Notifications'
import App from '../../../models/App'
import Users from '../../../models/Users'
import ActivityTrackersModel from '../../../models/ActivityTrackers'

import _ from 'lodash'

import '../../../styles/tracker.less'
import {parseBoolean} from '../../../util'

interface Props {
  app: App
  users: Users
  activityTrackers: ActivityTrackersModel
  location: any
  navigate: (url: string, silent?: boolean) => any
  navigateToBackend: (url) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  getTrackers: (queryParams?, reset?) => any
  updateTracker: (model) => any
  deleteTracker: (model) => any
  t: (key, params?) => any
}

interface State {
}

class ActivityTrackers extends Component<Props, State> {
  componentDidMount() {
    const {getTrackers, getUsers, location} = this.props
    const connectSuccess = parseBoolean(location.query && location.query.success)

    if (!_.isNil(connectSuccess)) {

      if (connectSuccess) {
        infoKey('trackersView.connectSuccess')
      } else {
        warningKey('trackersView.connectFailure')
      }
    }

    getTrackers()
    getUsers(ROLES.SUBJECT)
  }

  render() {
    const {
      activityTrackers,
      users,
      deleteTracker,
      updateTracker,
      navigate,
      navigateToBackend,
      app,
      t
    } = this.props

    return (
      <div key='activity-trackers-view-container' className='trackers-view'>
        <h1 className='page-title'>{t('trackersView.title')}</h1>
        <Results
          ref='results'
          navigate={navigate}
          navigateToBackend={navigateToBackend}
          app={app}
          deleteTracker={deleteTracker}
          updateTracker={updateTracker}
          trackers={activityTrackers}
          users={users}
          t={t}
        />
      </div>
    )
  }
}

const mapActionToProps = {
  updateTracker: ActivityTrackerModule.updateModel,
  deleteTracker: ActivityTrackerModule.deleteModel,
  getTrackers: ActivityTrackersModule.getModels,
  getUsers: UsersModule.getUsers,
  navigate,
  navigateToBackend
}

const mapStateToProps = ({app, activityTrackers, users}, _ownProps) => {
  return {
    app,
    activityTrackers,
    users
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(ActivityTrackers))
