import React from 'react'
import {PureComponent} from 'react'
import {Checkbox, ControlLabel, FormGroup} from 'react-bootstrap'

import classNames from 'classnames'

interface Props {
  groupClassName?: string
  labelClassName?: string
  inputClassName?: string
  field: string
  label: string | React.ReactNode
  value: boolean
  disabled: boolean
  onChange: () => any
}

interface State {}

export default class CheckboxFormInput extends PureComponent<Props, State> {
  render() {
    const {
      groupClassName,
      labelClassName,
      inputClassName,
      label,
      field,
      value,
      disabled,
      onChange
    } = this.props
    return (
      <FormGroup bsClass={classNames('form-group', groupClassName)} type='checkbox'>
        <ControlLabel bsClass={classNames('control-label', labelClassName)}>{label}</ControlLabel>
        <div className={inputClassName}>
          <Checkbox
            key={`checkbox-${field}`}
            checked={value}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      </FormGroup>
    )
  }
}
