import React, {FunctionComponent} from 'react'
import {getDataStatusClassName} from '../util'

interface Props {
  rowData: any
  data?: any
  column?: any
  children?: any
}

const PercentageColumn: FunctionComponent<Props> = (props) => {

  const {data: {value, level}} = props
  const className = getDataStatusClassName(level)

  return <span className={className}>{value} %</span>
}

export default PercentageColumn
