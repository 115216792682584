import React from 'react'
import {Component} from 'react'
import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './NotificationsContainer.less'

interface Props {
  store: any
}

interface State {}

export default class NotificationsContainer extends Component<Props, State> {
  render() {
    return (
      <ToastContainer
        closeButton={false}
        hideProgressBar={true}
        toastClassName='notification-toast'
      />
    )
  }
}
