import ModelModule from './ModelModule'
import AdministrationEvent from '../models/AdministrationEvent'
import {bind} from '../util'
import moment from 'moment'

class AdministrationEventModule extends ModelModule<AdministrationEvent> {
  constructor() {
    super('event/administration', 'administrationEvent')
    bind(this, this.resetModelWithUserIdAndType)
  }

  protected getInitialState = () => new AdministrationEvent()

  public resetModelWithUserIdAndType(userId, type) {
    return (dispatch) => dispatch(this.resetModelAction({userId, type}))
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.resetModelType]: (_state, {payload: {userId, type}}) =>
        this.getInitialState()
          .set('personId', userId)
          .set('type', type)
          .set('period', 'period1')
          .set('time', moment())
    }
  }
}

export default new AdministrationEventModule()
