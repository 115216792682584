import React, {FunctionComponent} from 'react'
import {getDataStatusClassName} from '../util'
import moment from 'moment'
import i18n from '../services/I18n'
import _ from 'lodash'

interface Props {
  rowData: any
  data?: any
  column?: any
  children?: any
  t?: any
}

const LastSyncColumn: FunctionComponent<Props> = (props) => {

  const {data: {value, level}, t} = props
  const lastSyncText = _.isEmpty(value)
    ? t('reportsView.notAvailable')
    : moment.duration(moment(value).diff(moment()))
      .locale(i18n.language)
      .humanize(true)
  const className = getDataStatusClassName(level)

  return <span className={className}>{lastSyncText}</span>
}

export default LastSyncColumn
