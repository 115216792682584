import React from 'react'
import {PureComponent} from 'react'

interface Props {
  index?: number
  list: any
  handleClick: (idx) => any
}

interface State {
  index: number
}

class ConversationListView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      index: props.index >= 0 ? props.index : undefined
    }
  }

  handleClick = index => {
    this.setState({index})

    const {handleClick} = this.props

    if (handleClick) {
      handleClick(index)
    }
  }

  renderListItem = (o, i) => {
    const click = this.handleClick.bind(this, i)
    const current = i === this.state.index
    const selected = current ? 'selected ' : ''
    const badge = o.count > 0 && !current ? o.count : ''

    return (
      <li key={'conversation_' + i} onClick={click} className={selected + 'list-group-item'}>
        <span className='badge'>{badge}</span>
        <span className='title'>{o.title}</span>
        <span className='lastMessageTimestamp'>{o.lastMessageTimestamp}</span>
        <span className='lastMessage'>{o.lastMessage}</span>
      </li>
    )
  }

  render() {
    return <ul className='list-group'>{this.props.list.map(this.renderListItem)}</ul>
  }
}

export default ConversationListView
