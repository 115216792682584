import BaseModel from './BaseModel'

const constraints = {
  deviceId: {
    presence: {allowEmpty: false}
  }
}

export const interestedKeys = [
  'userId',
  'deviceId',
  'externalUserId',
  'accessToken',
  'authenticationToken',
  'refreshToken',
  'expiration'
]

const defaults = {
  userId: undefined,
  deviceId: undefined,
  externalUserId: undefined,
  accessToken: undefined,
  authenticationToken: undefined,
  refreshToken: undefined,
  expiration: undefined
}

class ActivityTracker extends BaseModel(defaults, interestedKeys, constraints)<ActivityTracker> {
  userId: number
  deviceId: number
  externalUserId: number
  accessToken: string
  authenticationToken: string
  refreshToken: string
  expiration: any

  fromJS(trackerJS: any = {}): any {
    return new ActivityTracker(trackerJS)
  }
}

export default ActivityTracker
