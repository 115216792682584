import React from 'react'
import _ from 'lodash'
import {Checkbox, FormGroup, Radio} from 'react-bootstrap'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'
import SanitizedHTML from 'react-sanitized-html'

interface Props extends BaseQuestionComponentProps {
}

interface State extends BaseQuestionComponentState {
}

export default class Select extends BaseQuestionComponent<Props, State> {

  renderOptions = () => {
    const {component, field, answer, disabled, language} = this.props
    const options = component.getOptions()
    const value = this.getAnswerValue()
    const localization = component.getLanguageLocalization(language)

    if (_.isEmpty(options)) {
      return null
    }

    if (options.length === 1) {
      const option = options[0] as any

      const onChange = event => {
        const newValue = event.target.checked ? option : null
        const updatedValue = answer.value.set(field, newValue)
        this.props.onChange(answer.set('value', this.compactRecord(updatedValue)))
      }

      return (
        <Checkbox
          disabled={disabled}
          name={field}
          value={option}
          checked={value === option}
          onChange={onChange}>
          <SanitizedHTML allowedTags={['b']} html={localization[option]}/>
        </Checkbox>
      )
    }

    const onChange = event => {
      if (event.target.checked) {
        this.props.onChange(answer.set('value', answer.value.set(field, event.target.value)))
      }
    }

    return options.map(option => {
      return (
        <Radio
          disabled={disabled}
          key={option}
          name={field}
          value={option}
          checked={value === option}
          onChange={onChange}>
          <SanitizedHTML allowedTags={['b']} html={localization[option]}/>
        </Radio>
      )
    })
  }

  getContent() {

    const {fieldError} = this.props

    return (
      <FormGroup validationState={fieldError ? 'error' : null}>
        {this.renderOptions()}
        {this.getError(fieldError)}
      </FormGroup>
    )
  }
}
