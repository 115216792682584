/**
 * Created by Mauritz Untamala on 31/12/15.
 */
import {DATE_DEFAULT_TIMEZONE, DATE_FORMAT, DATE_FORMAT_TIMEZONE} from '../config/constants'

import _ from 'lodash'
import moment from 'moment-timezone'
import i18n, {DEFAULT_LANGUAGE} from '../services/I18n'
import {formatDate} from '../util'

export function isEventUpdatedAfterEcrf(_value, object) {
  const updateTime = object.updateTime
  const ecrfTransferTime = object.ecrfTransferTime

  return !!(ecrfTransferTime && updateTime && moment(updateTime).isAfter(moment(ecrfTransferTime)))
}

export function getBooleanValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return l('boolean.' + value)
}

export function getDisabledValue(flag, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return l('boolean.' + !flag)
}

export function getSeverityCausalityOutcomeFilterValue(_value, rowData, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)

  return _.map(['severity', 'causality', 'outcome'], function(column) {
    const value = rowData[column]
    return l(column) + ': ' + (value ? l('adverseEvent.' + column + '.' + value) : '')
  }).join(' ')
}

export function getSeverityFilterValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return value ? l('adverseEvent.severity.' + value) : ''
}

export function getCausalityFilterValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return value ? l('adverseEvent.causality.' + value) : ''
}

export function getOutcomeFilterValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return value ? l('adverseEvent.outcome.' + value) : ''
}

export function getTimestampFilterValue(value, rowData, field, _t) {

  if (value) {

    const dateOnly = !!rowData[field + 'DateOnly']

    return dateOnly ? formatDate(value, dateOnly) : moment(value).format(DATE_FORMAT_TIMEZONE)
  }

  return ''
}

export function getTimestampWithTimezone(value, timezone) {
  return moment(value)
    .tz(timezone)
    .format(DATE_FORMAT_TIMEZONE)
}

export function getTimestampFilterValueForReports(value, rowData, field, _t?) {

  if (value) {

    const dateOnly = !!rowData[field + 'DateOnly']
    const timezone = rowData.timezone || DATE_DEFAULT_TIMEZONE

    return dateOnly
      ? moment(value)
        .tz(timezone)
        .format(DATE_FORMAT)
      : getTimestampWithTimezone(value, timezone)
  }

  return ''
}

export function getTimestampSortValue(value, _rowData, _field) {
  return value
    ? moment(value)
      .toDate()
      .getTime()
    : 0
}

export function getPeriodValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return value ? l('period.' + value) : l('unknown')
}

export function getAdministrationTypeValue(type, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return type ? l('administrationType.' + type) : l('unknown')
}

export function getRoles(roles) {
  let content = ''
  if (roles && _.size(_.compact(roles)) > 0) {
    content = roles.join(',')
  }
  return content
}

export function getPersonStatusValue(value, _object, _field, t) {
  const l = t || i18n.getFixedT(DEFAULT_LANGUAGE)
  return l('personStatus.' + value)
}
