import ModelsModule from './ModelsModule'
import Studies from '../models/Studies'
import _ from 'lodash'
import Study from './Study'
import {bind} from '../util'

const initialState = new Studies()

export class StudiesModule extends ModelsModule {
  constructor() {
    super('study', initialState)
    bind(this, this.fetchStudies)
  }

  public fetchStudies(studyIds) {
    let queryParams

    if (!_.isEmpty(studyIds)) {
      queryParams = {}

      const array = studyIds.reduce(function(accu, id) {
        if (accu !== '') {
          accu = accu + ','
        }
        return accu + id
      }, '')

      if (array !== '') {
        queryParams.studies = array
      }
    }

    return this.getModels(queryParams, true)
  }

  protected getAdditionalActionHandlers() {
    return {
      [Study.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    }
  }
}

export default new StudiesModule()
