import React from 'react'
import {Component} from 'react'
import SubjectMedicationEventView from '../../components/SubjectMedicationEventView'

interface Props {
  params: any
  location: any
}

interface State {}

export default class MedicationEvent extends Component<Props, State> {
  render() {
    const {params, location} = this.props
    const eventId = params.id ? parseInt(params.id, 10) : undefined

    return (
      <SubjectMedicationEventView
        key='subject-medication-event-view'
        navigateBackOnSaved={true}
        forceBackHref={true}
        location={location}
        eventId={eventId}
      />
    )
  }
}
