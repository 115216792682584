import ModelModule from './ModelModule'
import Site from '../models/Site'

export class SiteModule extends ModelModule<Site> {
  constructor() {
    super('site')
  }

  protected getInitialState = () => new Site()

  /**
   * @override
   */
  protected deleteSuccessMessageProps({name}) {
    return {name}
  }
}

export default new SiteModule()
