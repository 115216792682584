import ModelModule from './ModelModule'
import Message from '../models/Message'

export class MessageModule extends ModelModule<Message> {
  constructor() {
    super('message')
  }

  protected getInitialState = () => new Message()

  // suppress notifications for message saving
  protected onCreateSuccess(_model) {
    return (_dispatch) => null
  }

  // suppress notifications for message saving
  protected onUpdateSuccess(_model) {
    return (_dispatch) => null
  }
}

export default new MessageModule()
