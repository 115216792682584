/**
 * Created by Mauritz Untamala on 20/02/16.
 */
import React, {Component, PureComponent} from 'react'
import StatusColumn from './StatusColumn'
import TableComponent from './TableComponent'
import {getPersonStatusValue, getRoles} from './CommonFilterValueFunctions'

import _ from 'lodash'

import {connect} from 'react-redux'
import StudyGroups from '../modules/StudyGroups'
import ActivityTrackers from '../modules/ActivityTrackers'
import UserModule from '../modules/User'
import {navigate} from '../modules/Location'
import ActivityTrackersModel from '../models/ActivityTrackers'
import App from '../models/App'
import {Feature} from '../models/Feature'
import InvitationState from '../models/InvitationState'
import Users from '../models/Users'
import {UIAction} from '../models/UIAction'
import StudyGroupsModel from '../models/StudyGroups'
import Criteria from '../models/Criteria'

interface ActionsProps {
  app: App
  rowData: any
  sendResetSMS: (user) => any
  t: (key, params?) => any
}

interface ActionsState {
}

class ActionsColumn extends Component<ActionsProps, ActionsState> {
  sendResetSMS = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.sendResetSMS(this.props.rowData._src)
  }

  getActions = () => {
    if (this.props.rowData.id && this.props.app.hasAccessToAction(UIAction.SEND_RESET_SMS)) {
      return (
        <button
          key={'sendAuth_' + this.props.rowData.id}
          className='btn btn-default'
          onClick={this.sendResetSMS}
          role='button'>
          {this.props.t('button.resetUser')} <i className='glyphicon glyphicon-envelope'/>
        </button>
      )
    }
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}

interface ResetColumnProps {
  users: Users
  rowData: any
}

interface ResetColumnState {
}

class ResetColumn extends PureComponent<ResetColumnProps, ResetColumnState> {
  getPasswordResetStatus = data => {
    let retCss = 'fa '
    let user = this.props.users.getModelById(data.id)

    switch (user.getInvitationState()) {
      case InvitationState.NOT_SENT:
        retCss += 'fa-minus'
        break
      case InvitationState.PENDING_RESET:
      case InvitationState.PENDING_INVITATION:
        retCss += 'fa-mail-forward'
        break
      case InvitationState.RESET_DONE:
        retCss += 'fa-check'
        break
      default:
        retCss += 'fa-question'
    }

    return retCss
  }

  render() {
    return (
      <span>
        <i className={this.getPasswordResetStatus(this.props.rowData)}/>
      </span>
    )
  }
}

const mapActionCreators = {
  getGroups: StudyGroups.getModels,
  getTrackers: ActivityTrackers.getModels,
  sendResetSMS: UserModule.sendResetSMS,
  navigate
}

const mapStateToProps = ({app, studyGroups, activityTrackers}, ownProps) => {
  const userKeys = ownProps.users.getUserKeys()

  return {
    userKeys,
    studyGroups,
    activityTrackers,
    app
  }
}

interface Props {
  app: App
  criteria: Criteria
  users: Users
  studyGroups: StudyGroupsModel
  activityTrackers: ActivityTrackersModel
  userKeys: any[]
  onCriteriaChange: (criteria) => any
  getGroups: (queryParams?, reset?) => any
  getTrackers: (queryParams?, reset?) => any
  sendResetSMS: (user) => any
  onRowClick: (rowData) => any
  subjects?: boolean
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {
}

export class UsersTable extends Component<Props, State> {

  componentDidMount() {
    const {app, getGroups, getTrackers} = this.props

    getGroups()

    if (app.hasAccessToFeature(Feature.trackers)) {
      getTrackers()
    }
  }

  getResults = () => this.props.users.list.map(user => _.extend(user.toJS(), {_src: user})).toArray()

  getCommonColumns = () => {
    const {t, users} = this.props

    const getStatusValue = (...props) => {
      return getPersonStatusValue(..._.concat(props, t) as [any, any, string, Function])
    }

    return [
      {
        columnName: 'status',
        displayName: t('column.status'),
        cssClassName: 'user-status-column',
        customComponent: props => {
          return <span>{t(`personStatus.${props.data}`)}</span>
        },
        filterValue: getStatusValue,
        sortValue: getStatusValue
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      },
      {
        columnName: 'updating',
        displayName: '',
        cssClassName: 'status-column',
        customComponent: StatusColumn,
        sortable: false
      },
      {
        columnName: 'receivedInvitation',
        displayName: t('column.invitationStatus'),
        cssClassName: 'received-invitation-column',
        customComponent: props => <ResetColumn users={users} {...props} />,
        sortable: false
      }
    ]
  }

  getTrackerDeviceId = trackerId => {
    const tracker = this.props.activityTrackers.getModelById(trackerId)

    return tracker ? tracker.get('deviceId') : ''
  }

  personnelOnlyColumns = () => {
    const {t} = this.props

    const rolesColumn = ({field, rowData, data}) => {
      return (
        <div key={field + '_readonly_' + rowData._id} data-tip={t('tooltip.clickToEdit')}>
          {getRoles(data)}
        </div>
      )
    }

    return [
      {
        columnName: 'username',
        displayName: t('username'),
        cssClassName: 'phone-column'
      },
      {
        columnName: 'roles',
        displayName: t('column.roles'),
        cssClassName: 'roles-column',
        customComponent: rolesColumn,
        filterValue: getRoles,
        sortValue: getRoles
      }
    ]
  }

  subjectOnlyColumns = () => {
    const {studyGroups, t} = this.props

    const getStudyGroupName = groupIds => {
      if (_.isEmpty(groupIds)) {
        return ''
      }

      const groups = _.map(groupIds, (groupId) => {
        const studyGroup = studyGroups.getModelById(groupId)

        return studyGroup ? studyGroup.get('name') : undefined
      }).filter(_.negate(_.isNil))
      return groups.join(', ')
    }

    return [
      {
        columnName: 'screeningNumber',
        displayName: t('column.screeningNumber'),
        cssClassName: 'screening-number-column'
      },
      {
        columnName: 'subjectNumber',
        displayName: t('column.subjectNumber'),
        cssClassName: 'subject-number-column'
      },
      {
        columnName: 'studyGroupIds',
        displayName: t('column.studyGroups'),
        cssClassName: 'studyGroup-column',
        customComponent: ({data}) => <span>{getStudyGroupName(data)}</span>,
        filterValue: getStudyGroupName,
        sortValue: getStudyGroupName
      }
    ]
  }

  getColumnMetadata = () => {
    const {subjects, t, users, userKeys, app} = this.props
    let columns = []

    if (subjects) {
      columns = columns.concat(this.subjectOnlyColumns())

      if (app.hasAccessToFeature(Feature.trackers)) {
        columns.push({
          columnName: 'trackerId',
          displayName: t('column.deviceId'),
          customComponent: ({data}) => <span>{this.getTrackerDeviceId(data)}</span>,
          filterValue: this.getTrackerDeviceId,
          sortValue: this.getTrackerDeviceId
        })
      }
    } else {
      columns = columns.concat(this.personnelOnlyColumns())
    }

    columns = columns.concat(this.getCommonColumns())

    if (users.list.isEmpty()) {
      return []
    }

    return _.reduce(
      columns,
      (memo, column) => {
        if (
          /^(updating|actions|receivedInvitation)$/.test(column.columnName) ||
          _.includes(userKeys, column.columnName)
        ) {
          column.order = memo.length
          memo.push(column)
        }
        return memo
      },
      []
    )
  }

  onTabCriteriaChanged = tabCriteria => {
    if (this.props.criteria) {
      this.props.onCriteriaChange(
        this.props.criteria
          .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
          .set('sort', tabCriteria ? tabCriteria.sort : undefined)
          .set('filter', tabCriteria ? tabCriteria.filter : undefined)
      )
    }
  }

  render() {

    const columnMeta = this.getColumnMetadata()
    const {criteria, onRowClick, t} = this.props

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={columnMeta}
          onRowClick={onRowClick}
          filter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          useFixedHeader={true}
          onCriteriaChanged={this.onTabCriteriaChanged}
          t={t}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(UsersTable)
