import React, {PureComponent} from 'react'
import Select from 'react-select'
import SubPageView from '../../../components/SubPageView'

import classNames from 'classnames'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import I18n from '../../../services/I18n'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import PeriodsModule from '../../../modules/Periods'
import QuestionnaireAnswersModule from '../../../modules/QuestionnaireAnswers'
import QuestionnairesModule from '../../../modules/Questionnaires'
import QuestionnairesModel from '../../../models/Questionnaires'
import QuestionnaireAnswers from '../../../models/QuestionnaireAnswers'

import './Questionnaires.less'
import Periods from '../../../models/Periods'
import {unique} from '../../../util'
import _ from 'lodash'
import {SelectOption} from '../../../config/constants'
import {List} from 'immutable'
import Period from '../../../models/Period'
import Questionnaire from '../../../models/Questionnaire'

interface Props extends WithNamespaces {
  questionnaires: QuestionnairesModel
  questionnaireAnswers: QuestionnaireAnswers
  periods: Periods
  getQuestionnaires: (queryParams?, reset?) => any
  getQuestionnaireAnswers: (queryParams?, reset?) => any
  getPeriods: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  setupDone: boolean
  periodsWithQuestionnaires: List<Period>
  periodDayIdsWithQuestionnaires: number[]
  nextPeriodId: number
  nextPeriodDayId: number
  nextQuestionnaireId: number
  selectedPeriodId: number
  selectedPeriodDayId: number
  selectedQuestionnaireId: number
}

class Questionnaires extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      setupDone: false,
      periodsWithQuestionnaires: List<Period>(),
      periodDayIdsWithQuestionnaires: null,
      nextPeriodId: null,
      nextPeriodDayId: null,
      nextQuestionnaireId: null,
      selectedPeriodId: null,
      selectedPeriodDayId: null,
      selectedQuestionnaireId: null
    }
  }

  componentDidMount() {
    const {getQuestionnaires, getQuestionnaireAnswers, getPeriods} = this.props
    getQuestionnaires({lang: I18n.language})
    getQuestionnaireAnswers()
    getPeriods()
  }

  componentDidUpdate(prevProps) {

    const {questionnaires, questionnaireAnswers, periods} = this.props
    const hasChanges = questionnaires !== prevProps.questionnaires ||
      questionnaireAnswers !== prevProps.questionnaireAnswers ||
      periods !== prevProps.periods
    const isLoading = questionnaireAnswers.isLoading || questionnaires.isLoading || periods.isLoading

    if ((!this.state.setupDone || hasChanges) && !isLoading) {
      this.setupSelectionData(periods, questionnaires, questionnaireAnswers)
    }
  }

  setupSelectionData(
    periods: Periods,
    questionnaires: QuestionnairesModel,
    questionnaireAnswers: QuestionnaireAnswers
  ) {

    const periodDayIdsWithQuestionnaires = periods.getPeriodDayIdsWithQuestionnairesOrderedByPeriodDay(questionnaires)
    const periodsWithQuestionnaires = this.getPeriodsWithQuestionnaires(periods, periodDayIdsWithQuestionnaires)
    const allPeriodDayIdsOfAnswers = questionnaireAnswers.list
      .map(answer => answer.periodDayId)
      .toArray()
      .reduce(unique, [])
    // Get period day ids with answers in period day order
    const periodDayIdsWithAnswers = periodDayIdsWithQuestionnaires
      .filter(id => _.includes(allPeriodDayIdsOfAnswers, id))

    const [nextPeriodDayId, nextQuestionnaireId] = this.getNextPeriodDayId(
      periodDayIdsWithAnswers,
      periodDayIdsWithQuestionnaires
    )

    const nextPeriod = periodsWithQuestionnaires
      .find(period => !!period.getPeriodDays().find(day => day.id === nextPeriodDayId))
    const nextPeriodId = nextPeriod && nextPeriod.id

    this.setState({
      setupDone: true,
      periodsWithQuestionnaires,
      periodDayIdsWithQuestionnaires,
      nextPeriodId,
      nextPeriodDayId,
      nextQuestionnaireId,
      selectedPeriodId: nextPeriodId,
      selectedPeriodDayId: nextPeriodDayId,
      selectedQuestionnaireId: nextQuestionnaireId
    })
  }

  getNextPeriodDayId(periodDayIdsWithAnswers: number[], periodDayIdsWithQuestionnaires: number[]) {

    const {questionnaires, questionnaireAnswers} = this.props
    const lastPeriodDayIdAnswered = _.last(periodDayIdsWithAnswers) as number

    // Resolve if there are other unanswered questionnaires for same day
    if (lastPeriodDayIdAnswered) {

      const unansweredQuestionnaire = questionnaires.list
        .filter(questionnaire => questionnaire.getPeriodDayIds().includes(lastPeriodDayIdAnswered))
        .filter(questionnaire => !questionnaireAnswers.getAnswerForQuestionnaire(
          questionnaire.id,
          lastPeriodDayIdAnswered
        ))
        .sortBy(questionnaire => questionnaire.getOrder())
        .first()

      if (unansweredQuestionnaire) {

        return [lastPeriodDayIdAnswered, unansweredQuestionnaire.id]

      } else {
        // Resolve first unanswered questionnaire for the next period day

        const idx = periodDayIdsWithQuestionnaires.indexOf(lastPeriodDayIdAnswered)
        const nextPeriodDayId = (idx !== -1 && idx < (periodDayIdsWithQuestionnaires.length - 1))
          ? periodDayIdsWithQuestionnaires[idx + 1]
          : _.first(periodDayIdsWithQuestionnaires)
        const unansweredQuestionnaire = this.getUnansweredQuestionnaire(
          questionnaires,
          nextPeriodDayId,
          questionnaireAnswers
        )

        return [nextPeriodDayId, unansweredQuestionnaire && unansweredQuestionnaire.id]
      }
    } else {
      // No answers as of yet. Return the first unanswered questionnaire
      const nextPeriodDayId = _.first(periodDayIdsWithQuestionnaires)
      const unansweredQuestionnaire = this.getUnansweredQuestionnaire(
        questionnaires,
        nextPeriodDayId,
        questionnaireAnswers
      )

      return [nextPeriodDayId, unansweredQuestionnaire && unansweredQuestionnaire.id]
    }
  }

  getUnansweredQuestionnaire(
    questionnaires: QuestionnairesModel,
    periodDayId,
    questionnaireAnswers: QuestionnaireAnswers
  ) {

    return questionnaires.list
      .filter(questionnaire => questionnaire.getPeriodDayIds().find(id => id === periodDayId))
      .filter(questionnaire => !questionnaireAnswers.getAnswerForQuestionnaire(
        questionnaire.id,
        periodDayId
      ))
      .sortBy(questionnaire => questionnaire.getOrder())
      .first()
  }

  getPeriodsWithQuestionnaires(periods: Periods, periodDayIdsWithQuestionnaires) {
    return periods.list
      .filter(period => period.getPeriodDays()
        .some(day => _.includes(periodDayIdsWithQuestionnaires, day.id))
      )
      .sortBy(period => period.getOrder()) as List<Period>
  }

  getPeriodOptions = () => {

    const {periodsWithQuestionnaires} = this.state

    return periodsWithQuestionnaires
      .map(period => {
        return {
          label: period.getName(),
          value: period.id
        }
      })
      .toArray()
  }

  getPeriodDayOptions = () => {

    const {selectedPeriodId, periodsWithQuestionnaires, periodDayIdsWithQuestionnaires} = this.state
    const period = periodsWithQuestionnaires.find(period => period.id === selectedPeriodId)
    const periodDays = period
      ? period.getPeriodDays()
        .filter(day => _.includes(periodDayIdsWithQuestionnaires, day.id))
        .sortBy(day => day.getOrder())
        .toArray()
      : []

    return periodDays
      .map(periodDay => {
        return {
          label: periodDay.getName(),
          value: periodDay.id
        }
      })
  }

  getQuestionnaireOptions = () => {
    const {questionnaires, questionnaireAnswers} = this.props
    const {selectedPeriodDayId} = this.state

    return questionnaires.list
      .filter(
        questionnaire =>
          questionnaire.periodDayIds.includes(selectedPeriodDayId) &&
          questionnaire.hasPublishedLanguage(I18n.language) &&
          (
            questionnaire.multipleAnswers ||
            !questionnaireAnswers.getAnswerForQuestionnaire(questionnaire.id, selectedPeriodDayId)
          )
      )
      .sortBy(questionnaire => questionnaire.getOrder())
      .map(questionnaire => {
        return {
          label: questionnaire.getTitle(),
          value: questionnaire.id
        }
      })
      .toArray()
  }

  onPeriodChange = selectedOption => {
    const selectedPeriodId = selectedOption.value
    this.setState({selectedPeriodId, selectedPeriodDayId: null, selectedQuestionnaireId: null})
  }

  onPeriodDayChange = selectedOption => {
    const selectedPeriodDayId = selectedOption.value
    this.setState({selectedPeriodDayId, selectedQuestionnaireId: null})
  }

  onQuestionnaireChange = selectedOption => {
    const selectedQuestionnaireId = selectedOption.value
    this.setState({selectedQuestionnaireId})
  }

  getSelectedPeriodOption = (options) => options.find(({value}) => value === this.state.selectedPeriodId)

  getSelectedPeriodDayOption = (options) => options.find(({value}) => value === this.state.selectedPeriodDayId)

  getSelectedQuestionnaireOption = (options) => options.find(({value}) => value === this.state.selectedQuestionnaireId)

  getSelectInput = (
    field: string,
    value: SelectOption<any>,
    options: SelectOption<any>[],
    onChange: (option: SelectOption<any>) => void
  ) => {

    const {t} = this.props

    return (
      <div key={`questionnaire-picker-${field}`} className='form-group'>
        <div className='col-xs-12'>
          <Select key={`${field}_select`}
                  name={field}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  value={value ? value : null}
                  options={options}
                  onChange={onChange}
                  placeholder={t(`questionnaireAnswer.${field}`)}
                  noOptionsMessage={() => t('noOptions')}/>
        </div>
      </div>
    )

  }

  renderInputFields = () => {

    const periodOptions = this.getPeriodOptions()
    const periodDayOptions = this.getPeriodDayOptions()
    const questionnaireOptions = this.getQuestionnaireOptions()

    return [
      this.getSelectInput(
        'period',
        this.getSelectedPeriodOption(periodOptions),
        periodOptions,
        this.onPeriodChange
      ),
      this.getSelectInput(
        'periodDay',
        this.getSelectedPeriodDayOption(periodDayOptions),
        periodDayOptions,
        this.onPeriodDayChange
      ),
      this.getSelectInput(
        'questionnaire',
        this.getSelectedQuestionnaireOption(questionnaireOptions),
        questionnaireOptions,
        this.onQuestionnaireChange
      )
    ]
  }

  onStart = () => {
    const {selectedQuestionnaireId, selectedPeriodDayId} = this.state

    this.props.navigate(`/questionnaires/${selectedQuestionnaireId}/answer?periodDayId=${selectedPeriodDayId}`)
  }

  renderStartButton = () => {
    const {selectedQuestionnaireId} = this.state

    return (
      <button
        key='label'
        className={classNames('btn', 'btn-default', 'btn-lg', 'save-btn')}
        onClick={this.onStart}
        disabled={!selectedQuestionnaireId}>
        {this.props.t('button.start')}
      </button>
    )
  }

  renderNotification() {

    const {selectedQuestionnaireId, nextQuestionnaireId, nextPeriodDayId} = this.state

    if (!selectedQuestionnaireId || selectedQuestionnaireId === nextQuestionnaireId) {
      return undefined
    }

    const {questionnaires, periods, t} = this.props
    const nextQuestionnaire: Questionnaire = questionnaires.list.find(q => q.id === nextQuestionnaireId)
    const notification = t('questionnaireAnswer.unansweredNotification', {
      name: nextQuestionnaire ? nextQuestionnaire.getDisplayName(periods, nextPeriodDayId) : ''
    })

    return (<div className='questionnaire-notification'>{notification}</div>)
  }

  renderSelectQuestionnaire() {
    return (
      <div className='questionnaire-select-view'>
        <h1>{this.props.t('questionnairesViewTitle')}</h1>
        <p>{this.props.t('questionnairesViewHelp')}</p>
        <div className='form-horizontal'>
          {this.renderInputFields()}
          {this.renderNotification()}
          {this.renderStartButton()}
        </div>
      </div>
    )
  }

  render() {
    const navItems = [{label: 'back', href: '/questionnaires', className: 'back-navigation'}]

    return (
      <SubPageView navItems={navItems} t={this.props.t}>
        {this.renderSelectQuestionnaire()}
      </SubPageView>
    )
  }
}

const mapActionToProps = {
  getQuestionnaires: QuestionnairesModule.getModels,
  getQuestionnaireAnswers: QuestionnaireAnswersModule.getModels,
  getPeriods: PeriodsModule.getModels,
  navigate
}

const mapStateToProps = ({questionnaires, questionnaireAnswers, periods}, _ownProps) => {
  return {
    questionnaires,
    questionnaireAnswers,
    periods
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Questionnaires))
