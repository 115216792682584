import ModelsModule from './ModelsModule'
import Periods from '../models/Periods'
import Study from './Study'

const initialState = new Periods()

export class PeriodsModule extends ModelsModule {
  constructor() {
    super('period', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [Study.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Study.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    }
  }
}

export default new PeriodsModule()
