import React from 'react'
import {Component} from 'react'
import StudyView from '../../components/StudyView'

import {connect} from 'react-redux'
import SiteStudyModule from '../../modules/SiteStudy'
import SiteStudy from '../../models/SiteStudy'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  selectedSiteStudyId: number
  siteStudy: SiteStudy
  getSiteStudy: (id, queryParams?) => any
}

interface State {
}

class StudyInfo extends Component<Props, State> {
  componentDidMount() {
    const {getSiteStudy, selectedSiteStudyId} = this.props
    getSiteStudy(selectedSiteStudyId)
  }

  componentDidUpdate(prevProps) {
    const {selectedSiteStudyId, getSiteStudy} = this.props

    if (selectedSiteStudyId && prevProps.selectedSiteStudyId !== selectedSiteStudyId) {
      getSiteStudy(selectedSiteStudyId)
    }
  }

  render() {
    const {
      siteStudy: {studyId},
      t
    } = this.props

    return <StudyView studyId={studyId} notSubPage={true} t={t}/>
  }
}

const mapActionCreators = {
  getSiteStudy: SiteStudyModule.getModel
}

const mapStateToProps = ({siteStudy, authenticatedUser: {selectedSiteStudyId}}, _ownProps) => ({
  siteStudy,
  selectedSiteStudyId
})

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(StudyInfo))
