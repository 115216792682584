import React from 'react'
import {Component} from 'react'
import Input from './Input'
import SelectFormInput from './SelectFormInput'
import CheckboxFormInput from './CheckboxFormInput'

import classNames from 'classnames'
import {generateSelectOptions, getFieldError} from '../util'
import {List} from 'immutable'

import AccessRights from '../models/AccessRights'
import Study from '../models/Study'
import Users from '../models/Users'
import {Feature} from '../models/Feature'

interface Props {
  study: Study
  users: Users
  availableFeatures: List<Feature>
  availableLanguages: any
  accessRights: AccessRights
  onChange: (model) => any
  t: (key, params?) => any
}

interface State {
}

export default class StudyDetails extends Component<Props, State> {

  onFieldChangeCallback = field => value => {

    const {study, onChange} = this.props

    onChange(study.set(field, value))
  }

  createSelectInput = (field, writeAccess, options, multi?, placeholder?) => {

    const {study, onChange, t} = this.props

    return (
      <SelectFormInput
        key={'select-' + field}
        model={study}
        modelName='study'
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange}
        labelClassName='col-xs-5 col-sm-4'
        fieldClassName='col-xs-7 col-sm-8'
        t={t}
      />
    )
  }

  getUserLabel = user => user.visibleName()

  getPersonnelOptions = () => generateSelectOptions(this.props.users.list, this.getUserLabel, 'id')

  getInputField = (field, writeAccess) => {
    const {study, t} = this.props
    const fieldError = getFieldError(field, study.validate(), study.error)
    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t('studyView.' + field)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={study.get(field)}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    )
  }

  createCheckboxInput = (field, writeAccess) => {
    const {study, onChange, t} = this.props
    const inputClassName = classNames(['col-xs-7 col-sm-8', field])
    const value = study.get(field)

    const onCheckboxChange = () => {
      onChange(study.set(field, !value))
    }

    return (
      <CheckboxFormInput
        key={`checkbox-${field}`}
        labelClassName='col-xs-5 col-sm-4'
        inputClassName={inputClassName}
        field={field}
        label={t(`studyView.${field}`)}
        value={value}
        disabled={!writeAccess}
        onChange={onCheckboxChange}
      />
    )
  }

  getFeatureOptions = () => generateSelectOptions(this.props.availableFeatures)

  getLanguageOptions = () =>
    generateSelectOptions(this.props.availableLanguages, key => this.props.t(`language.${key}`))

  render() {
    const inputs = [
      'studyNumber',
      'studyName',
      'projectName',
      'personnelIds',
      'features',
      'languages',
      'isAnimal'
    ].map(field => {
      const {study, accessRights} = this.props

      const writeAccess = accessRights.hasWriteAccess('study', study, field)

      if (writeAccess !== false && writeAccess !== true) {
        return null
      }

      switch (field) {
        case 'personnelIds':
          return this.createSelectInput(field, writeAccess, this.getPersonnelOptions(), true)
        case 'features':
          return this.createSelectInput(field, writeAccess, this.getFeatureOptions(), true)
        case 'languages':
          return this.createSelectInput(field, writeAccess, this.getLanguageOptions(), true)
        case 'isAnimal':
          return this.createCheckboxInput(field, writeAccess)
        default:
          return this.getInputField(field, writeAccess)
      }
    })

    return <form className='form-horizontal'>{inputs}</form>
  }
}
