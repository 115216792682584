import React from 'react'
import {PureComponent} from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import pathToRegexp from 'path-to-regexp'

import {connect} from 'react-redux'

import '../styles/animation.less'

function createPathTester(path) {
  let regexp = pathToRegexp(path)
  return function(testPath) {
    return regexp.exec(testPath) !== null
  }
}

function createAnimationRule(from, to) {
  return {
    from: createPathTester(from),
    to: createPathTester(to)
  }
}

const animatedRules = [
  createAnimationRule('/messages', '/messages/nurses'),
  createAnimationRule('/messages', '/messages/investigators'),
  createAnimationRule('/events', '/event/create-medication-event'),
  createAnimationRule('/events', '/event/create-adverse-event'),
  createAnimationRule('/events', '/event/edit-medication-event/:id'),
  createAnimationRule('/events', '/event/edit-adverse-event/:id'),
  createAnimationRule('/administrations', '/event/create-charcoal-event'),
  createAnimationRule('/administrations', '/event/create-water-event'),
  createAnimationRule('/administrations', '/event/edit-charcoal-event/:id'),
  createAnimationRule('/administrations', '/event/edit-water-event/:id')
]

const mapStateToProps = ({location}, _ownProps) => {
  return {location}
}

interface Props {
  path: string
}

interface State {}

class PageAnimation extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  getFromAnimationRule = from => animatedRules.find(rule => rule.from(from))

  getToAnimationRule = to => animatedRules.find(rule => rule.to(to))

  isRootPage = path => !!this.getFromAnimationRule(path)

  isSubPage = path => !!this.getToAnimationRule(path)

  getPageType = path => {
    if (this.isRootPage(path)) {
      return 'root-page'
    } else if (this.isSubPage(path)) {
      return 'sub-page'
    } else {
      return null
    }
  }

  getTransitionGroup = (path, pageType, animate) => {
    return (
      <TransitionGroup component='div' className='main' enter={animate} exit={animate}>
        <CSSTransition key={path} classNames={'slider'} timeout={500}>
          <div className={`slide-page-wrapper ${pageType}`} key={path}>
            {this.props.children}
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }

  getContent = () => {
    return <div className='main'>{this.props.children}</div>
  }

  render() {
    const {path} = this.props
    const pageType = this.getPageType(path)
    const animate = !!pageType

    if (animate) {
      return this.getTransitionGroup(path, pageType, animate)
    }

    return this.getContent()
  }
}

export default connect(mapStateToProps)(PageAnimation)
