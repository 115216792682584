import ModelModule from './ModelModule'
import Questionnaire from '../models/Questionnaire'

export class QuestionnaireModule extends ModelModule<Questionnaire> {
  constructor() {
    super('questionnaire')
  }

  protected getInitialState = () => new Questionnaire()
}

export default new QuestionnaireModule()
