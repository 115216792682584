/**
 * Created by Mauritz Untamala on 01/03/2017.
 */
import {List, Record} from 'immutable'
import _ from 'lodash'
import pathToRegexp from 'path-to-regexp'
import {Feature} from './Feature'
import SiteStudy from './SiteStudy'
import {UIAction} from './UIAction'
import {ROLES, USER_TYPES} from '../config/constants'

const messagesRegExp = new RegExp('messages')

export class NavItems extends Record({
  default: List(),
  siteStudy: List(),
  selectableSiteStudies: List()
}) {
  default: List<any>
  siteStudy: List<any>
  selectableSiteStudies: List<any>
}

const defaultValues = {
  initializing: false,
  initialized: false,
  connectivity: true,
  navItems: new NavItems(),
  uiActions: List(),
  features: List(),
  availableFeatures: List(),
  availableRoles: List(),
  availableLanguages: List(),
  accessiblePages: List(),
  error: undefined,
  lastActionTime: undefined
}

class App extends Record(defaultValues) {
  initializing: boolean
  initialized: boolean
  connectivity: boolean
  navItems: NavItems
  uiActions: List<UIAction>
  features: List<Feature>
  availableFeatures: List<Feature>
  availableRoles: List<any>
  availableLanguages: List<any>
  accessiblePages: List<any>
  error: any
  lastActionTime: any

  public setConnectivity(connectivity) {
    return this.set('connectivity', connectivity)
  }

  public isConnected() {
    return this.connectivity
  }

  public setNavItems(navItemsJS) {
    const selectableSiteStudies = List(
      _.map(navItemsJS.selectableSiteStudies, siteStudy => new SiteStudy(siteStudy))
    )
    const navItems = this.navItems
      .set('default', List(navItemsJS.default))
      .set('siteStudy', List(navItemsJS.siteStudy))
      .set('selectableSiteStudies', selectableSiteStudies)
    const accessiblePages = this.extractAccessiblePages(navItemsJS.accessiblePages)

    return this.set('navItems', navItems).set('accessiblePages', accessiblePages)
  }

  public setFeatures(featuresJS) {
    return this.setListArray('features', featuresJS)
  }

  public setAvailableRoles(availableRolesJS) {
    return this.setListArray('availableRoles', availableRolesJS)
  }

  public setAvailableFeatures(availableFeaturesJS) {
    return this.setListArray('availableFeatures', availableFeaturesJS)
  }

  public setAvailableLanguages(availableLanguagesJS) {
    return this.setListArray('availableLanguages', availableLanguagesJS)
  }

  public setUIActions(uiActionsJS) {
    return this.setListArray('uiActions', uiActionsJS)
  }

  public hasAccessToAction(action: UIAction) {
    return this.uiActions.includes(action)
  }

  public hasAccessToFeature(feature: Feature) {
    return this.features.includes(feature)
  }

  hasAccessToPage(path) {
    path = path ? path.split('?')[0] : path

    return !!this.accessiblePages.find(
      accessiblePage => accessiblePage.pattern.exec(path) !== null
    )
  }

  public getAvailableRoles(type) {
    if (type === USER_TYPES.SUBJECT) {
      return this.availableRoles.filter(role => role === ROLES.SUBJECT)
    }

    if (type === USER_TYPES.PERSONNEL) {
      return this.availableRoles.filter(role => role !== ROLES.SUBJECT)
    }

    return this.availableRoles
  }

  public getNavItems() {
    return this.navItems
  }

  public getSelectableSiteStudies(siteIds) {
    if (!siteIds || _.isEmpty(siteIds)) {
      return this.navItems.selectableSiteStudies
    }

    return this.navItems.selectableSiteStudies.filter(siteStudy =>
      _.includes(siteIds, siteStudy.siteId)
    )
  }

  public canViewMessages() {
    return !!this.accessiblePages.find(({path}) => messagesRegExp.test(path))
  }

  public setLastActionTime(lastActionTime) {
    return this.set('lastActionTime', lastActionTime)
  }

  private extractAccessiblePages(pages) {
    const paths = _.chain(pages)
      .map(function(page) {
        const keys = []
        const pattern = pathToRegexp(page, keys)
        return {path: page, pattern: pattern, keys: keys}
      })
      .value()

    return List(paths)
  }

  private setListArray(key, arrayJS) {
    return this.set(key, List(_.isArray(arrayJS) ? arrayJS : []))
  }
}

export default App
