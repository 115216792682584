import React from 'react'
import {PureComponent} from 'react'
import Results from './Results'
import EcrfTransferTimeColumn from './EcrfTransferTimeColumn'
import TimestampColumn from '../../../../components/TimestampColumn'
import {getFilterValue, getSortValue} from './TabComponents'
import {List} from 'immutable'

import App from '../../../../models/App'
import MedicationEvent from '../../../../models/MedicationEvent'
import Criteria from '../../../../models/Criteria'
import BooleanColumn from './BooleanColumn'
import TranslationText from './TranslationText'
import {openNewTab} from '../../../../util'

const medicationEventColumns = [
  'siteStudyName',
  'person',
  'name',
  'time',
  'dosage',
  'usage',
  'comment',
  'personnelComment',
  'ecrfTransferTime',
  'personnel',
  'locked'
]

interface Props {
  app: App
  events: List<MedicationEvent>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any
  navigate: (url: string) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onRowClick?: (rowData) => any
  onProcessedData?: () => any
  t: (key, params?) => any
}

interface State {}

export default class MedicationEvents extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'time':
        return TimestampColumn
      case 'ecrfTransferTime':
        return EcrfTransferTimeColumn
      case 'locked':
        return BooleanColumn
      case 'dosage':
      case 'usage':
      case 'comment':
        return ({rowData, data, column}) => {

          const {translations} = rowData

          const translation = translations && translations.find(translation => translation.field === column)

          return (<TranslationText t={this.props.t} fieldValue={data} translation={translation}/>)
        }
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return medicationEventColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: getFilterValue(this.props, field),
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  onRowClick = rowData => {
    const {app} = this.props
    const path = `/admin/medication-event/${rowData.id}`

    if (app.hasAccessToPage(path)) {
      openNewTab(path)
    }
  }

  render() {
    return (
      <Results
        columns={this.getColumns()}
        results={this.props.events}
        ref='results'
        onRowClick={this.onRowClick}
        {...this.props}
      />
    )
  }
}
