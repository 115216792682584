import React from 'react'
import {Component} from 'react'
import SubjectMedicationEventView from '../../components/SubjectMedicationEventView'

interface Props {
  params: any
  location: string
}

interface State {}

export default class MedicationEvent extends Component<Props, State> {
  render() {
    const {params, location} = this.props

    return (
      <SubjectMedicationEventView
        key='investigator-medication-event-view'
        backHref='/admin/reports?tab=medicationEvents'
        location={location}
        eventId={parseInt(params.id, 10)}
      />
    )
  }
}
