import React, {PureComponent} from 'react'
import Period from '../../../../models/Period'
import Study from '../../../../models/Study'
import Select from 'react-select'
import TranslationInput from '../../../../components/TranslationInput'
import {SelectOption} from '../../../../config/constants'
import classNames from 'classnames'

interface Props {
  updatePeriod: (period) => any
  t: (key, params?) => any
  period: Period
  study: Study
  defaultLanguage: string
}

interface State {
  studyLanguageOptions: SelectOption<string>[]
  translationLanguageOptions: SelectOption<string>[]
  selectedTranslation: any
}

export default class PeriodTranslations extends PureComponent<Props, State> {

  constructor(props) {
    super(props)

    const {defaultLanguage} = props
    const studyLanguageOptions = this.getStudyLanguageOptions()
    const translationLanguageOptions = studyLanguageOptions.filter(option => option.value !== defaultLanguage)
    const firstLanguage = translationLanguageOptions.length > 0 ? translationLanguageOptions[0].value : undefined
    const selectedTranslation = firstLanguage
      ? translationLanguageOptions.find(o => o.value === firstLanguage)
      : undefined

    this.state = {
      studyLanguageOptions,
      translationLanguageOptions,
      selectedTranslation
    }
  }

  componentDidUpdate(prevProps: Props) {

    const {study, defaultLanguage, period} = this.props

    if (study.languages !== prevProps.study.languages ||
      defaultLanguage !== prevProps.defaultLanguage ||
      period !== prevProps.period) {

      const studyLanguageOptions = this.getStudyLanguageOptions()
      const translationLanguageOptions = studyLanguageOptions.filter(option => option.value !== defaultLanguage)
      let {selectedTranslation} = this.state

      if (selectedTranslation && !translationLanguageOptions.some(o => o.value === selectedTranslation.value) ||
        !selectedTranslation) {
        const firstLanguage = translationLanguageOptions.length > 0 ? translationLanguageOptions[0].value : undefined
        selectedTranslation = firstLanguage
          ? translationLanguageOptions.find(o => o.value === firstLanguage)
          : undefined
      }

      this.setState({studyLanguageOptions, translationLanguageOptions, selectedTranslation})
    }
  }

  getStudyLanguageOptions = () => {

    const {t, study, defaultLanguage, period} = this.props

    const sort = (a, b) => {
      if (a.isDefault !== b.isDefault) {
        return a.isDefault ? -1 : 1
      }

      return a.label.localeCompare(b.label)
    }

    return study.languages
      .map(language => {

        const isDefault = language === defaultLanguage
        const isValid = !period.validateByLanguage(language)

        return {
          value: language,
          label: t(`language.${language}`),
          isDefault,
          isValid
        }
      })
      .sort(sort)
      .toArray()
  }

  renderQuestionnaireLanguages = () => {

    const {studyLanguageOptions} = this.state

    const renderLanguage = ({isDefault, isValid, label, value}) => {

      const classes = classNames({'language': true, 'is-default': isDefault, 'is-valid': isValid})

      return (<div key={value} className={classes}>{label}</div>)
    }

    return (
      <div className='period-languages'>
        <label>{this.props.t('studyView.languages')}</label>
        <div className='languages'>{studyLanguageOptions.map(renderLanguage)}</div>
      </div>
    )
  }

  onTranslationLanguageChange = (selectedTranslation) => this.setState({selectedTranslation})

  renderSelectTranslationLanguage = () => {

    const {t} = this.props
    const {studyLanguageOptions, selectedTranslation} = this.state
    const translationLanguageOptions = studyLanguageOptions.filter(option => !option.isDefault)

    if (translationLanguageOptions.length === 1 && selectedTranslation) {

      return <div>{t(`language.${selectedTranslation.value}`)}</div>
    }

    return (
      <Select
        key='translation-language'
        name='translation-language'
        className='translation-language-select'
        isMulti={false}
        isClearable={false}
        options={translationLanguageOptions}
        value={selectedTranslation}
        onChange={this.onTranslationLanguageChange}
      />
    )
  }

  renderTranslationLanguageStatistics = () => {

    const {t, period, defaultLanguage} = this.props
    const {selectedTranslation} = this.state
    const count = period.getLanguageTranslations(selectedTranslation.value).length
    const expected = period.getLanguageTranslations(defaultLanguage).length

    return (
      <div>{t(`questionnaire.translationStatistics`, {count, expected})}</div>
    )
  }

  renderAdditionalLanguageColumnHeader = () => {

    const {selectedTranslation} = this.state

    if (!selectedTranslation) {
      return
    }

    return (
      <div className='translations-column additional-language'>
        {this.renderSelectTranslationLanguage()}
        {this.renderTranslationLanguageStatistics()}
      </div>
    )
  }

  renderTranslationInput = (index, language, field, order, value, tabIndex, key, showError = false) => {

    const onChange = (updatedValue) => {

      let {period, updatePeriod} = this.props

      if (index === 0) {
        updatePeriod(period.setField(field, updatedValue, language))
      } else {
        let periodDay = period.getPeriodDay(order)
        periodDay = periodDay.setField(field, updatedValue, language)
        updatePeriod(period.updatePeriodDay(periodDay))
      }
    }

    return (
      <TranslationInput key={key} tabIndex={tabIndex} value={value} showError={showError} onChange={onChange}/>
    )
  }

  renderTranslationRows = () => {

    const {period, defaultLanguage} = this.props
    const {selectedTranslation} = this.state
    const defaultLanguageTranslations = period.getLanguageTranslations(defaultLanguage)

    if (!selectedTranslation) {

      return (
        defaultLanguageTranslations.map((translationDetail, index) => {

          const {field, order, value} = translationDetail
          const translationInput = this.renderTranslationInput(
            index,
            defaultLanguage,
            field,
            order,
            value,
            0,
            'default' + index
          )

          return (
            <div key={`translations-row-${index}`} className='translations-row'>
              {translationInput}
            </div>
          )
        })
      )
    }

    const selectedLanguage = selectedTranslation.value
    const selectedLanguageTranslations = period.getLanguageTranslations(selectedLanguage)
    const rowCount = defaultLanguageTranslations.length
    const showErrors = true

    return (
      defaultLanguageTranslations.map((translationDetail, index) => {

        const {field, order, value} = translationDetail
        const selectedLanguageTranslationDetail = selectedLanguageTranslations
          .find(l => l.field === field && l.order === order)
        const valueInput = this.renderTranslationInput(
          index,
          defaultLanguage,
          field,
          order,
          value,
          index + 1,
          'default' + index
        )
        const translationInput = this.renderTranslationInput(
          index,
          selectedLanguage,
          field,
          order,
          selectedLanguageTranslationDetail && selectedLanguageTranslationDetail.value,
          rowCount + index + 1,
          'selected' + index,
          showErrors
        )

        return (
          <div key={`translations-row-${index}`} className='translations-row'>
            {valueInput}
            {translationInput}
          </div>
        )
      })
    )
  }

  renderTranslations = () => {

    const {t, defaultLanguage} = this.props

    if (!defaultLanguage) {
      return
    }

    return (
      <div className='translations'>
        <div className='translations-row translations-header'>
          <div className='translations-column'>{t(`language.${defaultLanguage}`)}</div>
          {this.renderAdditionalLanguageColumnHeader()}
        </div>
        {this.renderTranslationRows()}
      </div>
    )
  }

  render() {

    return (
      <div className='period-translations'>
        {this.renderQuestionnaireLanguages()}
        {this.renderTranslations()}
      </div>
    )
  }
}
