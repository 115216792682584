import {List, Record} from 'immutable'
import {generateUUID, isEqual} from '../util'
import {endOfLoading, endOfSaving, startOfLoading, startOfSaving} from './Common'
import ModelInterface from './ModelInterface'
import _ from 'lodash'
import {validate} from 'validate.js'

export default function BaseModel(defaults, equalsKeys = ['id'], constraints = {}) {
  const baseConstraints = {
    _id: {
      presence: {allowEmpty: false}
    }
  }

  const baseDefaults = {
    id: undefined,
    _id: undefined,
    isLoading: false,
    isSaving: false,
    error: undefined
  }

  const withBaseDefault = _.merge(baseDefaults, defaults)
  const withBaseConstraints = _.merge(baseConstraints, constraints)

  abstract class BaseModel<T extends ModelInterface<T>> extends Record(withBaseDefault)
    implements ModelInterface<T> {
    _id: string
    id: number
    isLoading: boolean
    isSaving: boolean
    error: any

    constructor(js = {}) {
      super(_.merge({_id: generateUUID()}, js))
    }

    abstract fromJS(js): T

    getId() {
      return this.id || this._id
    }

    setIdentityFrom(model: T): T {
      return this.set('id', model.id).set('_id', model._id) as any
    }

    identityEquals(model: T) {
      return (this.id && this.id === model.id) || (this._id && this._id === model._id)
    }

    hasIdentity(id) {
      return this.id === id || this._id === id
    }

    duplicate(): T {
      return this.set('id', undefined).set('_id', generateUUID()) as any
    }

    startOfLoading() {
      return startOfLoading(this)
    }

    endOfLoading(error) {
      return endOfLoading(this, error)
    }

    startOfSaving() {
      return startOfSaving(this)
    }

    endOfSaving(error) {
      return endOfSaving(this, error)
    }

    isEqual(otherObject) {
      return isEqual(equalsKeys, this.toJS(), otherObject.toJS())
    }

    getConstraints() {
      return withBaseConstraints
    }

    validate() {
      return this._validate(this.getConstraints())
    }

    isValid() {
      return !this.validate()
    }

    addTranslation(translation) {
      let list = this.get('translations')
      const current = list.find(m => translation.identityEquals(m))

      if (current) {
        const index = list.indexOf(current)

        list = list.set(index, translation.setIdentityFrom(current))
      } else {
        list = list.push(translation)
      }

      return this.set('translations', list)
    }

    getTranslation(field: string) {
      return this.get('translations').find(t => t.field === field)
    }

    protected _validate(validationConstraints) {
      return validate(this.toJS(), validationConstraints, {fullMessages: false})
    }

    protected setListArray(listArray: Array<string | object>, js?): BaseModel<T> {
      if (!js) {
        return this as BaseModel<T>
      }

      let model = this as BaseModel<T>

      listArray.forEach(listName => {
        if (_.isObject(listName)) {
          const resolvedListName = Object.keys(listName)[0]
          const func = listName[resolvedListName]
          model = model.set(
            resolvedListName,
            List((js[resolvedListName] || []).map(func))
          ) as BaseModel<T>
        } else {
          model = model.set(listName as string, List(js[listName as string] || [])) as BaseModel<T>
        }
      })

      return model
    }
  }

  return BaseModel
}
