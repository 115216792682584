import React from 'react'
import {Component} from 'react'
import SiteStudyView from '../../../components/SiteStudy'
import _ from 'lodash'
import {connect} from 'react-redux'
import {withNamespaces, WithNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  siteId: number
  isNew: boolean
  siteStudyId?: number
}

interface State {}

class SiteStudy extends Component<Props, State> {
  render() {
    const {siteId, siteStudyId, isNew, t} = this.props

    return <SiteStudyView key='site-study-view' isNew={isNew} siteId={siteId} siteStudyId={siteStudyId} t={t}/>
  }
}

const getParamInt = (params, key) => {
  return params[key] ? parseInt(params[key], 10) : undefined
}

const mapStateToProps = (_state, ownProps) => {

  const {params} = ownProps
  const siteStudyId = getParamInt(params, 'id')
  const siteId = getParamInt(params, 'siteId')
  const isNew = _.isNil(siteStudyId)

  return {siteId, siteStudyId, isNew}
}

const mapActionToProps = {}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(SiteStudy))
