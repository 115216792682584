import React from 'react'
import {Component} from 'react'

import _ from 'lodash'

interface Props {
  rowData: any
}

interface State {}

export default class ConsumedColumn extends Component<Props, State> {
  statusCount = status =>
    _.filter(this.props.rowData.receivers, (receiver: any) => receiver.status === status).length

  render() {
    const consumedCount = this.statusCount('CONSUMED')
    const failedCount = this.statusCount('FAILED')
    const totalCount = this.props.rowData.receivers.length

    const getConsumedClass = () => {
      if (consumedCount === totalCount) {
        return 'fa fa-check'
      } else if (failedCount > 0) {
        return 'fa fa-times'
      }
    }

    return (
      <div className='consumed'>
        {consumedCount + ' / ' + totalCount} <i className={getConsumedClass()}/>
      </div>
    )
  }
}
