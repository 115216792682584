/**
 * Created by Mauritz Untamala on 27/09/15.
 */
import React from 'react'
import {Component} from 'react'

interface Props {
  data: any
  rowData: any
  t?: (key, params?) => any
}

interface State {
  showOk: boolean
  showFail: boolean
}

export default class StatusColumn extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showOk: false,
      showFail: false
    }
  }

  componentDidUpdate(prevProps) {
    const {data, rowData} = this.props
    const {showOk, showFail} = this.state

    if (prevProps.data && !data && !rowData._src.error && !showOk) {
      this.setState({showOk: true})

      setTimeout(
        function() {
          this.setState({showOk: false})
        }.bind(this),
        2000
      )
    } else if (rowData._src.error && !(!showOk && showFail)) {
      this.setState({showOk: false, showFail: true})
    } else if (data && !(!showOk && !showFail)) {
      this.setState({showOk: false, showFail: false})
    }
  }

  render() {
    if (this.props.data) {
      return <i className='fa fa-refresh fa-spin fa-lg iconSaving'/>
    } else if (this.state.showOk) {
      return <i className='fa fa-check-circle fa-lg iconOK'/>
    } else if (this.state.showFail) {
      const error = this.props.rowData._src.error

      return (
        <i
          className='fa fa-exclamation-circle fa-lg iconFail'
          data-tip={error ? error.message : ''}
        />
      )
    } else {
      return <div/>
    }
  }
}
