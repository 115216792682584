import Period from './Period'
import OrderedCollection from './OrderedCollection'
import QuestionnairesModel from './Questionnaires'
import {accumulate, unique} from '../util'

export default class Periods extends OrderedCollection(Period)<Period> {

  getPeriodByDayId(dayId) {
    return this.list.find(period => !!period.getPeriodDays().find(day => day.id === dayId))
  }

  getPeriodDayIdsWithQuestionnairesOrderedByPeriodDay(questionnaires: QuestionnairesModel) {
    return questionnaires.list
      .map(q => q.getPeriodDayIds().toArray())
      .toArray()
      .reduce(accumulate, [])
      .reduce(unique, [])
      .sort((dayId, otherDayId) => {

        const period = this.getPeriodByDayId(dayId)
        const otherPeriod = this.getPeriodByDayId(otherDayId)

        if (!period || !otherPeriod) {
          return 0
        }

        if (period.getOrder() === otherPeriod.getOrder()) {
          return period.getPeriodDayById(dayId).getOrder() - otherPeriod.getPeriodDayById(otherDayId).getOrder()
        }

        return period.getOrder() - otherPeriod.getOrder()
      })
  }
}
