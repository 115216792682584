import {List} from 'immutable'
import BaseModel from './BaseModel'
import ReminderReceiver from './ReminderReceiver'

const constraints = {
  studySiteId: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = [
  'message',
  'receiverGroupIds',
  'receiverUserIds',
  'sendTime',
  'studySiteId'
]

const defaultValues = {
  message: '',
  receivers: List(),
  sendTime: undefined,
  consumed: false,
  studySiteId: undefined,
  _src: undefined
}

export default class Reminder extends BaseModel(defaultValues, interestedKeys, constraints)<Reminder> {
  message: string
  receivers: List<any>
  sendTime: any
  consumed: boolean
  studySiteId: number
  _src: any

  constructor(js?) {
    super(js)

    return this.setListArray(
      [{receivers: receiver => new ReminderReceiver(receiver)}],
      js
    ) as Reminder
  }

  fromJS(js): Reminder {
    return new Reminder(js)
  }
}
