import ModelModule from './ModelModule'
import QuestionnaireAnswer from '../models/QuestionnaireAnswer'
import {bind} from '../util'
import Questionnaire from '../models/Questionnaire'
import {update} from './ActionsCommon'

export class QuestionnaireAnswerModule extends ModelModule<QuestionnaireAnswer> {
  constructor() {
    super('questionnaire/answer', 'questionnaireAnswer')
    bind(this, this.resetQuestionnaireAnswer)
  }

  protected getInitialState = () => new QuestionnaireAnswer()

  resetQuestionnaireAnswer(questionnaire: Questionnaire, periodDayId: number) {
    return (dispatch, getState) => {
      const personId = getState().authenticatedUser.id

      const payload = {
        questionnaireId: questionnaire ? questionnaire.id : undefined,
        questionnaire,
        periodDayId,
        personId
      }

      dispatch(this.resetModelAction(payload))
    }
  }

  /**
   * @override
   */
  public updateModel(model) {
    return (dispatch, getState) => {

      dispatch(this.updateModelRequestAction(model))

      if (model.isValid()) {

        return dispatch(update(this.name, model.id, null, model))
          .then((response) => {

            const {[this.context]: model} = getState()

            if (model.isSaving) {
              dispatch(this.updateModelSuccessAction(response))
              return dispatch(this.onUpdateSuccess(model))
            }

          })
          .catch(error => dispatch(this.handleError(this.updateModelFailedAction, error)))
      } else {
        return Promise.resolve(dispatch(this.updateModelFailedAction(model.validate())))
      }
    }
  }

  /**
   * @override
   */
  protected getAdditionalActionHandlers() {
    return {
      [this.updateModelRequestType]: (_state, {payload}) => payload.startOfSaving(),
      [this.resetModelType]: (_state, {payload}) => new QuestionnaireAnswer(payload)
    }
  }
}

export default new QuestionnaireAnswerModule()
