import React from 'react'
import BaseCriteria, {BaseProps, BaseState} from './BaseCriteria'

import {List} from 'immutable'

import Study from '../models/Study'
import SiteStudy from '../models/SiteStudy'

export interface Props extends BaseProps {
  studies: List<Study>
  siteStudies: List<SiteStudy>
  showLocked?: boolean
}

interface State extends BaseState {}

export default class CriteriaForm extends BaseCriteria<Props, State> {
  onSelectedStudiesChange = selectedStudies =>
    this.onListCriteriaChange('studies', selectedStudies)

  onSelectedSiteStudiesChange = selectedSiteStudies =>
    this.onListCriteriaChange('siteStudies', selectedSiteStudies)

  getSiteStudyLabel = ({studyName, number}) => studyName + ' - ' + number

  getStudyCriteria = () =>
    this.createSelectCriteria('studies', this.getLabel, this.onSelectedStudiesChange)

  getSiteStudyCriteria = () =>
    this.createSelectCriteria(
      'siteStudies',
      this.getSiteStudyLabel,
      this.onSelectedSiteStudiesChange
    )

  getCriteriaForm = () => {
    const {showLocked} = this.props
    return (
      <div key='criteria-form' className='form-horizontal'>
        {this.getStudyCriteria()}
        {this.getSiteStudyCriteria()}
        {this.createSelectCriteria('users', this.getLabel, this.onSelectedUsersChange)}
        {this.createDateRangeCriteria()}
        {showLocked && this.createCheckboxCriteria('locked')}
      </div>
    )
  }
}
