/**
 * Created by Mauritz Untamala on 16/02/16.
 */
import React from 'react'
import {PureComponent} from 'react'
import SubPageView from './SubPageView'

import changeCase from 'change-case'
import _ from 'lodash'

import AccessRights from '../models/AccessRights'
import ModelInterface from '../models/ModelInterface'
import {bind} from '../util'

export interface BaseProps<M> {
  wrapperClass: string
  title: boolean | string | object

  modelId: number
  model: M
  modelName: string
  getModel: (id) => any
  resetModel: () => any
  saveModel: (model) => any

  accessRights: AccessRights
  getAccessRights: (modelName, model) => any

  editUrl: string
  backHref: string
  forceBackHref: boolean
  navigate: (url: string, silent?: boolean) => any
  navigateBackOnSaved?: boolean
  t: (key, params?) => any
}

export interface BaseState<M> {
  model: M
  modelWithoutChanges: M
  backButtonDisabled: boolean
}

export default abstract class ModelView<M extends ModelInterface<M>,
  P extends BaseProps<M>,
  S extends BaseState<M>> extends PureComponent<P, S> {
  debouncedAccessRights: any

  constructor(props: P) {
    super(props)

    this.state = {
      model: props.model,
      modelWithoutChanges: props.model,
      backButtonDisabled: false
    } as S

    this.debouncedAccessRights = _.debounce(props.getAccessRights, 500)
    bind(this, this.onModelSaved)
  }

  componentDidMount() {

    const {modelId} = this.props

    return this.fetchOrCreateModel(modelId)
  }

  componentDidUpdate(prevProps, _prevState) {
    const {modelId, model, modelName} = this.props

    if (prevProps.modelId !== modelId) {
      return this.fetchOrCreateModel(modelId)
    }

    // Update state if id changes or if user is updated but it's not saving
    // (we want to avoid the jitter of model when updating
    // as the isSaving is set to true while saving)
    if (prevProps.model.id !== model.id || prevProps.model !== model) {
      let updatedModel = model

      if (model.error) {
        updatedModel = this.state.model.endOfSaving(model.error)
      } else if (model.isSaving) {
        updatedModel = this.state.model.startOfSaving()
      }

      this.setState({model: updatedModel, modelWithoutChanges: updatedModel})

      if (!model.isSaving && !model.isLoading) {
        this.debouncedAccessRights(changeCase.paramCase(modelName), model)
      }
    }
  }

  fetchOrCreateModel = modelId => {
    if (modelId) {
      return this.props.getModel(modelId)
    } else {
      return this.props.resetModel()
    }
  }

  getTitle() {
    const {title, t, modelName} = this.props

    if (title === false) {
      return null
    }
    const viewName = modelName + 'View'

    return title ? <h1>{title}</h1> : <h1>{t(viewName + '.title')}</h1>
  }

  content = () => {
    const {model, t, wrapperClass, modelName} = this.props

    if (!model) {
      return <div key='model-loading'>{t('loading')}</div>
    }

    const viewName = modelName + 'View'
    const className = wrapperClass ? wrapperClass : changeCase.paramCase(viewName)

    return (
      <div className={className}>
        {this.getTitle()}
        {this.getContent()}
      </div>
    )
  }

  updateModel = model => this.setState({model})

  save = () => this.props.saveModel(this.state.model)

  abstract getContent()

  isFormDirty = () => {
    if (this.state.modelWithoutChanges !== this.state.model) {
      return true
    }

    return false
  }

  onModelSaved() {
    const {modelId, navigate, editUrl, navigateBackOnSaved, backHref} = this.props
    const {model} = this.state

    if (navigateBackOnSaved) {
      navigate(backHref)
      return
    }

    if (!modelId && model.id && editUrl) {
      navigate(`${editUrl}/${model.id}`)
    }
  }

  render() {
    const {backHref, forceBackHref, t} = this.props

    if (!backHref) {
      return this.content()
    }

    const navItems = backHref
      ? [
        {
          label: 'back',
          href: backHref,
          force: forceBackHref,
          className: 'back-navigation'
        }
      ]
      : null

    return (
      <SubPageView navItems={navItems} isFormDirty={this.isFormDirty()} t={t}>
        {this.content()}
      </SubPageView>
    )
  }
}
