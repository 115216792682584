/**
 * Created by Mauritz Untamala on 01/03/2017.
 */
import moment from 'moment'

const COMMON_DATE_FIELDS = ['createdAt', 'updatedAt']

export const startOfLoading = (container) => {
  return container.set('isLoading', true).set('error', null)
}

export const endOfLoading = (container, error) => {
  return container.set('isLoading', false).set('error', error)
}

export const startOfSaving = (container) => {
  return container.set('isSaving', true).set('error', null)
}

export const endOfSaving = (container, error) => {
  return container
    .set('isLoading', false)
    .set('isSaving', false)
    .set('error', error)
}

export const setDateFields = (model, fields?): any => {
  fields = fields ? fields.concat(COMMON_DATE_FIELDS) : COMMON_DATE_FIELDS

  fields.filter((field) => model.has(field)).forEach((field) => {
    model = model.set(field, model.get(field) ? moment(model.get(field)) : null)
  })

  return model
}

export interface Identifyable {
  getId(): string | number
}
