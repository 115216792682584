/**
 * Created by Mauritz Untamala on 17/03/16.
 */
import React from 'react'
import ActivityTrackerDetails from './ActivityTrackerDetails'
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView'
import SaveButton from '../../../components/SaveButton'

import changeCase from 'change-case'
import {withNamespaces} from 'react-i18next'
import {ROLES} from '../../../config/constants'

import {connect} from 'react-redux'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate} from '../../../modules/Location'
import ActivityTrackerModule from '../../../modules/ActivityTracker'
import UsersModule from '../../../modules/Users'
import ActivityTrackerModel from '../../../models/ActivityTracker'
import Users from '../../../models/Users'

import '../../../styles/tracker.less'

interface Props extends BaseProps<ActivityTrackerModel> {
  users: Users
  selectedSiteStudyId: number
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
}

interface State extends BaseState<ActivityTrackerModel> {}

class ActivityTracker extends ModelView<ActivityTrackerModel, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getUsers(ROLES.SUBJECT)
  }

  getContent() {
    const {model} = this.state
    const {modelName, users, selectedSiteStudyId, accessRights, t} = this.props

    return [
      (
        <ActivityTrackerDetails key='activity-tracker-details'
                                tracker={model}
                                users={users}
                                selectedSiteStudyId={selectedSiteStudyId}
                                accessRights={accessRights}
                                onChange={this.updateModel}
                                t={t}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      )
    ]
  }
}

const mapActionToProps = {
  saveModel: ActivityTrackerModule.saveModel,
  resetModel: ActivityTrackerModule.resetModel,
  getModel: ActivityTrackerModule.getModel,
  getUsers: UsersModule.getUsers,
  getAccessRights,
  navigate
}

const mapStateToProps = (
  {authenticatedUser: {selectedSiteStudyId}, accessRights, activityTracker, users},
  ownProps
) => {
  const modelId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined

  return {
    modelId,
    selectedSiteStudyId,
    accessRights,
    model: activityTracker,
    users
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelName: 'tracker',
    backHref: '/admin/trackers',
    forceBackHref: true,
    editUrl: `/admin/tracker`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps)
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(ActivityTracker))
