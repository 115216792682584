import {List} from 'immutable'
import _ from 'lodash'
import {formatDate} from '../util'
import {ROLES} from '../config/constants'
import {validators} from 'validate.js'
import moment from 'moment'
import i18n from './I18n'

export function registerCustomValidators() {
  validators.isBeforeAttribute = function(value, options, key, attributes) {
    function getMomentValue(dateValue, field, startTime?) {
      const dateOnlyField = field + 'DateOnly'
      const dateOnly = attributes[dateOnlyField]
      const momentValue = moment(dateValue)

      if (dateOnly) {
        return startTime ? momentValue.startOf('day') : momentValue.endOf('day')
      }

      return momentValue
    }

    if (attributes[options]) {
      const moment1 = getMomentValue(value, key, true)
      const moment2 = getMomentValue(attributes[options], options)

      if (moment1.diff(moment2) >= 0) {
        return i18n.t('validate.dateMustBeBeforeAttribute', {
          attribute: i18n.t('validate.attribute.' + options),
          date: formatDate(moment1, attributes[key + 'DateOnly']),
          date2: formatDate(moment2, attributes[options + 'DateOnly'])
        })
      }
    }

    return null
  }

  validators.isBeforeNow = function(value, _options, key, attributes) {
    if (value) {
      const moment1 = moment(value)
      const now = moment().endOf('minute')

      if (moment1.diff(now) >= 0) {
        const dateOnly = attributes[key + 'DateOnly']

        return i18n.t('validate.dateMustBeBeforeNow', {
          date: formatDate(moment1, dateOnly),
          date2: formatDate(now)
        })
      }
    }

    return null
  }

  validators.roles = function(value, _options, _key, _attributes) {
    if (value) {
      const list = List.isList(value) ? value : List(value)
      const hasSubject = list.find(role => role === ROLES.SUBJECT)

      if (hasSubject && list.size > 1) {
        return i18n.t('validate.roles')
      }
    }

    return null
  }

  validators.listSize = function(value, options, _key, _attributes) {
    if (value) {
      const list = List.isList(value) ? value : List(value)

      if (list.size !== parseInt(options, 10)) {
        return i18n.t('validate.listSize', {length: options})
      }
    }

    return null
  }

  validators.inclusion = function(value, options, _key, _attributes) {
    if (value) {
      if (
        (!_.isArray(value) && !_.includes(options, value)) ||
        (_.isArray(value) && _.difference(value, options).length > 0)
      ) {
        return i18n.t('errors.language.invalid', {allowedLanguages: options})
      }
    }

    return undefined
  }

  validators.presence.message = i18n.t('validate.presence')
  validators.email.message = i18n.t('validate.email')
  validators.equality.message = i18n.t('validate.equality')
  validators.length.tooShort = i18n.t('validate.tooShort')
}
