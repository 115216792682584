import React from 'react'
import {PureComponent} from 'react'
import Results from './Results'
import TypeColumn from './TypeColumn'
import PeriodColumn from './PeriodColumn'
import BooleanColumn from './BooleanColumn'
import TimestampColumn from '../../../../components/TimestampColumn'
import {getFilterValue, getSortValue} from './TabComponents'
import {List} from 'immutable'

import AdministrationEvent from '../../../../models/AdministrationEvent'
import Criteria from '../../../../models/Criteria'

const administrationColumns = [
  'siteStudyName',
  'person',
  'type',
  'time',
  'period',
  'rescheduled',
  'locked'
]

interface Props {
  events: List<AdministrationEvent>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class Administrations extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'type':
        return TypeColumn
      case 'time':
        return TimestampColumn
      case 'period':
        return PeriodColumn
      case 'rescheduled':
      case 'locked':
        return BooleanColumn
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return administrationColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: getFilterValue(this.props, field),
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    const {events, ...rest} = this.props
    return <Results columns={this.getColumns()} results={events} ref='results' {...rest} />
  }
}
