import React from 'react'
import {PureComponent} from 'react'
import AdverseEvent from './components/AdverseEvent'
import MedicationEvent from './components/MedicationEvent'

import {WithNamespaces, withNamespaces} from 'react-i18next'
import {List} from 'immutable'

import {connect} from 'react-redux'
import AdverseEvents from '../../../modules/AdverseEvents'
import {navigate} from '../../../modules/Location'
import MedicationEvents from '../../../modules/MedicationEvents'
import AdverseEventModel from '../../../models/AdverseEvent'
import AdverseEventsModel from '../../../models/AdverseEvents'
import MedicationEventModel from '../../../models/MedicationEvent'
import MedicationEventsModel from '../../../models/MedicationEvents'

import '../../../styles/events.less'

interface Props extends WithNamespaces {
  adverseEvents: AdverseEventsModel
  medicationEvents: MedicationEventsModel
  getAdverseEvents: (queryParams?, reset?) => any
  getMedicationEvents: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  events: List<AdverseEventModel | MedicationEventModel>
}

class Events extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    const events = props.adverseEvents.list.concat(props.medicationEvents.list) as List<any>
    this.state = {events}
  }

  componentDidMount() {
    const {getAdverseEvents, getMedicationEvents} = this.props
    getAdverseEvents()
    getMedicationEvents()
  }

  componentDidUpdate(prevProps) {
    const {adverseEvents, medicationEvents} = this.props

    if (
      prevProps.adverseEvents !== adverseEvents ||
      prevProps.medicationEvents !== medicationEvents
    ) {
      const events = adverseEvents.list.concat(medicationEvents.list) as any
      this.setState({events})
    }
  }

  addNewMedicineEvent = () => this.props.navigate('/event/create-medication-event')

  addNewAdverseEvent = () => this.props.navigate('/event/create-adverse-event')

  getListItem = event => {
    const {navigate} = this.props

    if (event instanceof AdverseEventModel) {
      return <AdverseEvent key={event._id} event={event} navigate={navigate}/>
    } else if (event instanceof MedicationEventModel) {
      return <MedicationEvent key={event._id} event={event} navigate={navigate}/>
    } else {
      throw new Error('Unsupported event' + event.toString())
    }
  }

  getListItems() {
    const {t} = this.props

    let listItems = []

    listItems.push(
      <li className='list-group-item add' key='add_new_medicine' onClick={this.addNewMedicineEvent}>
        {t('addNewMedicineEventText')}
      </li>
    )
    listItems.push(
      <li
        className='list-group-item add'
        key='add_new_adverse_event'
        onClick={this.addNewAdverseEvent}>
        {t('addNewAdverseEventText')}
      </li>
    )
    listItems.push(this.state.events.map(this.getListItem))

    return listItems
  }

  render() {
    const {t} = this.props

    return (
      <div className='container-fluid events-view'>
        <h1>{t('subjectEventViewTitle')}</h1>

        <p>{t('subjectEventViewHelp')}</p>
        <ul className='list-group'>{this.getListItems()}</ul>
      </div>
    )
  }
}

const mapStateToProps = ({adverseEvents, medicationEvents}, _ownProps) => {
  return {
    adverseEvents,
    medicationEvents
  }
}

const mapActionToProps = {
  getAdverseEvents: AdverseEvents.getModels,
  getMedicationEvents: MedicationEvents.getModels,
  navigate
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Events))
