import Reminders from '../models/Reminders'
import ModelsModule from './ModelsModule'
import ReminderModule from './Reminder'

const initialState = new Reminders()

export class RemindersModule extends ModelsModule {
  constructor() {
    super('reminder', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [ReminderModule.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [ReminderModule.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [ReminderModule.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new RemindersModule()
