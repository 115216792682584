import React, {Component} from 'react'
import DeleteButton from '../../../../components/DeleteButton'

import {UIAction} from '../../../../models/UIAction'
import App from '../../../../models/App'

interface Props {
  app: App
  rowData: any
  updateTracker: (model) => any
  deleteTracker: (model) => any
}

interface State {}

export default class ActionsColumn extends Component<Props, State> {
  delete = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.deleteTracker(this.props.rowData._src)
  }

  unlink = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.updateTracker(this.props.rowData._src.set('userId', null))
  }

  getActions = () => {
    const {app, rowData} = this.props
    let actions = []

    if (app.hasAccessToAction(UIAction.DELETE_TRACKER)) {

      const deleteBtnKey = `deleteTracker_${rowData.id}`

      actions.push(
        <DeleteButton
          key={deleteBtnKey}
          id={deleteBtnKey}
          remove={this.delete}/>
      )
    }

    if (app.hasAccessToAction(UIAction.UNLINK_TRACKER) && rowData.userId) {
      actions.push(
        <button
          key={`unlink-tracker-${rowData.id}`}
          className='btn btn-default'
          onClick={this.unlink}
          role='button'>
          <i className='fa fa-chain-broken'/>
        </button>
      )
    }

    return actions
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}
