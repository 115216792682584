import {List} from 'immutable'
import _ from 'lodash'
import moment from 'moment'
import BaseModel from './BaseModel'
import {stringToIntArray, stringToArray} from '../util'
import {setDateFields} from './Common'
import User from './User'
import Users from './Users'
import SiteStudies from './SiteStudies'

const defaultValues = {
  from: undefined,
  to: undefined,
  users: List(),
  groups: List(),
  studies: List(),
  siteStudies: List(),
  selected: List(),
  study: undefined,
  tab: undefined,
  type: undefined,
  columnName: undefined,
  sort: undefined,
  filter: undefined,
  locked: false,
  lang: undefined,
  ids: undefined
}

const idLists = ['users', 'groups', 'studies', 'siteStudies', 'ids']
const stringLists = ['selected']
const dateFields = ['from', 'to']

const fieldParams = ['tab', 'study', 'type', 'filter', 'columnName', 'sort', 'locked', 'lang']
const listParams = ['users', 'groups', 'siteStudies', 'studies', 'ids', 'selected']

const equalsKeys = dateFields.concat(listParams, fieldParams)

export default class Criteria extends BaseModel(defaultValues, equalsKeys)<Criteria> {
  from: any
  to: any
  users: List<number>
  groups: List<any>
  studies: List<number>
  siteStudies: List<number>
  study: any
  tab: any
  type: undefined
  columnName: any
  sort: any
  filter: any
  locked: boolean
  lang: string
  ids: List<number>
  selected: List<any>

  static getInitialCriteriaWithoutDateRange(js = {}) {
    return new Criteria(_.merge({filter: ''}, js))
  }

  static fromQuery(js: any = {}): Criteria {
    if (js) {
      idLists.forEach(field => {
        js[field] = stringToIntArray(js[field])
      })
      stringLists.forEach(field => {
        js[field] = stringToArray(js[field])
      })
    }

    return new Criteria(js)
  }

  static getInitialCriteria(additionalCriteria?) {
    let from = moment()
      .subtract(4, 'months')
      .startOf('day')
    let to = moment().endOf('day')

    return new Criteria(_.merge({from, to}, additionalCriteria))
  }

  constructor(js?) {
    super(js)

    if (js) {
      let criteria = this.setListArray(listParams, js) as Criteria

      return setDateFields(criteria, dateFields)
    }
  }

  fromJS(js: any = {}): Criteria {
    return new Criteria(js)
  }

  listToParams(queryParams, fields) {
    _.each(
      fields,
      function(field) {
        let list = this[field]

        if (list) {
          let array = list.reduce(function(accu, id) {
            if (accu !== '') {
              accu = accu + ','
            }
            return accu + id
          }, '')
          if (array !== '') {
            queryParams[field] = array
          }
        }
      }.bind(this)
    )
  }

  fieldsToParams(queryParams, fields) {
    _.each(
      fields,
      function(field) {
        if (this[field]) {
          queryParams[field] = this[field]
        }
      }.bind(this)
    )
  }

  getQueryParams() {
    let queryParams = {}

    this.listToParams(queryParams, listParams)
    this.fieldsToParams(queryParams, fieldParams)

    _.each(
      dateFields,
      (dateField) => {
        if (this[dateField]) {
          queryParams[dateField] = this[dateField].toISOString()
        }
      }
    )

    return queryParams
  }

  sanitizeCriteria(user: User, users: Users, siteStudies: SiteStudies) {

    let criteria = this as Criteria

    // If studies change adjust the site studies
    if (!siteStudies.isEmpty() && !siteStudies.isLoading) {

      const criteriaStudies = criteria.studies

      if (!criteriaStudies.isEmpty()) {

        const filteredSiteStudies = siteStudies.list
          .filter(({studyId}) => criteriaStudies.includes(studyId))
          .map(({id}) => id)
        criteria = criteria.set('siteStudies', filteredSiteStudies) as Criteria
      }
    }

    // Filter site studies that do not belong to selected studies
    if (!siteStudies.isEmpty() && !siteStudies.isLoading && !criteria.studies.isEmpty()) {

      let filteredSiteStudies = criteria.siteStudies.filter(siteStudyId => {

        let siteStudy = siteStudies.getModelById(siteStudyId)

        if (!siteStudy) {
          return false
        }

        return criteria.studies.includes(siteStudy.studyId)
      })

      criteria = criteria.set('siteStudies', filteredSiteStudies) as Criteria
    }

    // Add users selected site study as default if empty
    if (criteria.siteStudies.isEmpty()) {
      criteria = criteria.set('siteStudies', List.of(user.selectedSiteStudyId)) as Criteria
    }

    if (!users.isEmpty() && !users.isLoading) {

      // Filter users not included in selected site studies
      let filteredUsers = criteria.users.filter(userId => {

        let user = users.getModelById(userId)

        if (!user) {
          return false
        }

        return !!user.siteStudyIds.find(id => criteria.siteStudies.includes(id))
      })

      criteria = criteria.set('users', filteredUsers) as Criteria
    }

    return criteria
  }

  setIds(ids: List<number>): Criteria {
    return this.set('ids', ids) as Criteria
  }
}
