/// ../window.d.ts
import {applyMiddleware, compose, createStore} from 'redux'
import makeRootReducer from './reducers'
import {Map} from 'immutable'
import {routerMiddleware} from 'react-router-redux'
import thunk from 'redux-thunk'
import appConfig from '../config'
import {createLogger} from 'redux-logger'
import AuthenticatedUserModule from '../modules/AuthenticatedUser'
import moment from 'moment'
import {isIntervalAction} from '../util'

export default (initialState = Map(), browserHistory?: any): Redux.Store<any> => {
  const middlewares: Redux.Middleware[] = [routerMiddleware(browserHistory), thunk]

  /** Add Only Dev. Middlewares */
  if (appConfig.env.toString() !== 'production' && process.env.BROWSER) {
    const logger = createLogger()
    middlewares.push(logger)
  }

  const composeEnhancers =
    (appConfig.env.toString() !== 'production' &&
      typeof window === 'object' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const appReducer = makeRootReducer()

  const rootReducer = (state, action) => {
    if (action.type === AuthenticatedUserModule.getLogoutSuccessType()) {
      state = initialState
    }

    if (!isIntervalAction(action) && state.app) {
      const app = state.app.setLastActionTime(moment())

      state = state.set('app', app)
    }

    return appReducer(state, action)
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  if (appConfig.env.toString() === 'development' && (module as any).hot) {
    (module as any).hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers'))
    })
  }

  return store
}
