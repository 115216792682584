/**
 * Created by Mauritz Untamala on 18/02/16.
 */
import React from 'react'
import GroupDetails from './components/GroupDetails'
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView'
import SaveButton from '../../../components/SaveButton'

import changeCase from 'change-case'
import {withNamespaces} from 'react-i18next'
import {ROLES, USER_TYPES} from '../../../config/constants'

import {connect} from 'react-redux'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate} from '../../../modules/Location'
import StudyGroupModule from '../../../modules/StudyGroup'
import Users from '../../../modules/Users'
import StudyGroupModel from '../../../models/StudyGroup'
import UsersModel from '../../../models/Users'

import '../../../styles/study-group.less'

interface Props extends BaseProps<StudyGroupModel> {
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  users: UsersModel
  selectedSiteStudyId: number
}

interface State extends BaseState<StudyGroupModel> {}

class StudyGroup extends ModelView<StudyGroupModel, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount()

    const {getUsers, selectedSiteStudyId} = this.props
    getUsers(ROLES.SUBJECT, USER_TYPES.SUBJECT, null, selectedSiteStudyId)
  }

  getContent() {
    const {model} = this.state
    const {users, accessRights, modelName, t} = this.props

    return [
      (
        <GroupDetails key='group-details'
                      group={model}
                      users={users}
                      accessRights={accessRights}
                      onChange={this.updateModel}
                      t={t}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      )
    ]
  }
}

const mapStateToProps = (
  {authenticatedUser: {selectedSiteStudyId}, studyGroup, users, accessRights},
  ownProps
) => {
  const {id} = ownProps.params
  const modelId = id ? parseInt(id, 10) : null

  return {
    model: studyGroup,
    modelId,
    selectedSiteStudyId,
    users,
    accessRights
  }
}

const mapActionToProps = {
  getModel: StudyGroupModule.getModel,
  resetModel: StudyGroupModule.resetModelWithSiteStudyId,
  saveModel: StudyGroupModule.saveModel,
  getUsers: Users.getUsers,
  getAccessRights,
  navigate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelName: 'studyGroup',
    backHref: '/admin/study-groups',
    forceBackHref: true,
    editUrl: `/admin/study-group`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(stateProps.selectedSiteStudyId)
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(StudyGroup))
