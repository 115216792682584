import React from 'react'
import {Component} from 'react'

import classNames from 'classnames'

import ModelInterface from '../../../../models/ModelInterface'

export interface BaseProps<M> {
  event: M
  navigate: (url: string, silent?: boolean) => any
}

export interface BaseState {}

export default abstract class EventComponent<M extends ModelInterface<M>,
  P extends BaseProps<M>,
  S extends BaseState> extends Component<P, S> {
  abstract getTopContent
  abstract getLowerContent

  editUrl: string

  protected constructor(props, editUrl) {
    super(props)
    this.editUrl = editUrl
  }

  onClick = () => this.props.navigate(`${this.editUrl}/${this.props.event.id}`)

  render() {
    const {event} = this.props
    const classes = classNames({
      'list-group-item': true,
      readonly: event.get('locked')
    })

    return (
      <li className={classes} onClick={this.onClick}>
        <div className='row event-row'>
          <span className='col-xs-12 col-sm-3'>{this.getTopContent()}</span>
          <span className='description name col-xs-12 col-sm-9'> {this.getLowerContent()}</span>
        </div>
      </li>
    )
  }
}
