import ModelsModule from './ModelsModule'
import Sites from '../models/Sites'
import Site from './Site'

const initialState = new Sites()

export class SitesModule extends ModelsModule {
  constructor() {
    super('site', initialState)
  }

  protected getAdditionalActionHandlers() {
    return {
      [Site.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Site.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [Site.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    }
  }
}

export default new SitesModule()
