import BaseModel from './BaseModel'
import I18n from '../services/I18n'
import {List} from 'immutable'
import PeriodDay from './PeriodDay'
import {accumulate, getTwoDigitLanguageCode, prefixKeys, reOrder} from '../util'
import _ from 'lodash'
import OrderedModel from './OrderedModel'

const constraints = {
  studyId: {
    presence: {allowEmpty: false}
  },
  name: {
    presence: {allowEmpty: false}
  },
  days: {
    presence: {allowEmpty: true},
    length: {
      minimum: 0
    }
  }
}

const equalsKeys = ['order']

const defaultValues = {
  studyId: undefined,
  order: undefined,
  name: undefined,
  days: List<PeriodDay>()
}

const constraintsByLanguage = lang => {
  return _.merge(
    {},
    constraints,
    {
      [`name.${lang}`]: {
        presence: {allowEmpty: false}
      }
    }
  )
}

export interface TranslationDetails {
  field: string
  value: string
  order?: number
}

export default class Period
  extends BaseModel(defaultValues, equalsKeys, constraints)<Period>
  implements OrderedModel<Period> {
  studyId: number
  order: number
  name: object
  days: List<PeriodDay>

  constructor(js?: any) {
    super(js)

    return this.setListArray([{days: js => new PeriodDay(js)}], js) as Period
  }

  fromJS(js: any): Period {
    return new Period(js)
  }

  getName() {
    return this.getLocalizedKey('name')
  }

  getStudyId() {
    return this.get('studyId')
  }

  setStudyId(studyId: number) {
    return this.set('studyId', studyId) as Period
  }

  getOrder() {
    return this.get('order')
  }

  setOrder(order: number) {
    return this.set('order', order) as Period
  }

  getPeriodDays(): List<PeriodDay> {
    return this.get('days') as List<PeriodDay>
  }

  setPeriodDays(days: List<PeriodDay>) {
    return this.set('days', days) as Period
  }

  getField(key: string, lang: string) {
    const field = this.get(key)

    switch (key) {
      case 'name':
        return field && field[lang] ? field[lang] : ''
      default:
        return field
    }
  }

  setField(key: string, value: any, lang: string) {
    const oldValue = this.get(key)
    let newValue

    switch (key) {
      case 'name':
        newValue = {...oldValue, [lang]: value}
        break
      default:
        newValue = value
    }
    return this.set(key, newValue) as Period
  }

  validateByLanguages(languages: string[]) {

    const combinedErrors = languages.map(language => this.validateByLanguage(language))
      .reduce((accu, value) => _.merge(accu, value), {})

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined
  }

  validateByLanguage(lang: string) {

    const periodErrors = this._validate(lang ? constraintsByLanguage(lang) : constraints)
    const periodDaysErrors = this.validatePeriodDaysByLanguage(lang)
    const combinedErrors = _.merge({}, periodErrors, periodDaysErrors)

    return !_.isEmpty(combinedErrors) ? combinedErrors : undefined
  }

  getPeriodDay(order: number): PeriodDay {
    return this.getPeriodDays()
      .find(day => day.order === order)
  }

  getPeriodDayById(dayId: number): PeriodDay {
    return this.getPeriodDays()
      .find(day => day.id === dayId)
  }

  getDisplayTitle(periodDayId: number) {
    const periodDay = this.getPeriodDayById(periodDayId)

    return `${this.getName()}${periodDay ? ` - ${periodDay.getName()}` : ''}`
  }

  addPeriodDay(language: string) {
    const list = this.getPeriodDays()
    const lastEntry = list.last()
    const order = lastEntry ? lastEntry.getOrder() + 1 : 1

    const periodDay = new PeriodDay({
      name: {
        [language]: ''
      },
      order
    })

    return this.setPeriodDays(list.push(periodDay))
  }

  updatePeriodDay(model: PeriodDay): Period {
    let list = this.getPeriodDays()
    const current = list.find(m => model.identityEquals(m))

    if (current) {
      const index = list.indexOf(current)
      return this.setPeriodDays(list.set(index, model.setIdentityFrom(current)))
    }

    throw new Error('No existing model found with id ' + model.getId())
  }

  deletePeriodDay(model: PeriodDay) {
    let list = this.getPeriodDays()
    const current = list.find(m => model.identityEquals(m))

    if (current) {
      const index = list.indexOf(current)
      return this.setPeriodDays(list.remove(index))
    } else {
      throw new Error('No existing model found with id ' + model.getId())
    }
  }

  reOrderPeriodDays(srcOrder: number, dstOrder: number) {

    const newPeriodDays = this.getPeriodDays()
      .map((day: PeriodDay) => reOrder(day, srcOrder, dstOrder))
      .sortBy(day => day.order) as List<PeriodDay>

    return this.setPeriodDays(newPeriodDays)
  }

  duplicateWithOrder(order: number): Period {
    return this.duplicate()
      .setOrder(order)
      .setPeriodDays(this.getPeriodDays().map(c => c.duplicate()) as List<PeriodDay>)
  }

  cleanupTranslations(defaultLanguage: string, additionalLanguages: string[]) {

    const nameLocalization = this.get('name')
    let model = this as Period

    if (_.isEmpty(nameLocalization && nameLocalization[defaultLanguage])) {

      model = model.set('name', undefined) as Period

    } else {

      const languages = [defaultLanguage].concat(additionalLanguages || [])
      const obsoleteLanguages = Object.keys(nameLocalization).filter(lang => !_.includes(languages, lang))

      model = model.set('name', _.omit(nameLocalization, obsoleteLanguages)) as Period
    }

    return model.setPeriodDays(model.getPeriodDays()
      .map(c => c.cleanupTranslations(defaultLanguage, additionalLanguages)) as List<PeriodDay>) as Period
  }

  getLanguageTranslations(language): TranslationDetails[] {

    const field = 'name'
    const nameTranslation = {
      field,
      value: this.getField(field, language)
    }

    return this.getPeriodDays()
      .map(day => day.getLanguageTranslations(language))
      .filter(translations => !_.isEmpty(translations))
      .toArray()
      .reduce(accumulate, [nameTranslation])
  }

  private getLocalizedKey(key) {
    const language = getTwoDigitLanguageCode(I18n.language)

    if (this[key]) {
      return this[key][language] ? this[key][language] : this[key][Object.keys(this[key])[0]]
    }

    return I18n.t('questionnaire.noLocalization', {key, language})
  }

  private validatePeriodDaysByLanguage(lang: string) {

    return this.getPeriodDays()
      .map((day, index) => prefixKeys(`periodDays[${index}]`, day.validateByLanguage(lang)))
      .filter(errors => !_.isEmpty(errors))
      .toArray()
      .reduce((accu, value) => _.merge(accu, value), {})
  }
}
