import BaseModel from './BaseModel'
import {setDateFields} from './Common'

const constraints = {
  personId: {
    presence: {allowEmpty: false}
  },
  type: {
    presence: {allowEmpty: false}
  },
  time: {
    presence: true,
    isBeforeNow: true
  },
  period: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['personId', 'type', 'time', 'period', 'rescheduled']

const defaultValues = {
  personId: undefined,
  type: undefined,
  time: undefined,
  timeDateOnly: false,
  period: undefined,
  rescheduled: false,
  updateTime: undefined,
  locked: false,
  studySiteId: undefined
}

export default class AdministrationEvent extends BaseModel(
  defaultValues,
  interestedKeys,
  constraints
)<AdministrationEvent> {
  personId: number
  type: string
  time: any
  timeDateOnly: any
  period: any
  rescheduled: boolean
  updateTime: any
  locked: boolean
  studySiteId: number

  constructor(js?) {
    super(js)

    if (js) {
      return setDateFields(this, ['time', 'ecrfTransferTime'])
    }
  }

  fromJS(eventJS): AdministrationEvent {
    return new AdministrationEvent(eventJS)
  }
}
