import React from 'react'
import {ControlLabel, FormGroup} from 'react-bootstrap'
import SanitizedHTML from 'react-sanitized-html'
import TimePickerAccordion from '../TimePicker'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'

import moment from 'moment'

interface Props extends BaseQuestionComponentProps {
}

interface State extends BaseQuestionComponentState {
}

export default class DateTime extends BaseQuestionComponent<Props, State> {

  onChange = change => {
    const {field, answer} = this.props

    const updatedValue = answer.value.set(field, change.time)
    this.props.onChange(answer.set('value', this.compactRecord(updatedValue)))
  }

  getContent() {
    const {fieldError, disabled, t} = this.props
    let value = this.getAnswerValue()

    if (value && !moment.isMoment(value)) {
      value = moment(value)
    }

    const label = <SanitizedHTML allowedTags={['b']} html={this.getLocalization()}/>

    return (
      <FormGroup validationState={fieldError ? 'error' : null}>

        <ControlLabel>{label}</ControlLabel>

        <TimePickerAccordion
          time={value}
          showDateOnlyToggle={false}
          showNow={true}
          readonly={disabled}
          onChange={this.onChange}
          t={t}
        />

        {this.getError(fieldError)}
      </FormGroup>
    )
  }
}
