/**
 * Created by jarkkohiitoluoma on 26/11/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import ConversationListView from '../../components/ConversationListView'

import {WithNamespaces, withNamespaces} from 'react-i18next'
import ListenerManager, {ListenerContext, MessageListener} from '../../services/ListenerManager'
import {ROLES} from '../../config/constants'

import {connect} from 'react-redux'
import {navigate} from '../../modules/Location'
import ConversationsModule from '../../modules/Conversations'
import ConversationsModel from '../../models/Conversations'
import User from '../../models/User'

import '../../styles/messages.less'

interface Props extends WithNamespaces {
  authenticatedUser: User
  conversations: ConversationsModel
  getConversations: () => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  conversations: any[]
  index: number
}

class Conversations extends PureComponent<Props, State> implements MessageListener {
  constructor(props: Props) {
    super(props)
    const {conversations} = props
    this.state = {
      conversations: this.summarizeConversations(conversations),
      index: 0
    }
  }

  componentDidMount() {
    this.props.getConversations()
    ListenerManager.addListener(ListenerContext.messageReceived, this)
  }

  componentWillUnmount() {
    ListenerManager.removeListener(ListenerContext.messageReceived, this)
  }

  componentDidUpdate(prevProps) {
    const {conversations} = this.props

    if (prevProps.conversations !== conversations) {
      this.setState({conversations: this.summarizeConversations(conversations)})
    }
  }

  receiveMessage(_msg) {
    const {getConversations} = this.props
    getConversations()
  }

  summarizeConversations = (conversationsModel: ConversationsModel) => {
    const {t, authenticatedUser} = this.props
    const myId = authenticatedUser.id

    const conversations = [
      {
        count: 0,
        title: t('messaging.nurses'),
        urlParam: 'nurses',
        replyRole: ROLES.STUDY_NURSE,
        dedicatedSubjectId: myId
      },
      {
        count: 0,
        title: t('messaging.investigators'),
        urlParam: 'investigators',
        replyRole: ROLES.INVESTIGATOR,
        dedicatedSubjectId: myId
      }
    ]

    if (conversationsModel.list.isEmpty()) {
      return conversations
    }

    return conversations.map(conversation => {
      const conversationModel = conversationsModel.list.find(({replyRole, dedicatedSubjectId}) => {
        return replyRole === conversation.replyRole && dedicatedSubjectId === myId
      })

      if (conversationModel) {
        conversation.count = conversationModel.messages.reduce(
          (memo, {personFromId, consumedTimestamp}) => {
            if (personFromId !== myId && !consumedTimestamp) {
              return memo + 1
            }

            return memo
          },
          0
        )
      }

      return conversation
    })
  }

  handleClick = index =>
    this.props.navigate('/messages/' + this.state.conversations[index].urlParam)

  handleMessageSent = () => this.props.getConversations()

  render() {
    let list = this.state.conversations

    if (!list) {
      return <div>{this.props.t('loading')}</div>
    }

    return (
      <div key='subject-conversation-list-view' className='subject-messages-view'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <ConversationListView list={list} handleClick={this.handleClick}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapActionToProps = {
  getConversations: ConversationsModule.getModels,
  navigate
}

const mapStateToProps = ({authenticatedUser, conversations, users}, _ownProps) => {
  return {
    authenticatedUser,
    conversations,
    users
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Conversations))
