import {routerActions} from 'react-router-redux'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history3/redirect'

export const UserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => !state.authenticatedUser.authenticated,
  authenticatingSelector: (state) => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsNotAuthenticated'
})

export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => state.authenticatedUser.authenticated,
  authenticatingSelector: (state) => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
})

export const UserIsAdministrator = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) =>
    state.authenticatedUser.authenticated && state.authenticatedUser.isPersonnel(),
  authenticatingSelector: (state) => state.authenticatedUser.isLoading,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
})
