import React from 'react'
import {Component, Fragment} from 'react'
import Input from '../../../../components/Input'

import _ from 'lodash'
import {getFieldError} from '../../../../util'
import User from '../../../../models/User'

const logo = require('../../../../images/orion-pharma-logo-white.svg')

interface Props {
  model: User
  onSubmit: (model) => any
  t: (key, params?) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {
  model: User
  showError: boolean
}

export default class ForgotPasswordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      model: props.model,
      showError: !_.isUndefined(props.model.error)
    }
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    const {model: propModel} = this.props
    const {model: stateModel} = this.state

    // Update state if id changes or if user is updated but it's not saving
    // (we want to avoid the jitter of model when updating
    // as the isSaving is set to true while saving)
    if (prevProps.model.id !== propModel.id || prevProps.model !== propModel) {
      let updatedModel = propModel

      if (propModel.error) {
        updatedModel = stateModel.endOfSaving(propModel.error)
      } else if (propModel.isSaving) {
        updatedModel = stateModel.startOfSaving()
      }

      this.setState({model: updatedModel})
    }

    const {model: prevStateModel} = _prevState

    if (prevStateModel.account !== stateModel.account && stateModel.error) {
      this.setState({model: stateModel.endOfSaving(undefined), showError: false})
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const {model} = this.state
    const {onSubmit} = this.props

    this.setState(
      {showError: true},
      () => {
        if (!model.validateForgotPassword()) {
          onSubmit(model)
        }
      }
    )
  }

  onFieldChange = (field) => (value) => {
    const {model} = this.state

    this.setState({
      model: model.set(field, value) as User
    })
  }

  getInputField = (field, autoFocus = false) => {
    const {t} = this.props
    const {model, showError} = this.state
    const inputId = `input-${field}`
    const fieldError = getFieldError(field, model.validateForgotPassword(), model.error)

    return (
      <Input
        key={inputId}
        label={t(`forgotPassword.${field}`)}
        labelClassName={`label-${inputId}`}
        error={showError && fieldError}
        onChange={this.onFieldChange(field)}
        value={model.get(field) || ''}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        autoFocus={autoFocus}
        t={t}
      />
    )
  }

  render() {
    const {t} = this.props
    const {model} = this.state

    return (
      <Fragment>
        <div className='logo'>
          <img src={logo} alt={t('organization')}/>
        </div>
        <form key='forgot-password-form' className='form-forgot-password' onSubmit={this.handleSubmit}>
          {this.getInputField('account', true)}
          <button
            id='forgot-password'
            className='btn btn-lg btn-info btn-block'
            disabled={_.isEmpty(model.account)}
            type='submit'>
            {t('forgotPassword.resetPassword')}
          </button>
        </form>
      </Fragment>
    )
  }
}
