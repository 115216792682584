import React, {PureComponent} from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd'
import PeriodDayView from './PeriodDay'
import {List} from 'immutable'
import PeriodDay from '../../../../models/PeriodDay'

interface Props {
  periodDays: List<PeriodDay>
  language: string
  onOrderChange: (src, dst) => void
  onChange: (question) => any
  onDelete: (question) => any
  t: (key, params?) => any
}

interface State {
}

const draggingItemBackground = '#85bcd3'
const itemBackground = '#feffff'
const draggingListBackground = '#f0f0f0'
const listBackground = 'transparent'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0 0 15px 0',
  background: isDragging ? draggingItemBackground : itemBackground,
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? draggingListBackground : listBackground,
  marginTop: '15px'
})

interface DraggablePeriodDayProps {
  periodDay: PeriodDay
  language: string
  onChange: (periodDay) => any
  onDelete: (periodDay) => any
  t: (key, params?) => any
  index: number
}

interface DraggablePeriodDayState {
}

class DraggablePeriodDay extends PureComponent<DraggablePeriodDayProps, DraggablePeriodDayState> {

  render() {

    const {
      periodDay,
      index,
      onChange,
      onDelete,
      language,
      t
    } = this.props
    const periodDayId = `period-day-${periodDay.order}`
    const draggableContent = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {

      return (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
          <PeriodDayView
            periodDay={periodDay}
            language={language}
            onChange={onChange}
            onDelete={onDelete}
            t={t}/>
        </div>
      )
    }

    return (
      <Draggable
        draggableId={periodDayId}
        index={index}>
        {draggableContent}
      </Draggable>
    )
  }
}

export default class PeriodDays extends PureComponent<Props, State> {

  renderPeriodDay = (periodDay, index) => {

    const {onChange, onDelete, language, t} = this.props

    return (
      <DraggablePeriodDay index={index}
                         key={`draggable-period-day-${periodDay.order}`}
                         periodDay={periodDay}
                         onChange={onChange}
                         onDelete={onDelete}
                         language={language}
                         t={t}/>
    )
  }

  renderPeriodDays = () => {

    const {periodDays} = this.props
    const droppableContent = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {

      return (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
          {periodDays.map((q, index) => this.renderPeriodDay(q, index))}
        </div>
      )
    }

    return (
      <Droppable key='period-day-droppable' droppableId='periodDayDrop'>
        {droppableContent}
      </Droppable>
    )
  }

  onDragEnd = (result) => {

    if (!result.destination) {
      return
    }

    const {onOrderChange, periodDays} = this.props
    const srcIdx = result.source.index
    const dstIdx = result.destination.index
    const srcOrder = periodDays.get(srcIdx).order
    const dstOrder = periodDays.get(dstIdx).order

    onOrderChange(srcOrder, dstOrder)
  }

  render() {

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.renderPeriodDays()}
      </DragDropContext>
    )
  }
}
