import React from 'react'
import {Component} from 'react'
import LoginForm from '../components/LoginForm'

import {connect} from 'react-redux'
import AuthenticatedUser from '../../../modules/AuthenticatedUser'
import User from '../../../models/User'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import {navigate} from '../../../modules/Location'
import {UserIsNotAuthenticated} from '../../../config/policies'

interface Props extends WithNamespaces {
  user: User
  login: (username, password, redirect?) => any
  redirect: string
  navigate: (url: string, silent?: boolean) => any
}

interface State {}

@UserIsNotAuthenticated
class LoginView extends Component<Props, State> {
  _handleLogin = (identity, password) => this.props.login(identity, password, this.props.redirect)

  render() {
    const {user, t, navigate} = this.props

    return (
      <div className='container'>
        <LoginForm
          onSubmit={this._handleLogin}
          isProcessing={user.isLoading}
          error={user.error}
          navigate={navigate}
          t={t}/>
      </div>
    )
  }
}

const mapActionCreators = {
  login: AuthenticatedUser.login,
  navigate
}

const mapStateToProps = (state, ownProps) => ({
  user: state.authenticatedUser,
  redirect: ownProps.location.query.redirect
})

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(LoginView))
