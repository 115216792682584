import {enumToValues} from '../util'

export enum TabId {
  subjects = 'subjects',
  messages = 'messages',
  medicationEvents = 'medicationEvents',
  adverseEvents = 'adverseEvents',
  administrations = 'administrations',
  questionnaires = 'questionnaires',
  questionnaireStatus = 'questionnaireStatus',
  healthTrackings = 'healthTrackings',
  sleepTrackings = 'sleepTrackings',
  intakeEvents = 'intakeEvents',
  offstateEvents = 'offstateEvents',
  wearableStatusHourly = 'wearableStatusHourly',
  dataCollectionHourly = 'dataCollectionHourly',
  garminSleepEvents = 'garminSleepEvents',
  wearableStatusTimeline = 'wearableStatusTimeline',
  dataCollectionTimeline = 'dataCollectionTimeline',
  qosSummary = 'qosSummary',
  active = 'active',
  activeTimeline = 'activeTimeline'
}

export namespace TabId {

  export function isCriteria(tabId) {
    switch (tabId) {
      case TabId.healthTrackings:
      case TabId.sleepTrackings:
        return false
      default:
        return true
    }
  }

  const criteriaTabIds = enumToValues(TabId).filter(tab => TabId.isCriteria(tab))

  export function getCriteriaTabIds() {

    return criteriaTabIds
  }

  const additionalTabIds = enumToValues(TabId).filter(tab => !TabId.isCriteria(tab))

  export function getAdditionalTabIds() {

    return additionalTabIds
  }
}
