import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import {List} from 'immutable'
import EventTranslation from './EventTranslation'

const constraints = {
  personId: {
    presence: {allowEmpty: false}
  },
  name: {
    presence: {allowEmpty: false}
  },
  time: {
    presence: true,
    isBeforeNow: true
  },
  dosage: {
    presence: {allowEmpty: false}
  },
  usage: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['personId', 'name', 'time', 'dosage', 'usage']

const defaultValues = {
  personId: undefined,
  name: undefined,
  time: undefined,
  timeDateOnly: undefined,
  dosage: undefined,
  usage: undefined,
  comment: undefined,
  personnelComment: undefined,
  personnelId: undefined,
  updateTime: undefined,
  ecrfTransferTime: undefined,
  locked: false,
  studySiteId: undefined,
  translations: List<EventTranslation>()
}

class MedicationEvent extends BaseModel(defaultValues, interestedKeys, constraints)<MedicationEvent> {
  personId: number
  name: string
  time: any
  timeDateOnly: any
  dosage: any
  usage: any
  comment: any
  personnelComment: any
  personnelId: number
  updateTime: any
  ecrfTransferTime: any
  locked: boolean
  studySiteId: number
  translations: List<EventTranslation>

  constructor(js?) {
    super(js)

    if (js) {
      return setDateFields(this, ['time', 'ecrfTransferTime'])
        .setListArray(
          [
            {translations: js => new EventTranslation(js)}
          ],
          js
        ) as MedicationEvent
    }
  }

  fromJS(js): MedicationEvent {
    return new MedicationEvent(js)
  }
}

export default MedicationEvent
