import AppLayout from '../layouts/AppLayout'
import AuthenticationRoute from './Authentication'
import AdminRoute from './Admin'
import SubjectRoute from './Subject'
import Error from './Error'

export const createRoutes = (store) => {
  return {
    path: '/',
    childRoutes: [
      AuthenticationRoute,
      {
        component: AppLayout,
        indexRoute: {
          onEnter: (_nextState, replace) => {
            const user = store.getState().authenticatedUser

            if (!user.authenticated) {
              return replace('/login')
            } else if (user.isPersonnel()) {
              return replace('/admin/profile')
            } else {
              return replace(`/home`)
            }
          }
        }
      },
      AdminRoute,
      SubjectRoute,
      {path: 'error/:id', component: Error}
    ]
  }
}

export default createRoutes
