import React from 'react'
import {PureComponent} from 'react'
import TableComponent from '../../../../components/TableComponent'

import {List} from 'immutable'

import Criteria from '../../../../models/Criteria'

interface Props {
  columns: any
  results: List<any>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  showFilter?: boolean
  rowKey?: string
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  onCellClick?: (cellInfo) => any
  t: (key, params?) => any
}

interface State {}

export default class Results extends PureComponent<Props, State> {
  render() {
    const {
      criteria,
      rowKey,
      results,
      showFilter,
      columns,
      onProcessedData,
      onCriteriaChanged,
      onRowClick,
      hasMore,
      loading,
      loadMore,
      onCellClick,
      t
    } = this.props

    return (
      <TableComponent
        key='results'
        ref='results'
        data={results.toJS()}
        showFilter={showFilter}
        rowKey={rowKey || 'id'}
        columns={columns}
        useFixedHeader={true}
        onProcessedData={onProcessedData}
        onCriteriaChanged={onCriteriaChanged}
        filter={criteria ? criteria.filter : undefined}
        initialSortColumn={criteria ? criteria.columnName : undefined}
        initialSortState={criteria ? criteria.sort : undefined}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        hasMore={hasMore}
        loadMore={loadMore}
        loading={loading}
        t={t}
      />
    )
  }
}
