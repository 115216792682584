import React from 'react'
import NavigationLayout from '../../layouts/NavigationLayout'
import AppLayout from '../../layouts/AppLayout/AppLayout'
import Home from './Home'
import AdministrationEvents from './AdministrationEvents'
import AdministrationEvent from './AdministrationEvent'
import MedicationEvent from './MedicationEvent'
import Events from './Events'
import AdverseEvent from './AdverseEvent'
import Conversations from './Conversations'
import Messages from './Messages'
import QuestionnairesRoutes from './Questionnaires'

export default {
  component: AppLayout,
  childRoutes: [
    {
      component: NavigationLayout,
      childRoutes: [
        {path: '/home', component: Home},
        {path: '/administrations', component: AdministrationEvents},
        {path: '/events', component: Events},
        {path: '/messages', component: Conversations}
      ]
    },
    {
      path: '/event/create-charcoal-event',
      component: (props) => <AdministrationEvent type='charcoal' {...props} />
    },
    {
      path: '/event/create-water-event',
      component: (props) => <AdministrationEvent type='water' {...props} />
    },
    {
      path: '/event/edit-charcoal-event/:id',
      component: (props) => <AdministrationEvent type='charcoal' {...props} />
    },
    {
      path: '/event/edit-water-event/:id',
      component: (props) => <AdministrationEvent type='water' {...props} />
    },
    {path: '/event/create-medication-event', component: MedicationEvent},
    {path: '/event/edit-medication-event/:id', component: MedicationEvent},
    {path: '/event/create-adverse-event', component: AdverseEvent},
    {path: '/event/edit-adverse-event/:id', component: AdverseEvent},
    {path: '/messages/:replyRole', component: Messages},
    QuestionnairesRoutes
  ]
}
