import {Record} from 'immutable'

const defaultValues = {
  message: undefined,
  fieldErrors: undefined,
  object: undefined
}

export default class ErrorModel extends Record(defaultValues) {
  message: any
  fieldErrors: any
  object: any

  static fromJS(js): ErrorModel {
    return new ErrorModel(js)
  }
}
