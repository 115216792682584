import BaseModel from './BaseModel'
import {List, Map} from 'immutable'

const defaults = {
  options: List
}

export default class AdverseEventOptions extends BaseModel(defaults)<AdverseEventOptions> {
  options: Map<string, any>

  constructor(js?) {
    super(js)

    let options = Map<string, List<any>>()

    if (js) {
      Object.keys(js).forEach((key) => {
        options = options.set(key, List(js[key])) as Map<string, List<any>>
      })
    }

    return this.set('options', options) as AdverseEventOptions
  }

  fromJS(js: any = {}): any {
    return new AdverseEventOptions(js)
  }

  getOptions() {
    return this.options.toArray()
  }

  getSelectOptions(field) {
    const options = this.options.get(field)
    return options ? options.toJS() : []
  }
}
