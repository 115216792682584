import React from 'react'
import {Component} from 'react'
import {HelpBlock} from 'react-bootstrap'

import QuestionAnswerTranslation from '../../models/QuestionAnswerTranslation'
import QuestionComponent from '../../models/QuestionComponent'
import QuestionAnswer from '../../models/QuestionAnswer'

import _ from 'lodash'
import {fromJS} from 'immutable'

export interface BaseQuestionComponentProps {
  isPersonnel: boolean
  enableTranslationFeature: boolean
  translation: QuestionAnswerTranslation
  component: QuestionComponent
  field: string
  fieldError: any
  disabled: boolean
  answer: QuestionAnswer
  onChange: (answer) => any
  onTranslationChange: (answer) => any
  language: string
  t: (key, args?) => any
}

export interface BaseQuestionComponentState {
}

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''))

abstract class BaseQuestionComponent<P extends BaseQuestionComponentProps, S extends BaseQuestionComponentState>
  extends Component<P, S> {

  abstract getContent()

  compactRecord = (record) => fromJS(compactObject(record.toJS()))

  getLocalization() {

    const {component, language} = this.props

    return component.getLanguageLocalization(language)
  }

  getAnswerValue() {

    const {answer, field} = this.props

    return answer.getFieldValue(field)
  }

  getError(error) {

    if (error) {
      return <HelpBlock>{error}</HelpBlock>
    }
  }

  render() {
    const {isPersonnel, enableTranslationFeature} = this.props

    return (
      <div className='component'>
        <div className={!isPersonnel || !enableTranslationFeature ? 'col-xs-12' : 'col-xs-6'}>
          {this.getContent()}
        </div>
      </div>
    )
  }
}

export default BaseQuestionComponent
