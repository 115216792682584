import React, {Fragment, PureComponent} from 'react'
import Questionnaires from '../../../modules/Questionnaires'
import {withNamespaces} from 'react-i18next'
import {connect} from 'react-redux'
import {List} from 'immutable'
import SubPageView from '../../../components/SubPageView'
import QuestionnairesModel from '../../../models/Questionnaires'
import QuestionnaireAnswerComponent from '../../../components/QuestionnaireAnswer'
import QuestionnaireAnswer from '../../../models/QuestionnaireAnswer'
import User from '../../../models/User'
import AccessRights from '../../../models/AccessRights'
import QuestionnaireAnswerModule from '../../../modules/QuestionnaireAnswer'
import {getAccessRights} from '../../../modules/AccessRights'
import {navigate} from '../../../modules/Location'
import ReactToPrint from 'react-to-print'
import Periods from '../../../models/Periods'
import PeriodsModule from '../../../modules/Periods'
import {IndexMapEntry} from './Questionnaires'
import {parseIntOrNull} from '../../../util'
import qs from 'qs'

interface Props {
  periods: Periods
  location: Location
  tab: string
  page: number
  selected: string
  questionnaires: QuestionnairesModel
  authenticatedUser: User
  accessRights: AccessRights
  t: (key, params?) => any
  getQuestionnaires: (queryParams?, reset?) => any
  navigate: (url: string, silent?: boolean) => any
  createPreview: (questionnaire) => any
  getAccessRights: (objectName, object) => any
  getPeriods: (queryParams?, reset?) => any
}

interface State {
  questionnaireAnswers: List<QuestionnaireAnswer>
}

const printPageStyle = 'size: A4;  margin: 2mm;'

class PreviewQuestionnaires extends PureComponent<Props, State> {

  private printRef

  constructor(props: Props) {
    super(props)

    this.state = {
      questionnaireAnswers: List<QuestionnaireAnswer>()
    }
  }

  componentDidMount() {

    const {questionnaires, getQuestionnaires, periods, getPeriods} = this.props

    if (periods.list.isEmpty()) {
      getPeriods()
    }

    if (questionnaires.list.isEmpty) {
      getQuestionnaires(undefined, true)
    } else {
      this.generateQuestionnaireAnswers()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    const {questionnaires, periods, selected} = this.props
    const isLoading = questionnaires.isLoading || periods.isLoading

    if (!isLoading && (
      questionnaires !== prevProps.questionnaires || periods !== prevProps.periods || prevProps.selected !== selected
    )) {
      this.generateQuestionnaireAnswers()
    }
  }

  setPrintAreaRef = (node) => this.printRef = node

  getPrintAreaRef = () => this.printRef

  generateQuestionnaireAnswers = () => {
    const {questionnaires, selected} = this.props

    const idEntries = List<IndexMapEntry>(
      selected
        ? selected.split(',').map(value => {
          const [questionnaireId, periodDayId] = value.split('-').map(parseIntOrNull)
          return {questionnaireId, periodDayId}
        })
        : []
    )

    const questionnaireAnswers = idEntries.map(({questionnaireId, periodDayId}) => {
      const questionnaire = questionnaires.getModelById(questionnaireId)

      if (questionnaire) {
        return new QuestionnaireAnswer({
            questionnaire,
            questionnaireId,
            periodDayId
          })
      }

      return undefined
    })
      .filter(value => !!value) as List<QuestionnaireAnswer>

    this.setState({questionnaireAnswers})
  }

  onPreviewSave = (questionnaireAnswer: QuestionnaireAnswer) => {
    const {questionnaireId, periodDayId} = questionnaireAnswer
    const {questionnaireAnswers} = this.state
    const existingAnswer = questionnaireAnswers.find(answer => {
      return answer.questionnaireId === questionnaireId && answer.periodDayId === periodDayId
    })
    const index = questionnaireAnswers.indexOf(existingAnswer)

    this.setState({questionnaireAnswers: questionnaireAnswers.set(index, questionnaireAnswer)})
  }

  renderQuestionnaire = (questionnaireAnswer: QuestionnaireAnswer) => {

    const {
      authenticatedUser,
      location,
      navigate,
      accessRights,
      t,
      getAccessRights,
      createPreview,
      periods
    } = this.props

    const {periodDayId, questionnaire} = questionnaireAnswer
    const pages = questionnaire.getPages()
    const period = periods.getPeriodByDayId(periodDayId)
    const title = period ? period.getDisplayTitle(periodDayId) : ''

    const questionnaireContent = pages.map((page) => (
        <QuestionnaireAnswerComponent key={`preview-questionnaire-${questionnaire.id}-${periodDayId}-page-${page}`}
                                      wrapperClass='questionnaire-preview preview-list-item'
                                      isPreview={true}
                                      onlyContent={true}
                                      forPrinting={true}
                                      model={questionnaireAnswer}
                                      modelId={questionnaireAnswer.id}
                                      modelName='questionnaire-answer'
                                      questionnaire={questionnaire}
                                      title={title}
                                      location={location}
                                      page={page}
                                      navigate={navigate}
                                      accessRights={accessRights}
                                      getAccessRights={getAccessRights}
                                      authenticatedUser={authenticatedUser}
                                      getModel={() => createPreview(questionnaire)}
                                      editUrl='/admin/questionnaires'
                                      backHref='/admin/questionnaires'
                                      forceBackHref={true}
                                      resetModel={() => createPreview(questionnaire)}
                                      saveModel={this.onPreviewSave}
                                      t={t}/>
      )
    )

    return (
      <Fragment key={`preview-questionnaire-${questionnaire.id}-${periodDayId}`}>
        {questionnaireContent}
        <div key='questionnaire-tail' className='questionnaire-tail'>
          <span>{t('questionnaire.ending')}</span>
        </div>
      </Fragment>
    )
  }

  renderPrintBtn = () => {

    const {t} = this.props

    return (
      <button className='print-btn'>
        <i className='fa fa-print print'/> {t('questionnaire.print')}
      </button>
    )
  }

  render() {

    const {questionnaireAnswers} = this.state
    const queryParams = this.props.selected ? `?${qs.stringify(this.props.selected)}` : ''
    const navItems = [{label: 'back', href: `/admin/questionnaires${queryParams}`, className: 'back-navigation'}]

    return (
      <SubPageView navItems={navItems} t={this.props.t}>
        <div className='preview-heading'>
          <ReactToPrint trigger={this.renderPrintBtn}
                        pageStyle={printPageStyle}
                        content={this.getPrintAreaRef}/>
        </div>
        <div ref={this.setPrintAreaRef}
             className='print-area'>
          {questionnaireAnswers.map((qa) => this.renderQuestionnaire(qa)).toArray()}
        </div>
      </SubPageView>
    )
  }
}

const mapActionToProps = {
  getQuestionnaires: Questionnaires.getModels,
  getPeriods: PeriodsModule.getModels,
  createPreview: QuestionnaireAnswerModule.resetQuestionnaireAnswer,
  getAccessRights,
  navigate
}

const mapStateToProps = ({accessRights, questionnaires, authenticatedUser, periods}, ownProps) => {

  const {location: {query: {page, tab, selected}}} = ownProps

  const currentPage = page ? parseInt(page, 10) : 1

  return {
    periods,
    accessRights,
    authenticatedUser,
    questionnaires,
    selected,
    page: currentPage,
    tab
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(PreviewQuestionnaires)
)
