import {List} from 'immutable'
import BaseModel from './BaseModel'

const constraints = {
  name: {
    presence: {allowEmpty: false}
  },
  studySiteId: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = ['name', 'userIds', 'studySiteId']

const defaultValues = {
  name: undefined,
  userIds: List(),
  studySiteId: undefined
}

export default class StudyGroup extends BaseModel(defaultValues, interestedKeys, constraints)<StudyGroup> {
  name: string
  userIds: List<number>
  studySiteId: number

  constructor(js?) {
    super(js)

    return this.setListArray(['userIds'], js) as StudyGroup
  }

  fromJS(js): StudyGroup {
    return new StudyGroup(js)
  }

  setStudySiteId(studySiteId) {
    return this.set('studySiteId', studySiteId)
  }
}
