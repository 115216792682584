/**
 * Created by Mauritz Untamala on 22/03/16.
 */
import React from 'react'
import {Component} from 'react'

import classnames from 'classnames'
import {bind} from '../util'

import {DownloadStatus} from '../models/DownloadStatus'

export interface BaseProps {
  pollDownload: (id) => any
  downloadResource: (download) => any
  download: any
  t: (key, params?) => any
}

export interface BaseState {
  download: any
  pollerId: any
}

class BaseDownload<P extends BaseProps, S extends BaseState> extends Component<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      download: undefined,
      pollerId: undefined
    } as S
    bind(this, this.getDownloadButton)
  }

  componentWillUnmount() {
    const {pollerId} = this.state
    if (pollerId) {
      clearInterval(pollerId)
    }
  }

  componentDidUpdate(prevProps: P, _prevState: S) {

    const prevDownload = prevProps.download
    const {download} = this.props

    if (prevDownload.id !== download.id) {

      const pollerId = setInterval(this.pollDownload(download.id), 1000)

      this.setState({pollerId})
    }
  }

  clearPollDownload = (pollerId) => {
    clearInterval(pollerId)
    this.setState({pollerId: null})
  }

  pollDownload = (id) => () => {
    const {download, pollDownload, downloadResource} = this.props
    const {pollerId} = this.state

    if (download.id === id && download.status !== DownloadStatus.ready) {

      pollDownload(id)

    } else if (download.id !== id) {

      this.clearPollDownload(pollerId)

    } else if (download.status === DownloadStatus.ready) {

      this.clearPollDownload(pollerId)
      downloadResource(download)
    }
  }

  getDownloadButton(onDownload, localeKey, type = null) {
    const {pollerId} = this.state
    const downloading = !!pollerId
    const classes = classnames('download btn btn-default', {downloading: downloading}, type)

    return (
      <button className={classes} disabled={downloading} onClick={onDownload}>
        <span>{this.props.t(localeKey)}</span>
      </button>
    )
  }
}

export default BaseDownload
