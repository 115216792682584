import React from 'react'
import {Fragment} from 'react'
import BaseEventComponent, {BaseEventProps, BaseEventState} from './BaseEventComponent'
import SaveButton from './SaveButton'
import SelectFormInput from './SelectFormInput'

import _ from 'lodash'
import classNames from 'classnames'
import {withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import {getAccessRights} from '../modules/AccessRights'
import AdverseEventOptionsModule from '../modules/AdverseEventOptions'
import AdverseEventModule from '../modules/AdverseEvent'
import {navigate} from '../modules/Location'
import AdverseEvent from '../models/AdverseEvent'
import AdverseEventOptions from '../models/AdverseEventOptions'

import '../styles/events.less'

const subjectFields = [
  'description',
  'dateRange'
]

const personnelRemarkFields = [
  'severity',
  'causality',
  'outcome',
  'ecrfTransferTime'
]

const investigatorFields = subjectFields.concat(
  personnelRemarkFields,
  ['personnelComment']
)

interface Props extends BaseEventProps<AdverseEvent> {
  adverseEventOptions: AdverseEventOptions
  getAdverseEventSelectOptions: () => any
}

interface State extends BaseEventState<AdverseEvent> {
}

class SubjectAdverseEventView extends BaseEventComponent<AdverseEvent, Props, State> {
  constructor(props: Props) {
    super(props, subjectFields, investigatorFields)
  }

  componentDidMount() {
    super.componentDidMount()
    this.props.getAdverseEventSelectOptions()
  }

  createSelectInput = (field, writeAccess, options, multi, placeholder?) => {
    const formGroupClassNames = {
      'col-xs-12': true,
      'col-sm-6': true,
      'col-md-3': true,
      'form-group': false
    }

    return (
      <SelectFormInput
        key={'select-' + field}
        formGroupClassName={formGroupClassNames}
        model={this.state.model}
        modelName='adverse-event'
        labelKey={field}
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={this.updateModel}
        labelClassName='control-label event-form-input-label'
        fieldClassName={field}
        t={this.props.t}
      />
    )
  }

  investigatorRemarks = () => {
    const {t} = this.props
    const {model} = this.state

    const getInputs = () => {
      return personnelRemarkFields.map((field) => {
        const writeAccess = this.getWriteAccess(field)

        switch (field) {
          case 'severity':
          case 'causality':
          case 'outcome':
            return this.createSelectInput(field, writeAccess, this.getFieldOptions(field), false)
          case 'ecrfTransferTime':
            const onEcrfTimeChange = change => this.updateModel(model.set('ecrfTransferTime', change.time))
            return this.createTimePicker(
              'adverseEvent',
              field,
              writeAccess,
              onEcrfTimeChange,
              {
                showDateOnlyToggle: false,
                showNow: true,
                showReset: true
              },
              t('ecrfTransferTime'),
              classNames(['col-xs-12', 'col-sm-6', 'col-md-3', 'adverse-event-ecrf-transfer-time'])
            )
          default:
            return null
        }
      })
    }

    return (
      <div key='investigator-remarks' className='row investigator-remarks'>
        {getInputs()}
      </div>
    )
  }

  dateRange = () => {

    const {model} = this.state
    const context = 'adverseEvent'
    const startedField = 'started'
    const endedField = 'ended'
    const writeAccessStarted = this.getWriteAccess(startedField)
    const writeAccessEnded = this.getWriteAccess(endedField)

    if (writeAccessStarted === null && writeAccessEnded === null) {
      return null
    }

    const started = writeAccessStarted !== null && this.createTimePicker(
      context,
      startedField,
      writeAccessEnded,
      this.onTimeFieldChange(startedField, 'startedDateOnly'),
      {
        dateOnly: model.startedDateOnly,
        showDateOnlyToggle: false
      }
    )

    const ended = writeAccessEnded !== null && this.createTimePicker(
      context,
      endedField,
      writeAccessEnded,
      this.onTimeFieldChange(endedField, 'endedDateOnly'),
      {
        dateOnly: model.endedDateOnly,
        showDateOnlyToggle: false
      }
    )

    return (
      <Fragment>
        {started}
        {ended}
      </Fragment>
    )
  }

  getFieldOptions = field => {
    return _.map(this.props.adverseEventOptions.getSelectOptions(field), option => {
      return {
        label: this.props.t('adverseEvent.' + field + '.' + option),
        value: option
      }
    })
  }

  getFields = () => {
    if (this.props.authenticatedUser.isSubject()) {
      return subjectFields
    } else {
      return subjectFields.concat(['investigatorRemarks', 'personnelComment'])
    }
  }

  getInputFields = () => {

    const {authenticatedUser} = this.props
    const {model} = this.state

    const disabled = model.locked
    const isPersonnel = authenticatedUser.isPersonnel()

    const textAreaAdditionalProps = {
      fieldInputClassName: !isPersonnel ? 'col-xs-12' : 'col-xs-6',
      translationInputClassName: isPersonnel && 'col-xs-6',
      labelClassName: 'event-form-input-label',
      translationLabelClassName: 'event-form-input-label',
      rows: 2
    }

    return this.getFields().map(field => {
      switch (field) {
        case 'description':
          return this.createInputFieldWithTranslation(
            'adverseEvent',
            'description',
            this.getWriteAccess(field),
            'textarea',
            textAreaAdditionalProps,
            disabled
          )

        case 'dateRange':
          return this.dateRange()

        case 'investigatorRemarks':
          return this.investigatorRemarks()

        case 'personnelComment':
          return this.createInputField(
            'adverseEvent',
            'personnelComment',
            this.getWriteAccess(field),
            'textarea',
            _.merge({}, textAreaAdditionalProps, {groupClassName: 'col-xs-12'})
          )

        default:
          return null
      }
    })
  }

  getContent() {
    const {model} = this.state
    const {modelName, authenticatedUser, t} = this.props

    const isPersonnel = authenticatedUser.isPersonnel()
    const disabled = !isPersonnel && model.locked
    const className = classNames({
      'container': isPersonnel,
      'event-form': true
    })

    return (
      <div className={className}>
        {this.getHeading()}
        {this.getInputFields()}
        <div className='row'>
          <div className='col-xs-12'>
            <SaveButton
              enabled={!disabled}
              model={model}
              accessRightsKey={modelName}
              save={this.save}
              onModelSaved={this.onModelSaved}
              t={t}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (
  {authenticatedUser, adverseEvent, accessRights, adverseEventOptions},
  _ownProps
) => {
  return {
    authenticatedUser,
    model: adverseEvent,
    accessRights,
    adverseEventOptions
  }
}

const mapActionToProps = {
  getModel: AdverseEventModule.getModel,
  resetModel: AdverseEventModule.resetModelWithUserId,
  saveModel: AdverseEventModule.saveModel,
  getAccessRights,
  getAdverseEventSelectOptions: AdverseEventOptionsModule.getOptions,
  navigate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelId: ownProps.eventId,
    modelName: 'adverseEvent',
    backHref: ownProps.backHref || '/events',
    editUrl: `/event/edit-adverse-event`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(stateProps.authenticatedUser.id)
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(SubjectAdverseEventView))
