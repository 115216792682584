import {List} from 'immutable'
import _ from 'lodash'
import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import Question from './Question'
import QuestionAnswer from './QuestionAnswer'
import {Type} from './QuestionComponent'
import Questionnaire from './Questionnaire'
import QuestionAnswerTranslation from './QuestionAnswerTranslation'
import {pageEqual, samePageAndOrder} from '../util'
import I18n from '../services/I18n'

const constraints = {
  questionnaireId: {
    presence: {allowEmpty: false}
  },
  personId: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = null

const defaultValues = {
  questionnaireId: undefined,
  questionnaire: undefined,
  personId: undefined,
  answers: List<QuestionAnswer>(),
  translations: List<QuestionAnswerTranslation>(),
  ecrfTransferTime: undefined,
  personnelComment: undefined,
  locked: false,
  createTime: undefined,
  updateTime: undefined,
  siteStudyName: undefined,
  person: undefined,
  periodDayId: undefined
}

class QuestionnaireAnswer extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionnaireAnswer> {
  questionnaireId: number
  questionnaire: Questionnaire
  personId: number
  answers: List<QuestionAnswer>
  translations: List<QuestionAnswerTranslation>
  ecrfTransferTime: any
  personnelComment: any
  locked: boolean
  createTime: any
  updateTime: any
  siteStudyName: string
  person: string
  periodDayId: number

  constructor(js?: any) {
    super(js)

    if (js) {

      const self = this.setQuestionnaire(new Questionnaire(js.questionnaire))

      return setDateFields(self, ['createTime', 'updateTime', 'ecrfTransferTime']).setListArray(
        [
          {answers: js => new QuestionAnswer(js)},
          {translations: js => new QuestionAnswerTranslation(js)}
        ],
        js
      ) as QuestionnaireAnswer
    }
  }

  fromJS(js: any): QuestionnaireAnswer {
    return new QuestionnaireAnswer(js)
  }

  setQuestionnaire(questionnaire: Questionnaire) {
    return this.set('questionnaire', questionnaire) as QuestionnaireAnswer
  }

  getQuestionAnswer(question: Question) {
    return this.answers.find(a => samePageAndOrder(a, question))
  }

  addAnswer(model: QuestionAnswer) {
    let list = this.answers
    const current = list.find(m => model.identityEquals(m))

    if (current) {
      const index = list.indexOf(current)

      list = list.set(index, model.setIdentityFrom(current))
    } else {
      list = list.push(model)
    }

    return this.set('answers', list)
  }

  getQuestionAnswerTranslations(answer: QuestionAnswer): List<QuestionAnswerTranslation> {

    return this.translations
      .filter(t => samePageAndOrder(t, answer)) as List<QuestionAnswerTranslation>
  }

  validatePage(page: number) {

    if (!this.questionnaire) {
      return {fieldErrors: {questionnaire: I18n.t('validate.presence')}}
    }

    const lastPage = this.questionnaire.getLastPage()
    const onLastPage = lastPage === page

    if (onLastPage) {
      return this.validate()
    }

    const minimum = this.questionnaire.questions
      .filter(q => this.isNotInstructionOrOptional(q) && pageEqual(q, {page})).size

    const questionnaireAnswerResult = this._validate(
      _.merge({}, constraints, {
        answers: {
          presence: {
            allowEmpty: true
          },
          length: {
            minimum
          }
        }
      })
    )

    if (questionnaireAnswerResult) {
      return questionnaireAnswerResult
    }

    return this.validateAnswers(this.questionnaire.getQuestions().filter(q => pageEqual(q, {page})))
  }

  validatePersonnelFields() {

    return undefined // No validation decided yet. Another story if needed.
  }

  /**
   * @override
   */
  getConstraints() {

    const constraints = super.getConstraints()
    const minimum = this.questionnaire.questions.filter(this.isNotInstructionOrOptional).size

    return _.merge({}, constraints, {
      answers: {
        presence: {
          allowEmpty: true
        },
        length: {
          minimum
        }
      }
    })
  }

  validate() {

    const result = super.validate()

    if (result) {
      return result
    }

    return this.validateAnswers(this.questionnaire.getQuestions())
  }

  private isNotInstructionOrOptional(question: Question) {
    return !question.components.every(c => c.getType() === Type.instruction)
      && question.components.some(c => c.isMandatory())
  }

  private validateAnswers(questions) {

    const results = questions
      .map(question => {

        const answer = this.getQuestionAnswer(question)

        if (!answer) {

          if (question.hasMandatoryFields()) {

            return {answers: 'validate.presence'}
          }

          return undefined
        }

        return answer.validateWithQuestion(question, this.answers)
      })
      .filter(result => !!result)

    if (results.isEmpty()) {
      return undefined
    }

    return results.toArray()
  }
}

export default QuestionnaireAnswer
