/**
 * Created by Mauritz Untamala on 07/03/2017.
 */
import React from 'react'
import {Component} from 'react'
import {NumberPicker} from 'react-widgets'

import _ from 'lodash'
import {round} from '../util'

interface Props {
  value: number
  min: number
  max: number
  disabled: boolean
  format: string
  decimalDigits: number
  parse: (str) => number
  onChange: (value) => any
}

interface State {}

export default class NumberPickerComponent extends Component<Props, State> {
  onChange = (value, props) => {
    const getNewValue = () => {
      if (value === null) {
        return value
      }

      const strValue = value.toString()
      const currentDecimalDigits = strValue.indexOf('.') !== -1 ? strValue.split('.')[1].length : 0
      const {decimalDigits} = this.props

      if (currentDecimalDigits > decimalDigits) {
        return props.lastValue
      } else {
        return round(value, decimalDigits)
      }
    }

    this.props.onChange(getNewValue())
  }

  getProperties = () => {
    const {value, decimalDigits} = this.props

    if (!decimalDigits) {
      return this.props
    }

    return _.omit(_.merge({}, this.props, {value, onChange: this.onChange}), 'decimalDigits')
  }

  render() {
    const adjustedProperties = this.getProperties()

    return <NumberPicker {...adjustedProperties} />
  }
}
