import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import Translation from './Translation'

const constraints = {
  order: {
    presence: {allowEmpty: false}
  },
  page: {
    presence: {allowEmpty: false}
  },
  field: {
    presence: {allowEmpty: false}
  },
  value: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = ['order', 'page', 'field']

const defaultValues = {
  order: undefined,
  page: undefined,
  field: undefined,
  value: undefined,
  valueHash: undefined,
  answerHash: undefined,
  updateTime: undefined
}

export default class QuestionAnswerTranslation
  extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionAnswerTranslation>
  implements Translation {
  order: number
  page: number
  field: string
  value: string
  valueHash: number
  answerHash: number
  updateTime: any

  fromJS(js: any): QuestionAnswerTranslation {

    return setDateFields(new QuestionAnswerTranslation(js), ['updateTime'])
  }

  getContentHash() {
    return this.answerHash
  }

  getTranslationHash() {
    return this.valueHash
  }

  getTranslation() {
    return this.value
  }

  setTranslation(newTranslation: string) {
    return this
      .set('value', newTranslation)
      .set('valueHash', undefined)
  }
}
