import React, {Component} from 'react'
import Input from '../../../components/Input'
import SelectFormInput from '../../../components/SelectFormInput'

import classNames from 'classnames'
import {generateSelectOptions, getFieldError} from '../../../util'

import ActivityTracker from '../../../models/ActivityTracker'
import Users from '../../../models/Users'
import AccessRights from '../../../models/AccessRights'

interface Props {
  accessRights: AccessRights
  tracker: ActivityTracker
  users: Users
  selectedSiteStudyId: number
  onChange: (model) => any
  t: (key, params?) => any
}

interface State {
}

export default class ActivityTrackerDetails extends Component<Props, State> {

  onFieldChangeCallback = field => value => {

    const {onChange, tracker} = this.props

    onChange(tracker.set(field, value))
  }

  createSelectInput = (field, writeAccess, options, multi?, placeholder?) => {

    const {t, tracker, onChange} = this.props

    return (
      <SelectFormInput
        key={'select-' + field}
        model={tracker}
        modelName='tracker'
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange}
        labelClassName='col-xs-5 col-sm-4'
        fieldClassName='col-xs-7 col-sm-8'
        t={t}
      />
    )
  }

  getUserLabel = user => user.visibleName()

  getSubjectOptions = () => {
    const users = this.props.users.list.filter(u => {
      return (
        u.siteStudyIds.includes(this.props.selectedSiteStudyId) ||
        u.get('id') === this.props.tracker.userId
      )
    })

    return generateSelectOptions(users, this.getUserLabel, 'id')
  }

  getInputField = (field, writeAccess) => {

    const {tracker, t} = this.props
    const fieldError = getFieldError(
      field,
      tracker.validate(),
      tracker.error
    )
    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t('trackerView.' + field)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={tracker.get(field)}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    )
  }

  render() {
    const inputs = [
      'userId',
      'deviceId',
      'externalUserId',
      'accessToken',
      'authenticationToken',
      'expiration'
    ].map(field => {
      const writeAccess = this.props.accessRights.hasWriteAccess(
        'tracker',
        this.props.tracker,
        field
      )

      if (!writeAccess) {
        let readAccess = this.props.accessRights.hasReadAccess(
          'tracker',
          this.props.tracker,
          field
        )

        if (!readAccess) {
          return null
        }
      }

      switch (field) {
        case 'userId':
          return this.createSelectInput(field, false, this.getSubjectOptions(), false)
        default:
          return this.getInputField(field, writeAccess)
      }
    })

    return <form className='form-horizontal'>{inputs}</form>
  }
}
