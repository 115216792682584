import {combineReducers} from 'redux-immutable'
import accessRights from '../modules/AccessRights'
import authenticatedUser from '../modules/AuthenticatedUser'
import app from '../modules/App'
import activityTracker from '../modules/ActivityTracker'
import activityTrackers from '../modules/ActivityTrackers'
import administrationEvent from '../modules/AdministrationEvent'
import administrationEvents from '../modules/AdministrationEvents'
import adverseEvent from '../modules/AdverseEvent'
import adverseEvents from '../modules/AdverseEvents'
import adverseEventOptions from '../modules/AdverseEventOptions'
import audit from '../modules/Audit'
import audits from '../modules/Audits'
import conversations from '../modules/Conversations'
import download from '../modules/Download'
import medicationEvent from '../modules/MedicationEvent'
import medicationEvents from '../modules/MedicationEvents'
import message from '../modules/Message'
import messages from '../modules/Messages'
import pagination from '../modules/Pagination'
import administrationPeriod from '../modules/AdministrationPeriod'
import reminder from '../modules/Reminder'
import reminders from '../modules/Reminders'
import reports from '../modules/Reports'
import routing from '../modules/Routing'
import site from '../modules/Site'
import sites from '../modules/Sites'
import siteStudy from '../modules/SiteStudy'
import siteStudies from '../modules/SiteStudies'
import smss from '../modules/Smss'
import study from '../modules/Study'
import studies from '../modules/Studies'
import period from '../modules/Period'
import periods from '../modules/Periods'
import studyGroup from '../modules/StudyGroup'
import studyGroups from '../modules/StudyGroups'
import user from '../modules/User'
import users from '../modules/Users'
import questionnaire from '../modules/Questionnaire'
import questionnaires from '../modules/Questionnaires'
import questionnaireAnswer from '../modules/QuestionnaireAnswer'
import questionnaireAnswers from '../modules/QuestionnaireAnswers'
import localization from '../modules/Localization'
import localizations from '../modules/Localizations'
import location from '../modules/Location'

export const makeRootReducer = (asyncReducers?: any) => {
  return combineReducers({
    accessRights,
    activityTracker: activityTracker.getReducer(),
    activityTrackers: activityTrackers.getReducer(),
    administrationEvent: administrationEvent.getReducer(),
    administrationEvents: administrationEvents.getReducer(),
    adverseEvent: adverseEvent.getReducer(),
    adverseEvents: adverseEvents.getReducer(),
    adverseEventOptions: adverseEventOptions.getReducer(),
    authenticatedUser: authenticatedUser.getReducer(),
    app,
    auditTrail: audit.getReducer(),
    auditTrails: audits.getReducer(),
    conversations: conversations.getReducer(),
    download,
    location,
    medicationEvent: medicationEvent.getReducer(),
    medicationEvents: medicationEvents.getReducer(),
    message: message.getReducer(),
    messages: messages.getReducer(),
    pagination,
    administrationPeriod: administrationPeriod.getReducer(),
    reminder: reminder.getReducer(),
    reminders: reminders.getReducer(),
    reports: reports.getReducer(),
    routing,
    site: site.getReducer(),
    sites: sites.getReducer(),
    siteStudy: siteStudy.getReducer(),
    siteStudies: siteStudies.getReducer(),
    smss: smss.getReducer(),
    study: study.getReducer(),
    studies: studies.getReducer(),
    period: period.getReducer(),
    periods: periods.getReducer(),
    studyGroup: studyGroup.getReducer(),
    studyGroups: studyGroups.getReducer(),
    user: user.getReducer(),
    users: users.getReducer(),
    questionnaire: questionnaire.getReducer(),
    questionnaires: questionnaires.getReducer(),
    questionnaireAnswer: questionnaireAnswer.getReducer(),
    questionnaireAnswers: questionnaireAnswers.getReducer(),
    localization: localization.getReducer(),
    localizations: localizations.getReducer(),
    ...asyncReducers
  })
}

export const injectReducer = (store: any, {key, reducer}: any) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
