import moment, {DurationInputArg2} from 'moment'
import Config from '../config'
import _ from 'lodash'
import uuid from 'uuid'
import {Type} from '../models/QuestionComponent'
import OrderedModel from '../models/OrderedModel'
import {DataStatus} from '../models/DataStatus'
import Criteria from '../models/Criteria'
import {List} from 'immutable'
import WearableStatusHourly from '../models/WearableStatusHourly'
import DataColectionHourly from '../routes/Admin/Reports/components/DataCollectionHourly'
import momentRange from './momentRange'
import {DATE_DEFAULT_TIMEZONE} from '../config/constants'

export function generateUUID() {
  return uuid.v4()
}

export function formatDate(date, dateOnly = false) {
  if (dateOnly) {
    return moment(date).format('D.M.YYYY')
  } else {
    return moment(date).format('D.M.YYYY HH:mm')
  }
}

export function bind(obj, ...methods) {
  methods.forEach(method => {
    if (method.name !== '' && !!obj[method.name]) {
      obj[method.name] = obj[method.name].bind(obj)
    } else {
      const name: any = Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).find(
        propertyName => obj[propertyName] === method
      )
      if (obj[name]) {
        obj[name] = obj[name].bind(obj)
      }
    }
  })
}

export const logDebug = (...parts) => {
  if (Config.env.NODE_ENV === 'development') {
    console.log(parts)
  }
}

export function getFieldError(field, validationErrors, modelError, value?) {
  const fieldErrorsV2 = modelError && modelError.fieldErrorsV2
  const fieldErrorV2 = fieldErrorsV2
    ? fieldErrorsV2.find(fe => fe.field === field && (value ? fe.value === value : true))
    : undefined

  if (fieldErrorV2) {
    return fieldErrorV2
  }

  const getFieldError = () => {
    const fieldErrors = modelError && modelError.fieldErrors
    const object = modelError && modelError.object

    if (
      fieldErrors &&
      fieldErrors[field] &&
      (!value || (value && object && object[field] === value))
    ) {
      return modelError.fieldErrors[field]
    } else if (validationErrors && validationErrors[field]) {
      return validationErrors[field]
    }
  }

  const fieldError = getFieldError()

  return _.isArray(fieldError) ? fieldError.join(' ') : fieldError
}

export function toIntArray(array) {
  if (_.isEmpty(array)) {
    return []
  }

  if (!_.isArray(array)) {
    array = [array]
  }

  return array.map(value => parseInt(value, 10))
}

export const parseIntOrNull = (value, nullValue = null) => value ? parseInt(value, 10) : nullValue

export function stringToIntArray(values) {
  if (_.isEmpty(values)) {
    return []
  }

  if (_.isArray(values)) {
    return toIntArray(values)
  } else {
    values = values.split(',')

    return toIntArray(values)
  }
}

export function stringToArray(values) {
  if (_.isEmpty(values)) {
    return []
  }

  if (_.isArray(values)) {
    return values
  } else {
    return values.split(',')
  }
}

export function getStringValue(value) {
  return value || ''
}

export const hourRegex = /^(\d{0,2})(?:(\.)([05])?)?$/
export const phoneRegex = '\\+?[1-9][\\d- ]{1,14}'
export const phoneOrEmailRegex = /^(\+?[1-9][\d- ]{1,14}|[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,})$/
export const numberRegex = /^\d+$/
export const subjectNumberRegex = /^[1-9]\d{3}$/

export function isNull(variable) {
  return typeof variable === 'undefined' || variable === null
}

const timeFields = ['hour', 'minute', 'second', 'millisecond']

export function setTimeNow(date) {
  const now = moment()
  let time = moment(date)

  _.each(timeFields, function(field: any) {
    time = time.set(field, now.get(field))
  })

  return time
}

export function isEqual(interestedKeys, object, otherObject) {
  return _.isMatchWith(object, otherObject, function(value, other, key) {
    if (_.includes(interestedKeys, key)) {
      if (moment.isMoment(value)) {
        return value.isSame(other)
      }

      if (_.isArray(value)) {
        return _.xor(value, other).length === 0
      }

      value = _.isUndefined(value) ? null : value
      other = _.isUndefined(other) ? null : other

      return _.isEqual(value, other)
    }
    return true
  })
}

export function generateSelectOptions(list, label?, id?) {
  if (!list) {
    return []
  }

  function generateLabel(object) {
    if (_.isFunction(label)) {
      return label(object)
    } else if (_.isArray(label)) {
      return _.map(label, frag => object.get(frag)).reduce((accu, value) => {
        if (accu === '') {
          return value
        }

        return accu + ' - ' + value
      }, '')
    }
    return object.get(label)
  }

  return list
    .map(object => {
      return {
        label: label ? generateLabel(object) : object,
        value: id ? object.get(id) : object
      }
    })
    .toArray()
}

export function getTwoDigitLanguageCode(language) {
  return _.toLower(language && language.indexOf('-') !== -1 ? language.split('-')[0] : language)
}

export function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export function enumValues(object, isNumberEnum?) {
  return Object.keys(object)
    .map(key => object[key])
    .filter(val => typeof val === (!isNumberEnum ? 'string' : 'number'))
}

export function getQuestionFieldName(type: Type): string {
  return `${type}-${this.generateUUID()}`
}

export const hashCode = (raw: string): number => {
  if (_.isEmpty(raw)) {
    return undefined
  }

  let hash = 0

  if (raw.length === 0) {
    return hash
  }

  for (let i = 0; i < raw.length; i++) {
    const chr = raw.charCodeAt(i)
    // noinspection TsLint
    hash = ((hash << 5) - hash) + chr
    // noinspection TsLint
    hash |= 0 // Convert to 32bit integer
  }

  return hash
}

export function reOrder<T>(orderedObject: OrderedModel<T>, srcOrder, dstOrder) {

  const order = orderedObject.getOrder()

  if (order === srcOrder) {
    return orderedObject.setOrder(dstOrder)
  }

  if (srcOrder > dstOrder && order >= dstOrder && order < srcOrder) {
    return orderedObject.setOrder(order + 1)
  }

  if (srcOrder < dstOrder && order > srcOrder && order <= dstOrder) {
    return orderedObject.setOrder(order - 1)
  }

  return orderedObject
}

export const pageEqual = (o1, o2) => o1 && o2 && o1.page === o2.page

export const samePageAndOrder = (obj1, obj2) => {
  return obj1.page === obj2.page && obj1.order === obj2.order
}

export const isEmptyHash = (hash: number) => _.isNil(hash) || hash === 0

export const isIntervalAction = (action) => /(App.*)|(conversation\.SET_UNREAD_COUNT)/.test(action.type)

export const accumulate = (accu, value) => accu.concat(_.isArray(value) ? value : [value])

export const unique = (accu, value) => accu.indexOf(value) === -1 ? accu.concat(value) : accu

export const prefixKeys = (prefix, object) => {

  if (!_.isObject(object)) {
    return undefined
  }

  const adjusted = {}

  Object.keys(object).forEach(key => {
    adjusted[`${prefix}.${key}`] = object[key]
  })

  return adjusted
}

export const enumToValues = (_enum, isNumberEnum?) => {
  return Object.keys(_enum)
    .map(k => _enum[k])
    .filter(val => typeof val === (!isNumberEnum ? 'string' : 'number'))
}

export const getDataStatusClassName = (dataStatus: DataStatus) => {
  switch (dataStatus) {
    case DataStatus.ideal:
      return 'status--ideal'
    case DataStatus.warning:
      return 'status--warning'
    case DataStatus.danger:
      return 'status--danger'
    default:
      return 'status--missing'
  }
}

export const getDataStatusColor = (dataStatus: DataStatus) => {

  const idealColor = '#ccf6c5'
  const warningColor = '#fff8ba'
  const errorColor = '#f6cfc5'
  const missingColor = '#cdcdcd'

  switch (dataStatus) {
    case DataStatus.ideal:
      return idealColor
    case DataStatus.warning:
      return warningColor
    case DataStatus.danger:
      return errorColor
    default:
      return missingColor
  }
}

export const getTimelineData = (
  events: List<WearableStatusHourly> | List<DataColectionHourly>,
  criteria: Criteria,
  unit: DurationInputArg2 = 'hours',
  defaultTimezone: string = DATE_DEFAULT_TIMEZONE) => {

  const reportTimeRange = getReportTimeRange(criteria, unit)

  const eventsGroupByPerson = _.groupBy(events.toJS(), 'person')

  let formattedData = []

  Object.keys(eventsGroupByPerson)
    .forEach(key => {

      const events = eventsGroupByPerson[key]

      const person = key
      const siteStudyName = events[0].siteStudyName

      const timeline = Array.from(reportTimeRange.by(unit)).map(startTime => {

        const foundEvent = events.find((item) => startTime.isSame(moment(item.startTime)))

        return foundEvent || createMissingData(startTime, unit, defaultTimezone)
      })

      formattedData.push({
        _id: generateUUID(),
        person,
        siteStudyName,
        timeline
      })
    })

  return List(formattedData)
}

const createMissingData = (startTime, unit, timezone) => {

  const endTime = startTime.clone().add(1, unit)

  return {
    id: startTime.unix(),
    startTime,
    endTime,
    timezone,
    value: -1,
    percentage: {
      value: '-1',
      level: DataStatus.missing
    }
  }
}

export const getReportTimeRange = (criteria: Criteria, unit: DurationInputArg2) => {

  const flooredStartTimeToUnit = moment(criteria.from).startOf(unit)
  const roundedEndTimeToUnit = moment(criteria.to).endOf(unit)

  return momentRange.range(flooredStartTimeToUnit, roundedEndTimeToUnit)
}

export function parseBoolean(value) {

  if (value && (value === true || (value as any).toString().toLowerCase() === 'true')) {

    return true

  } else if (value && (value === false || (value as any).toString().toLowerCase() === 'false')) {

    return false
  }

  return undefined
}

export function openNewTab(url) {
  return window.open(url, '_blank')
}
