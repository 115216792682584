/**
 * Created by Mauritz Untamala on 11/02/15.
 */
import React from 'react'
import ModelView, {BaseProps, BaseState} from '../ModelView'
import SiteStudiesTable from './SiteStudiesTable'
import Tabs from '../Tabs'
import SaveButton from '../SaveButton'
import SiteUsers from './SiteUsers'
import SiteDetails from './SiteDetails'

import changeCase from 'change-case'

import {connect} from 'react-redux'
import {getAccessRights} from '../../modules/AccessRights'
import {navigate} from '../../modules/Location'
import SiteModule from '../../modules/Site'
import Site from '../../models/Site'

const tabsIds = ['studies', 'personnel']

interface Props extends BaseProps<Site> {
  tab: string
}

interface State extends BaseState<Site> {
}

class SiteView extends ModelView<Site, Props, State> {
  constructor(props: Props) {
    super(props)
  }

  getActiveTab = () => {
    const {tab} = this.props

    return tab ? tab : tabsIds[0]
  }

  onTabClick = (tab, event) => {
    event.preventDefault()
    this.props.navigate('/admin/site/' + this.props.modelId + '?tab=' + tab)
  }

  getTabComponent = activeTab => {

    const {modelId, t} = this.props
    const {model} = this.state

    switch (activeTab) {
      case 'studies':
        return (
          <SiteStudiesTable siteId={modelId}
                            site={model}
                            t={t}/>
        )
      case 'personnel':
        return (
          <SiteUsers siteId={modelId}
                     site={model}
                     t={t}/>
        )
      default:
        return null
    }
  }

  getTabContent = () => {
    if (!this.props.modelId) {
      return
    }

    const activeTab = this.getActiveTab()

    return (
      <Tabs
        key={'tab-' + activeTab}
        activeTab={activeTab}
        onTabClick={this.onTabClick}
        context='siteView.tabs'
        tabIds={tabsIds}>
        {this.getTabComponent(activeTab)}
      </Tabs>
    )
  }

  getContent() {
    const {model} = this.state
    const {modelName, accessRights, t} = this.props

    return [
      (
        <SiteDetails key='site-details'
                     site={model}
                     onChange={this.updateModel}
                     accessRights={accessRights}
                     t={t}/>
      ),
      (
        <SaveButton key='save-button'
                    model={model}
                    accessRightsKey={changeCase.paramCase(modelName)}
                    save={this.save}
                    onModelSaved={this.onModelSaved}
                    t={t}/>
      ),
      this.getTabContent()
    ]
  }
}

const mapActionCreators = {
  getModel: SiteModule.getModel,
  saveModel: SiteModule.saveModel,
  resetModel: SiteModule.resetModel,
  getAccessRights,
  navigate
}

const mapStateToProps = ({site, accessRights}, _ownProps) => ({
  model: site,
  accessRights
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelId: ownProps.siteId,
    modelName: 'site',
    backHref: '/admin/sites',
    forceBackHref: true,
    editUrl: `/admin/site`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps)
}

export default connect(
  mapStateToProps,
  mapActionCreators,
  mergeProps
)(SiteView)
