/**
 * Created by Mauritz Untamala on 12/12/15.
 */
import React from 'react'
import qs from 'qs'
import BaseDownload, {BaseProps, BaseState} from '../../../components/BaseDownloadComponent'
import {
  firstAllowedTab,
  isTabAllowed,
  TabContent,
  Tabs,
  TabSelection,
  TabSelectionModel
} from './components/TabComponents'
import Subjects from './components/Subjects'
import Messages from './components/Messages'
import MedicationEvents from './components/MedicationEvents'
import AdverseEvents from './components/AdverseEvents'
import Administrations from './components/Administrations'
import HealthTrackings from './components/HealthTrackings'
import SleepTrackings from './components/SleepTrackings'
import Criteria from '../../../components/Criteria'
import Questionnaires from './components/Questionnaires'
import QuestionnaireStatus from './components/QuestionnaireStatus'

import _ from 'lodash'
import moment from 'moment'
import i18n from '../../../services/I18n'
import {withNamespaces} from 'react-i18next'
import {List} from 'immutable'
import {DATE_DEFAULT_TIMEZONE, ROLES} from '../../../config/constants'

import {connect} from 'react-redux'
import Users from '../../../modules/Users'
import Studies from '../../../modules/Studies'
import SiteStudies from '../../../modules/SiteStudies'
import ReportsModule from '../../../modules/Reports'
import {downloadResource, pollDownload} from '../../../modules/Download'
import {navigate} from '../../../modules/Location'
import {PaginationContext} from '../../../models/Pagination'
import CriteriaModel from '../../../models/Criteria'
import User from '../../../models/User'
import UsersModel from '../../../models/Users'
import SiteStudyModel from '../../../models/SiteStudy'
import SiteStudiesModel from '../../../models/SiteStudies'
import ReportsModel from '../../../models/Reports'
import App from '../../../models/App'
import StudiesModel from '../../../models/Studies'

import './Reports.less'
import {TabId} from '../../../models/TabId'
import IntakeEvents from './components/IntakeEvents'
import OffstateEvents from './components/OffstateEvents'
import WearableStatusHourly from './components/WearableStatusHourly'
import DataColectionHourly from './components/DataCollectionHourly'
import GarminSleepEvents from './components/GarminSleepEvents'
import WearableTimeline from './components/WearableTimeline'
import DataCollectionTimeline from './components/DataCollectionTimeline'
import QosSummary from './components/QosSummary'
import Active from './components/Active'

const getSelectedSiteStudies = (siteStudies, criteria) => {
  const selectedSiteStudyIds = criteria.siteStudies

  return siteStudies.list.filter(function(siteStudy) {
    return selectedSiteStudyIds.includes(siteStudy.id)
  })
}

const sanitizeCriteria = (user, users, siteStudies, criteria) => {
  if (!criteria.get('tab')) {
    criteria = criteria.set('tab', firstAllowedTab(getSelectedSiteStudies(siteStudies, criteria)))
  }

  return criteria.sanitizeCriteria(user, users, siteStudies)
}

const getInitialCriteria = () => {
  return CriteriaModel.getInitialCriteria({
    lang: i18n.language
  })
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams
    ? CriteriaModel.fromQuery(query)
    : getInitialCriteria()
}

interface Props extends BaseProps {
  app: App
  criteria: CriteriaModel
  user: User
  users: UsersModel
  studies: StudiesModel
  siteStudy: SiteStudyModel
  siteStudies: SiteStudiesModel
  reports: ReportsModel
  location: string
  selectedSiteStudies: any
  pagination: any
  updateCriteria: (location, criteria) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  getReports: (queryParams?, reset?) => any
  getSiteStudies: () => any
  getStudies: (studyIds: number[]) => any
  resetReports: (tabId?: TabId) => any
  downloadReports: (criteria, queryParams) => any
  navigate: (url: string) => any
}

interface State extends BaseState {
  tabSelection: TabSelectionModel
  criteria: CriteriaModel
}

class Reports extends BaseDownload<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      download: undefined,
      pollerId: undefined,
      tabSelection: new TabSelectionModel(),
      criteria: props.criteria
    }
  }

  componentDidMount() {
    this.props.resetReports(this.props.criteria.tab)
    this.props.getSiteStudies()
    this.fetchData(this.props)
  }

  componentWillUnmount() {
    this.props.resetReports()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState)

    const {user, criteria, getSiteStudies} = this.props

    if (prevProps.criteria.tab !== criteria.tab) {
      this.props.resetReports(criteria.tab)
    }

    if (prevProps.user !== user && !user.isSaving && !user.isLoading) {
      getSiteStudies()
      this.setState({tabSelection: new TabSelectionModel()})
    }

    if (!prevProps.criteria.isEqual(criteria)) {
      this.fetchData(this.props, prevProps)
    }
  }

  fetchData = (props: Props, prevProps?: Props) => {

    const {getUsers, getReports, getStudies, criteria, user} = props

    if (!prevProps || !criteria.siteStudies.equals(prevProps.criteria.siteStudies)) {
      getUsers(ROLES.SUBJECT, null, null, criteria.siteStudies.toJS())
    }

    if (!prevProps || !user.studyIds.equals(prevProps.user.studyIds)) {
      getStudies(user.studyIds.toJS())
    }

    getReports(criteria.getQueryParams(), true)
  }

  onCriteriaChange = criteria => {

    const {updateCriteria, getUsers, user, users, siteStudies, location} = this.props
    criteria = sanitizeCriteria(user, users, siteStudies, criteria)

    if (
      !_.isEqual(this.props.criteria.siteStudies.toJS().sort(), criteria.siteStudies.toJS().sort())
    ) {
      getUsers(ROLES.SUBJECT, null, null, criteria.siteStudies.toJS())
    }

    updateCriteria(location, criteria)
  }

  getActiveTab = () => {
    const {criteria} = this.props
    const activeTab = criteria.tab ? criteria.tab : firstAllowedTab(this.props.selectedSiteStudies)

    if (isTabAllowed(this.props.selectedSiteStudies, activeTab)) {
      return activeTab
    }

    return firstAllowedTab(this.props.selectedSiteStudies)
  }

  onTabCriteriaChanged = tabCriteria =>
    this.updateCriteriaWithTabCriteria(this.props.criteria, tabCriteria)

  loadMore = () => {
    const {getReports, reports, criteria, pagination} = this.props

    if (!reports.isLoading && pagination.hasMore) {
      getReports(criteria.getQueryParams())
    }
  }

  getContent = tab => {
    const {criteria, pagination, reports, app, navigate, t, siteStudy} = this.props

    const props = {
      navigate,
      app,
      criteria,
      onCriteriaChanged: this.onTabCriteriaChanged,
      hasMore: pagination.hasMore,
      loadMore: this.loadMore,
      loading: reports.isLoading,
      t,
      defaultTimezone: siteStudy.timezone || DATE_DEFAULT_TIMEZONE
    }

    const results = (reports.tabId === tab
      ? reports.list.map(item => item.object)
      : List()) as List<any>

    switch (tab) {
      case TabId.subjects:
        return <Subjects subjects={results} {...props} />
      case TabId.messages:
        return <Messages messages={results} {...props} />
      case TabId.medicationEvents:
        return <MedicationEvents events={results} {...props} />
      case TabId.adverseEvents:
        return <AdverseEvents events={results} {...props} />
      case TabId.administrations:
        return <Administrations events={results} {...props} />
      case TabId.healthTrackings:
        return <HealthTrackings trackings={results} {...props} />
      case TabId.sleepTrackings:
        return <SleepTrackings trackings={results} {...props} />
      case TabId.questionnaires:
        return <Questionnaires answers={results} {...props} />
      case TabId.questionnaireStatus:
        return <QuestionnaireStatus questionnaireStatus={results} {...props} />
      case TabId.intakeEvents:
        return <IntakeEvents events={results} {...props} />
      case TabId.offstateEvents:
        return <OffstateEvents events={results} {...props} />
      case TabId.wearableStatusHourly:
        return <WearableStatusHourly events={results} {...props}/>
      case TabId.dataCollectionHourly:
        return <DataColectionHourly events={results} {...props}/>
      case TabId.garminSleepEvents:
        return <GarminSleepEvents events={results} {...props} />
      case TabId.wearableStatusTimeline:
        return <WearableTimeline events={results} {...props}/>
      case TabId.activeTimeline:
        return <DataCollectionTimeline events={results} unit='days' {...props}/>
      case TabId.dataCollectionTimeline:
        return <DataCollectionTimeline events={results} {...props}/>
      case TabId.qosSummary:
        return <QosSummary events={results} {...props}/>
      case TabId.active:
        return <Active events={results} {...props}/>
      default:
        throw new Error('Unknown tab: ' + tab)
    }
  }

  onTabSelection = tabSelection => this.setState({tabSelection: tabSelection})

  getSelectedTabs = () => {
    const tabSelection = this.state.tabSelection

    if (tabSelection.get('all')) {
      return TabId.getCriteriaTabIds().filter(isTabAllowed.bind(undefined, this.props.selectedSiteStudies))
    } else {
      return tabSelection.selectedTabs()
    }
  }

  downloadReports = type => {
    return this.props.downloadReports(this.props.criteria, {
      type,
      tabs: this.getSelectedTabs(),
      lang: i18n.language,
      utcOffset: moment().utcOffset()
    })
  }

  onDownloadExcel = () => this.downloadReports('excel')

  onDownloadPdf = () => this.downloadReports('pdf')

  onTabClick = tab => {
    let {criteria} = this.props

    if (criteria.get('tab') !== tab) {

      this.updateCriteriaWithTabCriteria(criteria.set('tab', tab))
    }
  }

  updateCriteriaWithTabCriteria = (criteria, tabCriteria?) => {
    const {from, to, tab, users} = criteria

    return this.onCriteriaChange(
      criteria
        .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
        .set('sort', tabCriteria ? tabCriteria.sort : undefined)
        .set('filter', tabCriteria ? tabCriteria.filter : undefined)
        .set('from', tabCriteria && tabCriteria.from ? tabCriteria.from : from)
        .set('to', tabCriteria && tabCriteria.to ? tabCriteria.to : to)
        .set('tab', tabCriteria && tabCriteria.tab ? tabCriteria.tab : tab)
        .set('users', tabCriteria && tabCriteria.users ? tabCriteria.users : users)
    )
  }

  onHealthDataClick = () => {
    return this.props.downloadReports(this.props.criteria, {
      type: 'excelHealth',
      tabs: [TabId.healthTrackings, TabId.sleepTrackings],
      lang: i18n.language
    })
  }

  getHealthDataButton = () => {
    if (isTabAllowed(this.props.selectedSiteStudies, TabId.healthTrackings)) {
      return this.getDownloadButton(this.onHealthDataClick, 'button.saveHealthData', 'excelHealth')
    }

    return
  }

  renderViewQuestionnaireAnswersButton = (tab: TabId) => {
    const {t, app} = this.props

    if (!app.hasAccessToPage('/admin/questionnaires/:qid/answer/:id')) {
      return undefined
    }

    switch (tab) {
      case TabId.questionnaires:
      case TabId.questionnaireStatus:
        return (
          <button className='btn btn-default' onClick={this.onViewQuestionnaireAnswersClick}>
            <span>{t('button.viewQuestionnaireAnswers')}</span>
          </button>)
      default:
        return undefined
    }
  }

  onViewQuestionnaireAnswersClick = () => {
    const queryParams = this.props.criteria.getQueryParams()
    const urlParams = _.isEmpty(queryParams) ? '' : `?${qs.stringify(queryParams)}`

    this.props.navigate(`/admin/questionnaires/answers/view${urlParams}`)
  }

  render() {
    const activeTab = this.getActiveTab()
    const {t, users, studies, siteStudies, criteria} = this.props

    return (
      <div className='reports-view'>
        <h1>{t('reportsView.title')}</h1>
        <Criteria
          users={users.list}
          studies={studies.list}
          siteStudies={siteStudies.list}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          showLocked={true}
          t={t}>
          {this.getHealthDataButton()}

          {this.getDownloadButton(this.onDownloadExcel, 'button.saveToCSV', 'excel')}

          {this.getDownloadButton(this.onDownloadPdf, 'button.saveToPDF', 'pdf')}

          {this.renderViewQuestionnaireAnswersButton(activeTab)}

          <TabSelection
            tabSelection={this.state.tabSelection}
            onChange={this.onTabSelection}
            selectedSiteStudies={this.props.selectedSiteStudies}
            t={t}
          />
        </Criteria>
        <Tabs
          activeTab={activeTab}
          onTabClick={this.onTabClick}
          selectedSiteStudies={this.props.selectedSiteStudies}
          t={t}
        />
        <TabContent tab={activeTab}>{this.getContent(activeTab)}</TabContent>
      </div>
    )
  }
}

const mapStateToProps = (
  {app, authenticatedUser, users, reports, studies, siteStudy, siteStudies, download, pagination},
  ownProps
) => {
  const user = authenticatedUser
  const criteria = sanitizeCriteria(user, users, siteStudies, getCriteria(ownProps.location))
  const selectedSiteStudies = getSelectedSiteStudies(siteStudies, criteria)

  return {
    app,
    criteria,
    user,
    users,
    reports,
    studies,
    siteStudy,
    siteStudies,
    download,
    selectedSiteStudies,
    pagination: pagination.getPagination(PaginationContext.REPORT)
  }
}

const mapActionToProps = {
  navigate,
  pollDownload,
  downloadResource,
  downloadReports: ReportsModule.downloadReports,
  updateCriteria: ReportsModule.updateCriteria,
  getReports: ReportsModule.getModels,
  resetReports: ReportsModule.reset,
  getUsers: Users.getUsers,
  getStudies: Studies.fetchStudies,
  getSiteStudies: SiteStudies.fetchSiteStudiesWithIds
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps
  )(Reports)
)
