import BaseModel from './BaseModel'

const interestedKeys = ['content']

const defaultValues = {
  id: undefined,
  content: undefined,
  personFromId: undefined,
  personToId: undefined,
  responseToId: undefined,
  conversationId: undefined,
  writtenTimestamp: undefined,
  consumedTimestamp: undefined,

  firstName: undefined,
  lastName: undefined,
  subjectNumber: undefined,
  screeningNumber: undefined,

  dedicatedSubjectId: undefined,
  replyRole: undefined
}

export default class Message extends BaseModel(defaultValues, interestedKeys)<Message> {
  id: number
  content: any
  personFromId: number
  personToId: number
  responseToId: number
  conversationId: number
  writtenTimestamp: any
  consumedTimestamp: any

  firstName: string
  lastName: string
  subjectNumber: string
  screeningNumber: string

  dedicatedSubjectId: any
  replyRole: any

  fromJS(js): Message {
    return new Message(js)
  }
}
