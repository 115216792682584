import {fromJS, Map} from 'immutable'
import BaseModel from './BaseModel'
import _ from 'lodash'
import changeCase from 'change-case'

const defaults = {
  accessMap: Map<any, any>()
}

export default class AccessRights extends BaseModel(defaults)<AccessRights> {
  accessMap: Map<any, any>

  constructor(js?) {
    super(js)

    if (js) {
      return this.addAccessRights(js) as AccessRights
    }
  }

  fromJS(accessRightsJS: any = {}): AccessRights {
    return new AccessRights(accessRightsJS)
  }

  addAccessRights(accessRightsJS) {
    const {objectName, access, object} = accessRightsJS

    let objectMap = this.accessMap.get(objectName, Map()).set(object._id, fromJS(access))
    const accessMap = this.accessMap.set(objectName, objectMap)

    return this.set('accessMap', accessMap)
  }

  getAccessRights(objectName, object): Map<any, any> {
    if (!object) {
      return null
    }

    const objectMap = this.accessMap.get(changeCase.paramCase(objectName), Map())
    const accessRights = objectMap.get(object._id)

    return !!accessRights ? accessRights : objectMap.first()
  }

  hasWriteAccess(objectName, object, field) {
    const accessRights = this.getAccessRights(objectName, object)
    const fieldRights = accessRights && accessRights.get(field)

    return !!(fieldRights && fieldRights.get('write'))
  }

  hasReadAccess(objectName, object, field) {
    const accessRights = this.getAccessRights(objectName, object)
    const fieldRights = accessRights && accessRights.get(field)

    return !!(fieldRights && (fieldRights.get('write') || fieldRights.get('read')))
  }

  hasWriteAccessToAnyField(objectName, object) {
    const accessRights = this.getAccessRights(objectName, object)

    if (!accessRights) {
      return false
    }

    const rights = accessRights.toJS()
    const keys = _.without(Object.keys(rights), 'id', '_id', 'updating', 'editing', 'error')

    return !!_.find(keys, (key) => rights[key].write)
  }
}
