import React from 'react'
import BaseEventComponent, {BaseEventProps, BaseEventState} from './BaseEventComponent'
import SaveButton from './SaveButton'

import changeCase from 'change-case'
import classNames from 'classnames'
import _ from 'lodash'
import {withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import {getAccessRights} from '../modules/AccessRights'
import {navigate} from '../modules/Location'
import MedicationEvent from '../modules/MedicationEvent'
import MedicationEventModel from '../models/MedicationEvent'

import '../styles/events.less'

const subjectFields = [
  'name',
  'time',
  'dosage',
  'usage',
  'comment'
]

const investigatorFields = subjectFields.concat(['personnelComment'])

interface Props extends BaseEventProps<MedicationEventModel> {
}

interface State extends BaseEventState<MedicationEventModel> {
}

class SubjectMedicationEventView extends BaseEventComponent<MedicationEventModel, Props, State> {
  constructor(props: Props) {
    super(props, subjectFields, investigatorFields)
  }

  getInputFields = () => {
    const {authenticatedUser, t} = this.props
    const {model} = this.state
    const context = 'medicationEvent'
    const isPersonnel = authenticatedUser.isPersonnel()

    const commonAdditionalProps = {
      labelClassName: 'event-form-input-label',
      translationLabelClassName: 'event-form-input-label'
    }

    const commonTranslationAdditionalProps = {
      fieldInputClassName: !isPersonnel ? 'col-xs-12' : 'col-xs-6',
      translationInputClassName: isPersonnel && 'col-xs-6'
    }

    const textInputAdditionalProps = _.merge(
      {groupClassName: !isPersonnel ? 'col-xs-12' : 'col-xs-6'},
      commonAdditionalProps
    )

    const textAreaAdditionalProps = _.merge(
      {rows: 3},
      commonAdditionalProps
    )

    const timePickerAdditionalProps = {
      showDateOnlyToggle: false,
      dateOnly: model.timeDateOnly
    }

    const ecrfTimePickerAdditionalProps = {
      showDateOnlyToggle: false,
      showNow: true,
      showReset: true
    }

    const disabled = model.locked

    return investigatorFields.concat(['ecrfTransferTime'])
      .map(field => {
        const writeAccess = this.getWriteAccess(field)

        if (writeAccess === null) {
          return null
        }

        switch (field) {
          case 'name':
            return this.createInputField(
              context,
              field,
              writeAccess,
              'text',
              textInputAdditionalProps,
              disabled
            )
          case 'dosage':
            return this.createInputFieldWithTranslation(
              context,
              field,
              writeAccess,
              'text',
              _.merge({}, commonAdditionalProps, commonTranslationAdditionalProps),
              disabled
            )

          case 'usage':
          case 'comment':
            return this.createInputFieldWithTranslation(
              context,
              field,
              writeAccess,
              'textarea',
              _.merge({}, textAreaAdditionalProps, commonTranslationAdditionalProps),
              disabled
            )

          case 'time':
            return this.createTimePicker(
              context,
              field,
              writeAccess,
              this.onTimeFieldChange('time', 'timeDateOnly'),
              timePickerAdditionalProps
            )

          case 'personnelComment':
            return this.createInputField(
              context,
              'personnelComment',
              writeAccess,
              'textarea',
              _.merge({}, textAreaAdditionalProps, {groupClassName: 'col-xs-12'})
            )

          case 'ecrfTransferTime':
            const onEcrfTimeChange = (change) => this.updateModel(model.set(field, change.time))

            return this.createTimePicker(
              context,
              field,
              writeAccess,
              onEcrfTimeChange,
              ecrfTimePickerAdditionalProps,
              t('ecrfTransferTime'),
              'col-xs-12'
            )

          default:
            return null
        }
      })
  }

  getContent() {
    const {model} = this.state
    const {modelName, t, authenticatedUser} = this.props

    const isPersonnel = authenticatedUser.isPersonnel()
    const disabled = !isPersonnel && model.locked
    const className = classNames({
      'container': isPersonnel,
      'event-form': true
    })

    return (
      <div className={className}>
        {this.getHeading()}
        {this.getInputFields()}
        <div className='row'>
          <div className='col-xs-12'>
            <SaveButton
              enabled={!disabled}
              model={model}
              accessRightsKey={changeCase.paramCase(modelName)}
              save={this.save}
              onModelSaved={this.onModelSaved}
              t={t}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({authenticatedUser, medicationEvent, accessRights}, _ownProps) => {
  return {
    authenticatedUser,
    model: medicationEvent,
    accessRights
  }
}

const mapActionToProps = {
  getModel: MedicationEvent.getModel,
  resetModel: MedicationEvent.resetModelWithUserId,
  saveModel: MedicationEvent.saveModel,
  getAccessRights,
  navigate
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const additionalProps = {
    modelId: ownProps.eventId,
    modelName: 'medicationEvent',
    backHref: ownProps.backHref || '/events',
    editUrl: `/event/edit-medication-event`
  }

  return Object.assign(additionalProps, ownProps, stateProps, dispatchProps, {
    resetModel: () => dispatchProps.resetModel(stateProps.authenticatedUser.id)
  })
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps,
  mergeProps
)(SubjectMedicationEventView))
