import BaseModel from './BaseModel'

const interestedKeys = ['id']

const defaults = {
  who: undefined,
  what: undefined,
  time: undefined,
  original: undefined,
  updated: undefined
}

export default class AuditTrail extends BaseModel(defaults, interestedKeys)<AuditTrail> {
  who: any
  what: any
  time: any
  original: any
  updated: any

  fromJS(auditJS: any = {}): any {
    return new AuditTrail(auditJS)
  }
}
