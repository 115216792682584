import {PaginationContext} from './../models/Pagination'
import ModelsModule from './ModelsModule'
import QuestionnaireAnswer from './QuestionnaireAnswer'
import QuestionnaireAnswers from '../models/QuestionnaireAnswers'

const initialState = new QuestionnaireAnswers()

export class QuestionnaireAnswersModule extends ModelsModule {
  constructor() {
    super('questionnaire/answer', initialState, PaginationContext.QUESTIONNAIRE_ANSWERS)
  }

  protected getAdditionalActionHandlers() {
    return {
      [QuestionnaireAnswer.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [QuestionnaireAnswer.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [QuestionnaireAnswer.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new QuestionnaireAnswersModule()
