import EventComponent, {BaseProps, BaseState} from './EventComponent'

import {formatDate} from '../../../../util'

import AdverseEventModel from '../../../../models/AdverseEvent'

interface Props extends BaseProps<AdverseEventModel> {}

interface State extends BaseState {}

export default class AdverseEvent extends EventComponent<AdverseEventModel, Props, State> {
  constructor(props: Props) {
    super(props, '/event/edit-adverse-event')
  }

  getTopContent = () => formatDate(this.props.event.started, this.props.event.startedDateOnly)

  getLowerContent = () => this.props.event.description
}
