import React from 'react'
import {Component} from 'react'
import Results from './Results'
import StringArrayColumn from './StringArrayColumn'
import {getFilterValue, getSortValue} from './TabComponents'

import Criteria from '../../../../models/Criteria'

const subjectColumns = [
  'siteStudyName',
  'subjectNumber',
  'studyGroups',
  'status',
  'screeningNumber',
  'roles'
]

interface Props {
  subjects: any
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loadMore?: () => any
  loading?: boolean
  onRowClick?: (rowData) => any
  onProcessedData?: () => any
  t: (key, params?) => any
}

interface State {}

export default class Subjects extends Component<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'status':
        return ({rowData}) => {

          const {status} = rowData
          const {t} = this.props

          return <span>{t(`personStatus.${status}`)}</span>
        }
      case 'roles':
      case 'studyGroups':
        return StringArrayColumn
      default:
        return null
    }
  }

  getColumns = () => {
    return subjectColumns.map(field => {
      return {
        columnName: field,
        displayName: this.props.t('column.' + field),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    return (
      <Results
        ref='results'
        columns={this.getColumns()}
        results={this.props.subjects}
        {...this.props}
      />
    )
  }
}
