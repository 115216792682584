import BaseModel from './BaseModel'

const defaults = {
  type: undefined,
  period: undefined,
  rescheduled: false
}

export default class AdministrationPeriod extends BaseModel(defaults)<AdministrationPeriod> {
  type: string
  period: any
  rescheduled: boolean

  fromJS(periodJS: any = {}): any {
    return new AdministrationPeriod(periodJS)
  }
}
