import React from 'react'
import {Component} from 'react'
import DeleteButton from '../../../../components/DeleteButton'

import App from '../../../../models/App'
import {UIAction} from '../../../../models/UIAction'

interface Props {
  app: App
  rowData: any
  deleteModel: (model) => any
}

interface State {}

export default class ActionsColumn extends Component<Props, State> {
  remove = event => {
    event.preventDefault()
    event.stopPropagation()
    const {deleteModel, rowData} = this.props
    deleteModel(rowData._src)
  }

  getActions = () => {
    const {app, rowData} = this.props

    if (app.hasAccessToAction(UIAction.DELETE_STUDY)) {
      return <DeleteButton id={rowData.id} remove={this.remove}/>
    }
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}
