import {List, Record} from 'immutable'

const defaultValues = {historyStack: List(), currentPage: undefined}

export default class Location extends Record(defaultValues) {
  historyStack: List<any>
  currentPage: any

  public pushState(state) {
    const previous = this.currentPage
    const currentPage = state
    let historyStack = this.historyStack
    const lastInStack = historyStack.last()

    if (previous) {
      if (lastInStack && previous.split('?')[0] !== lastInStack.split('?')[0]) {
        historyStack = historyStack.push(previous)
      } else {
        historyStack = historyStack.pop().push(previous)
      }
    }

    if (historyStack.size > 20) {
      historyStack = historyStack.shift()
    }

    return this.set('historyStack', historyStack).set('currentPage', currentPage)
  }

  public getPreviousState() {
    return this.historyStack.last() // Get latest history location
  }

  public popHistoryStack() {
    // Take latest history location and remove it from top
    // Reset current page so we don't cause back loops.
    return this.set('historyStack', this.historyStack.pop()).set('currentPage', undefined)
  }

  public checkIfStackSizeHasPreviousState() {
    return !this.historyStack.isEmpty()
  }
}
