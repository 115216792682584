import React from 'react'
import {ControlLabel, FormGroup} from 'react-bootstrap'
import SanitizedHTML from 'react-sanitized-html'
import NumberPicker from '../NumberPicker'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'

interface Props extends BaseQuestionComponentProps {
}

interface State extends BaseQuestionComponentState {
}

export default class Number extends BaseQuestionComponent<Props, State> {

  onChange = value => {
    const {field, answer} = this.props

    const updatedValue = answer.value.set(field, value)
    this.props.onChange(answer.set('value', this.compactRecord(updatedValue)))
  }

  parse(strVal) {
    strVal = strVal.replace(',', '.')

    return parseFloat(strVal)
  }

  getContent() {
    const {component, answer, fieldError, disabled, language} = this.props
    const localization = component.getLanguageLocalization(language)
    const label = localization && localization.label
    const unit = localization && localization.unit
    const field = component.getField()
    const options = component.getOptions()
    const value = answer.getFieldValue(field)
    const min = options && options.min
    const max = options && options.max
    const format = options && options.format
    const decimalDigits = options && options.decimalDigits

    return (
      <FormGroup className='question-number-input' validationState={fieldError ? 'error' : null}>

        <ControlLabel>
          <SanitizedHTML allowedTags={['b']} html={label}/>
        </ControlLabel>

        <NumberPicker
          value={value}
          onChange={this.onChange}
          min={min}
          max={max}
          disabled={disabled}
          format={`${format ? format : '-##'}${unit ? unit : ''}`}
          decimalDigits={decimalDigits || 1}
          parse={this.parse}
        />
        {this.getError(fieldError)}
      </FormGroup>
    )
  }
}
