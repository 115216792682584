import React from 'react'
import {Component} from 'react'
import UserView from '../../../components/UserView'

import {connect} from 'react-redux'
import UserModule from '../../../modules/User'
import UserModel from '../../../models/User'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  userId: number
  user: UserModel
  getModel: (id, queryParams?) => any
  saveModel: (model, pathComponents?, queryParams?) => any
  resetModel: () => any
}

interface State {
}

class User extends Component<Props, State> {
  render() {
    const {userId, user, getModel, saveModel, resetModel, t} = this.props

    return (
      <UserView
        key='user-view'
        user={user}
        userId={userId}
        getModel={getModel}
        saveModel={saveModel}
        resetModel={resetModel}
        t={t}
      />
    )
  }
}

const mapStateToProps = ({user}, ownProps) => {
  const userId = ownProps.params.id ? parseInt(ownProps.params.id, 10) : undefined

  return {userId, user}
}

const mapActionToProps = {
  getModel: UserModule.getModel,
  saveModel: UserModule.saveModel,
  resetModel: UserModule.resetModel
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(User))
