import Conversations from '../models/Conversations'
import ModelsModule from './ModelsModule'
import {get, update} from './ActionsCommon'
import {bind} from '../util'
import {createAction} from 'redux-actions'

const initialState = new Conversations()

export class ConversationsModule extends ModelsModule {
  setUnreadCountType: string
  setUnreadCountAction: any

  constructor() {
    super('conversation', initialState)
    bind(this, this.lockConversation, this.setUnreadCount, this.fetchUnreadCount)
  }

  public setUnreadCount(count) {
    return (dispatch) => dispatch(this.setUnreadCountAction(count))
  }

  public lockConversation(conversationId: number, unlock: boolean) {
    return (dispatch) => {
      return dispatch(update(this.name, 'lock', null, {conversationId, unlock}))
        .then((response) => dispatch(this.getModelSuccessAction(response)))
        .catch((error) => dispatch(this.handleError(this.getModelsFailedAction, error)))
    }
  }

  public fetchUnreadCount() {
    return (dispatch) => {
      return dispatch(get('message', 'unread'))
        .then((unreadCount) => dispatch(this.setUnreadCountAction(parseInt(unreadCount, 10))))
        .catch((error) => dispatch(this.handleError(this.getModelsFailedAction, error)))
    }
  }

  protected getAdditionalActionHandlers() {
    return {
      [this.setUnreadCountType]: (state, {payload}) => state.setUnreadCount(payload)
    }
  }

  protected initializeTypes() {
    super.initializeTypes()
    this.setUnreadCountType = `${this.name}.SET_UNREAD_COUNT`
  }

  protected initializeActions() {
    super.initializeActions()
    this.setUnreadCountAction = createAction(this.setUnreadCountType)
  }
}

export default new ConversationsModule()
