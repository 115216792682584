/**
 * Created by Mauritz Untamala on 26/02/16.
 */
import React from 'react'
import {Component} from 'react'
import Select from 'react-select'

import _ from 'lodash'
import classNames from 'classnames'
import {List} from 'immutable'
import {getFieldError} from '../util'

interface Props {
  model: any
  modelName: string
  field: string
  writeAccess: boolean
  options: any[]
  onChange: (model) => any

  labelKey?: string
  multi?: boolean
  placeholder?: string
  forceShowError?: boolean
  labelClassName?: string
  fieldClassName?: string
  formGroupClassName?: object
  t: (key, params?) => any
}

interface State {
  showError: boolean
}

export default class SelectFormInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.getNewState()
  }

  getNewState() {
    return {showError: this.props.forceShowError}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.model._id !== this.props.model._id && this.state.showError) {
      this.setState({showError: false})
    }
  }

  onBlur = () => this.setState({showError: true})

  onFocus = () => this.setState({showError: this.props.forceShowError ? true : false})

  onSelectChange = selectedOption => {
    let fieldName = this.props.field
    let value

    if (!selectedOption) {
      value = null
    } else if (List.isList(this.props.model.get(fieldName))) {
      value = List(_.map(_.isArray(selectedOption) ? selectedOption : [selectedOption], 'value'))
    } else {
      value = selectedOption.value
    }

    this.props.onChange(this.props.model.set(fieldName, value))
  }

  getError = fieldError => {
    if (fieldError && this.state.showError) {
      return <div className='error'>{fieldError}</div>
    }
  }

  getLabelClassName = () => {
    return classNames(
      this.props.labelClassName ? this.props.labelClassName : 'col-xs-3 col-sm-2',
      'control-label'
    )
  }

  getFieldClassName = field => {
    return classNames([
      this.props.fieldClassName ? this.props.fieldClassName : 'col-xs-9 col-sm-10',
      field
    ])
  }

  getFormGroupClassName = fieldError => {
    return classNames(
      _.merge(
        {
          'form-group': true,
          'has-error': !!fieldError && this.state.showError
        },
        this.props.formGroupClassName || {}
      )
    )
  }

  getValue = (model, field, options, multi) => {
    let value = model.get(field)
    value = value ? (List.isList(value) ? value.toJS() : value) : []

    const getSelectedOptions = () => {
      if (_.isArray(value)) {
        return options.filter(option => _.includes(value, option.value))
      } else {
        return options.filter(option => option.value === value)
      }
    }

    return multi ? getSelectedOptions() : _.first(getSelectedOptions())
  }

  render() {
    const {
      field,
      labelKey,
      model,
      placeholder,
      writeAccess,
      multi,
      options,
      t,
      modelName
    } = this.props
    const fieldError = getFieldError(field, model.validate(), model.error)
    const value = this.getValue(model, field, options, multi)
    const inputKey = `select-${field}`

    return (
      <div key={`select-${modelName}-${field}`} className={this.getFormGroupClassName(fieldError)}>
        <label className={this.getLabelClassName()} htmlFor={inputKey}>
          {t(labelKey ? labelKey : modelName + 'View.' + field)}
        </label>

        <div className={this.getFieldClassName(field)}>
          <Select key={inputKey}
                  id={inputKey}
                  isDisabled={!writeAccess}
                  name={inputKey}
                  value={_.isEmpty(value) ? null : value}
                  isMulti={!!multi}
                  isClearable={true}
                  options={options}
                  onChange={this.onSelectChange}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  placeholder={placeholder || t('select')}
                  noOptionsMessage={() => t('noOptions')}/>
          <span className='help-block'>{this.getError(fieldError)}</span>
        </div>
      </div>
    )
  }
}
