/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import React from 'react'
import {Component} from 'react'
import {Nav, Navbar, NavItem} from 'react-bootstrap'

import _ from 'lodash'

import {connect} from 'react-redux'
import {navigateBack} from '../modules/Location'

const mapStateToProps = (_state, _ownProps) => ({})
const mapActionCreators = {navigate: navigateBack}

interface Props {
  navItems: any[]
  isFormDirty: boolean
  currentHref: string
  navigate: (url: string, silent?: boolean) => any
  t: (key, params?) => any
}

interface State {
}

class SubPageView extends Component<Props, State> {
  onLinkClick({disabled, href, force}, _event) {
    if (!disabled) {
      const {isFormDirty, t, navigate} = this.props

      if (isFormDirty) {
        if (confirm(t('confirm.unsavedEntries'))) {
          navigate(href, force)
        }
      } else {
        navigate(href, force)
      }
    }
  }

  getNavItems() {
    const {navItems, t, currentHref} = this.props

    return _.map(navItems, (navItem, index) => {
      const href = navItem.disabled ? undefined : navItem.href

      return (
        <NavItem
          key={'navitem-' + index}
          eventKey={index + 1}
          active={href === currentHref && !!href}
          onClick={this.onLinkClick.bind(this, navItem)}
          disabled={navItem.disabled}>
          <span key={'i18n_' + 'navigation.' + navItem.label} className={navItem.className}>
            {t('navigation.' + navItem.label)}
          </span>
        </NavItem>
      )
    })
  }

  render() {
    return (
      <div key='sub-navigation-view' className='navigation-view'>
        <Navbar key='navbar' fluid={true} fixedTop={true}>
          <Nav navbar={true}>{this.getNavItems()}</Nav>
        </Navbar>
        {this.props.children}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapActionCreators
)(SubPageView)
