import BaseModel from './BaseModel'
import {setDateFields} from './Common'
import Translation from './Translation'

const constraints = {
  field: {
    presence: {allowEmpty: false}
  },
  value: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = ['field']

const defaultValues = {
  field: undefined,
  value: undefined,
  valueHash: undefined,
  fieldValueHash: undefined,
  updateTime: undefined,
  isOutdated: undefined
}

export default class EventTranslation
  extends BaseModel(defaultValues, equalsKeys, constraints)<EventTranslation> implements Translation {
  field: string
  value: string
  updateTime: any
  valueHash: number
  fieldValueHash: number
  isOutdated: boolean

  fromJS(js: any): EventTranslation {

    return setDateFields(new EventTranslation(js), ['updateTime'])
  }

  getContentHash() {
    return this.fieldValueHash
  }

  getTranslationHash() {
    return this.valueHash
  }

  getTranslation() {
    return this.value
  }

  setTranslation(newTranslation: string) {
    return this
      .set('value', newTranslation)
      .set('valueHash', undefined)
  }
}
