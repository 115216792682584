import QuestionnaireAnswer from './QuestionnaireAnswer'
import Collection from './ImmutableCollection'

export default class QuestionnaireAnswers extends Collection(QuestionnaireAnswer)<QuestionnaireAnswer> {
  getAnswerForQuestionnaire(questionnaireId: number, periodDayId: number) {
    return this.list.find((answer) => {
      return answer.questionnaireId === questionnaireId && answer.periodDayId === periodDayId
    })
  }
}
