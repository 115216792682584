import React from 'react'
import {Component} from 'react'
import DeleteButton from '../DeleteButton'

import {UIAction} from '../../models/UIAction'
import App from '../../models/App'
import User from '../../models/User'

interface Props {
  app: App
  authenticatedUser: User
  rowData: any
  deleteSiteStudy: (model) => any
  updateUser: (model) => any
  t?: (key, params?) => any
}

interface State {}

export default class ActionsColumn extends Component<Props, State> {
  remove = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.deleteSiteStudy(this.props.rowData._src)
  }

  select = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.updateUser(
      this.props.authenticatedUser.set('selectedSiteStudyId', this.props.rowData.id)
    )
  }

  getActions = () => {
    const actions = []

    actions.push(
      <button
        key={'selectSiteStudy_' + this.props.rowData.id}
        className='btn btn-default'
        onClick={this.select}
        role='button'>
        {this.props.t('button.select')} <i className='fa fa-sign-in'/>
      </button>
    )

    if (this.props.app.hasAccessToAction(UIAction.DELETE_SITE_STUDY)) {
      actions.push(
        <DeleteButton key='delete-button' id={this.props.rowData.id} remove={this.remove}/>
      )
    }

    return actions
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}
