import ModelModule from './ModelModule'
import Study from '../models/Study'
import {getFeatures, getNavItems} from './App'

export class StudyModule extends ModelModule<Study> {
  constructor() {
    super('study')
  }

  protected getInitialState = () => new Study()

  /**
   * @override
   */
  protected deleteSuccessMessageProps({name}) {
    return {name}
  }

  protected onUpdateSuccess(model) {
    return (dispatch) => {
      dispatch(super.onUpdateSuccess(model))
      dispatch(getNavItems())
      dispatch(getFeatures())
    }
  }
}

export default new StudyModule()
