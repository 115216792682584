import React from 'react'
import {Component} from 'react'

import {WithNamespaces, withNamespaces} from 'react-i18next'

import './Error.less'

interface Props extends WithNamespaces {
  id: string
  errorId: any
}

interface State {}

class Error extends Component<Props, State> {
  render() {
    const {t, id} = this.props
    const title = t('error')
    const message = t('errors.' + id)

    return (
      <div className='errors-view-container'>
        <div className='container-fluid'>
          <div className='panel panel-default'>
            <div className='panel-body'>
              <h1>{title}</h1>
              <p className='error-message' data-error-key={this.props.errorId}>
                {message}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces(['common'], {wait: true})(Error)
