import React from 'react'
import SanitizedHTML from 'react-sanitized-html'
import InputWithTranslation from '../InputWithTranslation'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'
import _ from 'lodash'

interface Props extends BaseQuestionComponentProps {
  componentClass?: string
}

interface State extends BaseQuestionComponentState {
  answerHash: number
  valueHash: number
}

export default class Text extends BaseQuestionComponent<Props, State> {

  onChange = (newValue) => {

    const {field, answer, onChange} = this.props
    const updatedValue = answer.value.set(field, newValue)

    onChange(answer.set('value', this.compactRecord(updatedValue)))
  }

  getContent() {
    const {
      isPersonnel,
      enableTranslationFeature,
      componentClass,
      field,
      fieldError,
      disabled,
      t,
      translation,
      onTranslationChange
    } = this.props

    const title = this.getLocalization()
    const label = <SanitizedHTML allowedTags={['b']} html={title}/>
    const value = this.getAnswerValue()
    const additionalProps = _.isEmpty(fieldError) ? {} : {forceShowError: true}
    const withTranslation = isPersonnel && enableTranslationFeature

    return (
      <InputWithTranslation fieldValue={value}
                            onFieldChange={this.onChange}
                            disabled={disabled}
                            error={fieldError}
                            type={componentClass}
                            field={field}
                            label={label}
                            t={t}
                            onTranslationChange={onTranslationChange}
                            translation={translation}
                            enableTranslationFeature={withTranslation}
                            fieldInputClassName={withTranslation ? 'col-xs-6' : 'col-xs-12'}
                            translationInputClassName={withTranslation && 'col-xs-6'}
                            labelClassName='questionnaire-answer-input-label'
                            {...additionalProps}/>
    )
  }

  render() {
    return this.getContent()
  }
}
