/**
 * Created by Mauritz Untamala on 11/02/16.
 */
import React from 'react'
import {PureComponent} from 'react'
import Results from './components/Results'

import _ from 'lodash'

import {connect} from 'react-redux'
import SitesModule from '../../../modules/Sites'
import SiteModule from '../../../modules/Site'
import {navigate} from '../../../modules/Location'
import App from '../../../models/App'
import Criteria from '../../../models/Criteria'
import SitesModel from '../../../models/Sites'

import './Sites.less'
import {WithNamespaces, withNamespaces} from 'react-i18next'

interface Props extends WithNamespaces {
  app: App
  sites: SitesModel
  criteria: Criteria
  location: string
  getSites: (queryParams?, reset?) => any
  updateCriteria: (location, criteria) => any
  navigate: (url: string) => any
  deleteSite: (model) => any
}

interface State {
}

class Sites extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    const {criteria, getSites} = this.props
    getSites(criteria.getQueryParams(), true)
  }

  componentDidUpdate(prevProps) {
    const {getSites, criteria} = this.props

    if (!prevProps.criteria.isEqual(criteria)) {
      getSites(criteria.getQueryParams(), true)
    }
  }

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props
    updateCriteria(location, criteria)
  }

  render() {
    const {app, sites, criteria, deleteSite, navigate, t} = this.props

    return (
      <div key='sites-view-container' className='sites-view'>
        <h1 className='page-title'>
          {t('sitesView.title')}
        </h1>
        <Results
          ref='results'
          app={app}
          sites={sites.list}
          criteria={criteria}
          navigate={navigate}
          deleteModel={deleteSite}
          onCriteriaChange={this.onCriteriaChange}
          t={t}
        />
      </div>
    )
  }
}

const mapActionToProps = {
  updateCriteria: SitesModule.updateCriteria,
  getSites: SitesModule.getModels,
  deleteSite: SiteModule.deleteModel,
  navigate
}

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  return hasParams ? Criteria.fromQuery(query) : Criteria.getInitialCriteriaWithoutDateRange()
}

const mapStateToProps = ({app, sites}, ownProps) => {
  const criteria = getCriteria(ownProps.location)

  return {
    app,
    criteria,
    sites
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Sites))
