import React, {PureComponent} from 'react'
import TimestampColumn from '../../../../components/TimestampColumn'
import Criteria from '../../../../models/Criteria'
import Results from './Results'
import QosSummaryModel from '../../../../models/QosSummary'
import {getFilterValue, getSortValue} from './TabComponents'
import moment from 'moment'
import {TabId} from '../../../../models/TabId'
import {List} from 'immutable'
import PercentageColumn from '../../../../components/PercentageColumn'
import LastSyncColumn from '../../../../components/LastSyncColumn'

const wearableHourlyColumns = [
  'siteStudyName',
  'person',
  'startTime',
  'endTime',
  'coverage',
  'worn',
  'lastSync',
  'offstateEvents',
  'intakeEvents'
]

interface Props {
  events: List<QosSummaryModel>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class QosSummary extends PureComponent<Props, State> {
  getCustomComponent = field => {
    switch (field) {
      case 'startTime':
      case 'endTime':
        return TimestampColumn
      case 'lastSync':
        return LastSyncColumn
      case 'coverage':
      case 'worn':
        return PercentageColumn
      default:
        return null
    }
  }

  onCellClick = (cellInfo) => {
    const {criteria, onCriteriaChanged} = this.props
    const {rowData, columnName} = cellInfo
    const {endTime, startTime, userId} = rowData

    const updatedTimeCriteria = criteria
      .set('from', moment(startTime))
      .set('to', moment(endTime))
      .set('users', [userId])

    switch (columnName) {
      case 'coverage':
        onCriteriaChanged(updatedTimeCriteria.set('tab', TabId.dataCollectionHourly))
        break
      case 'worn':
        onCriteriaChanged(updatedTimeCriteria.set('tab', TabId.wearableStatusHourly))
        break
      case 'offstateEvents':
        onCriteriaChanged(updatedTimeCriteria.set('tab', TabId.offstateEvents))
        break
      case 'intakeEvents':
        onCriteriaChanged(updatedTimeCriteria.set('tab', TabId.intakeEvents))
        break
      default:
        return
    }
  }

  getColumns = () => {
    const {t} = this.props

    return wearableHourlyColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field)
      }
    })
  }

  render() {
    const {events, ...rest} = this.props
    return (
      <Results
        onCellClick={this.onCellClick}
        columns={this.getColumns()}
        results={events}
        ref='results'
        {...rest} />
    )
  }
}
