import EventComponent, {BaseProps, BaseState} from './EventComponent'

import {formatDate} from '../../../../util'

import MedicationEventModel from '../../../../models/MedicationEvent'

interface Props extends BaseProps<MedicationEventModel> {}

interface State extends BaseState {}

export default class MedicationEvent extends EventComponent<MedicationEventModel, Props, State> {
  constructor(props: Props) {
    super(props, '/event/edit-medication-event')
  }

  getTopContent = () => formatDate(this.props.event.time, this.props.event.timeDateOnly)

  getLowerContent = () => this.props.event.name
}
