import ModelsModule from './ModelsModule'
import SiteStudies from '../models/SiteStudies'
import {get} from './ActionsCommon'
import _ from 'lodash'
import SiteStudy from './SiteStudy'
import {bind} from '../util'

const initialState = new SiteStudies()

export class SiteStudiesModule extends ModelsModule {
  constructor() {
    super('site-study', initialState)
    bind(this, this.fetchSiteStudies, this.fetchSiteStudiesWithIds)
  }

  public fetchSiteStudies(siteId) {
    if (siteId) {
      return (dispatch) => {
        return dispatch(get('site', [siteId, 'study']))
          .then((siteStudies) =>
            dispatch(this.getModelsSuccessAction({results: siteStudies, reset: true}))
          )
          .catch((error) => dispatch(this.getModelsFailedAction(error)))
      }
    } else {
      return this.getModels(null, true)
    }
  }

  public fetchSiteStudiesWithIds(siteStudyIds) {
    let queryParams

    if (!_.isEmpty(siteStudyIds)) {
      queryParams = {}

      const array = siteStudyIds.reduce(function(accu, id) {
        if (accu !== '') {
          accu = accu + ','
        }
        return accu + id
      }, '')

      if (array !== '') {
        queryParams.siteStudies = array
      }
    }

    return this.getModels(queryParams, true)
  }

  protected getAdditionalActionHandlers() {
    return {
      [SiteStudy.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [SiteStudy.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [SiteStudy.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new SiteStudiesModule()
