import React from 'react'
import {PureComponent} from 'react'
import ActionsColumn from './ActionsColumn'
import TableComponent from '../../../../components/TableComponent'
import _ from 'lodash'

import {UIAction} from '../../../../models/UIAction'
import Criteria from '../../../../models/Criteria'
import App from '../../../../models/App'

interface Props {
  app: App
  sites: any
  criteria: Criteria
  onCriteriaChange: (criteria) => any
  deleteModel: (model) => any
  navigate: (url: string) => any
  ref?: any
  t: (key, params?) => any
}

interface State {
}

export default class Results extends PureComponent<Props, State> {
  getResults = () => this.props.sites.map(study => _.extend(study.toJS(), {_src: study})).toArray()

  getColumnMetadata = () => {
    const {t} = this.props

    return [
      {
        columnName: 'name',
        displayName: t('column.siteName')
      },
      {
        columnName: 'number',
        displayName: t('column.siteNumber')
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ]
  }

  showSite = ({id}) => this.props.navigate('/admin/site/' + id)

  addSite = () => this.props.navigate('/admin/site/new')

  getAdderView = () => {
    if (this.props.app.hasAccessToAction(UIAction.ADD_SITE)) {
      return (
        <button className='btn btn-default add-model pull-right' onClick={this.addSite}>
          {this.props.t('button.addSite')}
        </button>
      )
    }
  }

  onTabCriteriaChanged = tabCriteria => {
    const {criteria, onCriteriaChange} = this.props
    onCriteriaChange(
      criteria
        .set('columnName', tabCriteria ? tabCriteria.columnName : undefined)
        .set('sort', tabCriteria ? tabCriteria.sort : undefined)
        .set('filter', tabCriteria ? tabCriteria.filter : undefined)
    )
  }

  render() {
    const {criteria, t} = this.props

    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          ref='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnMetadata()}
          useFixedHeader={true}
          filter={criteria ? criteria.filter : undefined}
          initialSortColumn={criteria ? criteria.columnName : undefined}
          initialSortState={criteria ? criteria.sort : undefined}
          onRowClick={this.showSite}
          topContent={this.getAdderView()}
          onCriteriaChanged={this.onTabCriteriaChanged}
          t={t}
        />
      </div>
    )
  }
}
