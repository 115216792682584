import React from 'react'
import {FunctionComponent} from 'react'
import {getTimestampFilterValueForReports} from './CommonFilterValueFunctions'
import {UTC_TIMEZONE} from '../config/constants'
import _ from 'lodash'

interface Props {
  rowData: any
  data?: any
  column?: any
  children?: any
}

const TimestampUtcColumn: FunctionComponent = (props: Props) => {

  const {data, rowData, column, children} = props
  const rowDataUtc = _.merge({}, rowData, {timezone: UTC_TIMEZONE})

  return (
    <span>
      {getTimestampFilterValueForReports(data, rowDataUtc, column)}
      {children}
    </span>
  )
}

export default TimestampUtcColumn
