import ModelModule from './ModelModule'
import Period from '../models/Period'

export class PeriodModule extends ModelModule<Period> {
  constructor() {
    super('period')
  }

  protected getInitialState = () => new Period()

  /**
   * @override
   */
  protected deleteSuccessMessageProps(period) {
    return period.getName()
  }
}

export default new PeriodModule()
