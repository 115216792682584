import React from 'react'
import {Component} from 'react'

interface Props {
  data: string[]
}

interface State {}

export default class StringArrayColumn extends Component<Props, State> {
  getContent = () => {
    const {data} = this.props

    return data.map((string, index) => {
      const comma = index > 0 ? <span className='comma'>,</span> : undefined

      return (
        <span key={index}>
          {comma}
          <span>{string}</span>
        </span>
      )
    })
  }

  render() {
    return <div>{this.getContent()}</div>
  }
}
