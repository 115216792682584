import Users from '../models/Users'
import ModelsModule from './ModelsModule'
import _ from 'lodash'
import {bind} from '../util'
import User from './User'

const initialState = new Users()

class UsersModule extends ModelsModule {
  constructor() {
    super('user', initialState)
    bind(this, this.getUsers)
  }

  public getUsers(role?, type?, siteId?, siteStudyIds?) {
    let queryParams = {} as any

    if (role) {
      queryParams = {role}
    }

    if (type) {
      queryParams = {type}
    }

    if (siteId) {
      queryParams = _.merge(queryParams, {site: siteId})
    }

    if (siteStudyIds) {
      if (_.isArray(siteStudyIds)) {
        siteStudyIds = siteStudyIds.reduce(function(accu, id) {
          if (accu !== '') {
            accu = accu + ','
          }
          return accu + id
        }, '')
      }

      queryParams = _.merge(queryParams, {siteStudies: siteStudyIds})
    }

    return this.getModels(queryParams, true)
  }

  protected getAdditionalActionHandlers() {
    return {
      [User.getCreateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getUpdateModelSuccessType()]: (state, {payload}) => state.addModelFromJS(payload),
      [User.getDeleteModelSuccessType()]: (state, {payload}) => state.removeModelWithId(payload.id)
    }
  }
}

export default new UsersModule()
