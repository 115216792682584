import {List} from 'immutable'
import React, {PureComponent} from 'react'
import DataCollectionHourly from '../../../../models/DataCollectionHourly'
import Criteria from '../../../../models/Criteria'
import Results from './Results'
import {getFilterValue, getSortValue} from './TabComponents'
import {getTimelineData} from '../../../../util'
import TimelineColumn from '../../../../components/TimelineColumn'
import {DurationInputArg2} from 'moment'

const dataCollectionColumns = [
  'siteStudyName',
  'person',
  'timeline'
]

interface Props {
  events: List<DataCollectionHourly>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any
  unit?: DurationInputArg2
  defaultTimezone: string

  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class DataCollectionTimeline extends PureComponent<Props, State> {

  getCustomComponent = field => {

    switch (field) {
      case 'timeline':
        return TimelineColumn
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return dataCollectionColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field),
        sortable: field !== 'timeline'
      }
    })
  }

  render() {
    const {events, criteria, unit, defaultTimezone, ...rest} = this.props
    return (
      <Results columns={this.getColumns()}
               results={getTimelineData(events, criteria, unit, defaultTimezone)}
               criteria={criteria}
               ref='results'
               {...rest} />
    )

  }
}
