import React from 'react'
import {Component} from 'react'

import classnames from 'classnames'
import _ from 'lodash'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import SiteStudyModule from '../../../modules/SiteStudy'

import User from '../../../models/User'
import SiteStudy from '../../../models/SiteStudy'

import './Home.less'

interface Props extends WithNamespaces {
  authenticatedUser: User
  siteStudy: SiteStudy
  getSiteStudy: (id, queryParams?) => any
}

interface State {}

class Home extends Component<Props, State> {
  componentDidMount() {
    const {authenticatedUser, getSiteStudy} = this.props

    getSiteStudy(authenticatedUser.selectedSiteStudyId)
  }

  render() {
    const {t, siteStudy} = this.props
    let content = []

    if (siteStudy) {
      content.push(
        <div key='organization' className='form-group'>
          <label className='col-xs-6 col-sm-3 col-md-2 control-label'>{t('organization')}</label>
          <div className='col-xs-6 col-sm-9 col-md-10'>
            <p className='form-control-static'>{siteStudy.welcome ? siteStudy.welcome : null}</p>
          </div>
        </div>
      )

      content.push(
        <div key='studyInfo' className='form-group'>
          <label className='col-xs-6 col-sm-3 col-md-2 control-label'>{t('studyInfo.info')}</label>
          <div className='col-xs-6 col-sm-9 col-md-10'>
            <p className='form-control-static'>
              {siteStudy.studyNumber + ' ' + siteStudy.studyName}
            </p>
          </div>
        </div>
      )

      content = content.concat(
        _.compact(
          ['studyCenterPhone', 'principalDoctorPhone', 'doctorOnDutyPhone'].map(field => {
            const value = siteStudy.get(field)

            if (value) {
              const classes = classnames('form-group', field)

              return (
                <div key={field} className={classes}>
                  <label className='col-xs-6 col-sm-3 col-md-2 control-label'>
                    {t('siteStudyView.' + field)}
                  </label>
                  <div className='col-xs-6 col-sm-9 col-md-10'>
                    <p className='form-control-static'>
                      <a href={`tel:${value}`}>{value}</a>
                    </p>
                  </div>
                </div>
              )
            }
          })
        )
      )
    }

    return (
      <div className='container-fluid home-view'>
        <h1>{t('welcome')}</h1>
        <div className='form-horizontal'>{content}</div>
      </div>
    )
  }
}

const mapActionToProps = {
  getSiteStudy: SiteStudyModule.getModel
}

const mapStateToProps = ({authenticatedUser, siteStudy}, _ownProps) => {
  return {
    authenticatedUser,
    siteStudy
  }
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Home))
