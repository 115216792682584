import ActivityTracker from '../models/ActivityTracker'
import ModelModule from './ModelModule'

export class ActivityTrackerModule extends ModelModule<ActivityTracker> {
  constructor() {
    super('tracker', 'activityTracker')
  }

  protected getInitialState = () => new ActivityTracker()

  protected deleteSuccessMessageProps(model) {
    return {name: model.deviceId}
  }
}

export default new ActivityTrackerModule()
