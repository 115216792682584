import React from 'react'
import {Component} from 'react'
import StudyGroups from '../../../../models/StudyGroups'

interface Props {
  studyGroups: StudyGroups
  data: Array<any>
  t?: (key, params?) => any
}

interface State {}

export default class GroupsColumn extends Component<Props, State> {
  getGroups = () => {
    const {studyGroups, data, t} = this.props

    return data.map((groupId, index) => {
      const group = studyGroups.getModelById(groupId)
      const name = group ? group.name : t('unknown')

      return (
        <span key={'groupName-' + index} className='badge'>
          {name}
        </span>
      )
    })
  }

  render() {
    return <div className='groups'>{this.getGroups()}</div>
  }
}
