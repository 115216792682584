import Conversation from './Conversation'
import Collection from './ImmutableCollection'

class Conversations extends Collection(Conversation, {unreadCount: 0})<Conversation> {
  public setUnreadCount(unreadCount) {
    return this.set('unreadCount', unreadCount)
  }
}

export default Conversations
