/**
 * Created by Mauritz Untamala on 22/12/15.
 */
import {createAction, handleActions} from 'redux-actions'
import AccessRights from '../models/AccessRights'
import {add} from './ActionsCommon'
import {paramCase} from 'change-case'

const initialState = new AccessRights()

const accessRightsPrefix = `AccessRights.GET_ACCESS_RIGHTS`

export const GET_ACCESS_RIGHTS_REQUEST = `${accessRightsPrefix}_REQUEST`
export const GET_ACCESS_RIGHTS_SUCCESS = `${accessRightsPrefix}_SUCCESS`
export const GET_ACCESS_RIGHTS_FAILED = `${accessRightsPrefix}_FAILED`

const requestAccessRights = createAction(GET_ACCESS_RIGHTS_REQUEST)
const receiveAccessRights = createAction(GET_ACCESS_RIGHTS_SUCCESS)
const invalidAccessRights = createAction(GET_ACCESS_RIGHTS_FAILED)

export function getAccessRights(objectName, object) {
  return (dispatch, _getState) => {
    if (!object) {
      return
    }

    dispatch(requestAccessRights())

    const paramCaseObjectName = paramCase(objectName)

    return dispatch(add('access', paramCaseObjectName, null, object))
      .then((access) =>
        dispatch(
          receiveAccessRights({
            access,
            objectName,
            object
          })
        )
      )
      .catch((err) => dispatch(invalidAccessRights(err)))
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [GET_ACCESS_RIGHTS_REQUEST]: (state) => state.startOfLoading(),
  [GET_ACCESS_RIGHTS_SUCCESS]: (state, {payload}) => state.addAccessRights(payload).endOfLoading(),
  [GET_ACCESS_RIGHTS_FAILED]: (state, {payload}) => state.endOfLoading(payload)
}

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState)
