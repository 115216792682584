import React, {PureComponent} from 'react'
import ActionsColumn from './ActionsColumn'
import TableComponent from '../../../../components/TableComponent'
import {getTimestampFilterValue, getTimestampSortValue} from '../../../../components/CommonFilterValueFunctions'

import _ from 'lodash'

import App from '../../../../models/App'
import ActivityTrackers from '../../../../models/ActivityTrackers'
import Users from '../../../../models/Users'
import {UIAction} from '../../../../models/UIAction'

interface Props {
  app: App
  users: Users
  trackers: ActivityTrackers
  navigate: (url: string, silent?: boolean) => any
  navigateToBackend: (url) => any
  updateTracker: (model) => any
  deleteTracker: (model) => any
  t: (key, params?) => any
}

interface State {}

export default class Results extends PureComponent<Props, State> {
  getResults = () => {
    return this.props.trackers.list
      .map(tracker => _.extend(tracker.toJS(), {_src: tracker}))
      .toArray()
  }

  getColumnMetadata = () => {
    const {users, t} = this.props

    const getUserVisibleName = (userId, _object, _field) => {
      const user = users.getModelById(userId)
      return user ? user.visibleName() : t('unknown')
    }

    const userColumn = props => {
      let user = users.getModelById(props.data)
      const visibleName = user ? user.visibleName() : t('unknown')

      return <span>{visibleName}</span>
    }

    return [
      {
        columnName: 'userId',
        displayName: t('column.user'),
        customComponent: userColumn,
        filterValue: getUserVisibleName,
        sortValue: getUserVisibleName
      },
      {
        columnName: 'deviceId',
        displayName: t('column.deviceId')
      },
      {
        columnName: 'expiration',
        displayName: t('column.setupTime'),
        filterValue: getTimestampFilterValue,
        sortValue: getTimestampSortValue
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ]
  }

  showTracker = rowData => this.props.navigate('/admin/tracker/' + rowData.id)

  addTracker = () => this.props.navigateToBackend('/garmin/connect')

  getAdderView = () => {
    const {app, t} = this.props

    if (app.hasAccessToAction(UIAction.ADD_TRACKER)) {
      return (
        <button className='btn btn-default add-model pull-right' onClick={this.addTracker}>
          {t('button.addTracker')}
        </button>
      )
    }
  }

  render() {
    return (
      <div className='table-panel'>
        <TableComponent
          key='results'
          ref='results'
          data={this.getResults()}
          showFilter={true}
          rowKey={'id'}
          columns={this.getColumnMetadata()}
          useFixedHeader={true}
          onRowClick={this.showTracker}
          topContent={this.getAdderView()}
          t={this.props.t}
        />
      </div>
    )
  }
}
