import React from 'react'
import {PureComponent} from 'react'

import classNames from 'classnames'
import {NotificationType} from '../../config/constants'

import './Notification.less'

interface Props {
  className?: string
  title?: string
  message: string
  type: NotificationType
}

interface State {}

export default class Notification extends PureComponent<Props, State> {
  render() {
    const {className, type, title, message} = this.props

    return (
      <div className={classNames('notification', className, type)}>
        {title && <h4 className='title'>{title}</h4>}
        <div className='message'>{message}</div>
      </div>
    )
  }
}
