import React, {Component, PureComponent} from 'react'
import {
  getAdministrationTypeValue,
  getBooleanValue,
  getCausalityFilterValue,
  getOutcomeFilterValue,
  getPeriodValue,
  getRoles,
  getSeverityCausalityOutcomeFilterValue,
  getSeverityFilterValue,
  getTimestampFilterValue,
  getTimestampSortValue
} from '../../../../components/CommonFilterValueFunctions'

import classNames from 'classnames'
import {Record} from 'immutable'

import {Feature} from '../../../../models/Feature'
import {TabId} from '../../../../models/TabId'
import _ from 'lodash'

const criteriaTabsIds = TabId.getCriteriaTabIds()
const additionalTabsIds = TabId.getAdditionalTabIds()

export const tabsIds = criteriaTabsIds.concat(additionalTabsIds) as [string]

export function getFilterValue(props, field) {
  const getUserVisibleName = (userId, _object, _field, t) => {
    const user = props.users.getModelById(userId)
    return user ? user.visibleName() : t('unknown')
  }

  const getStudyGroupFilterValue = (groupIds, _object, _field, t) => {
    if (_.isEmpty(groupIds)) {
      return t('subjectHasNoStudyGroup')
    }

    const groups = _.map(groupIds, groupId => props.groups.getModelById(groupId).get('name'))
    return groups.join(', ')
  }

  switch (field) {
    case 'roles':
      return getRoles
    case 'userId':
    case 'personId':
    case 'personnelId':
      return getUserVisibleName
    case 'studyGroupIds':
      return getStudyGroupFilterValue
    case 'startTime':
    case 'endTime':
    case 'writtenTimestamp':
    case 'consumedTimestamp':
    case 'started':
    case 'ended':
    case 'time':
    case 'ecrfTransferTime':
    case 'date':
    case 'updateTime':
      return getTimestampFilterValue
    case 'type':
      return getAdministrationTypeValue
    case 'period':
      return getPeriodValue
    case 'disabled':
    case 'rescheduled':
    case 'locked':
      return getBooleanValue
    case 'severityCausalityOutcome':
      return getSeverityCausalityOutcomeFilterValue
    case 'severity':
      return getSeverityFilterValue
    case 'causality':
      return getCausalityFilterValue
    case 'outcome':
      return getOutcomeFilterValue
    default:
      return function(value) {
        return value
      }
  }
}

export function getSortValue(props, field) {
  switch (field) {
    case 'writtenTimestamp':
    case 'consumedTimestamp':
    case 'started':
    case 'ended':
    case 'time':
    case 'ecrfTransferTime':
    case 'date':
    case 'updateTime':
      return getTimestampSortValue
    default:
      return getFilterValue(props, field)
  }
}

export function isTabAllowed(siteStudies, tab) {

  const isFeatureEnabled = (feature: Feature) => {
    return (siteStudies && !!siteStudies.find((siteStudy) => siteStudy.hasFeature(feature)))
  }

  switch (tab) {
    case TabId.administrations:
      return isFeatureEnabled(Feature.administration)
    case TabId.healthTrackings:
    case TabId.garminSleepEvents:
      return isFeatureEnabled(Feature.trackers)
    case TabId.questionnaires:
    case TabId.questionnaireStatus:
      return isFeatureEnabled(Feature.questionnaires)
    case TabId.sleepTrackings:
    case TabId.dataCollectionHourly:
    case TabId.wearableStatusHourly:
    case TabId.dataCollectionTimeline:
    case TabId.wearableStatusTimeline:
    case TabId.qosSummary:
      return isFeatureEnabled(Feature.daisyAnalytics)
    case TabId.intakeEvents:
    case TabId.offstateEvents:
      return isFeatureEnabled(Feature.patientReportedData)
    case TabId.activeTimeline:
    case TabId.active:
      return isFeatureEnabled(Feature.dtxAnalytics)
    default:
      return true
  }
}

export const firstAllowedTab = siteStudies =>
  tabsIds.find(isTabAllowed.bind(undefined, siteStudies))

export class TabSelectionModel extends Record({
  all: true,
  subjects: false,
  messages: false,
  medicationEvents: false,
  adverseEvents: false,
  administrations: false,
  questionnaires: false,
  questionnaireStatus: false,
  intakeEvents: false,
  offstateEvents: false,
  garminSleepEvents: false
}) {
  constructor(js?) {
    super(js)
  }

  setTabSelected(key, flag) {
    switch (key) {
      case 'all':
        if (flag) {
          return new TabSelectionModel({all: true})
        } else {
          return this.set(key, false)
        }
      case TabId.healthTrackings:
      case TabId.sleepTrackings:
      case TabId.garminSleepEvents:
      case TabId.subjects:
      case TabId.messages:
      case TabId.medicationEvents:
      case TabId.adverseEvents:
      case TabId.administrations:
      case TabId.questionnaires:
      case TabId.questionnaireStatus:
      case TabId.intakeEvents:
      case TabId.offstateEvents:
        let me = this as TabSelectionModel
        if (flag) {
          me = me.set('all', false) as TabSelectionModel
        }
        return me.set(key, flag)
      default:
        return this
    }
  }

  isSelected(tab) {
    return this.get('all') || this.get(tab)
  }

  selectedTabs() {
    return criteriaTabsIds.filter(tabId => this.isSelected(tabId))
  }
}

interface Props {
  activeTab: string
  onTabClick: (tab) => any
  selectedSiteStudies: any
  t: (key, params?) => any
}

interface State {
}

export class Tabs extends Component<Props, State> {
  isActiveTab = tab => this.props.activeTab === tab

  onTabClick = (tab, event) => {
    event.preventDefault()
    this.props.onTabClick(tab)
  }

  createTabs = () => {
    return tabsIds.filter(isTabAllowed.bind(undefined, this.props.selectedSiteStudies)).map(tab => {
      const classes = classNames({
        active: this.isActiveTab(tab)
      })

      return (
        <li key={tab} className={classes}>
          <a data-toggle='tab' onClick={this.onTabClick.bind(this, tab)}>
            {this.props.t('reportsView.tabs.' + tab)}
          </a>
        </li>
      )
    })
  }

  render() {
    return <ul className='nav nav-tabs'>{this.createTabs()}</ul>
  }
}

export const TabContent = ({tab, children}) => {
  return (
    <div className='tab-content'>
      <div id={tab} className='tab-pane fade in active'>
        {children}
      </div>
    </div>
  )
}

interface TabSelectionProps {
  tabSelection: any
  selectedSiteStudies: any
  onChange: (tab) => any
  t: (key, params?) => any
}

interface TabSelectionState {
}

export class TabSelection extends PureComponent<TabSelectionProps, TabSelectionState> {
  tabSelection = (tab, event) =>
    this.props.onChange(this.props.tabSelection.setTabSelected(tab, event.target.checked))

  render() {
    const tabs = this.props.tabSelection.toJS()
    const allowedTabs = Object.keys(tabs)
      .filter(isTabAllowed.bind(undefined, this.props.selectedSiteStudies))
      .map(key => {
        return (
          <div key={key}>
            <input
              type='checkbox'
              checked={tabs[key]}
              onChange={this.tabSelection.bind(this, key)}
            />
            {this.props.t('tabSelection.' + key)}
          </div>
        )
      })
    return (
      <div className='tab-selection'>
        <label>{this.props.t('tabSelection.title')}</label>
        {allowedTabs}
      </div>
    )
  }
}
