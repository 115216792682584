import React from 'react'
import {PureComponent} from 'react'
import Input from '../Input'
import SelectFormInput from '../SelectFormInput'

import _ from 'lodash'
import classNames from 'classnames'
import momentTz from 'moment-timezone'
import {generateSelectOptions, getFieldError} from '../../util'

import {connect} from 'react-redux'
import StudiesModule from '../../modules/Studies'
import UsersModule from '../../modules/Users'
import AccessRights from '../../models/AccessRights'
import SiteStudy from '../../models/SiteStudy'
import Studies from '../../models/Studies'
import Users from '../../models/Users'

const createTimezoneOptions = () => {
  return _.map(momentTz.tz.names(), name => {
    const nameWithOffset = `${name} (${momentTz()
      .tz(name)
      .format('Z')})`
    return {value: name, label: nameWithOffset}
  })
}

const timezoneNames = createTimezoneOptions()

interface Props {
  siteStudy: SiteStudy
  studies: Studies
  accessRights: AccessRights
  users: Users
  onChange: () => any
  getStudies: (queryParams?, reset?) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  t?: (key, params?) => any
}

interface State {
}

class SiteStudyDetails extends PureComponent<Props, State> {

  componentDidMount() {

    const {getStudies, siteStudy} = this.props

    getStudies()

    if (siteStudy.siteId) {
      this.getUsers()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {

    const {siteStudy} = this.props

    if (prevProps.siteStudy.siteId !== siteStudy.siteId) {
      this.getUsers()
    }
  }

  getUsers = () => {

    const {siteStudy, getUsers} = this.props

    getUsers(null, 'personnel', siteStudy.siteId)
  }

  onFieldChangeCallback = field => {
    return function(value) {
      this.props.onChange(this.props.siteStudy.set(field, value))
    }.bind(this)
  }

  createSelectInput = (field, writeAccess, options, multi?, placeholder?) => {

    const {siteStudy, onChange, t} = this.props

    return (
      <SelectFormInput
        key={'select-' + field}
        model={siteStudy}
        modelName='siteStudy'
        field={field}
        writeAccess={writeAccess}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange}
        labelClassName='col-xs-5 col-sm-4'
        fieldClassName='col-xs-7 col-sm-8'
        t={t}
      />
    )
  }

  getInputField = (field, writeAccess) => {
    const {siteStudy, t} = this.props

    const fieldError = getFieldError(field, siteStudy.validate(), siteStudy.error)

    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t(`siteStudyView.${field}`)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={siteStudy.get(field)}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    )
  }

  getTextareaField = (field, writeAccess) => {
    const {siteStudy, t} = this.props

    const fieldError = getFieldError(field, siteStudy.validate(), siteStudy.error)

    const wrapperClassName = classNames(['col-xs-7 col-sm-8', field])

    return (
      <Input
        key={'input_' + field}
        disabled={!writeAccess}
        label={t(`siteStudyView.${field}`)}
        labelClassName='col-xs-5 col-sm-4'
        error={fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={siteStudy.get(field)}
        wrapperClassName={wrapperClassName}
        type='textarea'
        t={t}
      />
    )
  }

  getStudyOptions = () => generateSelectOptions(this.props.studies.list, 'studyName', 'id')

  getUserLabel = user => user.visibleName()

  getPersonnelOptions = () => generateSelectOptions(this.props.users.list, this.getUserLabel, 'id')

  getLanguagesOptions = () => {
    const {studies, siteStudy, t} = this.props

    const selectedStudy = studies.getModelById(siteStudy.studyId)
    return selectedStudy
      ? generateSelectOptions(selectedStudy.languages, key => t('language.' + key))
      : []
  }

  render() {
    const {accessRights, siteStudy} = this.props

    let inputs = _.map(
      [
        'studyId',
        'number',
        'welcome',
        'studyCenterPhone',
        'principalDoctorPhone',
        'doctorOnDutyPhone',
        'personnelIds',
        'timezone',
        'language'
      ],
      field => {
        let writeAccess = accessRights.hasWriteAccess('site-study', siteStudy, field)

        if (writeAccess !== false && writeAccess !== true) {
          return null
        }

        switch (field) {
          case 'studyId':
            return this.createSelectInput(field, writeAccess, this.getStudyOptions(), false)
          case 'personnelIds':
            return this.createSelectInput(field, writeAccess, this.getPersonnelOptions(), true)
          case 'timezone':
            return this.createSelectInput(field, writeAccess, timezoneNames, false)
          case 'language':
            return this.createSelectInput(field, writeAccess, this.getLanguagesOptions(), false)
          case 'welcome':
            return this.getTextareaField(field, writeAccess)
          default:
            return this.getInputField(field, writeAccess)
        }
      }
    )

    return <form className='form-horizontal'>{inputs}</form>
  }
}

const mapActionCreators = {
  getStudies: StudiesModule.getModels,
  getUsers: UsersModule.getUsers
}

const mapStateToProps = ({studies, users}, _ownProps) => ({
  studies,
  users
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(SiteStudyDetails)
