import {List} from 'immutable'
import BaseModel from './BaseModel'
import {Feature} from './Feature'

const phoneValidation = {
  pattern: '\\+[1-9]\\d{1,14}',
  message: 'validate.invalidPhone'
}

const constraints = {
  studyId: {
    presence: {allowEmpty: false}
  },
  siteId: {
    presence: {allowEmpty: false}
  },
  number: {
    presence: {allowEmpty: false}
  },
  studyCenterPhone: {
    format: phoneValidation
  },
  principalDoctorPhone: {
    format: phoneValidation
  },
  doctorOnDutyPhone: {
    format: phoneValidation
  },
  timezone: {
    presence: {allowEmpty: false}
  },
  language: {
    presence: {allowEmpty: false}
  }
}

const interestedKeys = [
  'studyId',
  'siteId',
  'number',
  'personnelIds',
  'studyCenterPhone',
  'principalDoctorPhone',
  'doctorOnDutyPhone',
  'welcome'
]

const defaultValues = {
  studyId: undefined,
  siteId: undefined,
  number: undefined,
  studyCenterPhone: undefined,
  principalDoctorPhone: undefined,
  doctorOnDutyPhone: undefined,

  timezone: undefined,

  siteName: undefined,
  siteNumber: undefined,

  studyName: undefined,
  studyNumber: undefined,
  projectName: undefined,
  personnelIds: List(),
  features: List(),

  welcome: '',
  language: undefined
}

export default class SiteStudy extends BaseModel(defaultValues, interestedKeys, constraints)<SiteStudy> {
  studyId: number
  siteId: number
  number: number
  studyCenterPhone: string
  principalDoctorPhone: string
  doctorOnDutyPhone: string

  timezone: any

  siteName: any
  siteNumber: number

  studyName: any
  studyNumber: number
  projectName: any
  personnelIds: List<number>
  features: List<any>

  welcome: string
  language: any

  constructor(js?) {
    super(js)

    return this.setListArray(['personnelIds', 'features'], js) as SiteStudy
  }

  fromJS(js): SiteStudy {
    return new SiteStudy(js)
  }

  hasFeature(feature: Feature) {
    return this.features.includes(feature)
  }
}
