import BaseModel from './BaseModel'
import {fromJS, List} from 'immutable'
import Question from './Question'
import _ from 'lodash'
import {validate} from 'validate.js'
import {samePageAndOrder} from '../util'
import {QuestionComponentConstraintOperator} from './QuestionComponent'
import moment from 'moment'

const constraints = {
  order: {
    presence: {allowEmpty: false}
  },
  page: {
    presence: {allowEmpty: false}
  },
  value: {
    presence: {allowEmpty: true}
  }
}

const equalsKeys = ['order', 'page']

const defaultValues = {
  order: undefined,
  page: undefined,
  value: fromJS({})
}

export default class QuestionAnswer
  extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionAnswer> {

  static readonly targetAnswerNotFound = 'targetAnswerNotFound'
  static readonly notLessThan = 'notLessThan'
  static readonly notLessThanOrEqual = 'notLessThanOrEqual'
  static readonly notEqual = 'notEqual'
  static readonly notGreaterThan = 'notGreaterThan'
  static readonly notGreaterThanOrEqual = 'notGreaterThanOrEqual'

  order: number
  page: number
  value: any

  constructor(js?: any) {
    super(js)

    if (js.value) {
      return this.set('value', fromJS(js.value)) as QuestionAnswer
    }
  }

  fromJS(js: any): QuestionAnswer {
    return new QuestionAnswer(js)
  }

  getFieldValue(field: string) {
    if (this.value) {
      return this.value.get(field)
    }

    return null
  }

  validateWithQuestion(question: Question, answers: List<QuestionAnswer>) {
    const errors = super.validate()

    if (errors || !question) {
      return errors
    }

    const valueConstraints = question.components
      .filter(component => !component.isInstruction() && component.isMandatory())
      .map(component => component.getField())
      .reduce((accu, field) => {
        accu[field] = {
          presence: {allowEmpty: false}
        }

        return accu
      }, {})

    let validationErrors

    if (!_.isEmpty(valueConstraints)) {
      validationErrors = validate(this.value.toJS(), valueConstraints, {fullMessages: false})
    }

    if (question.hasCrossFieldConstraintFields()) {
      const crossFieldErrors = this.validateCrossField(question, answers)
      validationErrors = {...validationErrors, ...crossFieldErrors}
    }

    return _.isEmpty(validationErrors) ? undefined : validationErrors
  }

  validateCrossField(question: Question, answers: List<QuestionAnswer>) {

    const validationErrors = question.components
      .filter(component => !component.isInstruction() && component.isCrossFieldConstraint())
      .map(component => {

        const {operator, target} = component.getOptions()
        const targetAnswer = answers.find(answer => samePageAndOrder(answer, target))

        if (!targetAnswer) {
          return {[component.getField()]: [QuestionAnswer.targetAnswerNotFound]}
        }

        const sourceValue = this.value.get(component.getField())
        const targetValue = targetAnswer.value.get(target.field)
        let errorCode

        switch (operator) {
          case QuestionComponentConstraintOperator.lt:
            if (!moment(sourceValue).isBefore(targetValue)) {
              errorCode = QuestionAnswer.notLessThan
            }
            break
          case QuestionComponentConstraintOperator.lte:
            if (!moment(sourceValue).isSameOrBefore(targetValue)) {
              errorCode = QuestionAnswer.notLessThanOrEqual
            }
            break
          case QuestionComponentConstraintOperator.eq:
            if (!moment(sourceValue).isSame(targetValue)) {
              errorCode = QuestionAnswer.notEqual
            }
            break
          case QuestionComponentConstraintOperator.gt:
            if (!moment(sourceValue).isAfter(targetValue)) {
              errorCode = QuestionAnswer.notGreaterThan
            }
            break
          case QuestionComponentConstraintOperator.gte:
            if (!moment(sourceValue).isSameOrAfter(targetValue)) {
              errorCode = QuestionAnswer.notGreaterThanOrEqual
            }
            break
          default:
            break
        }

        return errorCode ? {[component.getField()]: [errorCode]} : undefined
      })
      .filter(error => !!error)
      .toArray()
      .reduce((accu, error) => ({...accu, ...error}), {})

    return validationErrors
  }
}
