import BaseModel from './BaseModel'
import {DownloadStatus} from './DownloadStatus'

const interestedKeys = ['id']

const defaultValues = {
  status: DownloadStatus.initial,
  userId: undefined,
  type: undefined
}

export default class Download extends BaseModel(defaultValues, interestedKeys)<Download> {
  status: DownloadStatus
  userId: number
  type: any

  fromJS(js): Download {
    return new Download(js)
  }
}
