import BaseModel from './BaseModel'
import I18n from '../services/I18n'
import {getTwoDigitLanguageCode} from '../util'
import _ from 'lodash'
import OrderedModel from './OrderedModel'
import {TranslationDetails} from './Period'

const constraints = {
  order: {
    presence: {allowEmpty: false}
  },
  name: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = ['order']

const defaultValues = {
  periodId: undefined,
  order: undefined,
  name: undefined
}

const constraintsByLanguage = lang => {
  return _.merge(
    {},
    constraints,
    {
      [`name.${lang}`]: {
        presence: {allowEmpty: false}
      }
    }
  )
}

export default class PeriodDay
  extends BaseModel(defaultValues, equalsKeys, constraints)<PeriodDay>
  implements OrderedModel<PeriodDay> {
  periodId: number
  order: number
  name: object

  fromJS(js: any): PeriodDay {
    return new PeriodDay(js)
  }

  getName() {
    return this.getLocalizedKey('name')
  }

  getPeriodId() {
    return this.get('periodId')
  }

  setPeriodId(periodId: number) {
    return this.set('periodId', periodId) as PeriodDay
  }

  getOrder() {
    return this.get('order')
  }

  setOrder(order: number) {
    return this.set('order', order) as PeriodDay
  }

  getField(key: string, lang: string) {
    const field = this.get(key)

    switch (key) {
      case 'name':
        return field && field[lang] ? field[lang] : ''
      default:
        return field
    }
  }

  setField(key: string, value: any, lang: string) {
    const oldValue = this.get(key)
    let newValue

    switch (key) {
      case 'name':
        newValue = {...oldValue, [lang]: value}
        break
      default:
        newValue = value
    }
    return this.set(key, newValue) as PeriodDay
  }

  validateByLanguage(lang: string) {

    return this._validate(lang ? constraintsByLanguage(lang) : constraints)
  }

  duplicateWithOrder(order: number): PeriodDay {
    return this.duplicate()
      .setOrder(order)
  }

  cleanupTranslations(defaultLanguage: string, additionalLanguages: string[]) {

    const nameLocalization = this.get('name')
    let model = this as PeriodDay

    if (_.isEmpty(nameLocalization && nameLocalization[defaultLanguage])) {

      model = model.set('name', undefined) as PeriodDay

    } else {

      const languages = [defaultLanguage].concat(additionalLanguages || [])
      const obsoleteLanguages = Object.keys(nameLocalization).filter(lang => !_.includes(languages, lang))

      model = model.set('name', _.omit(nameLocalization, obsoleteLanguages)) as PeriodDay
    }

    return model
  }

  getLanguageTranslations(language): TranslationDetails[] {

    const order = this.getOrder()
    const field = 'name'

    return [{
      order,
      field,
      value: this.getField(field, language)
    }]
  }

  private getLocalizedKey(key) {
    const language = getTwoDigitLanguageCode(I18n.language)

    if (this[key]) {
      return this[key][language] ? this[key][language] : this[key][Object.keys(this[key])[0]]
    }

    return I18n.t('questionnaire.noLocalization', {key, language})
  }
}
