/**
 * Created by Mauritz Untamala on 18/02/16.
 */
import React from 'react'
import {PureComponent} from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import _ from 'lodash'
import classNames from 'classnames'

import {connect} from 'react-redux'

const mapStateToProps = ({accessRights}, ownProps) => {
  const {accessRightsKey, model, enabled} = ownProps
  const enabledButton = accessRightsKey
    ? accessRights.hasWriteAccessToAnyField(accessRightsKey, model)
    : true

  return {
    enabled: _.isBoolean(enabled) ? enabled : enabledButton
  }
}

interface Props {
  model: any
  save: () => any
  onModelSaved: () => any
  accessRightsKey: string
  enabled: boolean
  t: (key, params?) => any
}

interface State {
  timeoutId: any
  animateEndOfTransition: boolean
}

class SaveButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      timeoutId: 0,
      animateEndOfTransition: false
    }
  }

  createUpdateButton(key) {
    const {enabled, model, t, save} = this.props

    return (
      <button
        key='label'
        className={classNames('btn', 'btn-default', 'btn-lg', 'save-btn', key)}
        onClick={save}
        disabled={!model.isValid() || enabled === false}>
        {t('button.' + key)}
      </button>
    )
  }

  getTransitionButton(key) {
    let content

    if (key === 'updated' || key === 'added') {
      content = <i className='fa fa-check'/>
    } else {
      content = this.props.t('button.' + key)
    }

    return (
      <button key={'label'} className={classNames('btn', 'btn-default', 'btn-lg', 'save-btn', key)}>
        {content}
      </button>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const {model, onModelSaved} = this.props
    const {animateEndOfTransition, timeoutId} = this.state

    if (prevProps.model.isSaving && !model.isSaving && !animateEndOfTransition) {
      this.setState({animateEndOfTransition: true})
    }

    if (animateEndOfTransition && !timeoutId) {
      const timeoutId = setTimeout(() => {
        this.setState({
          animateEndOfTransition: false,
          timeoutId: null
        })
      }, 1500)

      this.setState({timeoutId})
    } else if (prevState.animateEndOfTransition && !animateEndOfTransition && onModelSaved) {
      onModelSaved()
    }
  }

  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId)
    }
  }

  render() {
    const {model} = this.props
    let content

    if (model && model.isSaving) {
      content = this.getTransitionButton(model.error ? 'error' : model.id ? 'updating' : 'adding')
    } else if (this.state.animateEndOfTransition) {
      content = this.getTransitionButton(model.error ? 'error' : model.id ? 'updated' : 'added')
    } else if (model && model.id) {
      content = this.createUpdateButton('update')
    } else {
      content = this.createUpdateButton('add')
    }

    return (
      <TransitionGroup
        component='div'
        className='button-animation-wrapper'
        enter={true}
        exit={false}>
        <CSSTransition classNames='button-animation' timeout={2000}>
          {content}
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default connect(mapStateToProps)(SaveButton)
