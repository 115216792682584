import {List} from 'immutable'
import React, {PureComponent} from 'react'
import WearableStatusHourly from '../../../../models/WearableStatusHourly'
import Criteria from '../../../../models/Criteria'
import Results from './Results'
import {getFilterValue, getSortValue} from './TabComponents'
import {getTimelineData} from '../../../../util'
import TimelineColumn from '../../../../components/TimelineColumn'

const wearableHourlyColumns = [
  'siteStudyName',
  'person',
  'timeline'
]

interface Props {
  events: List<WearableStatusHourly>
  criteria: Criteria
  onCriteriaChanged: (criteria) => any
  defaultTimezone: string
  rowKey?: string
  showFilter?: boolean
  hasMore?: boolean
  loading?: boolean
  loadMore?: () => any
  onProcessedData?: () => any
  onRowClick?: (rowData) => any
  t: (key, params?) => any
}

interface State {}

export default class WearableTimeline extends PureComponent<Props, State> {

  getCustomComponent = field => {

    switch (field) {
      case 'timeline':
        return TimelineColumn
      default:
        return null
    }
  }

  getColumns = () => {
    const {t} = this.props

    return wearableHourlyColumns.map(field => {
      return {
        columnName: field,
        displayName: t(`column.${field}`),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: getFilterValue(this.props, field),
        sortValue: getSortValue(this.props, field),
        sortable: field !== 'timeline'
      }
    })
  }

  render() {
    const {events, criteria, defaultTimezone, ...rest} = this.props
    return (
      <Results columns={this.getColumns()}
               results={getTimelineData(events, criteria, undefined, defaultTimezone)}
               criteria={criteria}
               ref='results'
               {...rest}/>
    )

  }
}
