import ModelsModule from './ModelsModule'
import Smss from '../models/Smss'
import {SmsType} from '../models/SmsType'
import {bind} from '../util'

const initialState = new Smss()

export class SmssModule extends ModelsModule {
  constructor() {
    super('sms', initialState)
    bind(this, this.getAllOfType)
  }

  public getAllOfType(smsType?: SmsType) {
    const queryParams = {} as any

    if (smsType) {
      queryParams.smsType = smsType
    }

    return this.getModels(queryParams, true)
  }
}

export default new SmssModule()
