import ModelModule from './ModelModule'
import AdministrationPeriod from '../models/AdministrationPeriod'
import {get} from './ActionsCommon'
import {bind} from '../util'

export class AdministrationPeriodModule extends ModelModule<AdministrationPeriod> {
  constructor() {
    super('event/administration/next-period', 'period')
    bind(this, this.getNextPeriod)
  }

  protected getInitialState = () => new AdministrationPeriod()

  public getNextPeriod(type) {
    return (dispatch) => {
      dispatch(this.getModelRequestAction())

      return dispatch(get('event', ['administration', type, 'next-period']))
        .then(({period, rescheduled}) =>
          dispatch(this.getModelSuccessAction({period, rescheduled, type}))
        )
        .catch((error) => dispatch(this.handleError(this.getModelFailedAction, error)))
    }
  }
}

export default new AdministrationPeriodModule()
