import React from 'react'
import {Component} from 'react'
import {getAdministrationTypeValue} from '../../../../components/CommonFilterValueFunctions'

interface Props {
  data: any
  t?: (key: string, params?: object) => any
}

interface State {}

export default class TypeColumn extends Component<Props, State> {
  render() {
    const {data, t} = this.props
    return <span>{getAdministrationTypeValue(data, null, null, t)}</span>
  }
}
