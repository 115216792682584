import React from 'react'
import Input from './Input'
import {hashCode, isEmptyHash} from '../util'
import classNames from 'classnames'
import Translation from '../models/Translation'

interface Props {
  type: string
  field: string
  fieldValue: string
  disabled: boolean
  error: any
  translation: Translation
  enableTranslationFeature: boolean
  onFieldChange: (newValue) => any
  onTranslationChange: (newTranslation) => any
  t: (key, args?) => any

  label?: (() => string | React.ReactNode) | (string | React.ReactNode)
  translationLabel?: string
  placeholder?: string
  forceShowError?: boolean
  fieldInputClassName?: string
  translationInputClassName?: string
  groupClassName?: string
  wrapperClassName?: string
  labelClassName?: string
  translationLabelClassName?: string
}

interface State {
  contentHash
  translationHash
}

class InputWithTranslation extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    const {enableTranslationFeature, fieldValue, translation} = props

    this.state = {
      contentHash: enableTranslationFeature ? hashCode(fieldValue) : undefined,
      translationHash: translation ? translation.getTranslationHash() : undefined
    }
  }

  componentDidUpdate(prevProps: Props) {

    const {fieldValue, translation, enableTranslationFeature} = this.props

    if (enableTranslationFeature) {
      if (fieldValue !== prevProps.fieldValue) {

        this.setState({contentHash: fieldValue ? hashCode(fieldValue) : undefined})
      }

      const translationValue = translation.getTranslation()

      if (translation && translationValue && translation !== prevProps.translation) {
        this.setState({translationHash: hashCode(translationValue)})
      }
    }
  }

  renderFieldInput = () => {

    const {
      fieldValue,
      onFieldChange,
      disabled,
      error,
      groupClassName,
      wrapperClassName,
      label,
      labelClassName,
      forceShowError
    } = this.props

    const textInputAdditionalProps = {
      groupClassName,
      wrapperClassName,
      labelClassName,
      forceShowError
    }

    return this.renderInput(fieldValue, onFieldChange, disabled, textInputAdditionalProps, label, error)
  }

  renderTranslationComponent = () => {

    const {
      enableTranslationFeature,
      translation,
      t,
      groupClassName,
      wrapperClassName,
      translationLabel,
      translationLabelClassName
    } = this.props

    if (!enableTranslationFeature && !translation) {
      return null
    }

    const {contentHash, translationHash} = this.state

    const hasError = !isEmptyHash(translationHash)
      && translation.getTranslationHash() === translationHash
      && translation.getContentHash() !== contentHash

    const fieldError = hasError
      ? t('translation.updatedAfterTranslation')
      : undefined

    const translationValue = translation.getTranslation()
    const disabled = isEmptyHash(contentHash) && isEmptyHash(translationHash)

    const label = translationLabel || t('translation.title')

    const textInputAdditionalProps = {
      groupClassName: classNames(groupClassName, 'input-translation__translation'),
      labelClassName: classNames(translationLabelClassName, 'input-translation__label'),
      wrapperClassName: wrapperClassName
    }

    return this.renderInput(
      translationValue,
      this.onTranslationChange,
      disabled,
      textInputAdditionalProps,
      label,
      fieldError
    )
  }

  onTranslationChange = value => {
    const {onTranslationChange, translation} = this.props

    onTranslationChange(translation.setTranslation(value))
  }

  renderInput = (value, onChange, disabled, textInputAdditionalProps, label?, error?) => {

    const {type, field, placeholder, t} = this.props

    return (
      <Input
        label={label}
        type={type ? type : 'text'}
        name={field}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        error={error}
        t={t}
        {...textInputAdditionalProps}
      />
    )
  }

  render() {

    const {fieldInputClassName, translationInputClassName} = this.props

    return (
      <div className='row input-with-translation'>
        <div className={classNames('field-input', fieldInputClassName)}>
          {this.renderFieldInput()}
        </div>
        <div className={classNames('translation-input', translationInputClassName)}>
          {this.renderTranslationComponent()}
        </div>
      </div>
    )
  }
}

export default InputWithTranslation
