import _ from 'lodash'
import Config from '../config'
import i18n from 'i18next'
import moment from 'moment'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {registerCustomValidators} from './validators'

const resources = require('../locales/index.js')

export function reload(lng) {
  const res = _.cloneDeep(resources)

  if (res[lng]) {
    Object.keys(res[lng]).forEach(ns => {
      i18n.addResourceBundle(lng, ns, res[lng][ns])
    })
  }
}

export const DEFAULT_LANGUAGE = 'en'

export function initI18n() {
  i18n
    .use(XHR)
    .use(LanguageDetector)
    .init(
      {
        fallbackLng: DEFAULT_LANGUAGE,

        // have a common namespace used around the full app
        ns: ['common'],
        defaultNS: 'common',

        debug: Config.env.toString() === 'development',

        resources: _.cloneDeep(resources),

        interpolation: {
          escapeValue: false, // not needed for react!!
          formatSeparator: ',',
          prefix: '__',
          suffix: '__',
          format: (value, format) => {
            if (format === 'uppercase') {
              return value.toUpperCase()
            }

            return value
          }
        }
      },
      () => {
        moment.locale(i18n.language)
        registerCustomValidators()
      }
    )
}

export default i18n
