import BaseModel from './BaseModel'

const constraints = {
  studyId: {
    presence: {allowEmpty: false}
  },
  language: {
    presence: {allowEmpty: false}
  },
  namespace: {
    presence: {allowEmpty: false}
  },
  bundle: {
    presence: {allowEmpty: false}
  }
}

const equalsKeys = null

const defaultValues = {
  studyId: undefined,
  language: undefined,
  namespace: undefined,
  bundle: undefined
}

class Localization extends BaseModel(defaultValues, equalsKeys, constraints)<Localization> {
  studyId: number
  language: string
  namespace: string
  bundle: string

  fromJS(js): Localization {
    return new Localization(js)
  }
}

export default Localization
