/**
 * Created by Mauritz Untamala on 04/11/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Select from 'react-select'

import _ from 'lodash'

interface Props {
  selectedOptions: any
  options: any
  onChange: (value) => any
  t: (key, params?) => any
  name: string

  id?: string
  label?: string | ((model) => any)
  className?: string
  placeholder?: string
}

interface State {
}

export default class ImmutableSelect extends PureComponent<Props, State> {
  getLabel = object => {
    const {label} = this.props

    if (_.isFunction(label)) {
      return label(object)
    }

    return object[label]
  }

  getOptions = () => {
    return this.props.options
      .map(object => {
        return {
          label: this.getLabel(object),
          value: object.id
        }
      })
      .toArray()
  }

  getSelectedOptions = availableOptions => {
    const selectedOptions = this.props.selectedOptions.toArray()

    return availableOptions.filter(({value}) => _.includes(selectedOptions, value))
  }

  onChange = selectedOption => {
    const selectedOptionIds = _.map(
      _.isArray(selectedOption) ? selectedOption : [selectedOption],
      'value'
    )

    this.props.onChange(selectedOptionIds)
  }

  getPlaceholder = () => {
    const {t, placeholder} = this.props

    return placeholder ? placeholder : t('select')
  }

  render() {
    const {name, t} = this.props
    const options = this.getOptions()
    const value = this.getSelectedOptions(options)

    return (
      <Select key={`immutable-select-${name}`}
              name={name}
              isMulti={true}
              isClearable={true}
              options={options}
              value={value}
              onChange={this.onChange}
              placeholder={this.getPlaceholder()}
              noOptionsMessage={() => t('noOptions')}/>
    )
  }
}
