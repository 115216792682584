import React, {PureComponent} from 'react'
import Input from '../../../../components/Input'
import {getFieldError} from '../../../../util'
import _ from 'lodash'

import PeriodDay from '../../../../models/PeriodDay'

interface Props {
  periodDay: PeriodDay
  language: string
  onChange: (periodDay) => any
  onDelete: (periodDay) => any
  t: (key, params?) => any
}

interface State {
}

export default class PeriodDayView extends PureComponent<Props, State> {

  onFieldChange = (field, value, ignoreLocalization = false) => {

    const {periodDay, language, onChange} = this.props
    onChange(ignoreLocalization ?
      periodDay.set(field, value) :
      periodDay.setField(field, value, language))
  }

  onNameChange = (value) => {
    this.onFieldChange('name', value)
  }

  renderName = () => {
    const {periodDay, language, t} = this.props
    const field = 'name'

    const fieldError = getFieldError(
      `${field}.${language}`,
      periodDay.validateByLanguage(language),
      periodDay.error
    )

    return (
      <Input
        groupClassName='input-field col-xs-11'
        key={`input-${field}`}
        error={fieldError}
        onChange={this.onNameChange}
        value={periodDay.getField(field, language)}
        t={t}
        autoFocus={!periodDay.id}
      />
    )
  }

  onDelete = () => {

    const {periodDay, onDelete} = this.props

    onDelete(periodDay)
  }

  renderActions = () => {

    const {t} = this.props

    return (
      <div className='period-day-actions'>
        <i
          className='fa fa-trash-o delete'
          title={t('delete')}
          onClick={this.onDelete}/>
      </div>
    )
  }

  render() {
    const {periodDay: {order}} = this.props

    return (
      <div className='period-day-form' key={`question-${order}`}>
        <div className='period-day-top'>
          <h5 className='period-day-order'>{order}.</h5>
          <div className='draggable-icon'>
            {_.times(3, i => <i key={i}/>)}
          </div>
          {this.renderActions()}
        </div>
        <div className='name-row'>
          {this.renderName()}
        </div>
      </div>
    )
  }
}
