/**
 * Created by Mauritz Untamala on 20/02/16.
 */
import React from 'react'
import {Component} from 'react'

import _ from 'lodash'
import classNames from 'classnames'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import './Tabs.less'

interface Props extends WithNamespaces {
  context: string
  tabIds: string[]
  tabIdsWithError?: string[]
  activeTab: string
  onTabClick: (tab, event) => any
}

interface State {}

class Tabs extends Component<Props, State> {

  hasTabError = tab => _.includes(this.props.tabIdsWithError, tab)

  isActiveTab = tab => this.props.activeTab === tab

  createTabs = () => {
    return _.map(this.props.tabIds, tab => {
      const classes = classNames({
        active: this.isActiveTab(tab),
        'has-error': this.hasTabError(tab)
      })

      return (
        <li key={tab} className={classes}>
          <a data-toggle='tab' onClick={this.props.onTabClick.bind(null, tab)}>
            {this.props.t(this.props.context + '.' + tab)}
          </a>
        </li>
      )
    })
  }

  render() {
    return (
      <div className='tabs-container'>
        <ul className='nav nav-tabs'>{this.createTabs()}</ul>
        <div className='tab-content'>
          <div id={this.props.activeTab} className='tab-pane fade in active'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default withNamespaces(['common'], {wait: true})(Tabs)
