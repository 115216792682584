import NavigationLayout from '../../../layouts/NavigationLayout'
import Questionnaires from './Questionnaires'
import SelectQuestionnaire from './SelectQuestionnaire'
import QuestionnaireAnswer from './QuestionnaireAnswer'

export default {
  childRoutes: [
    {
      component: NavigationLayout,
      childRoutes: [{path: '/questionnaires', component: Questionnaires}]
    },
    {path: '/questionnaires/select', component: SelectQuestionnaire},
    {path: '/questionnaires/:qid/answer', component: QuestionnaireAnswer},
    {path: '/questionnaires/:qid/answer/:id', component: QuestionnaireAnswer}
  ]
}
