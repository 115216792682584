import AuditTrails from '../models/AuditTrails'
import ModelsModule from './ModelsModule'
import {get} from './ActionsCommon'
import {PaginationContext} from '../models/Pagination'
import {downloadFailed, downloadSuccess} from './Download'

const initialState = new AuditTrails()

class AuditTrailsModule extends ModelsModule {
  constructor() {
    super('audit', initialState, PaginationContext.AUDIT_TRAIL)
  }

  public downloadAuditTrails(criteria) {
    return (dispatch) => {
      return dispatch(get('audit', 'download', criteria.getQueryParams()))
        .then((response) => dispatch(downloadSuccess(response)))
        .catch((error) => dispatch(this.handleError(downloadFailed, error)))
    }
  }
}

export default new AuditTrailsModule()
