import Message from './Message'
import Collection from './ImmutableCollection'

export default class Messages extends Collection(Message)<Message> {
  public getDedicatedMessages(dedicatedSubjectId, replyRole) {
    return this.list.filter((message) => {
      return message.dedicatedSubjectId === dedicatedSubjectId && message.replyRole === replyRole
    })
  }
}
