import React from 'react'
import SanitizedHTML from 'react-sanitized-html'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'

interface Props extends BaseQuestionComponentProps {
}

interface State extends BaseQuestionComponentState {
}

export default class Instruction extends BaseQuestionComponent<Props, State> {

  getContent() {
    const content = this.getLocalization()

    return (
      <div className='question-instruction'>
        <SanitizedHTML html={content}/>
      </div>
    )
  }
}
