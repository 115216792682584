import React from 'react'
import {Component, PureComponent} from 'react'
import TableComponent from '../../components/TableComponent'

import _ from 'lodash'
import {WithNamespaces, withNamespaces} from 'react-i18next'

import {connect} from 'react-redux'
import {navigate} from '../../modules/Location'
import StudyGroupsModule from '../../modules/StudyGroups'
import StudyGroupModule from '../../modules/StudyGroup'
import App from '../../models/App'
import StudyGroupsModel from '../../models/StudyGroups'
import User from '../../models/User'
import {UIAction} from '../../models/UIAction'

import '../../styles/study-group.less'

interface ActionsColumnProps {
  app: App
  rowData: any
  deleteGroup: (group) => any
}

interface ActionsColumnState {}

class ActionsColumn extends Component<ActionsColumnProps, ActionsColumnState> {
  delete(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.deleteGroup(this.props.rowData._src)
  }

  getActions() {
    const actions = []

    if (this.props.app.hasAccessToAction(UIAction.DELETE_STUDY_GROUP)) {
      actions.push(
        <button
          key={'delete_group_' + this.props.rowData.id}
          className='btn btn-default'
          onClick={this.delete}
          role='button'>
          <i className='fa fa-trash-o'/>
        </button>
      )
    }

    return actions
  }

  render() {
    return <div className='actions-wrapper'>{this.getActions()}</div>
  }
}

interface Props extends WithNamespaces {
  app: App
  user: User
  studyGroups: StudyGroupsModel
  getGroups: (queryParams?, reset?) => any
  deleteGroup: (group) => any
  navigate: (url: string, silent?: boolean) => any
}

interface State {}

export class StudyGroups extends PureComponent<Props, State> {
  componentDidMount() {
    this.props.getGroups(null, true)
  }

  componentDidUpdate(prevProps) {
    const {user, getGroups} = this.props

    if (prevProps.user !== user && !user.isLoading && !user.isSaving) {
      getGroups(null, true)
    }
  }

  getResults = () => this.props.studyGroups.list.map(o => _.extend(o.toJS(), {_src: o})).toArray()

  getColumnMetadata = () => {
    const {t} = this.props

    return [
      {
        columnName: 'name',
        displayName: t('column.name'),
        order: 0,
        locked: false,
        visible: true,
        cssClassName: 'name-column'
      },
      {
        columnName: 'actions',
        displayName: t('column.actions'),
        cssClassName: 'actions-column',
        customComponent: props => <ActionsColumn {...this.props} {...props} />,
        sortable: false
      }
    ]
  }

  addStudyGroup = () => this.props.navigate('/admin/study-group/new')

  showStudyGroup = rowData => this.props.navigate('/admin/study-group/' + rowData.id)

  getAdderView = () => {
    if (this.props.app.hasAccessToAction(UIAction.ADD_STUDY_GROUP)) {
      return (
        <button className='btn btn-default add-model pull-right' onClick={this.addStudyGroup}>
          {this.props.t('button.addStudyGroup')}
        </button>
      )
    }
  }

  render() {

    const {t} = this.props

    return (
      <div className='study-groups-view'>
        <h1>{t('studyGroupsView.title')}</h1>
        <div className='table-panel'>
          <TableComponent
            key='results'
            ref='results'
            data={this.getResults()}
            showFilter={true}
            rowKey={'id'}
            columns={this.getColumnMetadata()}
            useFixedHeader={true}
            onRowClick={this.showStudyGroup}
            topContent={this.getAdderView()}
            t={t}
          />
        </div>
      </div>
    )
  }
}

const mapActionCreators = {
  getGroups: StudyGroupsModule.getModels,
  deleteGroup: StudyGroupModule.deleteModel,
  navigate
}

const mapStateToProps = ({authenticatedUser, studyGroups, app}, _ownProps) => ({
  user: authenticatedUser,
  studyGroups,
  app
})

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionCreators
)(StudyGroups))
