import React from 'react'
import {Component} from 'react'
import UsersView from '../../components/UsersView'

interface Props {
  location: string
}

interface State {}

export default class Subjects extends Component<Props, State> {
  render() {
    return <UsersView key='subjects-view' location={this.props.location} subjects={true}/>
  }
}
