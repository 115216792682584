import React, {FunctionComponent} from 'react'
import {getDataStatusColor} from '../util'
import TimelineChart from './TimelineChart/TimelineChart'
import {getTimestampWithTimezone} from './CommonFilterValueFunctions'

interface Props {
  rowData: any
  data?: any
  column?: any
  children?: any
  t?: any
}

const TimelineColumn: FunctionComponent<Props> = (props) => {

  const {data, t} = props

  const timelineData = data.map(item => {

    const {id, timezone, startTime, endTime, percentage: {value, level}} = item
    const color = getDataStatusColor(level)
    const formattedStartTime = getTimestampWithTimezone(startTime, timezone)
    const formattedEndTime = getTimestampWithTimezone(endTime, timezone)
    const percentageText = parseFloat(value) >= 0 ? `${value}%` : t('reportsView.notAvailable')

    return {
      id,
      key: id,
      tooltipText: `${formattedStartTime} - ${formattedEndTime}: ${percentageText}`,
      color
    }
  })

  return <TimelineChart data={timelineData}/>
}

export default TimelineColumn
