import ModelModule from './ModelModule'
import Localization from '../models/Localization'

export class LocalizationModule extends ModelModule<Localization> {
  constructor() {
    super('localization')
  }

  protected getInitialState = () => new Localization()
}

export default new LocalizationModule()
