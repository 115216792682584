/**
 * Created by Mauritz Untamala on 24/11/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Toggle from 'react-toggle'

import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import {setTimeNow} from '../../util'

import './TimePicker.less'

interface Props {
  time: any
  dateOnly: boolean
  showDateOnlyToggle: boolean
  showNow: boolean
  showReset: boolean
  disabled: boolean
  onChange: (value) => any
  error: object | string
  t: (key, params?) => any
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

interface State {
  time: moment.Moment
  dateOnly: boolean
}

class TimePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.getNextState(props)
  }

  componentDidUpdate(prevProps: Props) {
    const {time: prevTime, dateOnly: prevDateOnly} = prevProps
    const {time, dateOnly} = this.props

    if (prevTime !== time || prevDateOnly !== dateOnly) {
      this.setState(this.getNextState(this.props))
    }
  }

  getNextState = (props: Props) => {
    const time = _.isNil(props.time) ? moment() : moment(props.time)

    return {
      time: time.startOf('minute'),
      dateOnly: !!props.dateOnly
    }
  }

  onChange = (newState: State, changeProps?: State) => {
    this.setState(newState, () => this.props.onChange(changeProps || newState))
  }

  onTimeChange = (time) => {
    const {dateOnly} = this.state

    if (_.isNil(time)) {
      return this.onChange({time: moment(), dateOnly}, {time, dateOnly})
    }

    const {minDate, maxDate} = this.props

    if (minDate && minDate.isAfter(time)) {
      time = minDate.clone()
    }

    if (maxDate && maxDate.isBefore(time)) {
      time = maxDate.clone()
    }

    return this.onChange({time, dateOnly})
  }

  add = (field, increment) => {
    const self = this

    return function incrementField(event) {
      event.stopPropagation()
      event.preventDefault()

      self.onTimeChange(self.state.time.add(increment, field))
    }
  }

  toggleDateOnly = () => {
    const dateOnly = !this.state.dateOnly
    const time = this.state.time.clone()
    setTimeNow(time)

    this.onChange({time, dateOnly})
  }

  getDateOnlyToggle = () => {
    if (this.props.showDateOnlyToggle === false) {
      return
    }

    return (
      <div className='time-type'>
        <Toggle
          key='time-type'
          id='time-type'
          defaultChecked={this.state.dateOnly}
          disabled={this.props.disabled}
          onChange={this.toggleDateOnly}
        />
        <span>{this.props.t('toggleDateOnly')}</span>
      </div>
    )
  }

  getNowButton = () => {
    const {showNow, disabled, t} = this.props
    if (!showNow) {
      return
    }

    const setTimeNow = () => this.onTimeChange(moment())

    const className = classNames({
      'cursor-pointer': !disabled
    })

    return (
      <a className={className} onClick={disabled ? null : setTimeNow}>
        {t('now')}
      </a>
    )
  }

  getResetButton = () => {
    const {showReset, disabled, t} = this.props
    if (!showReset) {
      return
    }

    const setTimeUnset = () => this.onTimeChange(null)

    const className = classNames({
      'cursor-pointer': !disabled
    })

    return (
      <a className={className} onClick={disabled ? null : setTimeUnset}>
        {t('reset')}
      </a>
    )
  }

  getTimeControls = () => {
    const dateOnlyToggle = this.getDateOnlyToggle()
    const now = this.getNowButton()
    const reset = this.getResetButton()

    if (!dateOnlyToggle && !now && !reset) {
      return
    }

    return (
      <div className='time-controls'>
        {dateOnlyToggle}
        {now}
        {reset}
      </div>
    )
  }

  getTimeComponent = (className, field, format) => {
    return (
      <div className={className}>
        <button
          disabled={this.props.disabled}
          className='time-picker-arrow-up'
          onClick={this.add(field, 1)}
        />
        {this.state.time.format(format)}
        <button
          disabled={this.props.disabled}
          className='time-picker-arrow-down'
          onClick={this.add(field, -1)}
        />
      </div>
    )
  }

  render() {
    const {time, dateOnly} = this.state
    const {disabled} = this.props

    const daysClasses = classNames({
      'col-xs-3': true,
      'col-xs-offset-3': !!dateOnly
    })

    const hoursAndMinutesClasses = classNames({
      'col-xs-3': true,
      hidden: !!dateOnly
    })

    return (
      <div>
        <div className='row year-picker'>
          <button
            className='year-picker-arrow-prev'
            disabled={disabled}
            onClick={this.add('years', -1)}
          />
          {time.year()}
          <button
            className='year-picker-arrow-next'
            disabled={disabled}
            onClick={this.add('years', 1)}
          />
        </div>
        <div className='row time-picker'>
          {this.getTimeComponent(daysClasses, 'days', 'DD.')}
          {this.getTimeComponent('col-xs-3', 'months', 'MM.')}
          {this.getTimeComponent(hoursAndMinutesClasses, 'hours', 'HH')}
          {this.getTimeComponent(hoursAndMinutesClasses, 'minutes', 'mm')}
        </div>
        {this.getTimeControls()}
      </div>
    )
  }
}

export default TimePicker