import React from 'react'
import {Component} from 'react'
import SubjectAdverseEventView from '../../components/SubjectAdverseEventView'

interface Props {
  params: any
  location: string
}

interface State {}

export default class AdverseEvent extends Component<Props, State> {
  render() {
    const {params, location} = this.props

    return (
      <SubjectAdverseEventView
        key='investigator-adverse-event-view'
        backHref='/admin/reports?tab=adverseEvents'
        eventId={parseInt(params.id, 10)}
        location={location}
        informationEdit={true}
      />
    )
  }
}
