import React from 'react'
import _ from 'lodash'
import {List} from 'immutable'
import {Checkbox, FormGroup} from 'react-bootstrap'
import BaseQuestionComponent, {BaseQuestionComponentProps, BaseQuestionComponentState} from './BaseQuestionComponent'
import SanitizedHTML from 'react-sanitized-html'

interface Props extends BaseQuestionComponentProps {
}

interface State extends BaseQuestionComponentState {
}

export default class MultiSelect extends BaseQuestionComponent<Props, State> {

  onChange = (option, event) => {
    const {answer, field, onChange} = this.props
    const value = this.getAnswerValue()

    let updatedValue = null

    if (event.target.checked) {
      if (value) {
        if (!value.includes(option)) {
          updatedValue = answer.value.set(field, value.push(option))
        }
      } else {
        updatedValue = answer.value.set(field, List.of(option))
      }
    } else {
      if (value.includes(option)) {
        updatedValue = answer.value.set(field, value.delete(value.indexOf(option)))
      }
    }

    if (updatedValue) {
      onChange(answer.set('value', this.compactRecord(updatedValue)))
    }
  }

  renderOptions = () => {
    const {component, disabled} = this.props
    const options = component.getOptions()
    const value = this.getAnswerValue()
    const localization = this.getLocalization()

    if (_.isEmpty(options)) {
      return null
    }

    return options.map(option => (
      <Checkbox
        key={option}
        disabled={disabled}
        value={option}
        checked={value && value.includes(option)}
        onChange={event => this.onChange(option, event)}>
        <SanitizedHTML allowedTags={['b']} html={localization[option]}/>
      </Checkbox>
    ))
  }

  getContent() {

    const {fieldError} = this.props

    return (
      <FormGroup validationState={fieldError ? 'error' : null}>
        {this.renderOptions()}
        {this.getError(fieldError)}
      </FormGroup>
    )
  }
}
