/**
 * Created by Mauritz Untamala on 26/12/15.
 */
import React from 'react'
import {PureComponent} from 'react'
import Criteria from './Criteria'
import TableComponent from '../../../components/TableComponent'
import TimestampColumn from '../../../components/TimestampColumn'
import SeverityCausalityOutcomeColumn from '../../../components/SeverityCausalityOutcomeColumn'
import {
  getSeverityCausalityOutcomeFilterValue,
  getTimestampFilterValue,
  getTimestampSortValue
} from '../../../components/CommonFilterValueFunctions'

import _ from 'lodash'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import {ROLES} from '../../../config/constants'

import {connect} from 'react-redux'
import {navigate} from '../../../modules/Location'
import AdverseEventsModule from '../../../modules/AdverseEvents'
import UsersModule from '../../../modules/Users'
import CriteriaModel from '../../../models/Criteria'
import UsersModel from '../../../models/Users'
import AdverseEventsModel from '../../../models/AdverseEvents'

import '../../../styles/events.less'

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '')

  if (hasParams) {
    return CriteriaModel.fromQuery(query)
  } else {
    return CriteriaModel.getInitialCriteria()
  }
}

interface Props extends WithNamespaces {
  users: UsersModel
  events: AdverseEventsModel
  criteria: CriteriaModel
  location: string
  updateCriteria: (location, criteria) => any
  getUsers: (role?, type?, siteId?, siteStudyIds?) => any
  navigate: (url: string) => any
  getModels: (queryParams, reset) => any
}

interface State {
}

class AdverseEvents extends PureComponent<Props, State> {

  componentDidMount() {
    const {getUsers, getModels, criteria} = this.props
    getUsers(ROLES.SUBJECT)
    getModels(criteria.getQueryParams(), true)
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {

    const {getModels, criteria} = this.props

    if (!prevProps.criteria.isEqual(criteria)) {
      getModels(criteria.getQueryParams(), true)
    }
  }

  renderUserColumn = ({data}) => {
    const {users, t} = this.props
    let user = users.getModelById(data)
    const identifier = user ? user.visibleName() : t('unknown')

    return <span>{identifier}</span>
  }

  getCustomComponent = field => {
    switch (field) {
      case 'personId':
      case 'personnelId':
        return this.renderUserColumn
      case 'started':
      case 'ended':
      case 'ecrfTransferTime':
        return TimestampColumn
      case 'severityCausalityOutcome':
        return SeverityCausalityOutcomeColumn
      default:
        return null
    }
  }

  getFilterValue = field => {
    const getUserVisibleName = (userId, _object, _field, t) => {
      const user = this.props.users.getModelById(userId)
      return user ? user.visibleName() : t('unknown')
    }

    switch (field) {
      case 'personId':
        return getUserVisibleName
      case 'started':
      case 'ended':
      case 'ecrfTransferTime':
        return getTimestampFilterValue
      case 'severityCausalityOutcome':
        return getSeverityCausalityOutcomeFilterValue
      default:
        return null
    }
  }

  getSortValue = field => {
    switch (field) {
      case 'started':
      case 'ended':
      case 'ecrfTransferTime':
        return getTimestampSortValue
      default:
        return this.getFilterValue(field)
    }
  }

  getColumns = () => {
    return [
      'personId',
      'started',
      'ended',
      'description',
      'severityCausalityOutcome',
      'personnelComment',
      'ecrfTransferTime'
    ].map(field => {
      return {
        columnName: field,
        displayName: this.props.t('column.' + field),
        locked: false,
        visible: true,
        customComponent: this.getCustomComponent(field),
        filterValue: this.getFilterValue(field),
        sortValue: this.getSortValue(field)
      }
    })
  }

  onRowClick = rowData => this.props.navigate('/admin/adverse-event/' + rowData.id)

  onCriteriaChange = criteria => {
    const {location, updateCriteria} = this.props
    updateCriteria(location, criteria)
  }

  render() {
    const columns = this.getColumns()
    const {t, users, events, criteria} = this.props

    return (
      <div className='users-view-container'>
        <h1>{t('adverseEvents.title')}</h1>

        <Criteria
          users={users.list}
          criteria={criteria}
          onCriteriaChange={this.onCriteriaChange}
          t={t}/>

        <div className='table-panel'>
          <TableComponent
            key={'users_' + columns.length}
            data={events.list.toArray()}
            showFilter={true}
            rowKey={'id'}
            columns={columns}
            onRowClick={this.onRowClick}
            useFixedHeader={true}
            t={t}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({adverseEvents, users}, ownProps) => {
  const criteria = getCriteria(ownProps.location)

  return {
    criteria,
    users,
    events: adverseEvents
  }
}

const mapActionToProps = {
  getUsers: UsersModule.getUsers,
  updateCriteria: AdverseEventsModule.updateCriteria,
  getModels: AdverseEventsModule.getModels,
  navigate
}

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(AdverseEvents))
