import React from 'react'
import BaseDownloadComponent, {BaseProps, BaseState} from '../../../components/BaseDownloadComponent'
import {DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd'
import QuestionnaireListItem from './QuestionnaireListItem'
import Dropzone from 'react-dropzone'

import {connect} from 'react-redux'
import Questionnaires from '../../../modules/Questionnaires'
import QuestionnairesModel from '../../../models/Questionnaires'
import Questionnaire from '../../../modules/Questionnaire'
import Periods from '../../../modules/Periods'
import PeriodsModel from '../../../models/Periods'
import {navigate} from '../../../modules/Location'
import {error} from '../../../modules/Notifications'
import User from '../../../models/User'
import {downloadResource, pollDownload} from '../../../modules/Download'

import {withNamespaces} from 'react-i18next'
import {List} from 'immutable'
import _ from 'lodash'
import qs from 'qs'

import './Questionnaires.less'
import CriteriaModel from '../../../models/Criteria'
import Criteria from '../../../models/Criteria'
import {parseIntOrNull} from '../../../util'

const ZipMimeTypes = 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip'

export interface IndexMapEntry {
  questionnaireId: number
  periodDayId: number
}

interface Props extends BaseProps {
  authenticatedUser: User,
  questionnaires: QuestionnairesModel
  periods: PeriodsModel
  criteria: Criteria
  navigate: (url) => any
  getQuestionnaires: (id, reset) => any
  updateCriteria: (criteria) => any
  getPeriods: (queryparams?, reset?) => any
  exportQuestionnaires: (ids: List<number>) => any
  deleteQuestionnaire: (model) => any
  changeOrder: (questionnaireId, srcOrder, dstOrder) => any
  importQuestionnaires: (file) => any
}

interface State extends BaseState {
  holdingShift: boolean
  lastSelectedIdx: number
  listItems: List<IndexMapEntry>
}

const draggingListBackground = '#f0f0f0'

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? draggingListBackground : undefined
})

const shiftKey = 'Shift'

const getIndexRange = (start: number, end: number): number[] => {

  if (start <= end) {
    return _.range(start, end + 1)
  }

  return _.range(end, start + 1)
}

class QuestionnairesView extends BaseDownloadComponent<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      download: undefined,
      pollerId: undefined,
      holdingShift: false,
      lastSelectedIdx: undefined,
      listItems: this.getListItems(props)
    }
  }

  componentDidMount() {

    const {getPeriods, getQuestionnaires} = this.props

    getPeriods()
    getQuestionnaires(null, true)

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

    super.componentDidUpdate(prevProps, prevState)

    const {authenticatedUser, getQuestionnaires, questionnaires, periods, updateCriteria, criteria} = this.props

    if (authenticatedUser.selectedSiteStudyId !== prevProps.authenticatedUser.selectedSiteStudyId) {

      this.setState({lastSelectedIdx: undefined})
      updateCriteria(criteria.set('selected', List()))
      getQuestionnaires(null, true)
    }

    if (
      !questionnaires.isLoading &&
      !periods.isLoading &&
      (questionnaires !== prevProps.questionnaires || periods !== prevProps.periods)
    ) {
      this.setState({
        listItems: this.getListItems(this.props)
      })
    }
  }

  componentWillUnmount() {

    super.componentWillUnmount()

    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  }

  getSortedQuestionnaires = () => {
    return this.props.questionnaires.list.sort((e1, e2) => e1.order - e2.order)
  }

  isSelected = (idx: number): boolean => {
    const entry = this.state.listItems.get(idx)

    return !!this.props.criteria.selected.find(value => {
      const values = value.split('-')
      const [questionnaireId, periodDayId] = values.length === 2
        ? values.map(parseIntOrNull)
        : [undefined, undefined]

      return entry.questionnaireId === questionnaireId && entry.periodDayId === periodDayId
    })
  }

  onKeyDown = (event: KeyboardEvent) => {

    if (event.key === shiftKey) {
      this.setState({holdingShift: true})
    }
  }

  onKeyUp = (event: KeyboardEvent) => {

    if (event.key === shiftKey) {
      this.setState({holdingShift: false})
    }
  }

  getSelectionEntry({questionnaireId, periodDayId}: IndexMapEntry) {
    return `${questionnaireId}-${periodDayId}`
  }

  getSelectedListItems = (idx: number, isSelected: boolean): List<IndexMapEntry> => {

    const {criteria} = this.props
    const {holdingShift, lastSelectedIdx, listItems} = this.state

    if (holdingShift && !isSelected && !_.isNil(lastSelectedIdx)) {
      const newIndexes = getIndexRange(idx, lastSelectedIdx)

      return listItems
        .filter((entry, index) => {
          return newIndexes.indexOf(index) !== -1 || criteria.selected.contains(this.getSelectionEntry(entry))
        }) as List<IndexMapEntry>
    }

    if (isSelected) {

      return listItems
        .filter((entry, index) => {
          return index !== idx && criteria.selected.contains(this.getSelectionEntry(entry))
        }) as List<IndexMapEntry>
    }

    return listItems
      .filter((entry, index) => {
        return index === idx || criteria.selected.contains(this.getSelectionEntry(entry))
      }) as List<IndexMapEntry>
  }

  onSelectionChange = (idx: number) => {

    const isSelected = this.isSelected(idx)
    const selected = this.getSelectedListItems(idx, isSelected).map(this.getSelectionEntry)
    const lastSelectedIdx = isSelected ? undefined : idx

    this.setState({lastSelectedIdx})
    this.props.updateCriteria(this.props.criteria.set('selected', selected))
  }

  getListItems = (props): List<IndexMapEntry> => {
    const {questionnaires, periods} = props

    return List(periods.getPeriodDayIdsWithQuestionnairesOrderedByPeriodDay(questionnaires)
      .map((periodDayId) => {

        return questionnaires.getQuestionnairesByPeriodDay(periodDayId)
          .map((questionnaire) => {

            return {questionnaireId: questionnaire.id, periodDayId}
          })
      }))
      .reduce((accu: List<IndexMapEntry>, arr: List<IndexMapEntry>) => {
        return accu.concat(arr)
      }, List()) as List<IndexMapEntry>
  }

  renderListItems = () => {
    const {navigate, questionnaires, deleteQuestionnaire, periods, t} = this.props
    const {listItems} = this.state

    return listItems
      .map(({questionnaireId, periodDayId}, index) => {

        const questionnaire = questionnaires.getModelById(questionnaireId)

        return (
          <QuestionnaireListItem
            key={`questionnaire_item_${index}`}
            index={index}
            periods={periods}
            periodDayId={periodDayId}
            questionnaire={questionnaire}
            selected={this.isSelected(index)}
            onSelectionChange={this.onSelectionChange}
            navigate={navigate}
            deleteQuestionnaire={deleteQuestionnaire}
            t={t}
          />
        )
      })
  }

  getSelectedQuestionnaireIds = (): List<number> => {

    const {criteria} = this.props

    return criteria.selected.map(value => {
      const values = value.split('-')
      return values && values.length >= 1 ? parseIntOrNull(values[0]) : undefined
    })
      .filter(value => !!value)
      .toSet()// unique entries
      .toList()
  }

  onAddClick = () => {
    const {navigate} = this.props
    navigate(`/admin/questionnaires/new`)
  }

  renderImportButton = () => {
    const {t, importQuestionnaires} = this.props
    const content = ({getRootProps, getInputProps}) => (
      <button
        {...getRootProps({
          className: 'dropzone btn btn-default import-questionnaire'
        })}>
        <input {...getInputProps()} />
        <i className='fa fa-upload '/>
        {t('questionnaire.import.title')}
      </button>
    )

    const onDropAccepted = (files) => {
      importQuestionnaires(files[0])
    }

    const onDropRejected = (_files) => {
      error(this.props.t('questionnaire.import.error'))
    }

    return (
      <Dropzone multiple={false}
                accept={ZipMimeTypes}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}>{content}</Dropzone>
    )
  }
  onExportClick = () => {

    this.props.exportQuestionnaires(this.getSelectedQuestionnaireIds())
  }

  onPreviewClick = () => {

    const {criteria} = this.props
    const params = criteria.getQueryParams()
    const urlParams = _.isEmpty(params) ? '' : `?${qs.stringify(params)}`

    this.props.navigate(`/admin/questionnaires/preview${urlParams}`)
  }

  onDragEnd = (result) => {

    if (!result.destination) {
      return
    }

    const {changeOrder, questionnaires} = this.props
    const srcIdx = result.source.index
    const dstIdx = result.destination.index

    if (srcIdx !== dstIdx) {

      const srcQuestionnaire = questionnaires.getModelByIndex(srcIdx)
      const dstQuestionnaire = questionnaires.getModelByIndex(dstIdx)

      const {id, order} = srcQuestionnaire

      changeOrder(id, order, dstQuestionnaire.order)
    }
  }

  renderHeaderButtons = () => {

    const {t, criteria} = this.props
    const isActionDisabled = criteria.selected.isEmpty()

    return (
      <div className='pull-right header-btns'>
        <button className='add-questionnaire'
                onClick={this.onAddClick}>
          {t('questionnaire.add')}
        </button>
        {this.renderImportButton()}
        <button className='export-questionnaires'
                disabled={isActionDisabled}
                onClick={this.onExportClick}>
          <i className='fa fa-download export'/> {t('questionnaire.export')}
        </button>

        <button className='preview-questionnaires'
                disabled={isActionDisabled}
                onClick={this.onPreviewClick}>
          <i className='fa fa-search preview'/> {t('questionnaire.tabs.preview')}
        </button>
      </div>
    )
  }

  render() {
    const {t} = this.props
    const droppableContent = (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
      return (
        <ul ref={provided.innerRef} className='list-group' style={getListStyle(snapshot.isDraggingOver)}>
          {this.renderListItems()}
        </ul>
      )
    }

    return (
      <div className='questionnaire-management-view'>
        <div className='questionnaire-list-header'>
          <h1 className='questionnaire-list-title'>{t('questionnairesViewTitle')}</h1>
          {this.renderHeaderButtons()}
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable key='question-droppable' droppableId='questionDrop'>
            {droppableContent}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

const mapActionToProps = {
  getPeriods: Periods.getModels,
  getQuestionnaires: Questionnaires.getModels,
  deleteQuestionnaire: Questionnaire.deleteModel,
  navigate,
  updateCriteria: Questionnaires.updateCriteria,
  changeOrder: Questionnaires.changeOrder,
  importQuestionnaires: Questionnaires.import,
  exportQuestionnaires: Questionnaires.exportQuestionnaires,
  pollDownload,
  downloadResource
}

const mapStateToProps = ({questionnaires, authenticatedUser, download, periods}, ownProps) => {

  const {location} = ownProps
  const criteria = CriteriaModel.fromQuery(location.query)

  return {
    authenticatedUser,
    questionnaires,
    periods,
    download,
    criteria,
    location
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateCriteria: (criteria: Criteria) => dispatchProps.updateCriteria(ownProps.location, criteria)
  }
}

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionToProps,
    mergeProps
  )(QuestionnairesView)
)
