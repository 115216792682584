/**
 * Created by Mauritz Untamala on 26/12/15.
 */
import React from 'react'
import BaseCriteria, {BaseProps, BaseState} from '../../../components/BaseCriteria'

interface Props extends BaseProps {}

interface State extends BaseState {}

export default class Criteria extends BaseCriteria<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  getCriteriaForm = () => {
    return (
      <div key='criteria-form' className='form-horizontal'>
        {this.createSelectCriteria('users', this.getLabel, this.onSelectedUsersChange)}
        {this.createDateRangeCriteria()}
      </div>
    )
  }

  render() {
    return (
      <div className='criteria panel panel-default'>
        <div className='panel-body'>
          {this.getCriteriaForm()}
          {this.props.children}
        </div>
      </div>
    )
  }
}
