import AdverseEvents from '../models/AdverseEvents'
import queryString from 'querystring'
import {navigate} from './Location'
import ModelsModule from './ModelsModule'
import AdverseEvent from './AdverseEvent'

const initialState = new AdverseEvents()

class AdverseEventsModule extends ModelsModule {
  constructor() {
    super('event/adverse', initialState)
  }

  public updateAdverseEventCriteria(criteria) {
    return (dispatch) => {
      const queryParams = criteria.getQueryParams()
      const query = queryString.stringify(criteria.getQueryParams())
      dispatch(navigate('/admin/adverse-events' + (query.length > 0 ? '?' + query : ''), true))

      return dispatch(this.getModels(queryParams, true))
    }
  }

  protected getAdditionalActionHandlers() {
    return {
      [AdverseEvent.getCreateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [AdverseEvent.getUpdateModelSuccessType()]: (state, {payload}) =>
        state.addModelFromJS(payload),
      [AdverseEvent.getDeleteModelSuccessType()]: (state, {payload}) =>
        state.removeModelWithId(payload.id)
    }
  }
}

export default new AdverseEventsModule()
